import React, { useRef, useState } from 'react';
import { Table, Dropdown, Menu, Button, Image, Modal, Select, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { HiOutlineDotsVertical } from "react-icons/hi";
import axios from 'axios';
import redbubble from '../../../../../assets/red-circle.svg';
import greenbubble from '../../../../../assets/green-circle.svg';
import { IoArrowForward, IoEyeOutline, IoEyeSharp } from 'react-icons/io5';
import { LoadingOutlined } from '@ant-design/icons';
import { FaHourglass } from "react-icons/fa6";
import flagPortugal from '../../../../../assets/flags/flag-portugal.svg';
import flagSpain from '../../../../../assets/flags/flag-spain.svg';

const { Option } = Select;


const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);

const MenuComponent = ({ proposta, currentLanguage, saveToken, iDTokenAuthenticated, dealsData, setPropostasDealsData }) => {

    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [statusDaProposta, setStatusDaProposta] = useState(null);
    const [loading, setLoading] = useState(false);


    const handleAccept = () => setIsModalOpen(true);
    const handleOk = () => setIsModalOpen(false);
    const handleCancel = () => setIsModalOpen(false);

    const fetchDealsData = () => {
        const url = `${process.env.REACT_APP_GET_PROCURAR_DEALS}?token=${saveToken}&user_id=${iDTokenAuthenticated}&id_deal=${dealsData.id}&lang=${currentLanguage}&live`;
        axios.get(url)
            .then((response) => {
                setPropostasDealsData(response.data.deal.propostas);
            })
            .catch((error) => {
                console.error('Error occurred:', error);
            });
    };

    const handleChangeEstadoDaProposta = (value) => {
        setStatusDaProposta(value);
        setLoading(true)

        const dataFecharProposta = {
            id_proposta: proposta.id_proposta,
            id_deal: dealsData.id,
            deal_hubspot_fechado: value,
            user_id: iDTokenAuthenticated,
            user_token: saveToken,
        };

        axios.post(`${process.env.REACT_APP_PROPOSTA_FECHADA}?lang=${currentLanguage}`, dataFecharProposta)
            .then((response) => {
                if (response.data.sucesso) {
                    fetchDealsData();
                    setLoading(false)
                    setTimeout(() => {
                        setIsModalOpen(false);
                    }, 1500)
                } else {
                    setLoading(false)
                    console.error('Failed to update proposal status');
                }
            })
            .catch((error) => {
                console.error(error);
            });

    };


    return (
        <>
            <Menu>
                <Menu.Item key="view">
                    <Link className='flex gap-2 items-center font-semibold' to={`/${currentLanguage}/simulador/${proposta.expirou == 0 ? `?${t('propostas.nome_proposta_link')}` : `?${t('propostas.id_proposta_link')}`}=${proposta.expirou == 0 ? proposta.url_acesso : proposta.unique_id}`}><IoEyeSharp size={16} /> Ver</Link>
                </Menu.Item>
                <Menu.Item key="accept" >
                    <a className='flex gap-2 items-center font-semibold' href="#" onClick={() => handleAccept(proposta.id_proposta)}><FaCheckCircle /> Alterar estado </a>
                </Menu.Item>
            </Menu>
            <Modal
                title={'Alterar estado da Proposta'}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Fechar"
                footer={null}
            >
                <div className='flex flex-col gap-4'>

                    <div>
                        {loading ? (
                            <div className='flex items-center gap-5 flex-col justify-center text-md p-2.5'>
                                <Spin indicator={antIcon} />
                                <p className='text-black'>Aguarde por favor</p>
                            </div>
                        ) : (
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Selecione o estado"
                                onChange={handleChangeEstadoDaProposta}
                                value={statusDaProposta}
                            >
                                <Option value="1">Proposta Aceite</Option>
                                <Option value="0">Proposta em Aberto</Option>
                            </Select>
                        )}

                    </div>
                    <hr></hr>

                    <div className='grid'>
                        <h2 className='font-semibold text-base pb-3'>Informações da Proposta</h2>
                        <div className='flex items-center justify-between'>
                            <p className="text-black text-sm flex justify-between">Estado</p>
                            <span className={`font-bold text-end ${proposta.deal_hubspot_fechado === "1" ? 'text-verde' : 'text-laranja'}`}>
                                {proposta.deal_hubspot_fechado === "1" ? 'Proposta Aceite' : 'Proposta em Aberto'}
                            </span>
                        </div>
                        <div className='flex items-center justify-between'>
                            <p className="text-black text-sm flex justify-between">Criado por</p>
                            <span className="font-bold capitalize text-end">{proposta.nome_criou}</span>
                        </div>
                        <div className='flex items-center justify-between'>
                            <p className="text-black text-sm flex justify-between">País</p>
                            <span className="font-bold uppercase text-end">{proposta.pais}</span>
                        </div>
                        <div className='flex items-center justify-between'>
                            <p className="text-black text-sm flex justify-between">Nome da Proposta</p>
                            <span className="font-bold capitalize text-end">{proposta.nome_proposta}</span>
                        </div>
                        <div className='flex items-center justify-between'>
                            <p className="text-black text-sm flex justify-between">Data criação</p>
                            <span className="font-bold capitalize text-end">{proposta.data_criacao}</span>
                        </div>
                    </div>

                </div>

            </Modal>
        </>



    );
};


export default function PropostasDoDealHubspot({
    propostasDealsData,
    currentLanguage,
    formatarNumero,
    idDoDeal,
    selectedRowKeys,
    setSelectedRowKeys,
    iDTokenAuthenticated,
    saveToken,
    dealsData,
    setPropostasDealsData
}) {


    const { t } = useTranslation();


    return (
        <>
            <div className='card grid gap-4'>
             
                    <div className='flex gap-2 items-center justify-between'>
                        <p className='text-lg font-bold'>Lista das Proposta Associadas</p>
                    </div>

                <div className="w-full grid gap-4">
                    <div className="tabela-propostas">
                        <Table
                            className='hubspot-content'
                            dataSource={propostasDealsData}
                            rowKey={(propostasDealsData) => propostasDealsData.id_proposta}
                            pagination={false}
                            bordered
                        >

                            <Table.Column
                                title="País"
                                key="pais"
                                width={70}
                                render={(proposta) => (
                                    proposta.pais === "pt" ? (
                                        <img
                                            src={flagPortugal}
                                            alt="Portugal"
                                            style={{ marginRight: '8px', width: '35px', height: '22px' }}
                                        />
                                    ) : (
                                        <img
                                            src={flagSpain}
                                            alt="Spain"
                                            style={{ marginRight: '8px', width: '35px', height: '22px' }}
                                        />
                                    )
                                )}

                            />

                            <Table.Column
                                title={t('propostas.criado_por')}
                                key="criado_por"
                                width={150}
                                fixed={"left"}
                                render={(proposta) => (
                                    <span>{proposta.nome_criou}</span>
                                )}
                            />



                            <Table.Column
                                title={t('propostas.nome_da_proposta')}
                                key="pais"
                                render={(proposta) => (
                                    <Link to={`/${currentLanguage}/simulador/${proposta.expirou == 0 ? `?${t('propostas.nome_proposta_link')}` : `?${t('propostas.id_proposta_link')}`}=${proposta.expirou == 0 ? proposta.url_acesso : proposta.unique_id}`}>{proposta.nome_proposta}</Link>
                                )}

                            />

                            <Table.Column
                                title="Estado da Proposta"
                                key="estado"
                                render={(proposta) => {
                                    if (proposta.estado_proposta_hubspot === 'proposta_aceite') {
                                        return (
                                            <div className="rounded-full bg-verde text-white px-3 w-auto inline-flex items-center gap-2">
                                                <FaCheckCircle />
                                                Proposta Aceite
                                            </div>
                                        );
                                    } else if (proposta.estado_proposta_hubspot === 'aceitou_outra_proposta') {
                                        return (
                                            <div className="rounded-full bg-yellow-500 text-black px-3 w-auto inline-flex items-center gap-2">
                                                <FaTimesCircle />
                                                Aceitou outra Proposta
                                            </div>
                                        );
                                    } else if (proposta.estado_proposta_hubspot === 'proposta_aberta') {
                                        return (
                                            <div className="rounded-full bg-blue-500 text-white px-3 w-auto inline-flex items-center gap-2">
                                                <FaHourglass />
                                                Proposta em Aberto
                                            </div>
                                        );
                                    }
                                    return null; // Ou um valor padrão, caso não bata com nenhum tipo
                                }}
                            />

                            <Table.Column
                                title="Data de Criação"
                                key="data_criacao"
                                width={150}
                                render={(proposta) => (
                                    <span>{proposta.data_criacao}</span>
                                )}
                            />

                            <Table.Column
                                title={t('ultimas_pesquisas.imagem_viatura')}
                                className='text-center'
                                width={150}
                                render={(proposta) => (
                                    <Image
                                        style={{ textAlign: 'center' }}
                                        width={60}
                                        src={proposta.imagens?.[0]}
                                    />
                                )}
                            />



                            {/* <Table.Column
                                title="Proposta Aceite?"
                                key="estado"
                                className='text-center'
                                render={(proposta) => (
                                    proposta.deal_hubspot_fechado === "1" ? (
                                        <span className='flex justify-center'>
                                            <img style={{ height: '20px' }} src={greenbubble} alt="Green Bubble" />
                                        </span>
                                    ) : (
                                        <span className='flex justify-center'>
                                            <img style={{ height: '20px' }} src={redbubble} alt="Red Bubble" />
                                        </span>

                                    )
                                )}
                            /> */}

                            {/* <Table.Column
                                title={false}
                                key="select"
                                width={100}
                                fixed={'left'}
                                className='text-center'
                                render={(proposta) => (
                                    <Dropdown overlay={<MenuComponent setPropostasDealsData={setPropostasDealsData} dealsData={dealsData} proposta={proposta} currentLanguage={currentLanguage} saveToken={saveToken} iDTokenAuthenticated={iDTokenAuthenticated} />} trigger={['click']}>
                                        <Button className="bg-white p-2">
                                            <HiOutlineDotsVertical />
                                        </Button>
                                    </Dropdown>
                                )}
                            /> */}

                        </Table>
                    </div>
                </div>
            </div>
        </>
    );
}
