
import { BiCheckCircle, BiError } from "react-icons/bi";
import { useTranslation } from 'react-i18next';

export function AlertaHubspotMsg(props) {

    // console.log('props',props)

    const { t } = useTranslation();

    return (
        <div className="alertas-hubspot flex flex-col gap-2">
            {props.propsalerta.map((item, index) => (
                <div key={index} className={`flex flex-row items-center gap-4 rounded ${item.sucesso ? 'mensagem-de-sucesso' : 'mensagem-de-erro'}`} >
                    {item.sucesso ? (
                        <>
                            <BiCheckCircle />
                            <span>
                                <strong className="normal-case">Sucesso</strong>, {item.mensagem}.
                            </span>
                        </>
                    ) : (
                        <>
                            <BiError />
                            <span>
                                <strong className="normal-case">{t('mensagem.alerta')}</strong>, {item.mensagem}.
                            </span>
                        </>
                    )}
                </div>
            ))}
        </div>
    );
}
