import React, { useState } from "react";
import { Input, DatePicker, InputNumber, Space } from "antd";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);


export default function CompModelo9Particular({

    nomeEmpresa,
    setNomeEmpresa,
    sedeEmpresa,
    setSedeEmpresa,
    nifEmpresa,
    setNifEmpresa,
    nomeIndividual,
    setNomeIndividual,
    moradaIndividual,
    setMoradaIndividual,
    residenciaParticular,
    setResidenciaParticular,
    codigoPostalParticular,
    setCodigoPostalParticular,
    nifParticular,
    setNifParticular,
    telemovelInterno,
    setTelemovelInterno,
    emailInterno,
    setEmailInterno,
    numeroCCIndividual,
    setNumeroCCIndivual,
    validadeCCIndivual,
    setValidadeCCIndividual,
    numeroCertidaoPermanente,
    setNumeroCertidaoPermanente,

    marcaViatura,
    setMarcaViatura,
    modeloViatura,
    setModeloViatura,
    vinViatura,
    setVinViatura,
    matriculaViatura,
    setMatriculaViatura,
    categoriaViatura,
    setCategoriaViatura,
    tipoViatura,
    setTipoViatura,
    combustivelViatura,
    setCombustivelViatura,
    cilindradaViatura,
    setCilindradaViatura,
    matriculaAnteriorViatura,
    setMatriculaAnteriorViatura,
    dataPrimeiraMatriculaViatura,
    setDataPrimeiraMatriculaViatura,
    paisOrigemViatura,
    setPaisOrigemViatura,
    paisEmissorIndivual,
    setPaisEmissorIndividual,

    idDoNegocio,
    setIDdoNegocio,
    tipo,
    tipoSociedade,
    tipoContrato,
    dataDoDocumento,
    setDataDoDocumento,
    alertaDataDocumento
}) {

    const [codigoPostalParticularInicio, setCodigoPostalParticularInicio] = useState('');
    const [codigoPostalParticularFim, setCodigoPostalParticularFim] = useState('');



    const dayjsDate = (date) => {
        if (date && date !== '') {
            return dayjs(date, 'DD/MM/YYYY')
        }
    }

    const handleDataChange = (e) => {
        let day = "";
        let month = "";
        let year = "";

        if (e && e instanceof Object && e.hasOwnProperty('$y') && e.hasOwnProperty('$M') && e.hasOwnProperty('$D')) {
            // Extract day, month, and year
            day = e.$D.toString().padStart(2, '0');
            month = (e.$M + 1).toString().padStart(2, '0'); // Month is zero-based
            year = e.$y.toString();
        }

        setDataDoDocumento(day + '/' + month + '/' + year);
        // console.log(dataDoDocumento)
    };


    const handleNomeIndividualChange = (e) => {
        setNomeIndividual(e.target.value);
    };

    const handleMoradaIndividualChange = (e) => {
        setMoradaIndividual(e.target.value);
    };

    const handleResidenciaParticularChange = (e) => {
        setResidenciaParticular(e.target.value);
    };


    const handleCodigoPostalParticularChange = (value, part) => {
        if (value === "") {
          setCodigoPostalParticular('');
          setCodigoPostalParticularFim('');
          setCodigoPostalParticularInicio('');
        } else {
          if (part === 'inicio') {
            setCodigoPostalParticularInicio(value);
          } else if (part === 'fim') {
            setCodigoPostalParticularFim(value);
          }
      
          // Calcular o código postal formatado com base nos valores atuais
          const codigoPostalFormatado = `${part === 'inicio' ? value : codigoPostalParticularInicio}-${part === 'fim' ? value : codigoPostalParticularFim}`;
          setCodigoPostalParticular(codigoPostalFormatado);
        }
      };
      

    const handleNifParticularChange = (e) => {
        setNifParticular(e.target.value);
    };

    const handleTelemovelInternoChange = (e) => {
        setTelemovelInterno(e.target.value);
    };

    const handleEmailInternoChange = (e) => {
        setEmailInterno(e.target.value);
    };


    const handleNumeroCCIndivualChange = (e) => {
        setNumeroCCIndivual(e.target.value);
    };


    const handleValidadeCCIndividualChange = (e) => {

        let day = "";
        let month = "";
        let year = "";

        if (e && e instanceof Object && e.hasOwnProperty('$y') && e.hasOwnProperty('$M') && e.hasOwnProperty('$D')) {
            // Extract day, month, and year
            day = e.$D.toString().padStart(2, '0');
            month = (e.$M + 1).toString().padStart(2, '0'); // Month is zero-based
            year = e.$y.toString();
        }

        setValidadeCCIndividual(day + '/' + month + '/' + year);
        // console.log(dataDoDocumento)
    };


    const handleMarcaViaturaChange = (e) => {
        setMarcaViatura(e.target.value);
    };

    const handleModeloViaturaChange = (e) => {
        setModeloViatura(e.target.value);
    };

    const handleVinViaturaChange = (e) => {
        setVinViatura(e.target.value);
    };

    const handleMatriculaViaturaChange = (e) => {
        setMatriculaViatura(e.target.value);
    };


    const handleCategoriaViaturaChange = (e) => {
        setCategoriaViatura(e.target.value);
    };


    const handleTipoViaturaChange = (e) => {
        setTipoViatura(e.target.value);
    };

    const handleCombustivelViaturaChange = (e) => {
        setCombustivelViatura(e.target.value);
    };


    const handleCilindradaViaturaChange = (e) => {
        setCilindradaViatura(e.target.value);
    };


    const handleMatriculaAnteriorViaturaChange = (e) => {
        setMatriculaAnteriorViatura(e.target.value);
    };


    const handleDataPrimeiraMatriculaViaturaChange = (e) => {

        let day = "";
        let month = "";
        let year = "";

        if (e && e instanceof Object && e.hasOwnProperty('$y') && e.hasOwnProperty('$M') && e.hasOwnProperty('$D')) {
            // Extract day, month, and year
            day = e.$D.toString().padStart(2, '0');
            month = (e.$M + 1).toString().padStart(2, '0'); // Month is zero-based
            year = e.$y.toString();
        }

        setDataPrimeiraMatriculaViatura(day + '/' + month + '/' + year);

    };


    const handlePaisOrigemViaturaChange = (e) => {
        setPaisOrigemViatura(e.target.value);
    };



    const handleNomeEmpresaChange = (e) => {
        setNomeEmpresa(e.target.value);
    };

    const handleSedeEmpresaChange = (e) => {
        setSedeEmpresa(e.target.value);
    };

    const handleNifEmpresaChange = (e) => {
        setNifEmpresa(e.target.value);
    };

    const handleIDdoNegocioChange = (e) => {
        setIDdoNegocio(e.target.value);
    };

    const handleNumeroCertidaoPermanenteChange = (e) => {
        setNumeroCertidaoPermanente(e.target.value);
    };


    const handlePaisEmissorChange = (e) => {
        // console.log(e.target.value)
        setPaisEmissorIndividual(e.target.value);
    };


    // console.log(codigoPostalParticular)

    return (
        <>
            <div className="grid grid-cols-2 gap-2">

                <div className='grid gap-2'>
                    <p>ID do negócio</p>
                    <Input
                        placeholder="ID do negócio"
                        value={idDoNegocio}
                        onChange={handleIDdoNegocioChange}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>Data do documento</p>
                    <DatePicker placeholder="dd/mm/aaaa"
                        defaultValue={dayjsDate(dataDoDocumento)}
                        value={dayjsDate(dataDoDocumento)}
                        format={'DD/MM/YYYY'}
                        // picker="month"
                        onChange={handleDataChange}
                        required
                    />
                    {alertaDataDocumento && <small className="text-red-500">A data do documento é obrigatória</small>}
                </div>

                <div className='grid gap-2'>
                    <p>Entidade emissora</p>
                    <Input
                        placeholder="Entidade emissora"
                        value={paisEmissorIndivual}
                        onChange={handlePaisEmissorChange}
                        type="text"
                        required
                    />
                </div>
            </div >

            {tipoSociedade === "empresa" ? (
                <>
                    <p className="font-semibold">Detalhes da Empresa:</p>

                    <div className="grid grid-cols-2 gap-2">
                        <div className='grid gap-2'>
                            <p>Nome da Empresa</p>
                            <Input
                                placeholder="Nome da Empresa"
                                value={nomeEmpresa}
                                onChange={handleNomeEmpresaChange}
                                type="text"
                                required
                            />
                        </div>

                        <div className='grid gap-2'>
                            <p>NIF da Empresa</p>
                            <Input
                                placeholder="NIF da Empresa"
                                value={nifEmpresa}
                                onChange={handleNifEmpresaChange}
                                type="text"
                                required
                            />
                        </div>

                        <div className='grid gap-2'>
                            <p>Nº Certidão permantente</p>
                            <Input
                                placeholder="Nº Certidão permantente"
                                value={numeroCertidaoPermanente}
                                onChange={handleNumeroCertidaoPermanenteChange}
                                type="text"
                                required
                            />
                        </div>

                        <div className='grid gap-2'>
                            <p>Sede da Empresa</p>
                            <Input
                                placeholder="Sede da Empresa"
                                value={sedeEmpresa}
                                onChange={handleSedeEmpresaChange}
                                type="text"
                                required
                            />
                        </div>
                    </div>
                </>
            ) : null
            }


            <p className="font-semibold">Detalhes do Cliente:</p>

            <div className="grid grid-cols-2 gap-2">

                {tipoSociedade === "particular" ? (
                    <>
                        <div className='grid gap-2'>
                            <p>Nome do cliente</p>
                            <Input
                                placeholder="Nome do cliente"
                                value={nomeIndividual}
                                onChange={handleNomeIndividualChange}
                                type="text"
                                required
                            />
                        </div>

                        <div className='grid gap-2'>
                            <p>NIF do cliente</p>
                            <Input
                                placeholder="NIF do cliente"
                                value={nifParticular}
                                onChange={handleNifParticularChange}
                                type="text"
                                required
                            />
                        </div>
                    </>
                ) : null}
                <div className='grid gap-2'>
                    <p>Telemóvel interno</p>
                    <Input
                        placeholder="Telemóvel interno"
                        value={telemovelInterno}
                        onChange={handleTelemovelInternoChange}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>Email interno</p>
                    <Input
                        placeholder="Email interno"
                        value={emailInterno}
                        onChange={handleEmailInternoChange}
                        type="text"
                        required
                    />
                </div>

                {tipoSociedade === "particular" ? (
                    <>
                        <div className='grid gap-2'>
                            <p>Morada do cliente</p>
                            <Input
                                placeholder="Morada do cliente"
                                value={moradaIndividual}
                                onChange={handleMoradaIndividualChange}
                                type="text"
                                required
                            />
                        </div>

                        <div className='grid gap-2'>
                            <p>Nº de CC do cliente</p>
                            <Input
                                placeholder="Nº de CC do cliente"
                                value={numeroCCIndividual}
                                onChange={handleNumeroCCIndivualChange}
                                type="text"
                                required
                            />
                        </div>

                        <div className='grid gap-2'>
                            <p>Validade CC do cliente</p>
                            <DatePicker placeholder="dd/mm/aaaa"
                                defaultValue={dayjsDate(validadeCCIndivual)}
                                value={dayjsDate(validadeCCIndivual)}
                                format={'DD/MM/YYYY'}
                                onChange={handleValidadeCCIndividualChange}
                                required
                            />
                            {alertaDataDocumento && <small className="text-red-500">A validade CC do cliente é obrigatória</small>}
                        </div>
                    </>
                ) : null}

                <div className='grid gap-2'>
                    <p>Localidade do cliente</p>
                    <Input
                        placeholder="Morada do cliente"
                        value={residenciaParticular}
                        onChange={handleResidenciaParticularChange}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>Codigo postal do cliente</p>
                    <Space.Compact>
                        <Input
                            style={{
                                width: '40%',
                            }}
                            placeholder="XXXX"
                            value={codigoPostalParticularInicio}
                            onChange={(e) => handleCodigoPostalParticularChange(e.target.value, 'inicio')}
                            type="text"
                            required
                        />
                        <Input
                            style={{
                                width: '40%',
                            }}
                            placeholder="XXX"
                            value={codigoPostalParticularFim}
                            onChange={(e) => handleCodigoPostalParticularChange(e.target.value, 'fim')}
                            type="text"
                            required
                        />
                        <Input
                            style={{
                                width: '100%',
                            }}
                            value={codigoPostalParticular}
                            readOnly
                            disabled
                        />
                    </Space.Compact>
                </div>



            </div>

            <p className="font-semibold">Detalhes da Viatura:</p>

            <div className="grid grid-cols-2 gap-2">
                <div className='grid gap-2'>
                    <p>Marca da viatura</p>
                    <Input
                        placeholder="Marca da viatura"
                        value={marcaViatura}
                        onChange={handleMarcaViaturaChange}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>Modelo da viatura</p>
                    <Input
                        placeholder="Modelo da viatura"
                        value={modeloViatura}
                        onChange={handleModeloViaturaChange}
                        type="text"
                        required
                    />
                </div>


                <div className='grid gap-2'>
                    <p>VIN da viatura</p>
                    <Input
                        placeholder="VIN da viatura"
                        value={vinViatura}
                        onChange={handleVinViaturaChange}
                        type="text"
                        required
                    />
                </div>

                {/* <div className='grid gap-2'>
                    <p>Matrícula da viatura</p>
                    <Input
                        placeholder="XX-AA-XX"
                        value={matriculaViatura}
                        onChange={handleMatriculaViaturaChange}
                        type="text"
                        required
                    />
                </div> */}



                <div className='grid gap-2'>
                    <p>Categoria da viatura</p>
                    <Input
                        placeholder="Categoria da viatura"
                        value={categoriaViatura}
                        onChange={handleCategoriaViaturaChange}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>Tipo da viatura</p>
                    <Input
                        placeholder="Tipo da viatura"
                        value={tipoViatura}
                        onChange={handleTipoViaturaChange}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>Combustivel da viatura</p>
                    <Input
                        placeholder="Combustivel da viatura"
                        value={combustivelViatura}
                        onChange={handleCombustivelViaturaChange}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>Cilindrada da viatura</p>
                    <Input
                        placeholder="Cilindrada da viatura"
                        value={cilindradaViatura}
                        onChange={handleCilindradaViaturaChange}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>Matrícula anterior da viatura</p>
                    <Input
                        placeholder="XX-AA-XX"
                        value={matriculaAnteriorViatura}
                        onChange={handleMatriculaAnteriorViaturaChange}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>Data da primeira matrícula da viatura</p>
                    <DatePicker placeholder="dd/mm/aaaa"
                        defaultValue={dayjsDate(dataPrimeiraMatriculaViatura)}
                        value={dayjsDate(dataPrimeiraMatriculaViatura)}
                        format={'DD/MM/YYYY'}
                        onChange={handleDataPrimeiraMatriculaViaturaChange}
                        required
                    />
                    {alertaDataDocumento && <small className="text-red-500">A data da primeira matrícula da viatura é obrigatória</small>}
                </div>

                <div className='grid gap-2'>
                    <p>País de origem da viatura</p>
                    <Input
                        placeholder="País de origem da viatura"
                        value={paisOrigemViatura}
                        onChange={handlePaisOrigemViaturaChange}
                        type="text"
                        required
                    />
                </div>

            </div >
        </>
    )
}

