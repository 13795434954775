import React, { useState, useRef } from 'react';
import { Table } from 'antd';
import { BiError } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function PropostasDoContactoHubspot({
    propostasDealsData,
    currentLanguage
}) {

    const [pesquisaEmailDeal, setPesquisaEmailDeal] = useState('');
    const inputRef = useRef(null);
    const { t } = useTranslation();

    return (
        <>
            <div className='card'>
                <div className='titulo-form'>
                    <p>Todas as Propostas</p>
                </div>
                <div className="grid gap-4 content-start popup-associar">
                    {propostasDealsData.length > 0 ? (
                        <>
                            <div>
                                <Table
                                    dataSource={propostasDealsData}
                                    rowKey={(deal) => deal.id_proposta}
                                    scroll={{ x: 'max-content' }}
                                    bordered
                                >

                                    <Table.Column
                                        title="Criado por"
                                        key="criado_por"
                                        render={(deal) => (
                                            <span>{deal.nome_criou}</span>
                                        )}
                                    />


                                    <Table.Column
                                        title="País"
                                        key="pais"
                                        render={(deal) => (
                                            <span className='uppercase'>{deal.pais}</span>
                                        )}

                                    />


                                    <Table.Column
                                        title="Nome da proposta"
                                        key="nome_propostas"
                                        render={(deal) => (
                                            <Link to={`/${currentLanguage}/simulador/${deal.expirou == 0 ? `?${t('propostas.nome_proposta_link')}` : `?${t('propostas.id_proposta_link')}`}=${deal.expirou == 0 ? deal.url_acesso : deal.unique_id}`}>{deal.nome_proposta}</Link>
                                        )}
                                    />

                                    <Table.Column
                                        title="Nome do deal"
                                        key="nome_propostas"
                                        render={(deal) => (
                                            <span>{deal.dealname}</span>
                                        )}
                                    />

                                    <Table.Column
                                        title="Data"
                                        key="data_criacao"
                                        render={(deal) => (
                                            <span>{deal.data_criacao}</span>
                                        )}
                                    />

                                </Table>
                            </div>
                        </>
                    ) : propostasDealsData.length === 0 && (
                        <div className="alertas-hubspot">
                            <div className={`flex flex-row items-center gap-4 rounded mensagem-de-erro`} >
                                <BiError />
                                <span>
                                    <strong className="normal-case">{t('mensagem.alerta')}</strong>, o contacto selecionado não tem <b>propostas</b> associados.
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
