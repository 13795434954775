import React from "react";
import { List } from "antd"; // Não precisa importar Table se não estiver usando

export default function DetalhesLogDeEmail({ selectedRecord }) {

    const eventColors = {
        'Enviado': 'bg-blue-400',      // Azul para "Enviado"
        'Entregue': 'bg-green-400',    // Verde para "Entregue"
        'Falha': 'bg-red-400',         // Vermelho para "Falha"
        'Aberto': 'bg-yellow-400',     // Amarelo para "Aberto"
        'Clique': 'bg-purple-400',     // Roxo para "Clique"
        'Unsubscribe': 'bg-gray-400'   // Cinza para "Unsubscribe"
    };

    return (
        <>
            <div className="grid gap-4">
                <div className="grid gap-2">
                    <p><strong>De:</strong> {selectedRecord.from_email}</p>
                    <p><strong>Para:</strong> {selectedRecord.to_email}</p>
                    <p><strong>Assunto:</strong> {selectedRecord.subject}</p>
                    <p><strong>Template:</strong> {selectedRecord.template_name}</p>
                    <p><strong>País:</strong> {selectedRecord.pais}</p>

                </div>
                <hr />
                {selectedRecord.body !== "" && (
                    <>
                        <div
                            dangerouslySetInnerHTML={{ __html: selectedRecord.body }}
                            style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '10px', height: '600px', overflow: 'auto' }}
                        />
                        <hr />
                    </>
                )}
                <div>
                    <p><strong>Lista de Eventos:</strong></p>
                    <List
                        itemLayout="horizontal"
                        dataSource={selectedRecord.events}
                        renderItem={item => {
                            const colorClass = eventColors[item.nome_evento] || 'bg-gray-300'; // Classe de cor baseada no tipo de evento, padrão cinza se não houver correspondência
                            return (
                                <List.Item>
                                    <div className='grid grid-cols-2 gap-4 w-full'>
                                        <div className={`rounded-full w-fit	 ${colorClass} text-white px-3 inline-flex items-center`}>
                                            <span className='flex gap-2 items-center'>
                                                {item.nome_evento} {/* Aqui você deve usar item.type ou a propriedade que representa o tipo de evento */}
                                            </span>
                                        </div>
                                        <span className='text-black text-xs font-normal sm:text-sm'>Data do Evento: {item.data_evento}</span>
                                    </div>
                                </List.Item>
                            );
                        }}
                    />
                </div>
            </div>
        </>
    );
}
