import React from 'react';
import { message, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { FiExternalLink } from 'react-icons/fi';
import { MdModeEdit } from "react-icons/md";
import PartilharComCliente from './partilhar-com-cliente';
import axios from 'axios';

const { Option } = Select;


export default function TopBarPropostaAssociada({
    setIsModalEditarVisible,
    nomeDaProposta,
    tituloDoMobileDaViatura,
    linkPartilhavel,
    dataCarros,
    formatarNumero,
    valorFinalISV,
    activeTabKeyResultadoSimulacao,
    precoFinalCarro,
    taxaDeServico,
    valorChaveNaMaoParticular,
    precoFinalDoCarroSemIva,
    valorChaveNaMaoEmpresa,
    idDaPropostaEncontrada,
    setCurrent,
    estadoDaProposta,
    setEstadoDaProposta,
    iDTokenAuthenticated,
    saveToken
}) {

    const { t } = useTranslation();


    const showModalEditar = (id_user) => {
        setIsModalEditarVisible(true);
    };

    const handleEstadodaProposta = (value) => {

        // setEstadoDaProposta(value)

        const dataFecharProposta = {
            id_proposta: idDaPropostaEncontrada,
            deal_hubspot_fechado: value,
            user_id: iDTokenAuthenticated,
            token: saveToken,
        };

        axios.post(`${process.env.REACT_APP_PROPOSTA_FECHADA}`, dataFecharProposta)
            .then((response) => {
                console.log(response)
                if (response.data.sucesso) {
                    message.success(`${response.data.mensagem}`);
                    setEstadoDaProposta(response.data.estado_proposta)
                } else {
                    message.error(`${response.data.mensagem}`);
                    // setEstadoDaProposta(response.data.estado_proposta)
                }
            })
            .catch((error) => {
                message.error(`Erro. Tente novamente!`);
            });

    };



    return (
        <>

            <div className='flex items-center gap-4'>

                <PartilharComCliente
                    tituloDoMobileDaViatura={tituloDoMobileDaViatura}
                    linkPartilhavel={linkPartilhavel}
                    dataCarros={dataCarros}
                    formatarNumero={formatarNumero}
                    valorFinalISV={valorFinalISV}
                    activeTabKeyResultadoSimulacao={activeTabKeyResultadoSimulacao}
                    nomeDaProposta={nomeDaProposta}
                    precoFinalCarro={precoFinalCarro}
                    taxaDeServico={taxaDeServico}
                    valorChaveNaMaoParticular={valorChaveNaMaoParticular}
                    precoFinalDoCarroSemIva={precoFinalDoCarroSemIva}
                    valorChaveNaMaoEmpresa={valorChaveNaMaoEmpresa}
                />

                <label className="flex items-center gap-2 cursor-pointer">
                    <MdModeEdit
                        className="text-2xl text-white cursor-pointer"
                        title="Editar Proposta"
                        onClick={showModalEditar}
                    />
                    <p onClick={showModalEditar}>Editar Simulação</p>
                </label>

                <label className="flex items-center gap-2 cursor-pointer">
                    <FiExternalLink
                        className="text-2xl text-white cursor-pointer"
                        title="Abrir link externo"
                        onClick={() => window.open(linkPartilhavel, '_blank')}
                    />
                    <p onClick={() => window.open(linkPartilhavel, '_blank')}>Abrir</p>
                </label>



            </div>

            <div className='flex gap-4 items-center'>
                Estado da Proposta
                <Select
                    placeholder="Selecione"
                    defaultValue="0"
                    style={{ width: 230 }}
                    onChange={handleEstadodaProposta}
                    value={estadoDaProposta}
                >
                    <Option value="proposta_aberta">Proposta em aberto</Option>
                    <Option value="proposta_aceite">Proposta aceite</Option>
                    <Option value="aceitou_outra_proposta" disabled>Aceitou outra proposta</Option>
                </Select>
            </div>

        </>
    );
}
