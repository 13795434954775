
import React, { useState, useEffect } from 'react';
import { DatePicker, Input, Select } from 'antd';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ToWords } from 'to-words';

dayjs.extend(customParseFormat);

const { Option } = Select;


export default function CompCreditoParticular({
    dataDoDocumento,
    setDataDoDocumento,

    nomeIndividual,
    setNomeIndividual,
    residenciaParticular,
    setResidenciaParticular,
    nifParticular,
    setNifParticular,
    numeroPassaporteParticular,
    setNumeroPassaporteParticular,

    nomeSocioEmpresa,
    setNomeSocioEmpresa,
    nomeEmpresa,
    setNomeEmpresa,
    sedeEmpresa,
    setSedeEmpresa,
    nifEmpresa,
    setNifEmpresa,
    nifGerente,
    setNifGerente,
    setDataSociosQuatro,
    tipoSociedade,

    paisDeOrigem,
    setPaisDeOrigem,
    marcaViatura,
    setMarcaViatura,
    modeloViatura,
    setModeloViatura,
    vinViatura,
    setVinViatura,


    valorChaveNaMao,
    setValorChaveNaMao,
    valorChaveNaMaoExtenso,
    setValorChaveNaMaoExtenso,
    quantidaEntregueImportrust,
    setQuantidaEntregueImportrust,
    quantidaEntregueImportrustExtenso,
    setQuantidaEntregueImportrustExtenso,
    taxasDeJuros,
    setTaxasDeJuros,


    idDoNegocio,
    setIDdoNegocio,
    alertaDataDocumento,
}) {



    const [numSocios, setnumSocios] = useState('0');
    const [inputs, setInputs] = useState([]);

    const toWords = new ToWords({
        localeCode: 'pt-PT', // Especifica o locale para português de Portugal
    });



    const handleDataChange = (e) => {
        let day = "";
        let month = "";
        let year = "";

        if (e && e instanceof Object && e.hasOwnProperty('$y') && e.hasOwnProperty('$M') && e.hasOwnProperty('$D')) {
            // Extract day, month, and year
            day = e.$D.toString().padStart(2, '0');
            month = (e.$M + 1).toString().padStart(2, '0'); // Month is zero-based
            year = e.$y.toString();
        }

        setDataDoDocumento(day + '/' + month + '/' + year);
    };

    const dayjsDate = (date) => {
        if (date && date !== '') {
            return dayjs(date, 'DD/MM/YYYY')
        }
    }




    // //vendas - empresa

    // const handleMarcaDaViaturaChange = (e) => {
    //     setMarcaViatura(e.target.value);
    // };


    // const handleModeloDaViaturaChange = (e) => {
    //     setModeloViatura(e.target.value);
    // };




    // const handleVINDaViaturaChange = (e) => {
    //     setVinViatura(e.target.value);
    // };



    const handleInputChange = (setter) => (e) => {
        setter(e.target.value);
    };



    const unidades = ['zero', 'um', 'dois', 'três', 'quatro', 'cinco', 'seis', 'sete', 'oito', 'nove'];
    const dezenas = ['dez', 'onze', 'doze', 'treze', 'quatorze', 'quinze', 'dezasseis', 'dezassete', 'dezoito', 'dezanove'];
    const dezenas10 = ['vinte', 'trinta', 'quarenta', 'cinquenta', 'sessenta', 'setenta', 'oitenta', 'noventa'];
    const centenas = ['cento', 'duzentos', 'trezentos', 'quatrocentos', 'quinhentos', 'seiscentos', 'setecentos', 'oitocentos', 'novecentos'];
    const milhar = 'mil';
    const milhao = 'milhão';
    const milhoes = 'milhões';
    const centimo = 'cêntimo'; // Para "cêntimos"

    function converterNumeroParaExtenso(numero) {
        if (numero === 0) return unidades[0];

        let extenso = '';

        // Milhões
        if (numero >= 1000000) {
            const milhoesPart = Math.floor(numero / 1000000);
            extenso += converterNumeroParaExtenso(milhoesPart) + ' ' + (milhoesPart > 1 ? milhoes : milhao);
            numero %= 1000000;
        }

        // Milhares
        if (numero >= 1000) {
            const milharesPart = Math.floor(numero / 1000);
            if (milharesPart === 1) {
                extenso += (extenso ? ' e ' : '') + milhar;
            } else {
                extenso += (extenso ? ' e ' : '') + converterNumeroParaExtenso(milharesPart) + ' ' + milhar;
            }
            numero %= 1000;
        }

        // Centenas
        if (numero >= 100) {
            const centenasPart = Math.floor(numero / 100);
            if (centenasPart === 1 && numero % 100 === 0) {
                extenso += (extenso ? ' e ' : '') + 'cento';
            } else {
                extenso += (extenso ? ' e ' : '') + centenas[centenasPart - 1];
            }
            numero %= 100;
        }

        // Dezenas e unidades
        if (numero >= 20) {
            const dezenasPart = Math.floor(numero / 10);
            extenso += (extenso ? ' e ' : '') + dezenas10[dezenasPart - 2];
            numero %= 10;
        } else if (numero >= 10) {
            extenso += (extenso ? ' e ' : '') + dezenas[numero - 10];
            return extenso;
        }

        if (numero > 0) {
            extenso += (extenso ? ' e ' : '') + unidades[numero];
        }

        return extenso.trim();
    }


    function converterValorParaExtenso(valor) {
        const [inteiro, decimal] = valor.toString().split('.');

        // console.log(inteiro);
        // console.log(decimal);

        let extenso = '';

        if (inteiro > 0) {
            extenso += converterNumeroParaExtenso(inteiro);
            extenso += ' euro' + (parseInt(inteiro) > 1 ? 's' : '');
        }

        if (decimal && decimal > 0) {
            extenso += (extenso ? ' e ' : '') + converterNumeroParaExtenso(decimal) + ' cêntimo' + (parseInt(decimal) > 1 ? 's' : '');
        } else {
            if (inteiro > 0) extenso += ' e zero cêntimos';
        }

        return capitalizarPrimeiraLetra(extenso.trim());
    }


    function capitalizarPrimeiraLetra(texto) {
        return texto.charAt(0).toUpperCase() + texto.slice(1);
    }



    useEffect(() => {
        const valorNumerico = parseFloat(valorChaveNaMao.replace(',', '.'));

        if (!isNaN(valorNumerico)) {
            setValorChaveNaMaoExtenso(converterValorParaExtenso(valorNumerico));
        } else {
            setValorChaveNaMaoExtenso('');
        }
    }, [valorChaveNaMao]);

    useEffect(() => {
        if (!isNaN(parseFloat(valorChaveNaMao))) {
            const valor = parseFloat(valorChaveNaMao);
            const quantia = valor * 0.20;
            setQuantidaEntregueImportrust(quantia.toFixed(2));
        } else {
            setQuantidaEntregueImportrust('');
        }
    }, [valorChaveNaMao]);

    useEffect(() => {

        // console.log(quantidaEntregueImportrust)
        const valorNumerico = quantidaEntregueImportrust.replace(',', '.')
        // console.log(valorNumerico)
        if (!isNaN(valorNumerico)) {
            setQuantidaEntregueImportrustExtenso(converterValorParaExtenso(valorNumerico));
        } else {
            setQuantidaEntregueImportrustExtenso('');
        }
    }, [quantidaEntregueImportrust]);


    const handleAdicionarMaisSocios = (value) => {
        setnumSocios(value);
        const numInputs = parseInt(value);
        const currentInputs = [...inputs]; // Copia os inputs existentes
        if (currentInputs.length > numInputs) {
            const updatedInputs = currentInputs.slice(0, numInputs); // Remove inputs extras se houverem
            setInputs(updatedInputs);
            setDataSociosQuatro(updatedInputs)
        } else {
            const newInputs = [];
            for (let i = currentInputs.length; i < numInputs; i++) {
                newInputs.push({ nome_gerente: "", nif_gerente: "" });
            }
            const updatedInputs = [...currentInputs, ...newInputs]; // Adiciona novos inputs aos existentes
            setInputs(updatedInputs);
            setDataSociosQuatro(updatedInputs)
        }
    };


    const handleNomeGerenteChange = (index, value) => {
        const newInputs = [...inputs];
        newInputs[index].nome_gerente = value;
        setInputs(newInputs);
        setDataSociosQuatro(newInputs)
    };

    const handleNifSocioChange = (index, value) => {
        const newInputs = [...inputs];
        newInputs[index].nif_gerente = value;
        setInputs(newInputs);
        setDataSociosQuatro(inputs)
    };


    const handleNomeEmpresaChange = (e) => {
        setNomeEmpresa(e.target.value);
    };

    const handleSedeEmpresaChange = (e) => {
        setSedeEmpresa(e.target.value);
    };

    const handleNifEmpresaChange = (e) => {
        setNifEmpresa(e.target.value);
    };

    
    const handleNomeSocioChange = (e) => {
        setNomeSocioEmpresa(e.target.value);
    };

    const handleNIFGerenteChange = (e) => {
        setNifGerente(e.target.value);
    };


    return (
        <>
            <div className="grid grid-cols-2 gap-2">
                <div className='grid gap-2'>
                    <p>ID do Negócio</p>
                    <Input
                        placeholder="ID do negócio"
                        value={idDoNegocio}
                        onChange={handleInputChange(setIDdoNegocio)}
                        type="text"
                        required
                    />
                </div>
                <div className='grid gap-2'>
                    <p>Data do documento</p>
                    <DatePicker
                        placeholder="dd/mm/aaaa"
                        defaultValue={dayjsDate(dataDoDocumento)}
                        value={dayjsDate(dataDoDocumento)}
                        format={'DD/MM/YYYY'}
                        onChange={handleDataChange}
                        required
                    />
                    {alertaDataDocumento && <small className="text-red-500">A data do documento é obrigatória</small>}
                </div>
            </div>

            {tipoSociedade === "particular" ? (
                <>
                    <div>
                        <p className="font-semibold">Detalhes do Cliente:</p>
                        <div className="grid grid-cols-2 gap-2">
                            <div className='grid gap-2'>
                                <p>Nome</p>
                                <Input
                                    placeholder="Nome do cliente"
                                    value={nomeIndividual}
                                    onChange={handleInputChange(setNomeIndividual)}
                                    type="text"
                                    required
                                />
                            </div>

                            <div className='grid gap-2'>
                                <p>NIF</p>
                                <Input
                                    placeholder="NIF do cliente"
                                    value={nifParticular}
                                    onChange={handleInputChange(setNifParticular)}
                                    type="text"
                                    required
                                />
                            </div>

                            <div className='grid gap-2'>
                                <p>Número do CC</p>
                                <Input
                                    placeholder="Número do CC do cliente"
                                    value={numeroPassaporteParticular}
                                    onChange={handleInputChange(setNumeroPassaporteParticular)}
                                    type="text"
                                    required
                                />
                            </div>

                            <div className='grid gap-2'>
                                <p>Residência</p>
                                <Input
                                    placeholder="Residência do cliente"
                                    value={residenciaParticular}
                                    onChange={handleInputChange(setResidenciaParticular)}
                                    type="text"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <p className="font-semibold">Detalhes do Cliente:</p>
                    <div className="grid grid-cols-2 gap-2">
                        <div className='grid gap-2'>
                            <p>Nome do Gerente</p>
                            <Input
                                placeholder="Nome do sócio gerente"
                                value={nomeSocioEmpresa}
                                onChange={handleNomeSocioChange}
                                type="text"
                                required
                            />
                        </div>

                        <div className='grid gap-2'>
                            <p>NIF do Gerente</p>
                            <Input
                                placeholder="NIF do sócio gerente"
                                value={nifGerente}
                                onChange={handleNIFGerenteChange}
                                type="text"
                                required
                            />
                        </div>

                    </div>

                    <p className="font-semibold">Detalhes da Empresa:</p>
                    <div className="grid grid-cols-2 gap-2">
                        <div className='grid gap-2'>
                            <p>Nome da empresa</p>
                            <Input
                                placeholder="Nome da empresa"
                                value={nomeEmpresa}
                                onChange={handleNomeEmpresaChange}
                                type="text"
                                required
                            />
                        </div>

                        <div className='grid gap-2'>
                            <p>Sede da empresa</p>
                            <Input
                                placeholder="Sede da empresa"
                                value={sedeEmpresa}
                                onChange={handleSedeEmpresaChange}
                                type="text"
                                required
                            />
                        </div>

                        {/* <div className='grid gap-2'>
                    <p>Número de Sócios</p>
                    <Input
                        placeholder="Número de Sócios"
                        value={numeroSocios}
                        onChange={(e) => setNumeroSocios(e.target.value)}
                        type="text"
                        required
                    />
                </div> */}

                        <div className='grid gap-2'>
                            <p>NIF da empresa</p>
                            <Input
                                placeholder="NIF da empresa"
                                value={nifEmpresa}
                                onChange={handleNifEmpresaChange}
                                type="text"
                                required
                            />
                        </div>
                    </div>

                    <div className="grid grid-col-2 gap-2">
                        <p>Número de Sócios</p>
                        <span className="text-sm">Preencha apenas os sócios para além do gerente já preenchido acima.</span>
                        <Select
                            placeholder="Selecione o numSocios"
                            value={numSocios}
                            onChange={handleAdicionarMaisSocios}
                        >
                            <Option value="0">0</Option>
                            <Option value="1">+1</Option>
                            <Option value="2">+2</Option>
                            <Option value="3">+3</Option>
                            <Option value="4">+4</Option>
                            <Option value="5">+5</Option>
                        </Select>
                        <div className="grid grid-cols-1 gap-2">
                            {inputs.map((input, index) => (
                                <div className="grid grid-cols-2 gap-2 separador-socios" key={index}>
                                    <div className="grid gap-2">
                                        <p>Nome do Sócio gerente {index + 1}</p>
                                        <Input
                                            placeholder="Nome do Sócio gerente"
                                            value={input.nome}
                                            onChange={(e) => handleNomeGerenteChange(index, e.target.value)}
                                            type="text"
                                            required
                                        />
                                    </div>



                                    <div className="grid gap-2">
                                        <p>NIF do Sócio gerente {index + 1}</p>
                                        <Input
                                            placeholder="NIF do Sócio gerente"
                                            value={input.nif}
                                            onChange={(e) => handleNifSocioChange(index, e.target.value)}
                                            type="text"
                                            required
                                        />
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}



            < p className="font-semibold">Detalhes da Viatura:</p >
            <div className="grid grid-cols-2 gap-2">
                <div className='grid gap-2'>
                    <p>Marca da viatura</p>
                    <Input
                        placeholder="Marca da viatura"
                        value={marcaViatura}
                        onChange={handleInputChange(setMarcaViatura)}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>Modelo da viatura</p>
                    <Input
                        placeholder="Modelo da viatura"
                        value={modeloViatura}
                        onChange={handleInputChange(setModeloViatura)}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>VIN da viatura</p>
                    <Input
                        placeholder="VIN da viatura"
                        value={vinViatura}
                        onChange={handleInputChange(setVinViatura)}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>País de origem</p>
                    <Input
                        placeholder="País de origem"
                        value={paisDeOrigem}
                        onChange={handleInputChange(setPaisDeOrigem)}
                        type="text"
                        required
                    />
                </div>
            </div>

            <p className="font-semibold">Outros Detalhes:</p>
            <div className="grid grid-cols-2 gap-2">
                <div className='grid gap-2'>
                    <p>Valor chave na mão</p>
                    <Input
                        placeholder="Valor chave na mão"
                        value={valorChaveNaMao}
                        onChange={handleInputChange(setValorChaveNaMao)}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>Valor chave na mão por extenso</p>
                    <Input
                        placeholder="Valor chave na mão por extenso"
                        value={valorChaveNaMaoExtenso}
                        onChange={handleInputChange(setValorChaveNaMaoExtenso)}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>Quantia entregue Importrust</p>
                    <Input
                        placeholder="Quantia entregue Importrust"
                        value={quantidaEntregueImportrust}
                        onChange={handleInputChange(setQuantidaEntregueImportrust)}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>Quantia entregue Importrust por extenso</p>
                    <Input
                        placeholder="Quantia entregue Importrust por extenso"
                        value={quantidaEntregueImportrustExtenso}
                        onChange={handleInputChange(setQuantidaEntregueImportrustExtenso)}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <div className="flex items-end gap-2">
                        <p>Taxa de Juros</p> - <span className="text-sm">Não precisam de colocar "%"</span>
                    </div>
                    <Input
                        placeholder="Taxa de Juros"
                        value={taxasDeJuros}
                        onChange={handleInputChange(setTaxasDeJuros)}
                        type="text"
                        required
                    />
                </div>
            </div>
        </>
    );
}