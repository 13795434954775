import React, {useEffect} from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import './styles.css';
import './styles/index.css';
// import './i18n';

const root = createRoot(document.getElementById('root'));


function ClearCache() {
  useEffect(() => {
    const clearCache = async () => {
      try {
        const cacheNames = await window.caches.keys();
        await Promise.all(cacheNames.map(cacheName => window.caches.delete(cacheName)));
        // console.log('Cache cleared successfully.');
      } catch (error) {
        console.error('Failed to clear cache:', error);
      }
    };

    clearCache();
  }, []);

  return null;
}

root.render(
  <BrowserRouter>
    <ClearCache />
    <App />
  </BrowserRouter>
);
