import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Select, Button, Spin, Input, Popconfirm, notification, Menu, Dropdown, Modal, message, Form } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);


export default function Notificacoes({ currentLanguage, saveToken, iDTokenAuthenticated, setCountNotificacoes }) {

    const { t } = useTranslation();

    //Responsive
    const isMobile = useMediaQuery({
        query: '(max-width: 1023px)'
    })

    const [notificacoes, setNotificacoes] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {

        const url = `${process.env.REACT_APP_URL_NOTIFICACOES}?token=${saveToken}&user_id=${iDTokenAuthenticated}&aberta=1`;

        axios.get(url)
            .then((response) => {
                setNotificacoes(response.data.mensagem.notificacoes);
                setCountNotificacoes(response.data.mensagem.contagem.nao_abertas)
            })
            .catch((error) => {

            });
    }, []);


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id_notificacao',
            key: 'id_notificacao',
        },
        {
            title: 'Data',
            dataIndex: 'data_criacao',
            key: 'data_criacao',
        },
        {
            title: 'Assunto',
            dataIndex: 'assunto',
            key: 'assunto',
        },
        {
            title: 'Mensagem',
            dataIndex: 'mensagem',
            key: 'mensagem',
            render: (html) => (
                <div dangerouslySetInnerHTML={{ __html: html }} />
            )
        }
    ];

    return (
        <>
            <div className='card grid gap-4'>
                <div className='flex items-center gap-4'>
                    <p className='text-lg font-bold'>Lista de Notificações</p>
                </div>
                <hr></hr>
                {loading ? (
                    <div className="flex flex-col gap-4 justify-center">
                        <Spin indicator={antIcon} />
                        <p className='text-white text-center'>{t('media_mercado.loading')}</p>
                    </div>
                ) : (
                    <div className="w-full">
                        <div className="tabela-propostas tabela-users">
                            <Table
                                dataSource={notificacoes}
                                columns={columns}
                                rowKey={(record) => record.id_user}
                                // pagination={false}
                                scroll={isMobile ? { x: 'max-content' } : { x: false }}
                                bordered
                                style={isMobile ? {} : { width: '100%' }}
                                tableLayout={isMobile ? undefined : 'auto'}
                            />
                        </div>
                    </div>
                )}

            </div>
        </>
    );
}