import React, { useEffect, useState } from "react";
import { Button, Input, Table, Spin } from 'antd';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);

export default function ListadeDocumentoGerados({ userData, saveToken, iDTokenAuthenticated }) {



    const [searchQuery, setSearchQuery] = useState('');
    const [searchFunQuery, setSearchFuncQuery] = useState('');
    const [listaDocumentosData, setListaDocumentosData] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {

        setLoading(true)

        const url = `${process.env.REACT_APP_URL_CONSULTAR_DOCUMENTOS_GERADOS}?token=${saveToken}&user_id=${iDTokenAuthenticated}`;

        axios.get(url)
            .then((response) => {
                setLoading(false)
                setListaDocumentosData(response.data);
                // console.log('responseresponse', response.data);

            })
            .catch((error) => {
                setLoading(false)
                console.error('Error occurred:', error);
            });
    }, []);


    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };


    const filteredPropostas = listaDocumentosData.filter((data) => {
        const nomeDoCriador = data.user.toLowerCase();
        const tipoDeDocumento = data.tipo.toLowerCase();

        const searchQueryLowerCase = searchQuery.toLowerCase();

        return (
            nomeDoCriador.includes(searchQueryLowerCase) ||
            tipoDeDocumento.includes(searchQueryLowerCase)
        );
    });



    const handleInputFuncChange = (event) => {
        setSearchFuncQuery(event.target.value);
    };


    const filteredFuncPropostas = listaDocumentosData.filter((data) => {
        const tipoDeDocumento = data.tipo.toLowerCase();
        // console.log(tipoDeDocumento)

        const searchQueryLowerCase = searchFunQuery.toLowerCase();

        return (
            tipoDeDocumento.includes(searchQueryLowerCase)
        );
    });

    return (
        <>
            <div className='card m-auto w-full'>
                <div className="titulo-form">
                    <p>Lista de Documentos Gerados</p>
                </div>
                {loading ? (
                    <>
                        <div className="card-white tabela-propostas flex flex-col gap-4 justify-center">
                            <Spin indicator={antIcon} />
                            <p className='text-black text-center'>A procurar...</p>
                        </div>
                    </>
                ) : (
                    <>
                        {userData.roles[0] === "administrator" ? (
                            <>
                                <div className="">
                                    <Input
                                        type="text"
                                        placeholder="Procurar por nome do criador ou tipo de documento"
                                        value={searchQuery}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <br></br>
                                <div className="w-full">
                                    <div className="">
                                        <Table
                                            dataSource={filteredPropostas}
                                            rowKey={(documento) => documento.nome}
                                            scroll={{ x: 'max-content' }}
                                            bordered
                                            pagination={{
                                                position: ['bottomCenter']
                                            }}
                                        >

                                            <Table.Column
                                                title="Data"
                                                key="data_criacao"
                                                // width={100}
                                                render={(documento) => (
                                                    <span>{documento.data_criacao}</span>
                                                )}
                                            />

                                            <Table.Column
                                                title="Criado por"
                                                key="user"
                                                // width={100}
                                                render={(documento) => (
                                                    <span>{documento.user}</span>
                                                )}
                                            />

                                            <Table.Column
                                                title="Tipo"
                                                key="tipo"
                                                // width={100}
                                                render={(documento) => (
                                                    <span>{documento.tipo}</span>
                                                )}
                                            />

                                            <Table.Column
                                                title="Ações"
                                                key="link"
                                                // width={100}
                                                render={(documento) => (
                                                    <div className="flex gap-4">
                                                        <Button type="primary" htmlType="submit" target="_blank" href={`https://${documento.link}`} className="bg-white text-black font-bold rounded">
                                                            Ver Documento
                                                        </Button>
                                                    </div>
                                                )}
                                            />

                                        </Table>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="">
                                    <Input
                                        type="text"
                                        placeholder="Procurar pelo tipo de documento"
                                        value={searchFunQuery}
                                        onChange={handleInputFuncChange}
                                    />
                                </div>
                                <br></br>
                                <div className="w-full">
                                    <div className="">
                                        <Table
                                            dataSource={filteredFuncPropostas}
                                            rowKey={(documento) => documento.nome}
                                            scroll={{ x: 'max-content' }}
                                            bordered
                                            pagination={{
                                                position: ['bottomCenter']
                                            }}
                                        >

                                            <Table.Column
                                                title="Data"
                                                key="data_criacao"
                                                // width={100}
                                                // fixed="left"
                                                render={(documento) => (
                                                    <span>{documento.data_criacao}</span>
                                                )}
                                            />

                                            <Table.Column
                                                title="Criado por"
                                                key="user"
                                                // width={100}
                                                // fixed="left"
                                                render={(documento) => (
                                                    <span>{documento.user}</span>
                                                )}
                                            />

                                            <Table.Column
                                                title="Tipo"
                                                key="tipo"
                                                // width={100}
                                                // fixed="left"
                                                render={(documento) => (
                                                    <span>{documento.tipo}</span>
                                                )}
                                            />

                                            <Table.Column
                                                title="Ações"
                                                key="link"
                                                // width={100}
                                                render={(documento) => (
                                                    <div className="flex gap-4">
                                                        <Button type="primary" htmlType="submit" target="_blank" href={`https://${documento.link}`} className="bg-white text-black font-bold rounded">
                                                            Ver Documento
                                                        </Button>
                                                    </div>
                                                )}
                                            />

                                        </Table>
                                    </div>
                                </div>
                            </>
                        )}

                        <div>
                            <div className="titulo-form">
                                <p>Ferramentas de apoio</p>
                            </div>
                            <Button type="primary" htmlType="submit" target="_blank" href="https://www.ilovepdf.com/pdf_to_word" className="bg-white text-black font-bold rounded">
                                Converter PDF to Word
                            </Button>
                        </div>
                    </>
                )}
            </div >
        </>
    )
}
