import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Image, Upload, Select, InputNumber, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Option } = Select;


export default function RegisterForm({ saveToken, iDTokenAuthenticated }) {

    const navigate = useNavigate();

    const [isBusinessDeveloper, setIsBusinessDeveloper] = useState(false);

    const [country, setCountry] = useState('pt');
    const [countryCode, setCountryCode] = useState('351');

    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');

    const [userLoginName, setUserLoginName] = useState('');
    const [userPrimeiroNome, setUserPrimeiroNome] = useState('');
    const [userUltimoNome, setUserUltimoNome] = useState('');
    const [phone, setPhone] = useState('');
    const [cargo, setCargo] = useState('');

    const [showOtherInput, setShowOtherInput] = useState(false);


    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState('');

    const handleCountryChange = (value) => {

        setCountry(value);
        setCountryCode(value === 'pt' ? '351' : '34');

    };


    const handlePositionChange = (value) => {
        if (value === 'Outro') {
            setShowOtherInput(true);
            setCargo(''); // Reset cargo for custom input
            setIsBusinessDeveloper(false)
        } else {
            setShowOtherInput(false);
            setCargo(value);
            setIsBusinessDeveloper(true)
        }
    };

   

    const handleRegister = async () => {

        try {

            const formData = new FormData();

            const indicativo = country === 'pt' ? '351' : '34';

            const fullPhone = `${indicativo}${phone}`;
            const fullEmail = `${userEmail}@importrust.com`;


            formData.append('user_id', iDTokenAuthenticated);
            formData.append('token', saveToken);
            formData.append('user_login', userLoginName);
            formData.append('user_pass', userPassword);
            formData.append('first_name', userPrimeiroNome);
            formData.append('last_name', userUltimoNome);
            formData.append('numero_whatsapp', fullPhone);
            formData.append('email_interno', fullEmail);
            formData.append('foto_perfil', image);
            formData.append('business_developer', isBusinessDeveloper);
            formData.append('cargo', cargo);
            formData.append('pais', country);

            let url = `${process.env.REACT_APP_URL_REGISTAR}`;
            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.sucesso) {
                message.success('Utilizador registado com sucesso!');
                setTimeout(() => {
                    navigate('/lista-de-utilizadores');
                }, 1000);
            } else {
                // setAlertas()
                message.error(`${response.data.mensagem}`);
            }
        } catch (error) {
            console.error('Erro no registo:', error.message);
        }
    };

    useEffect(() => {
        setUserLoginName(`${userPrimeiroNome}.${userUltimoNome}`.toLowerCase());
    }, [userPrimeiroNome, userUltimoNome]);


    const handlePhoneChange = (value) => {
        setPhone(value);
    };


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };


    return (
        <>
            <div className='grid gap-4'>
                <div className='card grid text-white gap-4'>
                    <div className='grid gap-1'>
                        <p className='text-lg font-bold'>Registar Utilizador</p>
                    </div>
                    <hr></hr>
                    <div className="grid gap-4">
                        <div className="grid outros-foms">
                            <Form name="register" enctype="multipart/form-data" onFinish={handleRegister}>

                                <div className="grid gap-2 mb-5">
                                    <p className="text-base text-white mb-2">Foto de Perfil</p>
                                    <div className="grid gap-2">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            className='text-white'
                                        />
                                        {preview && (
                                            <div>
                                                <p className="text-base text-white mb-2">Preview</p>
                                                <div className="bg-white p-4 rounded text-black mb-2">
                                                    <img src={preview} alt="Preview" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                                </div>
                                            </div>

                                        )}
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 gap-2">
                                    <div>
                                        <p className="text-base text-white mb-2">Primeiro nome</p>
                                        <Form.Item
                                            name="firstname"
                                            rules={[{ required: true, message: 'Por favor, insira o primeiro e último nome.' }]}
                                        >
                                            <Input
                                                placeholder="Primeiro nome"
                                                value={userPrimeiroNome}
                                                onChange={(e) => setUserPrimeiroNome(e.target.value)}
                                            />
                                        </Form.Item>
                                    </div>

                                    <div>
                                        <p className="text-base text-white mb-2">Último nome</p>
                                        <Form.Item
                                            name="lastname"
                                            rules={[{ required: true, message: 'Por favor, insira o primeiro e último nome.' }]}
                                        >
                                            <Input
                                                placeholder="Último nome"
                                                value={userUltimoNome}
                                                onChange={(e) => setUserUltimoNome(e.target.value)}

                                            />
                                        </Form.Item>
                                    </div>



                                    <div>
                                        <p className="text-base text-white mb-2">Login</p>
                                        {/* <Form.Item
                                            name="userLoginName"
                                            rules={[{ required: true, message: 'Por favor, insira o login.' }]}
                                        > */}
                                        <Input
                                            placeholder="Este campo é gerado automaticamente"
                                            value={userLoginName}
                                            readOnly
                                        />
                                        {/* </Form.Item> */}
                                    </div>


                                    <div>
                                        <p className="text-base text-white mb-2">Password</p>
                                        <Form.Item
                                            name="userPassword"
                                            rules={[{ required: true, message: 'Por favor, insira uma password.' }]}
                                        >
                                            <Input.Password
                                                placeholder="Password"
                                                value={userPassword}
                                                onChange={(e) => setUserPassword(e.target.value)}
                                            />
                                        </Form.Item>
                                    </div>


                                    <div>
                                        <p className="text-base text-white mb-2">Número da Empresa</p>
                                        <Form.Item
                                            name="phone"
                                            rules={[{ required: true, message: 'Por favor, insira o número da empresa.' }]}
                                        >
                                            <InputNumber
                                                addonBefore={countryCode}
                                                value={phone}
                                                onChange={handlePhoneChange} // Directly handle value
                                                style={{ width: '100%' }}
                                                placeholder="Número da Empresa"
                                            />
                                        </Form.Item>
                                    </div>

                                    <div>
                                        <p className="text-base text-white mb-2">Email Interno</p>

                                        <Form.Item name="email" rules={[{ required: true, message: 'Por favor, insira o email' }]}>
                                            <Input addonAfter="@importrust.com"
                                                value={userEmail}
                                                onChange={(e) => setUserEmail(e.target.value)} />
                                        </Form.Item>

                                    </div>
                                    <div>
                                        <p className="text-base text-white mb-2">País</p>
                                        {/* <Form.Item
                                            name="pais"
                                            rules={[{ required: true, message: 'Por favor, selecione o seu país.' }]}
                                        > */}
                                        <Select
                                            placeholder="Selecione o País"
                                            onChange={handleCountryChange}
                                            style={{ width: '100%' }}
                                            defaultValue="pt"
                                        >
                                            <Option value="pt">Portugal</Option>
                                            <Option value="es">Espanha</Option>
                                        </Select>
                                        {/* </Form.Item> */}
                                    </div>
                                    <div>
                                        <p className="text-base text-white mb-2">Cargo</p>
                                        <Form.Item
                                            name="cargo"
                                            rules={[{ required: true, message: 'Por favor, insira o seu cargo.' }]}
                                        >
                                            <Select
                                                placeholder="Selecione o cargo"
                                                onChange={handlePositionChange}
                                                value={showOtherInput ? 'Outro' : cargo}
                                            >
                                                <Option value="Business Developer">Business Developer</Option>
                                                <Option value="Outro">Outro</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    {showOtherInput && (
                                        <div>
                                            <p className="text-base text-white mb-2">Especifique o cargo</p>
                                            <Form.Item
                                                name="otherPosition"
                                                rules={[{ required: true, message: 'Por favor, insira o seu cargo.' }]}
                                            >
                                                <Input
                                                    placeholder="Especifique o cargo"
                                                    value={cargo}
                                                    onChange={(e) => setCargo(e.target.value)}
                                                />
                                            </Form.Item>
                                        </div>
                                    )}

                                </div>


                                <div className='flex justify-center mt-7'>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="w-full h-full bg-white text-black font-semibold"
                                        >
                                            Registar Utilizador
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Form>

                        </div>
                    </div>

                </div >
            </div >
        </>
    );
}
