import { useState } from 'react';
import CalculadoraCredito from '../credito/calculadora-credito';

export default function SimuladorDeCredito({ }) {

    const [taxaDeJuroCredito, setTaxaDeJuroCredito] = useState('6.50');
    const [entradaCredito, setEntradaCredito] = useState('');
    const [valorFinanciadoCredito, setValorFinanciadoCredito] = useState(0);
    const [prestacoesMesesCredito, setPrestacoesMesesCredito] = useState('');
    const [valorFinalCredito, setValorFinalCredito] = useState('');
    const [valorChaveNaMaoParaCredito, setValorChaveNaMaoParaCredito] = useState(''); //


    const [esconder, setEsconder] = useState(false);


    function formatarNumero(number, decimalPlaces = 0, thousandsSeparator = ' ', decimalSeparator = '.') {
        number = parseFloat(number);

        if (number < 10000) {
            //   return number.toFixed(decimalPlaces);
        }

        const formattedValue = number.toLocaleString('en-US', {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        });

        let parts = formattedValue.split('.');

        let integerPart = parts[0];

        if (thousandsSeparator === ' ') {
            integerPart = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ').replace(",", ' ');
        }

        let decimalPart = parts.length > 1 ? decimalSeparator + `${parts[1]}` : '';
        if (thousandsSeparator === '.') {
            decimalPart = parts.length > 1 ? `.${parts[1]}` : '';
        }

        return `${integerPart}${decimalPart}`;
    }



    const handleEnable = (checked) => {
        setEsconder(!esconder);
    };

    return (
        <div className='grid gap-4'>
            <div className='card grid text-white gap-4'>
                <div className='grid gap-1'>
                    <p className='text-lg font-bold'>Simulação de Crédito</p>
                    <p className='text-sm font-normal'>Versão disponível encontra-se em fase final de desenvolvimento. Valida todos os dados.</p>
                </div>
                <hr></hr>
                <CalculadoraCredito
                    setTaxaDeJuroCredito={setTaxaDeJuroCredito}
                    formatarNumero={formatarNumero}
                    taxaDeJuroCredito={taxaDeJuroCredito}
                    entradaCredito={entradaCredito}
                    setEntradaCredito={setEntradaCredito}
                    valorFinanciadoCredito={valorFinanciadoCredito}
                    setValorFinanciadoCredito={setValorFinanciadoCredito}
                    prestacoesMesesCredito={prestacoesMesesCredito}
                    setPrestacoesMesesCredito={setPrestacoesMesesCredito}
                    valorFinalCredito={valorFinalCredito}
                    setValorFinalCredito={setValorFinalCredito}
                    valorChaveNaMaoParaCredito={valorChaveNaMaoParaCredito}
                    setValorChaveNaMaoParaCredito={setValorChaveNaMaoParaCredito}
                />

            </div>

        </div>
    );
}
