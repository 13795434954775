import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

const COLORS = ['#e2f6e9', '#99dcb1', '#3ea163'];

// Custom tooltip component to add Euro symbol
const CustomTooltip = ({ payload, formatarNumero }) => {
    if (payload && payload.length) {
        return (
            <div className="custom-tooltip text-sm text-center">
                {payload[0].name}<br /> <strong>{formatarNumero(payload[0].value)} €</strong>
            </div>
        );
    }
    return null;
};

const ChartCreditoViatura = ({ valorEntrada, mticValor, comissaoDeCapital, formatarNumero }) => {
    const data = [
        { name: 'Comissão de Disponibilização de Capital', value: parseFloat(comissaoDeCapital) },
        { name: 'Valor de Entrada', value: parseFloat(valorEntrada) },
        { name: 'Valor total do Financiamento', value: parseFloat(mticValor) },
    ];

    return (
        <PieChart width={300} height={230}>
            <Pie
                data={data}
                innerRadius={30}
                outerRadius={100}
                paddingAngle={5}
                cornerRadius={5}
                startAngle={0}
                endAngle={180}
                cx={150}
                cy={150}
                dataKey="value"
            >
                {
                    data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))
                }
            </Pie>
            <Tooltip content={<CustomTooltip formatarNumero={formatarNumero} />} />
            {/* <Legend /> */}
        </PieChart>
    );
};

export default ChartCreditoViatura;
