import React, { useState, useEffect } from "react";
import { UserOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Image, Popover, Menu, Avatar, Select, Dropdown, Button, Divider } from "antd";
import { useTranslation } from 'react-i18next';
import flagPortugal from '../../assets/flags/flag-portugal.svg';
import flagSpain from '../../assets/flags/flag-spain.svg';
import geral from '../../locale/geral.json';
import { IoIosLogOut } from "react-icons/io";
import { useMediaQuery } from "react-responsive";

const PerfilMenu = ({ collapsed, onToggle, userData, currentLanguage, allLanguage, setCurrentLanguage }) => {

    const { t } = useTranslation();

    //Responsive
    const isMobile = useMediaQuery({
        query: '(max-width: 1023px)'
    })


    const [showButton, setShowButton] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const path = location.pathname;
        setShowButton(path !== "/");
    }, [location.pathname]);

    const handleLogout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        window.location.href = "/";
    };


    const handleChangeLanguage = (e) => {

        const currentLanguageFromURL = window.location.pathname.split('/')[1];
        const newURL = window.location.pathname.replace(`/${currentLanguageFromURL}/`, `/${e}/`);
        window.history.pushState({}, '', newURL);
        setCurrentLanguage(e);

    };



    return (
        <>
            <Popover
                placement="bottomRight"
                content={
                    <div className=" w-64 perfil-menu">
                        <div className='p-2 flex flex-col items-center gap-2'>
                            <Avatar className='m-1 bg-white' size={80} src={userData?.data?.foto_perfil} />
                            <span className='text-base font-semibold'>{userData?.data?.display_name}</span>
                            <span className='text-sm'>{userData?.data?.cargo}</span>
                        </div>
                        <Divider dashed />
                        <Menu>
                            <Menu.Item disabled>
                                {t('header.perfil')}
                            </Menu.Item>
                        </Menu>
                        {currentLanguage === 'pt' && (
                            <>
                                <Menu>
                                    <Menu.Item>

                                        <Link to={`/notificacoes`}>
                                            Lista de Notificações
                                        </Link>
                                    </Menu.Item>
                                </Menu>
                            </>
                        )}
                        <Divider dashed />
                        <Menu>
                            <Menu.Item onClick={handleLogout}>
                                {t('header.sair')}
                            </Menu.Item>
                        </Menu>
                    </div>
                }
                trigger="click"
            >
                <div className="flex gap-2 items-center cursor-pointer text-white">
                    {!isMobile && (

                        <div className="flex flex-col">
                            <span className="text-xs font-semibold">{userData?.data?.display_name}</span>
                            <span className="text-xs">{userData?.data?.cargo}</span>
                        </div>
                    )}
                    <Avatar className='m-1 bg-white' size={37} src={userData?.data?.foto_perfil} />
                </div>
            </Popover>
        </>
    );
};

export default PerfilMenu;
