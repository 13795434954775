import React, { useEffect, useState } from "react";
import axios from "axios";
import { Image, Input, Table, Spin, Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import Copyicon from "../../../components/pt/copyicon";


const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);


export default function UltimasPesquisas({ currentLanguage, iDTokenAuthenticated, saveToken, formatarNumero, userData }) {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [propostaData, setPropostaData] = useState([]);

    useEffect(() => {

        setLoading(true)

        const url = `${process.env.REACT_APP_GET_HISTORICO_PESQUISAS}?token=${saveToken}&user_id=${iDTokenAuthenticated}&lang=${currentLanguage}`;

        axios.get(url)
            .then((response) => {
                setLoading(false)
                //console.log(response)
                setPropostaData(response.data);
            })
            .catch((error) => {
                setLoading(true)
                console.error('Error occurred:', error);
            });
    }, []);


    return (
        <>
            <div className="historico-pesquisas">
                {loading ? (
                    <>
                        <div className='card'>
                            <div className="flex flex-col gap-4 justify-center">
                                <Spin indicator={antIcon} />
                                <p className='text-white text-center'>{t('media_mercado.loading')}</p>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="card flex flex-col gap-4">
                        <div className='grid gap-1'>
                            <p className='text-lg font-bold'>{t('ultimas_pesquisas.titulo_principal')}</p>
                        </div>
                        <hr></hr>
                        <div className="w-full">
                            <div className="">
                                <Table
                                    dataSource={propostaData}
                                    rowKey={(proposta) => proposta.id}
                                    pagination={{ pageSize: 30, showSizeChanger: false }}
                                    scroll={{ x: 'max-content' }}
                                    bordered
                                >

                                    {userData.roles[0] === "administrator" ? (
                                        <Table.Column
                                            title={t('ultimas_pesquisas.pesquisado_por')}
                                            key="nome_criou"
                                            render={(proposta) => (
                                                <div className='flex items-center gap-2'>
                                                    <img src={proposta.user_data.foto_perfil} className='h-8 rounded-full' alt='User Profile' />
                                                    <span>{proposta.user_data.nome_criou}</span>
                                                </div>
                                            )}
                                        />
                                    ) : null}

                                    <Table.Column
                                        title={t('ultimas_pesquisas.imagem_viatura')}
                                        // key=""
                                        className='text-center'
                                        render={(proposta) => (
                                            <Image
                                                style={{ textAlign: 'center', objectFit: 'contain' }}
                                                width={60}
                                                height={45}
                                                src={proposta.info.imagens}
                                            />
                                        )}
                                    />

                                    <Table.Column
                                        title={t('ultimas_pesquisas.data')}
                                        key="data_criacao"
                                        render={(proposta) => (
                                            proposta.data_criacao
                                        )}
                                    />

                                    <Table.Column
                                        title={t('ultimas_pesquisas.marca')}
                                        key="marca"
                                        render={(proposta) => (
                                            proposta.info.marca ? `${proposta.info.marca}` : ""
                                        )}
                                    />

                                    <Table.Column
                                        title="URL"
                                        key="url"
                                        render={(proposta) => (
                                            <div className="flex items-center gap-1">
                                                <Tooltip placement="topRight" title={proposta.url}>
                                                    {proposta.url.slice(0, 20)}...{proposta.url.slice(-5)}
                                                </Tooltip>
                                                <Copyicon text={proposta.url} />
                                            </div>
                                        )}
                                    />
                                </Table>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>

    )
}
