import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import axios from 'axios';
import { IoArrowBack } from 'react-icons/io5';
import { Button } from 'antd';


export default function InfoDoContactoHubspot({
    contactoInfoData
}) {


    return (
        <>
            <div className='card-hubspot'>
                <div className='titulo-form'>
                    <p>Perfil do Contacto</p>
                </div>
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                    <p className='text-base text-white'>Nome: <br /><span className='text-lg text-white font-medium capitalize'>{contactoInfoData.firstname} {contactoInfoData.lastname}</span></p>
                    <p className='text-base text-white'>Email: <br /><span className='text-lg text-white font-medium'>{contactoInfoData.email}</span></p>
                </div>
            </div>
        </>
    );
}
