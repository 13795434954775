import React, { useState, useEffect } from 'react'; // Corrigido: Adicionado `useEffect`
import { Table, Select, Modal, Checkbox, Button, Spin, DatePicker, Drawer, Tooltip, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaEye, FaArrowDown, FaArrowUp, FaEyeSlash } from 'react-icons/fa';
import flagPortugal from '../../../../assets/flags/flag-portugal.svg';
import flagSpain from '../../../../assets/flags/flag-spain.svg';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios'; // Certifique-se de importar o Axios
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import DetalhesLogDeEmail from './detalhes-de-log-email';
import { Link } from 'react-router-dom';
import { MdModeEdit } from 'react-icons/md';

const { RangePicker } = DatePicker; // Importação do componente RangePicker do DatePicker

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);


export default function ConsultarListaDeEmails({ saveToken, iDTokenAuthenticated }) {

    const { t } = useTranslation(); // Exemplo de uso do hook de tradução
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' }); // Responsividade para Mobile

    const [listaDeDados, setListaDeDados] = useState([]);
    const [dateRange, setDateRange] = useState([null, null]);
    const [loading, setLoading] = useState(false); // Correção: adicionado estado `loading`
    const [nomeEvento, setNomeEvento] = useState([]); // Adiciona estado para o nome do evento
    const [tipoDeEmail, setTipoDeEmail] = useState([]); // Adiciona estado para o nome do evento

    const [emailEstadoDe, setEmailEstadoDe] = useState(''); // Adiciona estado para o email de
    const [emailEstadoPara, setEmailEstadoPara] = useState(''); // Adiciona estado para o email para

    const [filtersSelected, setFiltersSelected] = useState({}); // Estado para filtros selecionados

    const [isDrawerVisible, setIsDrawerVisible] = useState(false); // Estado para controlar visibilidade do Drawer
    const [selectedRecord, setSelectedRecord] = useState(null); // Estado para armazenar o registro selecionado

    const [mostraMaisFiltros, setMotrarMaisFiltros] = useState(false); // Estado para mostrar esconder filtros
    const [limitPedidos, setLimitPedidos] = useState('100'); // Estado para mostrar o limit de resultados/pedido
    const [editarNumeroLimit, setEditarNumeroLimit] = useState(false); // Estado para mostrar esconder select de limit pedidos


    useEffect(() => {
        setLoading(true);

        const filtroNomedoEvento = nomeEvento.join(',');
        const filtroTemplate = tipoDeEmail.join(',');

        const obterListaDeEmails = {
            data_inicio: filtersSelected.dataCriacaoInicio,
            data_fim: filtersSelected.dataCriacaoFim,
            nome_evento: filtroNomedoEvento,
            template: filtroTemplate,
            de: filtersSelected.de,
            para: filtersSelected.para,
            limit: limitPedidos,
            user_id: iDTokenAuthenticated,
            user_token: saveToken,
        };

        axios.get(`${process.env.REACT_APP_OBTER_LOGS_EMAILS}`, { params: obterListaDeEmails }) // Corrigido: adicionado `params` no axios
            .then((response) => {
                // console.log(response)
                setListaDeDados(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error occurred:', error);
                setLoading(false);
            });
    }, [filtersSelected, nomeEvento, tipoDeEmail, limitPedidos]);

    const handleDateChange = (dates, dateStrings) => {
        setDateRange(dates);
        setFiltersSelected((prev) => ({
            ...prev,
            dataCriacaoInicio: dateStrings[0] || null,
            dataCriacaoFim: dateStrings[1] || null,
        }));
    };

    const handleReset = () => {
        setDateRange([null, null]);
        setFiltersSelected({});
        setNomeEvento([]);
        setTipoDeEmail([])
    };

    const handleEventoClick = (value) => {
        setNomeEvento(value);
        setFiltersSelected((prev) => ({ ...prev, nome_evento: value }));
    };

    const handleTipoDeEmailClick = (value) => {
        setTipoDeEmail(value);
        setFiltersSelected((prev) => ({ ...prev, template: value }));
    };


    const handleLimitPedidos = (value) => {
        setLimitPedidos(value);
    };


    const editarNumero = () => {
        setEditarNumeroLimit((prev) => !prev);
    };


    const eventColors = {
        'Enviado': 'bg-blue-400',      // Azul para "Enviado"
        'Entregue': 'bg-green-400',    // Verde para "Entregue"
        'Falha': 'bg-red-400',         // Vermelho para "Falha"
        'Aberto': 'bg-yellow-400',     // Amarelo para "Aberto"
        'Clique': 'bg-purple-400',     // Roxo para "Clique"
        'Unsubscribe': 'bg-gray-400'   // Cinza para "Unsubscribe"
    };

    const columns = [
        {
            title: 'Evento',
            dataIndex: 'nome_evento',
            key: 'nome_evento',
            render: (text) => {
                const colorClass = eventColors[text] || 'bg-gray-300'; // Classe de cor baseada no evento, padrão cinza se não houver correspondência
                return (
                    <div className={`rounded-full ${colorClass} text-white px-3 w-auto inline-flex items-center`}>
                        <span className='flex gap-2 items-center'>
                            {/* {text === 'Entregue' && <FaCheckCircle />} */}
                            {text}
                        </span>
                    </div>
                );
            }
        },
        {
            title: 'Data',
            dataIndex: 'data_evento',
            key: 'data_evento',
        },
        {
            title: 'Assunto',
            dataIndex: 'subject',
            key: 'subject',
            render: (text, record) => { // Corrigido: Mudar `record` para `(text, record)`
                const displayedName = `${text.slice(0, 50)}...`; // Use `text` diretamente para acessar o valor da coluna

                return (
                    <Tooltip placement="topRight" title={text}>
                        <span
                            style={{ cursor: 'pointer', color: '#fff' }} // Estilo para mostrar que é clicável
                            onClick={() => showDrawer(record)} // Ao clicar, chama a função para abrir o Drawer com o registro completo
                        >
                            {displayedName}
                        </span>
                    </Tooltip>
                );
            }
        },
        {
            title: 'De',
            dataIndex: 'from_email',
            key: 'from_email',
        },
        {
            title: 'Para',
            dataIndex: 'to_email',
            key: 'to_email',
        }
    ];

    const showDrawer = (record) => {

        const obterDetalhes = {
            id_log: record.id_log,
            user_id: iDTokenAuthenticated,
            user_token: saveToken,
        };

        axios.get(`${process.env.REACT_APP_DETALHES_DO_LOGS_DE_EMAILS}`, { params: obterDetalhes })
            .then((response) => {
                console.log(response)
                setSelectedRecord(response.data.data);
                setIsDrawerVisible(true);
            })
            .catch((error) => {
                setIsDrawerVisible(false);
            });


    };

    const closeDrawer = () => {
        setIsDrawerVisible(false); // Fecha o Drawer
        setSelectedRecord(null); // Limpa o registro selecionado
    };



    const toogleFiltroes = () => {
        setMotrarMaisFiltros(!mostraMaisFiltros)
    };

    return (
        <div className='card w-full flex flex-col justify-center items-center gap-4'>
            <div className='grid gap-4 grid-cols-1 w-full h-full'>
                <div className='grid gap-1'>
                    <p className='text-lg font-bold'>Logs de Emails</p>
                    <p className='text-sm font-normal'>Todos os emails enviados das automações</p>
                </div>
                <hr />
                {loading ? (
                    <div className="card flex flex-col gap-4 justify-center">
                        <Spin indicator={antIcon} />
                        <p className='text-white text-center'>{t('media_mercado.loading')}</p>
                    </div>
                ) : (
                    <div className='grid gap-4'>
                        <div className="w-full">
                            <div className='flex justify-between gap-4 mb-7 items-center'>
                                <div className='grid gap-2'>

                                    <div className='flex flex-row gap-2 items-center'>
                                        Filtros
                                        {Object.keys(filtersSelected).length > 0 && (
                                            <>
                                                <Button
                                                    type="primary"
                                                    className='shadow-none font-bold text-black bg-white'
                                                    onClick={handleReset}
                                                >
                                                    Limpar Filtros
                                                </Button>
                                                <div>|</div>
                                            </>
                                        )}

                                        <Select
                                            mode="multiple"
                                            placeholder={'Evento'}
                                            className='select-filters capitalize'
                                            onChange={(value) => handleEventoClick(value)}
                                            value={nomeEvento || undefined}
                                            style={{ width: '300px' }}
                                        >
                                            <Select.Option key="Enviado" value="Enviado" className="capitalize">Enviado</Select.Option>
                                            <Select.Option key="Entregue" value="Entregue" className="capitalize">Entregue</Select.Option>
                                            <Select.Option key="Falha" value="Falha" className="capitalize">Falha</Select.Option>
                                            <Select.Option key="Aberto" value="Aberto" className="capitalize">Aberto</Select.Option>
                                            <Select.Option key="Clique" value="Clique" className="capitalize">Clique</Select.Option>
                                            <Select.Option key="Unsubscribe" value="Unsubscribe" className="capitalize">Unsubscribe</Select.Option>
                                        </Select>

                                        <Select
                                            mode="multiple"
                                            placeholder="Tipo de Email"
                                            className="select-filters capitalize"
                                            onChange={(value) => handleTipoDeEmailClick(value)}
                                            value={tipoDeEmail || undefined}
                                            style={{ width: '350px' }}
                                        >
                                            <Select.Option key="isv_iuc" value="isv_iuc" className="capitalize">
                                                ISV/IUC
                                            </Select.Option>
                                            <Select.Option key="formulario_contacto_pt" value="formulario_contacto_pt" className="capitalize">
                                                Formulario Contacto [PT]
                                            </Select.Option>
                                            <Select.Option key="formulario_contacto_es" value="formulario_contacto_es" className="capitalize">
                                                Formulario Contacto [ES]
                                            </Select.Option>
                                            <Select.Option key="automacoes_stands_transportadoras" value="automacoes_stands_transportadoras" className="capitalize">
                                                Automacoes Stands Transportadoras
                                            </Select.Option>
                                            <Select.Option key="automacoes_pt" value="automacoes_pt" className="capitalize">
                                                Automacoes [PT]
                                            </Select.Option>
                                            <Select.Option key="automacoes_es" value="automacoes_es" className="capitalize">
                                                Automacoes [ES]
                                            </Select.Option>
                                            <Select.Option key="propostas_aceites" value="propostas_aceites" className="capitalize">
                                                Propostas Aceites
                                            </Select.Option>
                                        </Select>



                                        <RangePicker
                                            showTime={{ format: 'HH:mm' }} // Adicionado: mostrar seleção de horas e minutos
                                            format="YYYY-MM-DD HH:mm" // Definido o formato de exibição incluindo data e hora
                                            value={dateRange}
                                            onChange={handleDateChange}
                                            placeholder={['Data de início', 'Data de fim']}
                                        />



                                    </div>
                                    {mostraMaisFiltros && (
                                        <div className='flex flex-row gap-2 items-center'>
                                            <Input
                                                type="text"
                                                placeholder="De"
                                                value={emailEstadoDe}
                                                onChange={(event) => {
                                                    const value = event.target.value;
                                                    setEmailEstadoDe(value);
                                                    setFiltersSelected((prev) => ({ ...prev, de: value }));
                                                }}
                                                prefix={<SearchOutlined />}
                                            />


                                            <Input
                                                type="text"
                                                placeholder="Para"
                                                value={emailEstadoPara}
                                                onChange={(event) => {
                                                    const value = event.target.value;
                                                    setEmailEstadoPara(value);
                                                    setFiltersSelected((prev) => ({ ...prev, para: value }));
                                                }}
                                                prefix={<SearchOutlined />}
                                            />

                                        </div>
                                    )}
                                </div>
                                <div>
                                    <Link onClick={toogleFiltroes} className='flex justify-end items-center gap-2'>
                                        {mostraMaisFiltros ? <FaEyeSlash size={20} style={{ cursor: 'pointer' }} /> : <FaEye size={20} style={{ cursor: 'pointer' }} />}
                                        Filtros
                                    </Link>
                                </div>
                            </div>
                            <div className="tabela-propostas">
                                <Table
                                    dataSource={listaDeDados}
                                    columns={columns}
                                    rowKey={(record) => record.id_user}
                                    pagination={{ pageSize: 30, showSizeChanger: false }}
                                    scroll={isMobile ? { x: 'max-content' } : { x: false }}
                                    bordered
                                    style={isMobile ? {} : { width: '100%' }}
                                    tableLayout={isMobile ? undefined : 'auto'}
                                />
                            </div>

                            <div className="flex items-center gap-2">
                                Tabela mostra {limitPedidos} resultados.
                                <Link onClick={editarNumero} className="flex justify-end items-center gap-2">
                                    <MdModeEdit size={20} style={{ cursor: 'pointer' }} />
                                </Link>
                                {editarNumeroLimit && (
                                    <Select
                                        placeholder="Limit de Pedidos"
                                        className="select-filters capitalize"
                                        onChange={(value) => handleLimitPedidos(value)}
                                        value={limitPedidos || undefined}
                                    >
                                        <Select.Option key="100" value="100" className="capitalize">
                                            100
                                        </Select.Option>
                                        <Select.Option key="200" value="200" className="capitalize">
                                            200
                                        </Select.Option>
                                        <Select.Option key="300" value="300" className="capitalize">
                                            300
                                        </Select.Option>
                                    </Select>
                                )}
                            </div>



                        </div>
                    </div>
                )}
            </div>
            <Drawer
                title="Detalhes do Email"
                placement="right"
                onClose={closeDrawer}
                className='detalhes-de-log-email'
                visible={isDrawerVisible}
                width={800}
            >
                {selectedRecord && (
                    <DetalhesLogDeEmail selectedRecord={selectedRecord} />
                )}
            </Drawer>
        </div>
    );
}
