import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CheckOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Spin, Alert } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { IoMdCloseCircle } from "react-icons/io";

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);

function Login() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginLoading, setLoginLoading] = useState(false);
    const [loginError, setLoginError] = useState('');
    const [loginSuccess, setLoginSuccess] = useState('');

    const handleLogin = async () => {
        setLoginLoading(true);
        setLoginError('');
        setLoginSuccess('');

        try {
            const response = await axios.post(
                'https://backoffice.importrust.com/wp-json/importrust-login/login',
                {
                    username,
                    password,
                },
                { withCredentials: true }
            );

            if (response.data.success) {
                localStorage.setItem('user', JSON.stringify(response.data.user));
                localStorage.setItem('token', response.data.token);
                setLoginSuccess('Login bem-sucedido! Aguarde...');
                setTimeout(() => {
                    // RELOAD
                    window.location.reload();
                }, 1500);
            } else {
                setLoginError('Credenciais inválidas. Verifique seu utilizar e password.');
            }
        } catch (error) {
            setLoginError('Ocorreu um erro. Tente novamente.');
        } finally {
            setLoginLoading(false);
        }
    };

    return (
        <div className="sec-geral-top">
            <div className="form main flex flex-col gap-4">
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={() => handleLogin()}
                >
                    <p className='label-do-campo text-black pb-2'>{t('login.utilizador')}</p>
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: `${t('login.msg.username')}`,
                            },
                        ]}
                    >
                        <Input
                            className="input-geral"
                            value={username}
                            onChange={(e) => {
                                setUsername(e.target.value);
                                setLoginError('');
                            }} autoComplete="username"
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder={t('login.utilizador')}
                        />
                    </Form.Item>
                    <p className='label-do-campo text-black pb-2'>{t('login.password')}</p>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: `${t('login.msg.password')}`,
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="input-geral"
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            autoComplete="current-password"
                            placeholder={t('login.password')}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="bg-black w-full h-full text-white font-bold rounded"
                            disabled={loginLoading}
                        >
                            {loginLoading ? (
                                <Spin indicator={antIcon} />
                            ) : loginSuccess ? (
                                <div className="inline-flex items-center text-lg">
                                    <span className="animate-pulse font-bold text-sm mx-1">A Entrar</span>
                                    <span className="animate-pulse font-bold text-sm mx-1">.</span>
                                    <span className="animate-pulse font-bold text-sm mx-1" style={{ animationDelay: '0.2s' }}>.</span>
                                    <span className="animate-pulse font-bold text-sm mx-1" style={{ animationDelay: '0.4s' }}>.</span>
                                </div>
                            ) : (
                                <>
                                    <span className="font-bold text-sm">Entrar</span>
                                </>
                            )}
                        </Button>

                    </Form.Item>
                    {loginError && (
                        <div className='flex flex-col gap-2 items-center mb-4 text-red-600 card-black'>
                            <IoMdCloseCircle style={{ fontSize: 30 }} />
                            <span className='text-center text-xs'>{loginError}</span>
                        </div>
                    )}
                </Form>
            </div>
            <div className='text-xs flex flex-col items-center'>
                <span className='text-center'>
                    Para solicitar acesso ao Dashboard Importrust, <br />
                    <a className='underline' href='mailto:carlos.costa@importrust.com'>
                        entre em contato com a Equipa Tech da Importrust
                    </a>.
                </span>
            </div>
        </div>
    );
}

export default Login;
