import React, { useState, useEffect } from 'react'; // Importando useEffect
import { Layout, Button, Input, Popover } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, SearchOutlined } from '@ant-design/icons';
import PerfilMenu from '../user/perfil-menu';
import { GoSidebarCollapse } from "react-icons/go";
import { GoSidebarExpand } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IoMdNotifications, IoMdNotificationsOutline } from "react-icons/io";
import PopupNotificacoes from '../pt/notificacoes/popup-notificacoes';
import axios from 'axios';

const { Header } = Layout;

export default function CustomHeader({ saveToken, iDTokenAuthenticated, collapsed, notificacoes, setCountNotificacoes, countNotificacoes, onToggle, userData, currentLanguage, allLanguage, setCurrentLanguage, searchQuery, setSearchQuery }) {

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [isPopoverVisible, setIsPopoverVisible] = useState(false); //estado do
  const [notificacoesCarregadas, setNotificacoesCarregadas] = useState(false);

  useEffect(() => {
    if (searchQuery) {
      navigate(`/search?query=${searchQuery}`);
    }
  }, [searchQuery, navigate]);


  const handlePopoverClick = async () => {
    setIsPopoverVisible(prev => !prev);
    // if (!notificacoesCarregadas && !isPopoverVisible) {
      try {
        const url = `${process.env.REACT_APP_URL_NOTIFICACOES}?token=${saveToken}&user_id=${iDTokenAuthenticated}&aberta=1`;
        await axios.get(url);
        setNotificacoesCarregadas(true)
        setCountNotificacoes('0');
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    // }
  };

  return (
    <Header className="flex justify-between items-center p-4 gap-4 header-main">
      <div className='flex items-center gap-4'>
        <Button
          type="primary"
          onClick={onToggle}
        >
          {collapsed ? <GoSidebarCollapse /> : <GoSidebarExpand />}
        </Button>
        <div>
          <Input
            placeholder={t('topbar.titulo_principal')}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            prefix={<SearchOutlined />}
            style={{ marginRight: '8px' }}
          />
        </div>
      </div>

      <div className='flex items-center gap-7'>
        {notificacoes.length > 1 && (
          <Popover
            placement="bottomRight"
            content={<PopupNotificacoes notificacoes={notificacoes} />}
            trigger="click"
            // visible={isPopoverVisible}
            onClick={handlePopoverClick}
            // onVisibleChange={(visible) => setIsPopoverVisible(visible)}
          >
            <div className="relative flex gap-2 items-center cursor-pointer">
              <div className="border border-[#575757] text-white py-[4px] px-[15px] rounded transition-colors duration-300 ease-in-out flex items-center">

                {isPopoverVisible ? (
                  <IoMdNotifications size={20} />
                ) : (
                  <IoMdNotificationsOutline size={20} />
                )}

                {countNotificacoes.nao_abertas > 0 && (
                  <div className="absolute top-[-10px] right-[-10px] bg-red-600 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                    {countNotificacoes.nao_abertas}
                  </div>
                )}

              </div>
            </div>
          </Popover>
        )}

        <PerfilMenu userData={userData} allLanguage={allLanguage} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />

      </div>

    </Header>
  );
};
