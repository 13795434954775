import React from 'react';
import { Layout, Button } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import PerfilMenu from '../user/perfil-menu';
import { GoSidebarCollapse } from "react-icons/go";
import { GoSidebarExpand } from "react-icons/go";

const { Header } = Layout;

const CustomFooter = ({  }) => {
  return (
    <div className='p-4 text-center text-xs text-neutral-500 flex justify-between'>
      Importrust | Version {process.env.REACT_APP_VERSION}
      <a href='https://changelog.importrust.com' target='_blank' className='underline'>Changelog</a>
    </div>
  );
};

export default CustomFooter;
