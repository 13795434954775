import { Input, Select } from "antd";
import React, { useEffect } from "react";
import { DatePicker } from 'antd';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const { Option } = Select;

export default function CompContratoParticular({
    dataDoDocumento,
    setDataDoDocumento,
    nomeIndividual,
    setNomeIndividual,
    moradaIndividual,
    setMoradaIndividual,
    nifParticular,
    setNifParticular,
    numeroPassaporteParticular,
    setNumeroPassaporteParticular,
    valorServicoPrestado,
    setValorServicoPrestado,
    emailResponsavel,
    setEmailResponsavel,
    emailParticular,
    setEmailParticular,

    tipo,
    tipoContrato,
    tipoSociedade,

    escolhaDeDocumento,
    setEscolhaDeDocumento,

    marcaViatura,
    setMarcaViatura,
    modeloViatura,
    setModeloViatura,
    matriculaViatura,
    setMatriculaViatura,
    vinViatura,
    setVinViatura,
    seguroViatura,
    setSeguroViatura,
    idDoNegocio,
    setIDdoNegocio,
    percentagemServicoPrestado,
    setPercentagemServicoPrestado,
    valorDeposito,
    setValorDeposito,
    alertaDataDocumento
}) {

    // console.log('dataDoDocumento particular', dataDoDocumento);

    const handleDataChange = (e) => {
        let day = "";
        let month = "";
        let year = "";

        if (e && e instanceof Object && e.hasOwnProperty('$y') && e.hasOwnProperty('$M') && e.hasOwnProperty('$D')) {
            day = e.$D.toString().padStart(2, '0');
            month = (e.$M + 1).toString().padStart(2, '0');
            year = e.$y.toString();
        }

        setDataDoDocumento(day + '/' + month + '/' + year);
    };

    const dayjsDate = (date) => {
        if (date && date !== '') {
            return dayjs(date, 'DD/MM/YYYY')
        }

        return "";
    }



    const handleNomeChange = (e) => {
        setNomeIndividual(e.target.value);
    };

    const handleMoradaChange = (e) => {
        setMoradaIndividual(e.target.value);
    };

    const handleNIFChange = (e) => {
        setNifParticular(e.target.value);
    };

    const handleNumeroPassaporteChange = (e) => {
        setNumeroPassaporteParticular(e.target.value);
    };

    const handleValorServicoPrestadoChange = (e) => {
        setValorServicoPrestado(e.target.value);
    };

    const handleEmailRsponsavelChange = (e) => {
        setEmailResponsavel(e.target.value);
    };





    //vendas - particular

    const handleMarcaDaViaturaChange = (e) => {
        setMarcaViatura(e.target.value);
    };


    const handleModeloDaViaturaChange = (e) => {
        setModeloViatura(e.target.value);
    };


    const handleMatriculaDaViaturaChange = (e) => {
        setMatriculaViatura(e.target.value);
    };


    const handleVINDaViaturaChange = (e) => {
        setVinViatura(e.target.value);
    };


    const handleSeguroDaViaturaChange = (e) => {
        setSeguroViatura(e.target.value);
    };


    const handlePercentagemServicoPrestadoChange = (e) => {
        setPercentagemServicoPrestado(e.target.value);
    };


    const handleValorDepositolChange = (e) => {
        setValorDeposito(e.target.value);
    };


    const handleEmailParticularChange = (e) => {
        setEmailParticular(e.target.value);
    };





    // const handleSubmit = (e) => {
    //     e.preventDefault();
    // }




    const handleIDdoNegocioChange = (e) => {
        setIDdoNegocio(e.target.value);
    };


    const handleEscolhaDeDocumento = (e) => {
        setEscolhaDeDocumento(e);
    };





    return (
        <>

            <div className="grid grid-cols-2 gap-2">
                <div className='grid gap-2'>
                    <p>ID do negócio</p>
                    <Input
                        placeholder="ID do negócio"
                        value={idDoNegocio}
                        onChange={handleIDdoNegocioChange}
                        type="text"
                        required
                    />
                </div>
                <div className='grid gap-2'>
                    <p>Data do documento</p>
                    <DatePicker placeholder="dd/mm/aaaa"
                        defaultValue={dayjsDate(dataDoDocumento)}
                        value={dayjsDate(dataDoDocumento)}
                        format={'DD/MM/YYYY'}
                        // picker="month"
                        onChange={handleDataChange}
                        required
                    />
                    {alertaDataDocumento && <small className="text-red-500">A data do documento é obrigatória</small>}
                </div>
            </div>

            <p className="font-semibold">Detalhes do Cliente:</p>

            <div className="grid grid-cols-2 gap-2">

                <div className='grid gap-2'>
                    <p>Nome</p>
                    <Input
                        placeholder="Nome do cliente"
                        value={nomeIndividual}
                        onChange={handleNomeChange}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>Email</p>
                    <Input
                        placeholder="Email do Cliente"
                        value={emailParticular}
                        onChange={handleEmailParticularChange}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>Morada</p>
                    <Input
                        placeholder="Morada do cliente"
                        value={moradaIndividual}
                        onChange={handleMoradaChange}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>NIF</p>
                    <Input
                        placeholder="NIF do cliente"
                        value={nifParticular}
                        onChange={handleNIFChange}
                        type="text"
                        required
                    />
                </div>


                {tipoContrato !== "venda" && tipoSociedade !== "empresa" ? (
                    <>
                        <div className='grid gap-2'>
                            <p>Tipo de identificação</p>
                            <Select
                                placeholder="Selecione o documento"
                                value={escolhaDeDocumento ? escolhaDeDocumento : undefined}
                                onChange={handleEscolhaDeDocumento}
                            >
                                <Option value="passaporte">Nº de Passaporte</Option>
                                <Option value="cartaodecidadao">Nº Cartão de Cidadão</Option>
                            </Select>
                        </div>
                        {escolhaDeDocumento === "passaporte" ? (
                            <div className='grid gap-2'>
                                <p>Nº de Passaporte</p>
                                <Input
                                    placeholder="Nº de passaporte do cliente"
                                    value={numeroPassaporteParticular}
                                    onChange={handleNumeroPassaporteChange}
                                    type="text"

                                />
                            </div>
                        ) : escolhaDeDocumento === "cartaodecidadao" ? (
                            <div className='grid gap-2'>
                                <p>Nº Cartão de Cidadão</p>
                                <Input
                                    placeholder="Nº Cartão de Cidadão"
                                    value={numeroPassaporteParticular}
                                    onChange={handleNumeroPassaporteChange}
                                    type="text"

                                />
                            </div>
                        ) : null}
                    </>
                ) : null}





                {tipoContrato !== "venda" ? (
                    <div className='grid gap-2'>
                        <p>Valor do serviço prestado</p>
                        <Input
                            placeholder="xxxx,xx"
                            value={valorServicoPrestado}
                            onChange={handleValorServicoPrestadoChange}
                            type="text"
                            required
                        />
                    </div>
                ) : null}

                {tipoContrato !== "venda" || tipoContrato !== "procuracao" ? (
                    <div className='grid gap-2'>
                        <p>Email do responsável/tech sale</p>
                        <Input
                            placeholder="Email do responsável/tech sale"
                            value={emailResponsavel}
                            onChange={handleEmailRsponsavelChange}
                            type="text"
                            required
                        />
                    </div>
                ) : null}


            </div>



            {tipoContrato === "venda" && tipoSociedade === "particular" ? (
                <>
                    <p className="font-semibold">Detalhes da Viatura:</p>
                    <div className="grid grid-cols-2 gap-2">

                        <div className='grid gap-2'>
                            <p>Marca da viatura</p>
                            <Input
                                placeholder="Marca da viatura"
                                value={marcaViatura}
                                onChange={handleMarcaDaViaturaChange}
                                type="text"
                                required
                            />
                        </div>

                        <div className='grid gap-2'>
                            <p>Modelo da viatura</p>
                            <Input
                                placeholder="Modelo da viatura"
                                value={modeloViatura}
                                onChange={handleModeloDaViaturaChange}
                                type="text"
                                required
                            />
                        </div>

                        <div className='grid gap-2'>
                            <p>Matrícula da viatura</p>
                            <Input
                                placeholder="Matrícula da viatura"
                                value={matriculaViatura}
                                onChange={handleMatriculaDaViaturaChange}
                                type="text"
                                required
                            />
                        </div>

                        <div className='grid gap-2'>
                            <p>VIN da viatura</p>
                            <Input
                                placeholder="VIN da viatura"
                                value={vinViatura}
                                onChange={handleVINDaViaturaChange}
                                type="text"
                                required
                            />
                        </div>

                    </div>
                    <p className="font-semibold">Outros detalhes:</p>
                    <div className="grid grid-cols-2 gap-2">
                        <div className='grid gap-2'>
                            <p>Percentagem serviço prestado (%)</p>
                            <Input
                                placeholder="Percentagem serviço prestado (%)"
                                value={percentagemServicoPrestado}
                                onChange={handlePercentagemServicoPrestadoChange}
                                type="text"
                                required
                            />
                        </div>

                        <div className='grid gap-2'>
                            <p>Valor depósito</p>
                            <Input
                                placeholder="Valor depósito"
                                value={valorDeposito}
                                onChange={handleValorDepositolChange}
                                type="text"
                                required
                            />
                        </div>
                    </div>
                </>
            ) : null}



        </>


    )
}

