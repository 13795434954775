import React, { useState, useEffect } from "react";
import { Form, Button, Input, Spin, Select, message, Popover, Modal } from "antd";
import axios from "axios";
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import logoIcon from '../../assets/hubspot-icon.svg';
import TopBarPropostaAssociada from "./calculo/top-bar-proposta-associada";
import { IoIosArrowBack, IoMdHelpCircle } from "react-icons/io";
import PassoAPasso from "../passo-a-passo/passo-a-passo";
import { useNavigate } from "react-router-dom";
import PartilharComCliente from "./calculo/partilhar-com-cliente";
import { MdModeEdit } from "react-icons/md";
import { FiExternalLink } from "react-icons/fi";

const { Option } = Select;

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);


export default function PT_PartilharProposta({
    idDoDealAssociar,
    checkDataHubspot,
    setUrlCarroLink,
    valorFinalCredito,
    cilindrada,
    allDataLinkPartilhado,
    novoOuUsado,
    tipoDeCombustivel,
    emissoesGases,
    dataDeRegisto,
    particulas,
    tipoDeVeiculoParaOutros,
    tipoDeVeiculoParaInsencoes,
    outrosServicos,
    setEstadoDaPartilhaNomeDaProposta,
    precoFinalCarro,
    valorFinalISV,
    taxaDeServico,

    valorFinalIUC,
    iucCilindrada,
    iucCO2,
    iucAdicionalCO2,
    iucCoeficienteAnoMatriculo,
    iucAdicionalGasoleo,

    isvcomponentecilindrada,
    isvcomponenteambiental,
    isvReducaoAnosUsoCilindrada,
    isvReducaoAnosUsoAmbiental,
    isvAgravamentoParticulas,
    isvReducaoAnosUsoParticulas,
    isvTaxaAplicavelTabela,

    activeTabKeyResultadoSimulacao,

    showHubspot,
    setShowHubspot,
    setCheckDataHubspot,
    idDaPropostaEncontrada,
    setIdDaPropostaEncontrada,
    setNomeDoDeal,
    setNomeDoClienteDeal,
    setEmailDoClienteDeal,
    setDataDeAssociacaoDoDeal,
    setEtapaDoDeal,
    setPipelineDoDeal,

    setPartilhaNomeDaProposta,
    partilhaNomeDaProposta,
    tituloDaPropostaChaveNaMaoComLinkFinalParticular,
    precoFinalDoCarroSemIva,
    valorChaveNaMaoEmpresa,
    valorChaveNaMaoParticular,
    formatarNumero,
    dataCarros,
    setTituloDaPropostaChaveNaMao,
    tituloDaPropostaChaveNaMao,
    urlCarroLink,
    setNomeDaProposta,
    nomeDaProposta,
    saveToken,
    iDTokenAuthenticated,
    erroPedido,
    tabResultadoSimulacao,
    valorFinanciadoCredito,
    isCheckedCredito,
    comissaoCredito,
    valorDaViatura,
    valorFinalaoCliente,
    valorPedirAFabi,
    taxaDeJuroCredito,
    entradaCredito,
    prestacoesMesesCredito,
    comissaoDeServico,
    setIsModalOpen,
    setIsModalEditarVisible,
    nomeGeradoDaProposta,
    setAlertaLinkPartilhado,
    linkPartilhavel,
    setLinkPartilhavel,
    propostaAceiteAprovada,
    tipoDaProposta,
    setTipoDaProposta,
    estadoDaProposta,
    setEstadoDaProposta
}) {

    // console.log(tipoDaProposta)

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [form] = Form.useForm(); // Inicializa o hook do formulário

    const [loading, setLoading] = useState(false);

    const [tituloDoMobileDaViatura, setTituloDoMobileDaViatura] = useState('');

    const [isModalPassoAPassoVisible, setIsModalPassoAPassoVisible] = useState(false); //popup do passo a passo
    const [current, setCurrent] = useState(0);


    useEffect(() => {

        setTituloDoMobileDaViatura(allDataLinkPartilhado?.titulo)

    }, [allDataLinkPartilhado]);


    const handlePartilharProposta = (values) => {

        setLoading(true)

        let listaDeDadosAtualizados = {
            cilindrada: cilindrada,
            novo: novoOuUsado,
            tipo_de_combustivel: tipoDeCombustivel,
            emissoes_numerico: emissoesGases,
            data_registo: dataDeRegisto,
            particulas: particulas,
            tipo_de_veiculo: tipoDeVeiculoParaOutros,
            tipo_de_isencoes: tipoDeVeiculoParaInsencoes,
            outros_servicos: outrosServicos,
            isv: valorFinalISV,
            iuc: valorFinalIUC,

            iuc_cilindrada: iucCilindrada,
            iuc_co2: iucCO2,
            iuc_adicional_co2: iucAdicionalCO2,
            iuc_coeficiente_ano_matriculo: iucCoeficienteAnoMatriculo,
            iuc_adicional_gasoleo: iucAdicionalGasoleo,

            isv_componente_cilindrada: isvcomponentecilindrada,
            isv_componente_ambiental: isvcomponenteambiental,
            isv_reducao_anos_uso_cilindrada: isvReducaoAnosUsoCilindrada,
            isv_reducao_anos_uso_ambiental: isvReducaoAnosUsoAmbiental,
            isv_agravamento_particulas: isvAgravamentoParticulas,
            isv_reducao_anos_uso_particulas: isvReducaoAnosUsoParticulas,
            isv_taxa_aplicavel_tabela: isvTaxaAplicavelTabela,

            taxadeservico: taxaDeServico,
            comissaodeservico: comissaoDeServico,
            precofinal: precoFinalCarro,
            valor_chavenamao_particular: valorChaveNaMaoParticular,
            valor_chavenamao_empresa: valorChaveNaMaoEmpresa,
            empresa: tabResultadoSimulacao,


        };

        // Condicionalmente adicionar os dados de crédito se isCheckedCredito for verdadeiro
        if (isCheckedCredito) {
            listaDeDadosAtualizados = {
                ...listaDeDadosAtualizados,
                comissao_credito: comissaoCredito,
                processo_credito_valor_viatura: valorDaViatura,
                processo_credito_valor_final_ao_cliente: valorFinalaoCliente,
                processo_credito_valor_pedir_fabi: valorPedirAFabi,
                calculadora_credito_taxa_juros: taxaDeJuroCredito,
                calculadora_credito_entrada: entradaCredito,
                calculadora_credito_valor_financiado: valorFinanciadoCredito,
                calculadora_credito_meses: prestacoesMesesCredito,
                calculadora_credito_valor_final: valorFinalCredito,

            };
        }

        // console.log(nomeDaProposta)
        // console.log(tipoDaProposta)

        const dataCriarLink = {
            nome_proposta: nomeDaProposta,
            tipo_de_proposta: tipoDaProposta,
            estado_proposta_hubspot: estadoDaProposta,
            array_dados: dataCarros,
            user_id: iDTokenAuthenticated,
            user_token: saveToken,
            id_deal: idDoDealAssociar,
            array_dados_atualizado: listaDeDadosAtualizados,
        };

        axios.post(`${process.env.REACT_APP_CREATE_PROPOSTA_PT}`, dataCriarLink)
            .then((response) => {
                if (response.data.sucesso === true) {
                    // console.log(response.data)
                    setTipoDaProposta(response.data.tipo_proposta)
                    setEstadoDaProposta(response.data.estado_proposta)

                    // Mover o console.log para depois da atualização do estado
                    // console.log("Tipo da Proposta antes de mudar:", tipoDaProposta);
                    // console.log("Estado da Proposta antes de mudar:", estadoDaProposta);

                    setLinkPartilhavel(response.data.link_proposta);
                    setShowHubspot(true);
                    setIdDaPropostaEncontrada(response.data.id_proposta);
                    setAlertaLinkPartilhado(response.data.mensagem);
                    if (response.data.hubspot.deal) {
                        const hubspot = response.data.hubspot;
                        setCheckDataHubspot(hubspot);
                        setNomeDoDeal(hubspot.deal.info.properties.dealname);
                        setNomeDoClienteDeal(hubspot.contacto.info.properties.firstname);
                        setEmailDoClienteDeal(hubspot.contacto.info.properties.email);
                        setDataDeAssociacaoDoDeal(hubspot.deal.data_registo_hubspot);
                        setEtapaDoDeal(hubspot.deal.info.properties.dealstage);
                        setPipelineDoDeal(hubspot.deal.info.properties.pipeline);
                    }
                    message.success('Proposta gerada com sucesso!');
                    setLoading(false)
                } else {
                    message.error('Erro, tente novamente!');
                    setLoading(false)
                }
            })
            .catch((error) => {
                message.error('Erro, tente novamente!');
                setLoading(false)
            });
    };


    const handleChangeNomeDaProposta = (e) => {
        setNomeDaProposta(e.target.value);
        setPartilhaNomeDaProposta(nomeDaProposta)
        setShowHubspot(false)
    }

    const showModal = () => {
        setIsModalOpen(true);
    };



    const voltarAPesquisar = () => {
        setUrlCarroLink('');
        navigate('/pt/simulador')
        setEstadoDaPartilhaNomeDaProposta(false)
    };


    const handlePassoAPasso = () => {
        setIsModalPassoAPassoVisible(true);

        if (idDaPropostaEncontrada && checkDataHubspot.length === 0) {
            setCurrent(1);
        } else {
            setCurrent(0);
        }

    };

    const handleCancelComp = () => {
        setIsModalPassoAPassoVisible(false); // Fecha o modal
    };

    const handleTipoDeProposta = (value) => {
        setTipoDaProposta(value);
    };

    const showModalEditar = (id_user) => {
        setIsModalEditarVisible(true);
    };


    // console.log(tipoDaProposta)
    // console.log(estadoDaProposta)


    return (

        <>
            <div className={`flex gap-4 items-center ${loading ? 'justify-center' : 'justify-between'} card`}>
                {loading ? (
                    <div className='flex items-center gap-5'>
                        <Spin indicator={antIcon} />
                        <p className='text-white'>{t('partilha_da_proposta.gerar')}</p>
                    </div>
                ) : idDaPropostaEncontrada && checkDataHubspot.length === 0 ? (
                    <>
                        {/* <p className='text-sm font-normal'></p> */}
                        <div className="flex gap-4 items-center">
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="flex gap-2 items-center bg-white text-black font-bold rounded"
                                onClick={voltarAPesquisar}
                            >
                                <IoIosArrowBack /> Voltar a Pesquisar
                            </Button>
                            <div>|</div>
                            <p className='text-lg font-bold w-full'>Proposta nº: {idDaPropostaEncontrada}</p>
                        </div>
                        <div className="flex gap-4 items-center">
                            {tipoDaProposta === "proposta_cliente" && estadoDaProposta === "nao_associada" ? (
                                <>
                                    <Button
                                        className="flex gap-2 text-black items-center font-bold rounded p-2 w-max hubspot-btn"
                                        onClick={showModal}
                                    >
                                        <img
                                            src={logoIcon}
                                            alt="Logo"
                                            className="w-6 object-contain"
                                        />
                                        Associar Proposta
                                    </Button>
                                    <div>|</div>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="bg-yellow-300 text-black font-bold rounded"
                                        onClick={handlePassoAPasso}
                                    >
                                        <IoMdHelpCircle size={20} />
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <PartilharComCliente
                                        tituloDoMobileDaViatura={tituloDoMobileDaViatura}
                                        linkPartilhavel={linkPartilhavel}
                                        dataCarros={dataCarros}
                                        formatarNumero={formatarNumero}
                                        valorFinalISV={valorFinalISV}
                                        activeTabKeyResultadoSimulacao={activeTabKeyResultadoSimulacao}
                                        nomeDaProposta={nomeDaProposta}
                                        precoFinalCarro={precoFinalCarro}
                                        taxaDeServico={taxaDeServico}
                                        valorChaveNaMaoParticular={valorChaveNaMaoParticular}
                                        precoFinalDoCarroSemIva={precoFinalDoCarroSemIva}
                                        valorChaveNaMaoEmpresa={valorChaveNaMaoEmpresa}
                                    />
                                    <label className="flex items-center gap-2 cursor-pointer">
                                        <MdModeEdit
                                            className="text-2xl text-white cursor-pointer"
                                            title="Editar Proposta"
                                            onClick={showModalEditar}
                                        />
                                        <p onClick={showModalEditar}>Editar Simulação</p>
                                    </label>

                                    <label className="flex items-center gap-2 cursor-pointer">
                                        <FiExternalLink
                                            className="text-2xl text-white cursor-pointer"
                                            title="Abrir link externo"
                                            onClick={() => window.open(linkPartilhavel, '_blank')}
                                        />
                                        <p onClick={() => window.open(linkPartilhavel, '_blank')}>Abrir</p>
                                    </label>
                                </>
                            )}
                        </div>
                    </>
                ) : checkDataHubspot.length === 0 ? (
                    <div className="w-full flex gap-4 items-center justify-between">
                        <div className="flex gap-4 items-center">
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="flex gap-2 items-center bg-white text-black font-bold rounded"
                                onClick={voltarAPesquisar}
                            >
                                <IoIosArrowBack /> Voltar a Pesquisar
                            </Button>
                            <div>|</div>
                            <p className='text-lg font-bold w-full'>Gerar Proposta</p>
                        </div>
                        <Form
                            form={form}
                            onFinish={handlePartilharProposta}
                            layout="vertical"
                            initialValues={{ tipoDaProposta }} // Defina o valor inicial aqui
                        >
                            <div className="form-gerar-proposta" style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                                <Form.Item
                                    name="nomeDaProposta"
                                    rules={[{ required: true, message: 'Por favor, insira o nome da proposta.' }]}
                                    style={{ marginRight: '16px' }}
                                >
                                    <Input
                                        placeholder={t('partilha_da_proposta.input')}
                                        style={{ width: 400 }}
                                        onChange={handleChangeNomeDaProposta}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="tipoDaProposta"
                                    rules={[{ required: true, message: 'Por favor, selecione o tipo de proposta.' }]}
                                    style={{ marginRight: '16px' }}
                                >
                                    <Select
                                        placeholder="Tipo de Proposta"
                                        style={{ width: 230 }}
                                        value={tipoDaProposta}
                                        onChange={handleTipoDeProposta}
                                    >
                                        <Option value="proposta_cliente">Proposta Cliente</Option>
                                        <Option value="proposta_de_content">Proposta de Content</Option>
                                        <Option value="proposta_interna">Proposta Interna</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="bg-white text-black font-bold rounded"
                                    >
                                        {t('partilha_da_proposta.criar')}
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                ) : null}


                {checkDataHubspot.length !== 0 && (
                    <TopBarPropostaAssociada
                        setIsModalEditarVisible={setIsModalEditarVisible}
                        checkDataHubspot={checkDataHubspot}
                        tituloDoMobileDaViatura={tituloDoMobileDaViatura}
                        linkPartilhavel={linkPartilhavel}
                        dataCarros={dataCarros}
                        formatarNumero={formatarNumero}
                        valorFinalISV={valorFinalISV}
                        activeTabKeyResultadoSimulacao={activeTabKeyResultadoSimulacao}
                        nomeDaProposta={nomeDaProposta}
                        precoFinalCarro={precoFinalCarro}
                        taxaDeServico={taxaDeServico}
                        valorChaveNaMaoParticular={valorChaveNaMaoParticular}
                        precoFinalDoCarroSemIva={precoFinalDoCarroSemIva}
                        valorChaveNaMaoEmpresa={valorChaveNaMaoEmpresa}
                        setIsModalOpen={setIsModalOpen}
                        setIsModalPassoAPassoVisible={setIsModalPassoAPassoVisible}
                        setCurrent={setCurrent}
                        estadoDaProposta={estadoDaProposta}
                        setEstadoDaProposta={setEstadoDaProposta}
                        idDaPropostaEncontrada={idDaPropostaEncontrada}
                        saveToken={saveToken}
                        iDTokenAuthenticated={iDTokenAuthenticated}
                    />
                )}


                <Modal
                    title="Passo a Passo"
                    visible={isModalPassoAPassoVisible}
                    onCancel={handleCancelComp}
                    footer={null}
                >
                    <PassoAPasso setIsModalPassoAPassoVisible={setIsModalPassoAPassoVisible} current={current} setCurrent={setCurrent} />
                </Modal>

            </div>

        </>

    );
}


