import React,  { useState, useRef }  from "react";
import { Input, Select, Button, Checkbox, Collapse, Tooltip, Alert, notification, Modal } from 'antd';
import { AlertaMsg } from "./mensagens/mensagem_alerta";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DatePicker } from 'antd';
import { Option } from "antd/lib/mentions";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { RiEmotionUnhappyLine, RiEmotionNormalLine, RiEmotionHappyLine } from 'react-icons/ri'; // Importe os ícones necessários do react-icons
import { ExclamationCircleOutlined } from "@ant-design/icons";


dayjs.extend(customParseFormat);

//const { Option } = Select;
const { Panel } = Collapse;

export default function CalculoChaveMao({
    urlCarroLink,
    dataGeralISV,
    allDataLinkPartilhado,
    validarViaturaEletrico,
    temParametros,
    dataDeRegisto,
    novoOuUsado,
    tipoDeCombustivel,
    setTaxaDeServico,
    tipoDeVeiculoParaOutros,
    hibridoIsChecked,
    setCo2IsChecked,
    setHibridoValidationIsChecked,
    setNovoOuUsado,
    setTituloDaPropostaChaveNaMao,
    setOutrosServicos,
    setAlertasNoPedido,
    setCilindrada,
    setDataDeRegisto,
    setEmissoesGases,
    setHibridoIsChecked,
    setTipoDeCombustivel,
    setParticulas,
    setTipoDeVeiculoParaOutros,
    setWltpIsChecked,
    setTipoDeVeiculoParaInsencoes,
    alertasNoPedido,
    taxaDeServico,
    tipoDeVeiculoParaInsencoes,
    hibridoValidationIsChecked,
    outrosServicos,
    cilindrada,
    emissoesGases,
    wltpIsChecked,
    particulas,
    validarAnoDeRegisto,
    co2IsChecked,
    valorFinalISV,
    quantidadeDeKM,
    anoDoRegisto,
    IgnorarAvisos,
    formatarNumero,
    comissaoDeServico,
    setComissaoDeServico
}) {



    // const [api, contextHolder] = notification.useNotification();
    const timeoutRef = useRef(null);

    // const openNotification = (pauseOnHover) => {
    //     api.open({
    //         message: 'Atenção',
    //         description:
    //             'A taxa de serviço só pode ser inferior a 2800€ se for aprovada por um membro do board.',
    //         showProgress: true,
    //         pauseOnHover,
    //         placement: 'top', 
    //         icon: <ExclamationCircleOutlined style={{ color: '#faad14' }} />, // Alert icon
    //     });
    // };


    const warning = () => {
        Modal.warning({
          title: 'Atenção',
          content: 'A taxa de serviço só pode ser inferior a 2800€ se for aprovada por um membro do board.',
          className: 'custom-warning-modal',
        });
      };

    const handleTaxaDeServicoChange = (e) => {
        const value = e.target.value;
        setTaxaDeServico(value);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        if (value < 2800) {
            timeoutRef.current = setTimeout(() => {
                warning()
            }, 1000); // 1-second timeout
        }
    };

    const handleTipoDeVeiculoChange = (e) => {
        setTipoDeVeiculoParaInsencoes(e);
    };


    const handleNovoOuUsadoChange = (e) => {

        setNovoOuUsado(e);
    };


    const handleTipoDeVeiculoParaOutrosChange = (e) => {
        setTipoDeVeiculoParaOutros(e);
    };


    const handleDataDeRegistoChange = (e) => {
        let day = "";
        let month = "";
        let year = "";

        if (e && e instanceof Object && e.hasOwnProperty('$y') && e.hasOwnProperty('$M') && e.hasOwnProperty('$D')) {
            // Extract day, month, and year
            day = e.$D.toString().padStart(2, '0');
            month = (e.$M + 1).toString().padStart(2, '0'); // Month is zero-based
            year = e.$y.toString();
        }

        setDataDeRegisto(month + '/' + year);
    };


    const handleCilindradaChange = (e) => {
        setCilindrada(e.target.value);
    };


    const handleTipoDeCombustivelChange = (e) => {
        setTipoDeCombustivel(e);
    };


    const handleParticulasChange = (e) => {
        setParticulas(e);
    };


    const handleEmissoesGasesChange = (e) => {
        setEmissoesGases(e.target.value);
    };



    /// VALIDAR DATA MM/YYYY
    const isValidDateString = (dateString) => {
        // Add your custom date validation logic here
        // You can use libraries like Date-fns or moment.js for advanced date validation
        // For simplicity, this example only checks if the input follows the MM/YYYY format

        const regex = /^(0[1-9]|1[0-2])\/\d{4}$/; // MM/YYYY format
        return regex.test(dateString);
    };



    const handleHibridoChange = (e) => {
        setHibridoIsChecked(e.target.checked);
    };


    const handleWltpChange = (e) => {
        setWltpIsChecked(e.target.checked);
    };


    const handleOutrosServicosChange = (e) => {
        setOutrosServicos(e);
    };

    const handleCO2Change = (e) => {
        setCo2IsChecked(e.target.checked);
        setAlertasNoPedido(!alertasNoPedido)
    };

    const handlehibridoValidationChange = (e) => {
        setHibridoValidationIsChecked(e.target.checked);
    };


    const dayjsDate = (date) => {
        if (date && date !== '') {
            return dayjs(date, 'MM/YYYY')
        }
    }


    const calcularComissao = (taxa) => {
        if (!taxa || isNaN(taxa)) return 0;

        let resultado = taxa * 0.039
        setComissaoDeServico(resultado)

        return resultado
    };



    return (

        <div className='card grid gap-4'>
            <div className='flex justify-between items-center gap-4'>
                <div className='grid gap-1'>
                    <p className='text-lg font-semibold'>Cálculo do ISV</p>
                </div>
            </div>
            <hr></hr>
            <form className='grid gap-5'>
                <div>
                    <div className="mx-auto grid gap-4">
                        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                            {validarViaturaEletrico ? (
                                <>
                                    <div className='grid gap-2'>
                                        <p>Novo / Usado</p>
                                        <Select
                                            placeholder="Selecione"
                                            value={novoOuUsado ? novoOuUsado : undefined}
                                            onChange={handleNovoOuUsadoChange}
                                            disabled={temParametros}
                                        >
                                            <Option value="">Selecione</Option>
                                            <Option value="1">Novo</Option>
                                            <Option value="0">Usado</Option>
                                        </Select>
                                    </div>

                                    <div className='grid gap-2'>
                                        <p>Tipo de Combustível</p>
                                        <Select
                                            placeholder="Selecione"
                                            value={tipoDeCombustivel ? tipoDeCombustivel : undefined}
                                            onChange={handleTipoDeCombustivelChange}
                                            disabled={temParametros}
                                        >
                                            <Option value="">Selecione</Option>
                                            <Option
                                                value="Gasolina">Gasolina</Option>
                                            <Option
                                                value="Gasoleo">Gasoleo</Option>
                                            <Option
                                                value="Eletrico">Elétrico</Option>
                                        </Select>
                                    </div>

                                    {novoOuUsado === "Usado" ? (
                                        <div className='grid gap-2'>
                                            <p>Data 1º Registo</p>
                                            <DatePicker placeholder="MM/AAAA"
                                                defaultValue={dayjsDate(dataDeRegisto)}
                                                format={'MM/YYYY'}
                                                picker="month"
                                                onChange={handleDataDeRegistoChange}
                                                disabled={temParametros}
                                            />
                                        </div>
                                    ) : null}
                                </>
                            ) : (
                                <>
                                    <div className='grid gap-2'>
                                        <p>Cilindrada</p>
                                        <Input
                                            placeholder="Cilindrada"
                                            value={cilindrada}
                                            onChange={handleCilindradaChange}
                                            type={'number'}
                                            disabled={temParametros}
                                        />
                                    </div>

                                    <div className='grid gap-2'>
                                        <p>Novo / Usado</p>
                                        <Select
                                            placeholder="Selecione"
                                            value={novoOuUsado ? novoOuUsado : undefined}
                                            onChange={handleNovoOuUsadoChange}
                                            disabled={temParametros}
                                        >
                                            <Option value="">Selecione</Option>
                                            <Option value="Novo">Novo</Option>
                                            <Option value="Usado">Usado</Option>
                                        </Select>
                                    </div>



                                    <div className='grid gap-2'>
                                        <div className='grid gap-2'>
                                            <p>Tipo de Combustível</p>
                                            <Select
                                                placeholder="Selecione"
                                                value={tipoDeCombustivel ? tipoDeCombustivel : undefined}
                                                onChange={handleTipoDeCombustivelChange}
                                                disabled={temParametros}
                                            >
                                                <Option
                                                    value="">Selecione</Option>
                                                <Option
                                                    value="Gasolina">Gasolina</Option>
                                                <Option
                                                    value="Gasoleo">Gasoleo</Option>
                                                <Option
                                                    value="Eletrico">Elétrico</Option>
                                            </Select>
                                        </div>
                                        <div
                                            className='flex flex-row items-end'>
                                            <Checkbox checked={hibridoIsChecked}
                                                onChange={handleHibridoChange}
                                                style={{
                                                    color: 'white',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginBottom: '6px'
                                                }}
                                                disabled={temParametros}>
                                                Híbridos Plug-In
                                            </Checkbox>
                                        </div>
                                    </div>

                                    <div className='grid gap-2'>
                                        <div className='grid gap-2'>
                                            <p>Emissões Gases C02</p>
                                            <Input
                                                placeholder="Emissões Gases C02"
                                                value={emissoesGases}
                                                onChange={handleEmissoesGasesChange}
                                                type={'number'}
                                                disabled={temParametros}
                                            />
                                        </div>
                                        <div
                                            className='flex flex-row items-end'>
                                            <Checkbox
                                                checked={wltpIsChecked}
                                                onChange={handleWltpChange}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginBottom: '6px',
                                                    color: 'white',
                                                }}
                                                disabled={temParametros}
                                            >
                                                WLTP
                                            </Checkbox>

                                        </div>
                                    </div>

                                    {novoOuUsado === "Usado" ? (
                                        <div className='grid gap-2'>
                                            <p>Data 1º Registo</p>
                                            <DatePicker placeholder="MM/AAAA"
                                                defaultValue={dayjsDate(dataDeRegisto)}
                                                format={'MM/YYYY'}
                                                picker="month"
                                                onChange={handleDataDeRegistoChange}
                                                disabled={temParametros}
                                            />
                                        </div>
                                    ) : null}

                                    {tipoDeCombustivel === 'Gasoleo' && (
                                        <div className='grid gap-2'>
                                            <p>Particulas</p>
                                            <Select
                                                placeholder="Selecione"
                                                value={particulas ? particulas : undefined}
                                                onChange={handleParticulasChange}
                                                disabled={temParametros}
                                            >
                                                <Option
                                                    value="">Selecione</Option>
                                                <Option value="0">{'<'} 0,001
                                                    g/km</Option>
                                                <Option value="1">{'>'}= 0,001
                                                    g/km</Option>
                                            </Select>
                                        </div>
                                    )}

                                </>
                            )}
                        </div>
                        <Collapse>
                            <Panel header={<span
                                className="custom-collapse-header">Mais Detalhes</span>}
                                key="1">
                                <div className='grid gap-4'>
                                    <div className='grid gap-2'>
                                        <p>Tipo de Veículo</p>
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Selecione"
                                            value={tipoDeVeiculoParaOutros ? tipoDeVeiculoParaOutros : 'LigeiroPassageiros'}
                                            onChange={handleTipoDeVeiculoParaOutrosChange}
                                            disabled={temParametros || hibridoIsChecked}
                                        >
                                            <Option
                                                value="">Selecione</Option>
                                            <Option
                                                value="LigeiroPassageiros">Ligeiro
                                                de Passageiros</Option>
                                            <Option
                                                value="LSup2500kg">Ligeiro {'>'} 2500Kg</Option>
                                            <Option value="LMeMerc">Ligeiro
                                                Misto (cx 145x130),
                                                Mercadorias 4x2</Option>
                                            <Option value="LMercCF">Ligeiro
                                                Mercadorias Caixa
                                                Fechada</Option>
                                            <Option value="LMerc3Lug">Ligeiro
                                                Mercadorias 3
                                                Lugares</Option>
                                            <Option value="LMerc4x4">Ligeiro
                                                Mercadorias 4x4</Option>
                                            <Option
                                                value="LMerc4x2PB3500">Ligeiro
                                                Mercadorias, Cx.Fechada
                                                s/Cab. Integrada,
                                                PB=3500kg</Option>
                                            <Option value="LMercNT">Ligeiro
                                                Mercadorias Não
                                                Tributado pela Tabela
                                                B</Option>
                                            <Option value="LPGPL">Ligeiro
                                                Passageiros a
                                                GPL</Option>
                                            <Option value="LPGN">Ligeiro
                                                Passageiros a Gás
                                                Natural</Option>
                                            <Option
                                                value="Autocaravana">Autocaravana</Option>
                                            <Option value="MotoTriQuad">Motociclo,
                                                Triciclo,
                                                Quadriciclo</Option>
                                        </Select>
                                    </div>
                                    {/* {selecionarIsencao ? ( */}
                                    <div className='grid gap-2'>
                                        <p>Isenção</p>
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Selecione"
                                            value={tipoDeVeiculoParaInsencoes ? tipoDeVeiculoParaInsencoes : undefined}
                                            onChange={handleTipoDeVeiculoChange}
                                            disabled={temParametros}
                                        >
                                            <Option value="">Sem
                                                Isenção</Option>
                                            <Option
                                                value="familias_numerosas">Famílias
                                                numerosas</Option>
                                            <Option
                                                value="mudanca_residencia">Mudança
                                                de residência</Option>
                                            <Option
                                                value="pessoas_com_deficiência">Pessoas
                                                com deficiência</Option>
                                            <Option
                                                value="pessoas_com_deficiência_obriga_caixa_automatica">Pessoas
                                                com deficiência que
                                                obriga caixa
                                                automática</Option>
                                        </Select>
                                    </div>
                                    {/* ) : null} */}
                                    <div className='grid gap-2'>
                                        <p>Outros Serviços</p>
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Selecione"
                                            value={outrosServicos ? outrosServicos : undefined}
                                            onChange={handleOutrosServicosChange}
                                            disabled={temParametros}
                                        >
                                            <Option
                                                value="">Selecione</Option>
                                            <Option
                                                value="Legalização">Legalização</Option>
                                            <Option
                                                value="Legalização e Transporte">Legalização
                                                e Transporte</Option>
                                            <Option
                                                value="Sem outro serviço">Sem
                                                Outro Serviço</Option>
                                        </Select>
                                    </div>
                                </div>
                            </Panel>
                        </Collapse>
                        <div className='flex flex-col gap-2 text-md border-card bg-black'>
                            <p>
                                <span className="flex items-center gap-1">Taxa
                                    de serviço
                                    <Tooltip title="A taxa de serviço inclui o custo do transporte, seguro, legalização e todas as restantes despesas do processo de importação.">
                                        <AiOutlineInfoCircle className='icon-question' />
                                    </Tooltip></span>
                            </p>
                            <div className="flex items-center gap-2">
                                {/* {contextHolder} */}
                                <Input
                                    placeholder="Taxa de Serviço (€)"
                                    value={taxaDeServico}
                                    onChange={handleTaxaDeServicoChange}
                                    type="number"
                                    disabled={temParametros}
                                />
                                {taxaDeServico !== '' && (
                                    <div className="flex items-center gap-1">
                                        {taxaDeServico < 2800 && <span className="text-3xl">😔</span>}
                                        {taxaDeServico >= 2800 && taxaDeServico < 3000 && <span className="text-3xl">😁</span>}
                                        {taxaDeServico >= 3000 && <span className="text-3xl">🤑</span>}
                                    </div>
                                )}
                            </div>
                            <hr></hr>
                            <div className="flex items-center justify-between gap-2">
                                <span className="flex gap-1 text-sm justify-center items-center">
                                    Estimativa de comissão
                                    <Tooltip title="O valor apresentado é um calculo baseado na média de comissões de 2024, sendo que pode variar consoante os custos do deal (por exemplo, transporte mais caro).">
                                        <AiOutlineInfoCircle className='icon-question' />
                                    </Tooltip>
                                </span>
                                <span className="flex text-sm font-bold justify-center items-center">
                                    {taxaDeServico !== '' && `${formatarNumero(calcularComissao(taxaDeServico))}€`}
                                </span>
                            </div>

                        </div>

                    </div>

                    {!IgnorarAvisos && (
                        <div className="mt-4">
                            {validarAnoDeRegisto ? (
                                <div>
                                    <Checkbox checked={co2IsChecked}
                                        onChange={handleCO2Change} style={{
                                            color: 'white',
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '6px'
                                        }}
                                        disabled={temParametros}
                                    >
                                        Confirmei as Emissões de C02
                                    </Checkbox>
                                </div>
                            ) : null}


                            {hibridoIsChecked ? (
                                <div>
                                    <Checkbox checked={hibridoValidationIsChecked}
                                        onChange={handlehibridoValidationChange}
                                        style={{
                                            color: 'white',
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '6px'
                                        }}>
                                        Confirmei que a viatura tem no mínimo 50km autonomia eléctrica e menos de 50g/km CO2
                                    </Checkbox>
                                </div>
                            ) : null}

                            {quantidadeDeKM && anoDoRegisto ? (
                                <>
                                    <AlertaMsg
                                        mensagem="como a viatura tem menos de 6000 km e menos de 6 meses, irá pagar agravantes de Impostos (IVA + ISV) em Portugal." />
                                </>
                            ) : quantidadeDeKM ? (
                                <>
                                    <AlertaMsg
                                        mensagem="como a viatura tem menos de 6,000 km, irá pagar agravantes de Impostos (IVA + ISV) em Portugal." />
                                </>
                            ) : anoDoRegisto ? (
                                <>
                                    <AlertaMsg
                                        mensagem="a viatura tem menos de 6 meses, irá pagar agravantes de Impostos (IVA + ISV) em Portugal" />
                                </>
                            ) : null}

                            {alertasNoPedido ? (
                                <>
                                    <AlertaMsg
                                        mensagem="confirme as emissões antes de avançar" />
                                </>
                            ) : null}

                            {hibridoIsChecked && !hibridoValidationIsChecked ? (
                                <>
                                    <AlertaMsg
                                        mensagem="confirme que a viatura tem no mínimo 50km autonomia eléctrica e menos de 50g/km CO2" />
                                </>
                            ) : null}

                            {hibridoIsChecked && hibridoValidationIsChecked && (emissoesGases >= 50 || !urlCarroLink) ? (
                                <>
                                    <AlertaMsg
                                        mensagem="A viatura tem de ter menos de 50g/km CO2" />
                                </>
                            ) : null}

                            {!isValidDateString(dataDeRegisto) && novoOuUsado !== "Novo" ? (
                                <>
                                    <AlertaMsg
                                        mensagem="por favor insira uma data de 1º registo válida" />
                                </>
                            ) : null}

                        </div>
                    )}




                </div>
            </form >
            {dataGeralISV.erros ? (
                <div>
                    <p className='mt-5 text-red-600 text-sm'>
                        <span className='font-bold text-sm'>{dataGeralISV.erros}</span>
                    </p>
                </div>
            ) : null
            }
        </div >


    )
}