import React, { useState, useEffect } from 'react';
import { Button, Select, DatePicker, AutoComplete } from 'antd';
import axios from 'axios';
import { wait } from '@testing-library/user-event/dist/utils';

const { RangePicker } = DatePicker;
const { Option } = Select;

export default function FilterTabelaPropostas({ filtersSelected = {}, setFiltersSelected, saveToken, iDTokenAuthenticated, userData }) {


    const [utilizadores, setUtilizadores] = useState([]);


    useEffect(() => {
        const url = `${process.env.REACT_APP_OBTER_UTILIZADORES}?token=${saveToken}&user_id=${iDTokenAuthenticated}&bussiness_developer=1`;

        axios.get(url)
            .then((response) => {
                setUtilizadores(response.data.mensagem);
            })
            .catch((error) => {
            });
    }, []);

    const handleReset = () => {
        setFiltersSelected({});
    };

    const handleButtonClick = (filterType, value) => {
        setFiltersSelected(prev => ({ ...prev, [filterType]: value }));
    };


    return (
        <div className='flex flex-row gap-2 mb-7 items-center'>
            Filtros
            {Object.keys(filtersSelected).length > 0 && (
                <>
                    <Button
                        type="primary"
                        className='shadow-none font-bold text-black bg-white'
                        onClick={handleReset}
                    >
                        Limpar Filtros
                    </Button>
                    <div>|</div>
                </>
            )}
            {userData.roles[0] === "administrator" && (
                <>
                    <Select
                        placeholder={'Business Developer'}
                        className='select-filters capitalize'
                        onChange={(value) => handleButtonClick('id_criou', value)}
                        value={filtersSelected.id_criou || undefined}
                        style={{ width: '200px' }} // Define a largura de 200px
                    >
                        {utilizadores.map((utilizador) => (
                            <Option key={utilizador.id_user} value={utilizador.id_user}>
                                {utilizador.nome_criou}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        placeholder={'País'}
                        className='select-filters capitalize'
                        onChange={(value) => handleButtonClick('paisPropostas', value)}
                        value={filtersSelected.paisPropostas || undefined}
                        style={{ width: '100px' }} // Define a largura de 200px
                    >
                        <Select.Option key="PT" value="pt" className="capitalize">Portugal</Select.Option>
                        <Select.Option key="ES" value="es" className="capitalize">Espanha</Select.Option>
                    </Select>
                </>
            )}

            <Select
                placeholder={'Estado da Proposta'}
                className='select-filters capitalize'
                onChange={(value) => handleButtonClick('dealHubspotFechado', value)}
                value={filtersSelected.dealHubspotFechado || undefined}
                style={{ width: '150px' }} 
            >
                <Select.Option key="Aberto" value="1" className="capitalize">Aceite</Select.Option>
                <Select.Option key="Fechado" value="0" className="capitalize">Em Aberto</Select.Option>
            </Select>
        </div>
    );
}
