import { BiError } from "react-icons/bi";
import { useTranslation } from 'react-i18next';

export function AlertaMsg(props) {

    const { t } = useTranslation();


    return (
        <div className="mensagem-de-erro flex flex-row items-center gap-4 rounded p-2">
            <BiError />
            <span><strong>Alerta</strong>, {props.mensagem}.</span >
        </div>
    )
}
