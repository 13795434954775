import React from "react";
import { Link } from 'react-router-dom';
import { BiError } from "react-icons/bi";
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';


export default function ErroPagina({ }) {

    const { t } = useTranslation();

    return (
        <div className="sec-geral-top login-section card">
            <div className="form main flex flex-col align-center gap-4">
                <div className='flex flex-col justify-center items-center gap-4 text-center'>
                    <BiError className='iconc' />
                    <div className="flex-nogap">
                        <h1 className="text-center text-base font-bold">UPS...</h1>
                        <p className="texto-centro">{t('pagina_erro.frase_principal')}</p>
                    </div>
                    <Link to="/">
                        <Button type="primary" className="bg-white text-black font-bold rounded">
                            {t('pagina_erro.cta')}
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}
