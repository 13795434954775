import React from "react";
import { Collapse } from 'antd';

const { Panel } = Collapse;

export default function DadosAnterioresContratos({
    tipoProp,
    tipoPropSociedade,
    tipoPropContrato
}) {
    return (
        <div className='flex flex-col gap-4'>
            <Collapse defaultActiveKey={['1']}>
                <Panel header={<span className="custom-collapse-header">Clique para consultar dados anteriores</span>} key="1">
                    <div className='flex flex-col'>
                        <p className='capitalize font-semibold'><span className='text-neutral-500 normal-case bold'>Tipo:</span> {tipoProp}</p>
                        <p className='capitalize font-semibold'><span className='text-neutral-500 normal-case'>Cliente:</span> {tipoPropSociedade}</p>
                        {tipoProp !== "procuracao" && tipoProp !== "credito" && tipoProp!== "autorizacao_levantamento" && tipoProp!== "modelo_9" ? (
                            <p className='capitalize font-semibold'><span className='text-neutral-500 normal-case'>Tipo de Contrato:</span> {tipoPropContrato}</p>
                        ) : null}
                    </div>
                </Panel>
            </Collapse>
        </div>
    )
}
