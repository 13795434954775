import React, { useRef } from 'react';
import { Input, Button } from 'antd';
import { FiDelete } from 'react-icons/fi';
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { IoIosBackspace } from 'react-icons/io';




export default function PesquisaSimulacao({ setEstadoDaPartilhaNomeDaProposta, setEsconde, urlCarroLink, setUrlCarroLink }) {

    const { t } = useTranslation();

    const navigate = useNavigate();
    const inputRef = useRef(null);
    const location = useLocation();


    const handleUrlCarroLinkChange = (e) => {
        setUrlCarroLink(e.target.value);
        setEstadoDaPartilhaNomeDaProposta('')
    };



    const handleRefresh = () => {
        setEsconde(false);
        setUrlCarroLink('');
        //preciso de dar focus ao input em react
        inputRef.current.focus();

        //remove parametros
        const parametros = new URLSearchParams(location.search)
        parametros.delete('nome_proposta');
        parametros.delete('id_proposta');
        const url = `?${parametros.toString()}`;
        navigate(url, { replace: true });
    };

    return (
        <>
            <div className='flex flex-row sm:flex-row gap-2 items-center justify-between'>
                <Input
                    ref={inputRef}
                    placeholder={`${t('header.placeholder_pesquisa')}`}
                    value={urlCarroLink}
                    onChange={handleUrlCarroLinkChange}
                />
                <Button type="primary" htmlType="submit" to="/"
                    className="bg-white text-black font-bold rounded" onClick={handleRefresh}>
                    <IoIosBackspace className='iconc' />
                </Button>
            </div>
        </>
    );

}