import React, { useEffect, useState, useRef } from 'react';
import { Input, Table, Image, Spin, Button, Checkbox, Modal, AutoComplete } from 'antd';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { InformacaoMsg } from '../../mensagens/mensagem-informacao';
import { IoIosBackspace, IoMdAdd } from 'react-icons/io';
import logo from '../../../../assets/hubspot-icon.svg';
import { IoCloseCircleOutline, IoSearch } from "react-icons/io5";
import logonormal from '../../../../assets/hubspot.svg';



const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);



export default function ConsultarListaDeDealsHubspot({
    currentLanguage,
    idDaPropostaEncontrada,
    saveToken,
    iDTokenAuthenticated,
    setIdDaPropostaEncontrada }) {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [listaDeDeals, setListaDeDeals] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [data, setData] = useState([]);
    const [pesquisaEmailDeal, setPesquisaEmailDeal] = useState('');
    const [listaDeDealsPesquisa, setListaDeDealsPesquisa] = useState([]);
    const inputRef = useRef(null);


    useEffect(() => {

        setLoading(true)

        const url = `${process.env.REACT_APP_GET_PROCURAR_DEALS}?token=${saveToken}&user_id=${iDTokenAuthenticated}&lang=${currentLanguage}&live`;

        axios.get(url)
            .then((response) => {

                setLoading(false)
                setListaDeDeals(response.data.deals);

            })
            .catch((error) => {
                setLoading(true)
                console.error('Error occurred:', error);
            });
    }, []);


    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };


    const filteredPropostas = listaDeDeals.filter((data) => {
        const nomeDoDeal = data.properties.dealname.toLowerCase();
        // const emailDoCliente = data.properties.email.toLowerCase();

        const searchQueryLowerCase = searchQuery.toLowerCase();

        return (
            nomeDoDeal.includes(searchQueryLowerCase)
        );
    });


    const handleApagar = () => {
        setSearchQuery('')
    }



    const showModal = (value) => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };



    //pesquisa INPUT
    const handlePesquisaDealChange = (value) => {

        // console.log(value)

        if (value) {

            setPesquisaEmailDeal(value);

            let url = `${process.env.REACT_APP_GET_PROCURAR_DEALS}?email=${value}&token=${saveToken}&user_id=${iDTokenAuthenticated}&live`;

            axios.get(url)
                .then((response) => {
                    // console.log('responseresponseresponse', response)
                    const email = response.data.contactos.map((item) => ({
                        value: item.properties.email,
                        label: `${item.properties.firstname} ${item.properties.lastname || ''} | (${item.properties.email}) | (${item.properties.hs_calculated_phone_number || item.properties.mobilephone || ''})`,
                        deals: item.deals,
                        id: item.id
                    }));
                    // console.log(email)
                    //setListaDeDealsPesquisa(email);
                    // console.log(estadoDoAlerta)
                    setData(email)
                })
                .catch((error) => {
                    console.error('Error occurred:', error);
                });
        } else {
            setData([]);
        }

    };



    const handlePesquisaDealSelect = (value) => {
        const selectedOption = data.find(option => option.value === value);
        // console.log('se', selectedOption.id)
        if (selectedOption) {
            navigate(`/pt/hubspot/perfil-contacto/?id_contacto=${selectedOption.id}`);
        }



    };


    const handleClearEmail = () => {
        setPesquisaEmailDeal('')
    }



    const handleClosePopup = () => {
        setIsModalOpen(false)
    }



    return (
        <>
            <div>
                {loading ? (
                    <>
                        <div className='card'>
                            <div className="flex flex-col gap-4 justify-center">
                                <Spin indicator={antIcon} />
                                <p className='text-white text-center'>{t('media_mercado.loading')}</p>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="card grid gap-4">
                        <div className='grid gap-4'>
                            <div className='flex items-center gap-4'>
                                <p className='text-lg font-bold'>{t('hubspot_listadedeals.titulo_principal')}</p>
                                <div>|</div>
                                <Button
                                    className="flex gap-2 hubspot-btn items-center font-bold rounded"
                                    onClick={showModal}
                                >
                                    <div className='flex items-center gap-2'>
                                        <img
                                            src={logo}
                                            alt="Logo"
                                            className="w-6 object-contain"
                                        />
                                        Pesquisa Completa
                                    </div>
                                </Button>
                            </div>
                            <p className='text-sm font-normal'>Tabela mostra os últimos 100 deals.</p>

                        </div>

                        <hr></hr>
                  
                        <div className="flex gap-2">
                            <Input
                                type="text"
                                placeholder="Procurar por nome do deal"
                                value={searchQuery}
                                onChange={handleInputChange}
                            />
                            <Button type="primary" htmlType="submit" to="/"
                                className="bg-white text-black font-bold rounded" onClick={handleApagar}>
                                <IoIosBackspace className='iconc' />
                            </Button>
                        </div>
                     
                        <div className="w-full">
                            <div className="">
                                <Table
                                    className='hubspot-content'
                                    dataSource={filteredPropostas}
                                    rowKey={(deal) => deal.id}
                                    scroll={{ x: 'max-content' }}
                                    bordered

                                >

                                    <Table.Column
                                        title="ID do deal"
                                        key="id_deal"
                                        className='column-hubspot'
                                        render={(deal) => (
                                            <span>{deal.id}</span>
                                        )}
                                    />

                                    <Table.Column
                                        title="Nome do deal"
                                        key="nome_deal"
                                        className='column-hubspot'
                                        render={(deal) => (
                                            <>
                                                <Link className='capitalize' to={`/${currentLanguage}/hubspot/perfil-deal/?id_deal=${deal.id}`}>{deal.properties.dealname}</Link>
                                            </>
                                        )}
                                    />


                                    <Table.Column
                                        title="Data do deal"
                                        key="data_deal"
                                        className='column-hubspot'
                                        render={(deal) => (
                                            <span>{deal.createdAt}</span>
                                        )}
                                    />



                                    <Table.Column
                                        title="Estado do deal"
                                        key="nome_deal"
                                        className='column-hubspot'
                                        render={(deal) => (
                                            <span>{deal.properties.dealstage}</span>
                                        )}
                                    />


                                    <Table.Column
                                        title="Pipeline do deal"
                                        key="nome_deal"
                                        className='column-hubspot'
                                        render={(deal) => (
                                            <span>{deal.properties.pipeline}</span>
                                        )}
                                    />

                                    <Table.Column
                                        title="Nº de propostas"
                                        key="n_propostas"
                                        className='column-hubspot'
                                        render={(deal) => (
                                            <span>{deal.total_propostas}</span>
                                        )}
                                    />

                                </Table>
                            </div>
                            <Modal
                                title={null}
                                open={isModalOpen}
                                onOk={handleOk}
                                onCancel={handleCancel}
                                okText="Fechar"
                                footer={null}
                                width={1000}
                                className="hubspot-modal"
                            >
                                <div className='mt-7 mb-7 grid gap-4'>
                                    <div className='card'>
                                        <div className='flex items-center gap-4 text-white'>
                                            <img
                                                src={logonormal}
                                                alt="Logo"
                                                className="w-24 h-10 object-contain"
                                            />
                                            <span>|</span>
                                            <span className='text-base font-semibold'>Pesquisar completa dos Contactos</span>
                                        </div>
                                    </div>
                                    <div className="grid gap-4 content-start popup-associar">
                                        <div className='titulo-form'>
                                            <p>Pesquisa pelo email ou contacto do cliente</p>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <AutoComplete
                                                ref={inputRef}
                                                value={pesquisaEmailDeal}
                                                options={data}
                                                onChange={handlePesquisaDealChange}
                                                onSelect={handlePesquisaDealSelect}
                                                placeholder="Preenche com o email do cliente"
                                                style={{ width: '100%' }}
                                            />
                                            <Button type="primary" className="bg-white text-black font-bold rounded"
                                                onClick={handleClearEmail}>
                                                <IoIosBackspace className='iconc' />
                                            </Button>
                                        </div>
                                        <div className='flex items-center justify-end gap-2'>
                                            <Button type="primary" className="flex items-center gap-2 bg-white text-black font-bold rounded"
                                                onClick={handleClosePopup}>
                                                <IoCloseCircleOutline className='iconc' />
                                                Fechar
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                        </div>
                    </div>
                )}
            </div>

        </>
    );
}
