import React from 'react';
import { BiError } from 'react-icons/bi';
import { Button } from "antd";
import { HiOutlineMail } from "react-icons/hi";
import { FaWhatsapp } from "react-icons/fa6";
import { useTranslation } from 'react-i18next';


export default function ErroSection({ erroInicial }) {

    const { t } = useTranslation();


    return (
        <>
            <div className='card flex items-center gap-5 flex-col justify-center text-lg rounded-md p-2.5'>
                <BiError className='text-white text-3xl' />
                <p className='text-white frase-style'>{erroInicial}</p>
            </div >
        </>
    );


}