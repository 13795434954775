import React, { useState, useEffect } from "react";
import { Input, Select, Button, Checkbox, Collapse, Tooltip, Modal } from 'antd';
import { AlertaMsg } from "../pt/mensagens/mensagem_alerta";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DatePicker } from 'antd';
import { Option } from "antd/lib/mentions";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
import ES_RegistarViatura from "./es_form_registar_viatura";


dayjs.extend(customParseFormat);

//const { Option } = Select;
const { Panel } = Collapse;

export default function ES_CalculoChaveMao({
    currentLanguage,
    urlCarroLink,
    dataGeralISV,
    setListaDeModelos,
    idCidade,
    listaDeModelos,
    valorViaturaNova,
    setValorViaturaNova,
    idMarca,
    setIdMarca,
    setIdCidade,
    setIdModelo,
    idModelo,
    listaDeMarcas,
    idMunicipio,
    setIdMunicipio,
    listaDeMunicipios,
    allDataLinkPartilhado,
    validarViaturaEletrico,
    temParametros,
    dataDeRegisto,
    novoOuUsado,
    tipoDeCombustivel,
    setTaxaDeServico,
    tipoDeVeiculoParaOutros,
    hibridoIsChecked,
    setCo2IsChecked,
    setHibridoValidationIsChecked,
    setNovoOuUsado,
    setTituloDaPropostaChaveNaMao,
    setOutrosServicos,
    setAlertasNoPedido,
    setCilindrada,
    setDataDeRegisto,
    setEmissoesGases,
    setHibridoIsChecked,
    setTipoDeCombustivel,
    setParticulas,
    setTipoDeVeiculoParaOutros,
    setWltpIsChecked,
    setTipoDeVeiculoParaInsencoes,
    alertasNoPedido,
    taxaDeServico,
    tipoDeVeiculoParaInsencoes,
    hibridoValidationIsChecked,
    outrosServicos,
    cilindrada,
    emissoesGases,
    wltpIsChecked,
    particulas,
    validarAnoDeRegisto,
    co2IsChecked,
    valordaViaturaIsChecked,
    setValordaViaturaIsChecked,
    valorFinalISV,
    quantidadeDeKM,
    anoDoRegisto,
    IgnorarAvisos,
    listaDeCidades,
    saveToken,
    iDTokenAuthenticated
}) {

    const [isModalOpenModelo, setIsModalOpenModelo] = useState(false);


    // console.log(cilindrada)

    const handleTaxaDeServicoChange = (e) => {
        setTaxaDeServico(e.target.value);
    };



    const handleProvinciasChange = (e) => {
        setIdCidade(e);
        setIdMunicipio('');
        // console.log(e)
    };

    const handleMunicipioChange = (e) => {
        setIdMunicipio(e);
    };


    const handleMarcaChange = (e) => {
        // console.log('idMarca', idMarca)
        setIdMarca(e);
        setIdModelo('');
    };

    const handleModeloChange = (e) => {
        setIdModelo(e);

        const selectedObject = listaDeModelos.find(item => item.id_modelo === e);
        // console.log('selectedObject', selectedObject)
        setValorViaturaNova(selectedObject.valor_novo)

    };



    const handleValorDoVeiculoChange = (e) => {
        setValorViaturaNova(e.target.value);
    };



    const handleNovoOuUsadoChange = (e) => {
        setNovoOuUsado(e);
    };


    const handleTipoDeVeiculoChange = (e) => {
        setTipoDeVeiculoParaInsencoes(e);
    };


    const handleDataDeRegistoChange = (e) => {
        let day = "";
        let month = "";
        let year = "";

        if (e && e instanceof Object && e.hasOwnProperty('$y') && e.hasOwnProperty('$M') && e.hasOwnProperty('$D')) {
            // Extract day, month, and year
            day = e.$D.toString().padStart(2, '0');
            month = (e.$M + 1).toString().padStart(2, '0'); // Month is zero-based
            year = e.$y.toString();
        }

        setDataDeRegisto(month + '/' + year);
    };


    const handleCilindradaChange = (e) => {
        setCilindrada(e.target.value);
    };


    const handleTipoDeCombustivelChange = (e) => {
        setTipoDeCombustivel(e);
    };


    const handleParticulasChange = (e) => {
        setParticulas(e);
    };


    const handleEmissoesGasesChange = (e) => {
        setEmissoesGases(e.target.value);
    };



    /// VALIDAR DATA MM/YYYY
    const isValidDateString = (dateString) => {
        // Add your custom date validation logic here
        // You can use libraries like Date-fns or moment.js for advanced date validation
        // For simplicity, this example only checks if the input follows the MM/YYYY format

        const regex = /^(0[1-9]|1[0-2])\/\d{4}$/; // MM/YYYY format
        return regex.test(dateString);
    };



    const handleHibridoChange = (e) => {
        setHibridoIsChecked(e.target.checked);
    };



    const handleWltpChange = (e) => {
        setWltpIsChecked(e.target.checked);
    };


    const handleOutrosServicosChange = (e) => {
        setOutrosServicos(e);
    };

    const handleCO2Change = (e) => {
        setCo2IsChecked(e.target.checked);
        setAlertasNoPedido(!alertasNoPedido)
    };


    const handleValordaViaturaChange = (e) => {
        setValordaViaturaIsChecked(e.target.checked);
        // setValordaViaturaIsChecked(!valordaViaturaIsChecked)
    };

    const handlehibridoValidationChange = (e) => {
        setHibridoValidationIsChecked(e.target.checked);
    };


    // const handleTituloDaPropostaChaveNaMao = (e) => {
    //     setTituloDaPropostaChaveNaMao(e.target.value);
    // };


    const dayjsDate = (date) => {
        if (date && date !== '') {
            return dayjs(date, 'MM/YYYY')
        }
    }


    // console.log('allDataLinkPartilhado.combustivel_real', allDataLinkPartilhado.combustivel_real)

    // console.log('validarViaturaEletrico',validarViaturaEletrico)
    // console.log('validarAnoDeRegisto',validarAnoDeRegisto)
    // console.log('idCidade', idCidade)

    const onSearchProvincias = (value) => {
        // console.log('search:', value);
        const filteredCities = listaDeCidades.filter(cidade =>
            cidade.nome_cidade.toLowerCase().includes(value.toLowerCase())
        );
    };

    const onSearchMunicipios = (value) => {
        // console.log('search:', value);
        const filteredCities = listaDeMunicipios.filter(municipio =>
            municipio.nome_municipio.toLowerCase().includes(value.toLowerCase())
        );
    };

    const onSearchMarca = (value) => {
        // console.log('search:', value);
        const filteredCities = listaDeMarcas.filter(marca =>
            marca.nome_marca.toLowerCase().includes(value.toLowerCase())
        );
    };

    const onSearchModelo = (value) => {
        // console.log('search:', value);
        const filteredCities = listaDeModelos.filter(modelo =>
            modelo.nome_modelo.toLowerCase().includes(value.toLowerCase())
        );
    };

    // console.log(listaDeModelos)
    // console.log(idMarca)


    const showModalModelo = () => {
        setIsModalOpenModelo(true);
    };

    const handleOkModelo = () => {
        setIsModalOpenModelo(false);
    };

    const handleCancelModelo = () => {
        setIsModalOpenModelo(false);
    };


    return (

        <div className='card'>
            <div className='titulo-form'>
                <p className="font-semibold">Calculo del IVTM</p>
            </div>
            <form className=' grid  gap-5'>
                <div>
                    <div className="mx-auto grid gap-4">
                        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                            {listaDeCidades ? (
                                <div className="grid gap-2 content-start">
                                    <p>Provincias</p>
                                    <Select
                                        showSearch
                                        placeholder="Seleccione una provincia"
                                        value={idCidade ? idCidade : "Seleccione"}
                                        onChange={handleProvinciasChange}
                                        onSearch={onSearchProvincias}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        disabled={temParametros}
                                    >
                                        {listaDeCidades.map((cidade) => (
                                            <Option key={cidade.id_cidade} value={cidade.id_cidade}>
                                                {cidade.nome_cidade}
                                            </Option>
                                        ))}
                                    </Select>
                                    {idCidade == "" ? (
                                        <>
                                            <span className="text-red-600 text-sm">Seleccione una provincia.</span>
                                        </>
                                    ) : null}
                                </div>
                            ) : null}

                            {idCidade && Array.isArray(listaDeMunicipios) ? (
                                <div className="grid gap-2 content-start">
                                    <p>Munícipio</p>
                                    <Select
                                        showSearch
                                        placeholder="Seleccione un municipio"
                                        value={idMunicipio ? idMunicipio : "Seleccione"}
                                        onChange={handleMunicipioChange}
                                        onSearch={onSearchMunicipios}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        disabled={temParametros}
                                    >
                                        {listaDeMunicipios.map((municipio) => (
                                            <Option key={municipio.id_municipio} value={municipio.id_municipio}>{municipio.nome_municipio}</Option>
                                        ))}
                                    </Select>
                                    {idMunicipio == "" ? (
                                        <>
                                            <span className="text-red-600 text-sm">Seleccione un municipio.</span>
                                        </>
                                    ) : null}
                                </div>
                            ) : null}

                            <div className="grid gap-2 content-start">
                                <p>Nuevo / Usado</p>
                                <Select
                                    placeholder="Seleccione"
                                    value={novoOuUsado ? novoOuUsado : undefined}
                                    onChange={handleNovoOuUsadoChange}
                                    disabled={temParametros}
                                >
                                    <Option value="">Seleccione</Option>
                                    <Option value="1">Nuevo</Option>
                                    <Option value="0">Usado</Option>
                                </Select>
                            </div>

                            {novoOuUsado === "Usado" ? (
                                <div className='grid gap-2'>
                                    <div className='grid gap-2'>
                                        <p>Fecha de primer registro</p>
                                        <DatePicker placeholder="MM/AAAA"
                                            defaultValue={dayjsDate(dataDeRegisto)}
                                            format={'MM/YYYY'}
                                            picker="month"
                                            onChange={handleDataDeRegistoChange}
                                            disabled={temParametros}
                                        />
                                    </div>
                                </div>
                            ) : null}


                            {listaDeMarcas ? (
                                <div className="grid col-span-2 gap-2 content-start">
                                    <p>Marca</p>
                                    <Select
                                        showSearch
                                        placeholder="Seleccione una marca"
                                        value={idMarca ? idMarca : "Seleccione"}
                                        onChange={handleMarcaChange}
                                        onSearch={onSearchMarca}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        disabled={temParametros}
                                    >
                                        {listaDeMarcas.map((marca) => (
                                            <Option value={marca.id_marca}>{marca.nome_marca}</Option>
                                        ))}
                                    </Select>
                                </div>
                            ) : null}


                            {idMarca && Array.isArray(listaDeModelos) ? (

                                <div className="grid col-span-2 gap-2 content-start">

                                    <p>Modelo</p>
                                    <Select
                                        showSearch
                                        placeholder="Seleccione un modelo"
                                        value={idModelo ? idModelo : "Seleccione"}
                                        onChange={handleModeloChange}
                                        onSearch={onSearchModelo}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        disabled={temParametros}
                                    >
                                        {listaDeModelos.map((modelo) => (
                                            <Option key={modelo.id_modelo} value={modelo.id_modelo}>{modelo.nome_modelo}</Option>
                                        ))}
                                    </Select>

                                    {idModelo == "" ? (
                                        <>
                                            <span className="text-red-600 text-sm">Seleccione un modelo.</span>
                                        </>
                                    ) : null}



                                    <div className='flex justify-end gap-4 items-center'>
                                        <Button type="primary" className="flex w-full justify-center items-center gap-2 text-white font-bold rounded" onClick={showModalModelo}>
                                            <IoMdAdd size={20} />
                                            Registrar modelo
                                        </Button>
                                    </div>

                                </div>

                            ) : null}



                            <Modal
                                title={<span className="flex items-center gap-2"><IoMdAdd /> Registrar modelo de coche</span>}
                                open={isModalOpenModelo}
                                onOk={handleOkModelo}
                                onCancel={handleCancelModelo}
                                okText="Cerrar"
                                footer={null}
                            >
                                <ES_RegistarViatura
                                    currentLanguage={currentLanguage}
                                    listaDeMarcas={listaDeMarcas}
                                    saveToken={saveToken}
                                    iDTokenAuthenticated={iDTokenAuthenticated}
                                    setListaDeModelos={setListaDeModelos}
                                    idMarca={idMarca}
                                    setIdMarca={setIdMarca}
                                    listaDeModelos={listaDeModelos}
                                    idModelo={idModelo}
                                    setIdModelo={setIdModelo}
                                    valorViaturaNova={valorViaturaNova}
                                    setValorViaturaNova={setValorViaturaNova}
                                    setIsModalOpenModelo={setIsModalOpenModelo}
                                />
                            </Modal>




                            <div className="grid gap-2 content-start">
                                <p>Valor del Vehículo Nuevo</p>
                                <Input
                                    placeholder="Valor del Vehículo Nuevo"
                                    value={valorViaturaNova}
                                    onChange={handleValorDoVeiculoChange}
                                    type={'number'}
                                    disabled={temParametros}
                                />
                            </div>

                            {tipoDeCombustivel !== "Eletrico" && (
                                <div className="grid gap-2 content-start">
                                    <p>Cilindrada</p>
                                    <Input
                                        placeholder="Cilindrada"
                                        value={cilindrada}
                                        onChange={handleCilindradaChange}
                                        type={'number'}
                                        disabled={temParametros}
                                    />
                                </div>
                            )}

                            <div className="grid gap-2 content-start">
                                <p>Tipo de Combustible</p>
                                <Select
                                    placeholder="Selecione"
                                    value={tipoDeCombustivel ? tipoDeCombustivel : undefined}
                                    onChange={handleTipoDeCombustivelChange}
                                    disabled={temParametros}
                                >
                                    <Option value="">Selecione</Option>
                                    <Option
                                        value="Gasolina">Gasolina</Option>
                                    <Option
                                        value="Gasoleo">Diesel</Option>
                                    <Option
                                        value="Eletrico">Eléctrico</Option>
                                </Select>
                            </div>

                            {tipoDeCombustivel !== "Eletrico" && (
                                <div className="grid gap-2 content-start">
                                    <p>Emisiones de Gases CO2</p>
                                    <Input
                                        placeholder="Emisiones de Gases CO2"
                                        value={emissoesGases}
                                        onChange={handleEmissoesGasesChange}
                                        type={'number'}
                                        disabled={temParametros}
                                    />
                                </div>
                            )}
                        </div>



                        <div className='flex flex-col gap-2 text-md border-card bg-black'>
                            <p><span className="flex items-center gap-2">Gastos de servicio (€)
                                <Tooltip title="La tarifa de servicio incluye el costo del transporte, seguro, legalización y todos los demás gastos del proceso de importación.">
                                    <AiOutlineInfoCircle className='icon-question' />
                                </Tooltip></span></p>
                            <Input
                                placeholder="Gastos de servicio (€)"
                                value={taxaDeServico}
                                onChange={handleTaxaDeServicoChange}
                                type="number"
                                disabled={temParametros}
                            />
                        </div>

                        <Collapse>
                            <Panel header={<span
                                className="custom-collapse-header">Avanzado</span>}
                                key="1">
                                <div className='grid gap-4'>
                                    <div className='grid gap-2'>
                                        <p>Exención</p>
                                        <Select
                                            disabled={hibridoIsChecked || temParametros}
                                            style={{ width: '100%' }}
                                            placeholder="Selecione"
                                            value={tipoDeVeiculoParaInsencoes ? tipoDeVeiculoParaInsencoes : 'Sin exención'}
                                            onChange={handleTipoDeVeiculoChange}
                                        >
                                            <Option value="">Sin exención</Option>
                                            <Option value="familias_grandes">Famílias numerosas</Option>
                                            <Option value="cambio_residencia">Cambio de residencia</Option>
                                            <Option value="movilidad_reducida">Personas con discapacidad</Option>

                                        </Select>
                                    </div>
                                </div>
                            </Panel>
                        </Collapse>

                    </div>

                    {!IgnorarAvisos && (
                        <div className="mt-4">

                            {validarAnoDeRegisto ? (
                                <div>
                                    <Checkbox checked={co2IsChecked}
                                        onChange={handleCO2Change} style={{
                                            color: 'white',
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '6px'
                                        }}
                                        disabled={temParametros}
                                    >
                                        He confirmado las emisiones de C02
                                    </Checkbox>
                                </div>
                            ) : null}


                            {/* {validarValordaViatura ? ( */}
                            <div>
                                <Checkbox checked={valordaViaturaIsChecked}
                                    onChange={handleValordaViaturaChange} style={{
                                        color: 'white',
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: '6px'
                                    }}
                                    disabled={temParametros}
                                >
                                    He confirmado valor da viatura
                                </Checkbox>
                            </div>

                            {alertasNoPedido ? (
                                <>
                                    <AlertaMsg
                                        mensagem="confirme las emisiones antes de continuar" />
                                </>
                            ) : null}


                            {!valordaViaturaIsChecked ? (
                                <>
                                    <AlertaMsg
                                        mensagem="confirme el valor del coche antes de seguir adelante" />
                                </>
                            ) : null}

                            {idCidade == "" ? (
                                <>
                                    <AlertaMsg
                                        mensagem="seleccione una provincias" />
                                </>
                            ) : null}

                            {idCidade !== "" && idMunicipio == "" ? (
                                <>
                                    <AlertaMsg
                                        mensagem="seleccione un municipio" />
                                </>
                            ) : null}

                            {idMarca == "" ? (
                                <>
                                    <AlertaMsg
                                        mensagem="seleccione una marca" />
                                </>
                            ) : null}

                            {idMarca !== "" && idModelo == "" ? (
                                <>
                                    <AlertaMsg
                                        mensagem="seleccione un modelo" />
                                </>
                            ) : null}

                            {!isValidDateString(dataDeRegisto) && novoOuUsado !== "Novo" ? (
                                <>
                                    <AlertaMsg
                                        mensagem="introduzca una fecha válida para la 1ª inscripción." />
                                </>
                            ) : null}

                        </div>
                    )}

                </div>


            </form>
            {dataGeralISV.erros ? (
                <div>
                    <p className='mt-5 text-red-600 text-sm'>
                        <span className='font-bold text-sm'>{dataGeralISV.erros}</span>
                    </p>
                </div>
            ) : null}
        </div>

    )
}