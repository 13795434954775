import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

const NotificationItem = ({ subject, message, link }) => (
    <div className="grid gap-1 notification-item p-2 border-b last:border-b-0">
        <p className="font-bold">{subject}</p>
        <p dangerouslySetInnerHTML={{ __html: message }}/>
        {link && (
            <a href={link} className="text-black hover:text-black underline hover:underline" target="_blank" rel="noopener noreferrer">
                Ver mais
            </a>
        )}
    </div>
);

export default function PopupNotificacoes({ notificacoes }) {

    // console.log('notificacoes',notificacoes)

    return (
        <div className="popup-notificacoes max-w-[350px]">
            {notificacoes.map((notification) => (
                <NotificationItem
                    key={notification.id_notificacao}
                    data={notification.data_criacao}
                    subject={notification.assunto}
                    message={notification.mensagem}
                    link={notification.link}
                />
            ))}
            <div className='mt-5'>
                <Link to={`/notificacoes`} >
                    <Button
                        type="primary"
                        target="_blank"
                        className="flex text-center gap-2 justify-center items-center bg-white text-black font-bold rounded w-full"
                    >
                        Ver Todas
                    </Button>
                </Link>
            </div >
        </div >
    );
}
