import { useState, useEffect, useRef } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Form, Slider, InputNumber, Tooltip, Row, Col, Button, Modal, message } from 'antd';
import { useMediaQuery } from 'react-responsive';

import html2canvas from 'html2canvas';  // Importa a biblioteca html2canvas
import { FaPrint } from "react-icons/fa";
import { AiOutlinePushpin } from "react-icons/ai";
import ChartCreditoViatura from './chart-credito-viatura';
import ChartCreditoFinanciamento from './chart-credito-financiamento';

export default function CalculadoraCredito({
    formatarNumero,
    taxaDeJuroCredito,
    setTaxaDeJuroCredito,
    entradaCredito,
    setEntradaCredito,
    valorFinanciadoCredito,
    setValorFinanciadoCredito,
    prestacoesMesesCredito,
    setPrestacoesMesesCredito,
    valorFinalCredito,
    setValorFinalCredito,
    valorChaveNaMaoParaCredito,
    setValorChaveNaMaoParaCredito,
}) {


    const [valorEntrada, setValorEntrada] = useState('');
    const [mticValor, setMticValor] = useState('');
    const [jurosValor, setJurosValor] = useState('');
    const [comissaoDeCapital, setComissaoDeCapital] = useState('400');
    const [encargosdespesasValor, setEncargosdespesasValor] = useState('2400');
    const [isModalOpenJuros, setIsModalOpenJuros] = useState(false);

    const [isModalOpenDespesas, setIsModalOpenDespesas] = useState(false);

    const [impostoDoSeloValor, setImpostoDoSeloValor] = useState('');
    const [valorChaveNaMao, setValorChaveNaMao] = useState("");
    const [valorDoCarro, setValorDoCarro] = useState(0);
    const [valorDasDespesas, setValorDasDespesas] = useState(0);



    const divRef = useRef(null);  // Referência para a div que será capturada


    //Responsive
    const isMobile = useMediaQuery({
        query: '(max-width: 1023px)'
    })



    const handlePrestacoesMesesCredito = (value) => {
        setPrestacoesMesesCredito(value);
    };


    const handleTaxaDeJuroCredito = (value) => {
        setTaxaDeJuroCredito(value);
    };


    const handleComissaoDeCapital = (value) => {
        setComissaoDeCapital(value);
    };

    const handleEncargosEDespesas = (value) => {
        setEncargosdespesasValor(value);
    };

    const showModalJuros = () => {
        setIsModalOpenJuros(true);
    };

    const handleOkJuros = () => {
        setIsModalOpenJuros(false);
    };

    const handleCancelJuros = () => {
        setIsModalOpenJuros(false);
    };



    const showModalDespesas = () => {
        setIsModalOpenDespesas(true);
    };

    const handleOkDespesas = () => {
        setIsModalOpenDespesas(false);
    };

    const handleCancelDespesas = () => {
        setIsModalOpenDespesas(false);
    };

    // Função para capturar a div como imagem
    const captureDivAsImage = () => {
        if (divRef.current) {
            html2canvas(divRef.current).then((canvas) => {
                const img = canvas.toDataURL('image/png');
                // Criar um link para download da imagem
                const link = document.createElement('a');
                link.href = img;
                link.download = 'Simulador de Crédito - Importrust.png';
                link.click();
            });
        }
    };


    useEffect(() => {
        const calcularPrestacao = () => {

            // const montanteNecessario = valorChaveNaMaoParaCredito - valorEntrada;
            // setValorFinanciadoCredito(montanteNecessario);

            const valorFinanciado = parseFloat(valorChaveNaMaoParaCredito);
            const prestacoesMeses = parseFloat(prestacoesMesesCredito);

            if (!isNaN(valorEntrada) && !isNaN(valorFinanciado) && !isNaN(prestacoesMeses)) {
                const taxaJurosMensal = taxaDeJuroCredito / (12 * 100);
                const prestacao = (valorFinanciado * taxaJurosMensal) / (1 - Math.pow(1 + taxaJurosMensal, -prestacoesMeses));
                setValorFinalCredito(prestacao.toFixed(2));
            }
        };


        const calcularJuros = () => {
            const juros = (parseFloat(valorFinalCredito) * parseFloat(prestacoesMesesCredito)) - parseFloat(valorChaveNaMaoParaCredito);
            const impostoDoSelo = juros * 0.004;
            setJurosValor(juros.toFixed(2));
            setImpostoDoSeloValor(impostoDoSelo.toFixed(2));
        };


        const calcularMtic = () => {
            const mtic = (parseFloat(valorFinalCredito) * parseFloat(prestacoesMesesCredito)) + parseFloat(encargosdespesasValor);
            setMticValor(mtic.toFixed(2));
        };


        calcularPrestacao();
        calcularMtic();
        calcularJuros();

    }, [entradaCredito, valorEntrada, valorChaveNaMaoParaCredito, prestacoesMesesCredito, taxaDeJuroCredito, valorFinalCredito, encargosdespesasValor]);



    const converterMesesEmAnosEMeses = (meses) => {
        const anos = Math.floor(meses / 12);
        const mesesRestantes = meses % 12;
        return `${anos} ano${anos !== 1 ? 's' : ''} e ${mesesRestantes} mes${mesesRestantes !== 1 ? 'es' : ''}`;
    };



    // useEffect(() => {
    //     if (valorChaveNaMao > 0) {

    //         let valorMinimoEntrada = valorEntrada

    //         if(!valorEntrada){
    //             valorMinimoEntrada = 0.2 * valorChaveNaMao;
    //         }

    //         const novoValorChaveNaMaoParaCredito = valorChaveNaMao - valorEntrada;

    //         setValorEntrada(valorMinimoEntrada);
    //         setValorChaveNaMaoParaCredito(novoValorChaveNaMaoParaCredito);
    //     }
    // }, [valorChaveNaMao, valorEntrada]);


    // const handleChaveNaMao = (value) => {
    //     setValorChaveNaMao(value)
    // }

    // const handleValorEntrada = (novoValorEntrada) => {
    //     setValorEntrada(novoValorEntrada);
    // };


    useEffect(() => {
        // Quando valorChaveNaMao muda, ajusta o valorEntrada e valorChaveNaMaoParaCredito automaticamente
        if (valorChaveNaMao > 0) {
            const novoValorEntrada = 0.2 * valorChaveNaMao;
            setValorEntrada(novoValorEntrada);
            setValorChaveNaMaoParaCredito(valorChaveNaMao - novoValorEntrada);
        }
    }, [valorChaveNaMao]);

    const handleValorEntrada = (novoValorEntrada) => {
        setValorEntrada(novoValorEntrada);
        setValorChaveNaMaoParaCredito(valorChaveNaMao - novoValorEntrada); // Ajusta o valor financiado
    };



    useEffect(() => {
        const valorCalculado = parseFloat(valorEntrada) + parseFloat(mticValor) + parseFloat(comissaoDeCapital);
        setValorDoCarro(valorCalculado);
    }, [valorEntrada, mticValor, comissaoDeCapital]);



    useEffect(() => {
        const valorCalculado = parseFloat(encargosdespesasValor) + parseFloat(comissaoDeCapital);
        setValorDasDespesas(valorCalculado);
    }, [encargosdespesasValor, comissaoDeCapital]);


    return (
        <>
            <div className={valorChaveNaMaoParaCredito && prestacoesMesesCredito ? 'grid grid-cols-1 md:grid-cols-3 md:gap-4' : 'grid grid-cols-1 md:grid-cols-1 md:gap-4'}>

                <div className="col-span-2">
                    <Form layout="vertical" className="text-white">
                        <div className='grid gap-10 credito-main-block'>


                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">


                                <div>
                                    <p className="flex items-center gap-2">
                                        <span>Valor Chave na Mão</span>
                                        <Tooltip title="O Valor Chave na Mão inclui valor da Viatura, valor do ISV e o valor da Taxa de Serviço.">
                                            <AiOutlineInfoCircle size={15} />
                                        </Tooltip>
                                    </p>
                                    <h2 className="text-base font-extrabold">
                                        {valorChaveNaMao ? `${formatarNumero(valorChaveNaMao)} €` : `0€`}
                                    </h2>
                                    <Form.Item>
                                        <div className='flex gap-2 items-center'>
                                            <Slider
                                                min={0}
                                                max={500000}
                                                step={0.1}
                                                value={valorChaveNaMao}
                                                onChange={setValorChaveNaMao}
                                                style={{ width: '100%' }}
                                            />

                                            <InputNumber
                                                min={0}
                                                max={500000}
                                                step={0.1}
                                                value={valorChaveNaMao}
                                                onChange={setValorChaveNaMao}
                                                placeholder='Preenche com o valor chave na mão'
                                                className="w-36"

                                            />
                                        </div>
                                    </Form.Item>
                                </div>


                                <div>
                                    <p>Em quantos meses?</p>
                                    <div className='flex items-center gap-2'>
                                        <h2 className="text-base font-extrabold">
                                            {prestacoesMesesCredito ? `${formatarNumero(prestacoesMesesCredito)} meses` : `0 meses`}
                                        </h2>
                                        <div>|</div>
                                        <p>{converterMesesEmAnosEMeses(prestacoesMesesCredito)}</p>
                                    </div>
                                    <Form.Item>
                                        <div className='flex gap-2 items-center'>
                                            <Slider
                                                min={24}
                                                max={120}
                                                step={1}
                                                value={prestacoesMesesCredito}
                                                onChange={handlePrestacoesMesesCredito}
                                                style={{ width: '100%' }}
                                            />
                                            <InputNumber
                                                min={24}
                                                max={120}
                                                step={1}
                                                value={prestacoesMesesCredito}
                                                onChange={handlePrestacoesMesesCredito}
                                                placeholder='Preenche com o número de meses'
                                                className="w-36"
                                            />
                                        </div>
                                    </Form.Item>
                                </div>



                                <div>
                                    <p className="flex items-center gap-2">
                                        <span>Valor de Entrada</span>
                                        <Tooltip title="O valor de entrada não pode ser inferior a 20% do valor financiado.">
                                            <AiOutlineInfoCircle size={15} />
                                        </Tooltip>
                                    </p>
                                    <h2 className="text-base font-extrabold">
                                        {valorEntrada ? `${formatarNumero(valorEntrada)} €` : `0€`}
                                    </h2>
                                    <Form.Item>
                                        <div className='flex gap-2 items-center'>
                                            <Slider
                                                min={0.2 * valorChaveNaMao} // Mínimo é 20% do valor chave na mão
                                                max={valorChaveNaMao}
                                                step={0.1}
                                                value={valorEntrada}
                                                onChange={handleValorEntrada}
                                                style={{ width: '100%' }}
                                            />
                                            <InputNumber
                                                min={0.2 * valorChaveNaMao} // Mínimo é 20% do valor chave na mão
                                                max={valorChaveNaMao}
                                                step={0.1}
                                                value={valorEntrada}
                                                onChange={handleValorEntrada}
                                                placeholder='Preenche com o valor de entrada'
                                                className="w-36"

                                            />
                                        </div>
                                    </Form.Item>
                                </div>



                                <div>
                                    <p>Taxa de Juro</p>
                                    <h2 className="text-base font-extrabold">{taxaDeJuroCredito}%</h2>
                                    <Form.Item>
                                        <div className='flex gap-2 items-center'>
                                            <Slider
                                                min={6.5}
                                                max={100}
                                                step={0.1}
                                                value={taxaDeJuroCredito}
                                                onChange={handleTaxaDeJuroCredito}
                                                style={{ width: '100%' }}
                                            />

                                            <InputNumber
                                                min={0}
                                                max={100}
                                                step={0.1}
                                                value={taxaDeJuroCredito}
                                                onChange={handleTaxaDeJuroCredito}
                                                className="w-36"
                                            />
                                        </div>
                                    </Form.Item>
                                </div>


                            </div>



                            <div className='p-4 bg-white text-black rounded flex items-center justify-between'>
                                <p className="flex items-center gap-2">
                                    <span className='font-bold text-base'>Valor a Financiar</span>
                                    <Tooltip title="O Valor a Financiar correponde ao valor total do negócio (valor da viatura, isv e taxa de serviço) menos o valor de entrada.">
                                        <AiOutlineInfoCircle size={15} />
                                    </Tooltip>
                                </p>
                                <h2 className="text-lg font-extrabold">
                                    {valorChaveNaMaoParaCredito ? `${formatarNumero(valorChaveNaMaoParaCredito)} €` : `0€`}
                                </h2>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                                <div>
                                    <p className="flex items-center gap-2">
                                        <span>Encargos e Despesas</span>
                                        <Tooltip title="Encargos e Despesas - colocar texto">
                                            <AiOutlineInfoCircle size={15} />
                                        </Tooltip>
                                    </p>
                                    <div className='flex items-center gap-2'>
                                        <h2 className="text-base font-extrabold">
                                            {encargosdespesasValor ? `${formatarNumero(encargosdespesasValor)} €` : `0 €`}
                                        </h2>
                                    </div>
                                    <Form.Item>
                                        <div className='flex gap-2 items-center'>
                                            <Slider
                                                min={1}
                                                max={5000}
                                                step={1}
                                                value={encargosdespesasValor}
                                                onChange={handleEncargosEDespesas}
                                                style={{ width: '100%' }}
                                            />
                                            <InputNumber
                                                min={1}
                                                max={5000}
                                                step={1}
                                                value={encargosdespesasValor}
                                                onChange={handleEncargosEDespesas}
                                                placeholder='Preenche com o valor dos encargos'
                                                className="w-36"

                                            />
                                        </div>
                                    </Form.Item>
                                </div>

                                <div>
                                    <p>Comissão de Disponibilização de Capital</p>
                                    <div className='flex items-center gap-2'>
                                        <h2 className="text-base font-extrabold">
                                            {comissaoDeCapital ? `${formatarNumero(comissaoDeCapital)} €` : `0 €`}
                                        </h2>
                                    </div>
                                    <Form.Item>
                                        <div className='flex gap-2 items-center'>
                                            <Slider
                                                min={400}
                                                max={2000}
                                                step={1}
                                                value={comissaoDeCapital}
                                                onChange={handleComissaoDeCapital}
                                                style={{ width: '100%' }}
                                            />
                                            <InputNumber
                                                placeholder='Preenche com o valor da comissão'
                                                min={400}
                                                max={2000}
                                                step={1}
                                                value={comissaoDeCapital}
                                                onChange={handleComissaoDeCapital}
                                                className="w-36"
                                            />
                                        </div>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </Form>
                    {!isMobile && valorChaveNaMaoParaCredito && prestacoesMesesCredito && (
                        <>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4  mt-8">
                                <div className='bg-tipos border-main border-2 p-4 rounded'>
                                    <div className='flex items-center justify-center' style={{ marginTop: '-40px' }}>
                                        <ChartCreditoFinanciamento
                                            valorChaveNaMaoParaCredito={valorChaveNaMaoParaCredito}
                                            jurosValor={jurosValor}
                                            encargosdespesasValor={encargosdespesasValor}
                                            formatarNumero={formatarNumero}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 md:grid-cols-1 justify-items-center" style={{ marginTop: '-50px' }}>
                                        <p className="flex items-center gap-2 justify-center">
                                            <span className="text-sm">Valor total do Finaciamento</span>
                                        </p>
                                        <p>
                                            <span className="text-lg font-bold">
                                                {isNaN(mticValor) || mticValor === null ? `0 €` : `${formatarNumero(mticValor)} €`}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className='bg-tipos border-main border-2 p-4 rounded'>
                                    <div className='flex items-center justify-center' style={{ marginTop: '-40px' }}>
                                        <ChartCreditoViatura
                                            valorEntrada={valorEntrada}
                                            mticValor={mticValor}
                                            comissaoDeCapital={comissaoDeCapital}
                                            formatarNumero={formatarNumero}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 md:grid-cols-1 justify-items-center" style={{ marginTop: '-50px' }}>
                                        <p className="flex items-center gap-2 justify-center">
                                            <span className="text-sm">Valor total da Viatura</span>
                                        </p>
                                        <p>
                                            <span className="text-lg font-bold">
                                                {valorDoCarro ? `${formatarNumero(valorDoCarro)} €` : `0 €`}                                        </span>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </>
                    )}

                </div>

                <div
                    className={`transition-all duration-500 ease-in-out ${valorChaveNaMaoParaCredito && prestacoesMesesCredito
                        ? 'opacity-100'
                        : 'opacity-0 max-h-0 overflow-hidden'
                        }`}
                    style={{ transitionProperty: 'opacity, max-height' }}
                >
                    <div ref={divRef} className="bg-tipos border-main border-2 p-4 rounded grid grid-cols-1 gap-4 mt-5 md:mt-0">
                        <div className="grid grid-cols-1 md:grid-cols-1 gap-4 bg-[#fff] rounded text-black p-4">
                            <h2 className="text-center font-bold text-base">Total da simulação</h2>
                            <div className="flex gap-4 items-center justify-center">
                                <div className="flex w-14 h-14 rounded-full bg-cinza text-center justify-center items-center">
                                    <p><span className="text-base sm:text-base font-bold">{prestacoesMesesCredito}</span> X</p>
                                </div>
                                <p><span className="text-lg font-bold">{valorFinalCredito}</span> €/mês</p>
                            </div>
                        </div>
                        <hr />
                        <div className="flex justify-between gap-4 justify-items-center">
                            <div>
                                <p className="flex items-center gap-2">
                                    <span className="text-sm">TAN</span>
                                    <Tooltip title="A TAN (Taxa Anual Nominal) corresponde à taxa a que são cobrados os juros do empréstimo.">
                                        <AiOutlineInfoCircle size={15} />
                                    </Tooltip>
                                </p>
                                <p><span className="text-sm font-bold">{taxaDeJuroCredito ? `${taxaDeJuroCredito}%` : ``}</span></p>
                            </div>
                            <div>
                                <p className="flex items-center gap-2 justify-center">
                                    <span className="text-sm">TAEG</span>
                                    <Tooltip title="A TAEG (Taxa Anual Efetiva Global) é o custo total do crédito para o consumidor expresso em percentagem anual do montante do crédito concedido. No cálculo da TAEG incluem-se despesas de cobrança de reembolsos e pagamentos de juros bem como restantes encargos obrigatórios a suportar (impostos, comissões e seguros obrigatórios).">
                                        <AiOutlineInfoCircle size={15} />
                                    </Tooltip>
                                </p>
                                <p><span className="text-sm font-bold">{taxaDeJuroCredito ? `${taxaDeJuroCredito}%` : ``}</span></p>
                            </div>
                            <div>
                                <p className="flex items-center gap-2 justify-center">
                                    <span className="text-sm">Tipo de Taxa</span>
                                    <Tooltip title="A taxa fixa é uma taxa de juro que se mantém inalterada ao longo do empréstimo.">
                                        <AiOutlineInfoCircle size={15} />
                                    </Tooltip>
                                </p>
                                <p><span className="text-sm font-bold">Fixa</span></p>
                            </div>

                        </div>
                        <hr></hr>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="grid grid-cols-1 md:grid-cols-1 justify-items-center bg-[#fff] rounded text-black p-2">
                                <p className="flex items-center gap-2 justify-center">
                                    <span className="text-sm">MTIC</span>
                                    <Tooltip title="Corresponde ao Montante Total Imputado ao Consumidor (MTIC), que indica o valor total que vai pagar pelo crédito, incluindo o montante do crédito financiado, juros, comissões, impostos e encargos.">
                                        <AiOutlineInfoCircle size={15} />
                                    </Tooltip>
                                </p>
                                <p><span className="text-base sm:text-base  font-bold">{mticValor ? `${formatarNumero(mticValor)} €` : `0 €`}</span></p>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-1 justify-items-center bg-[#fff] rounded text-black p-2">
                                <p className="flex items-center gap-2 justify-center">
                                    <span className="text-sm text-center">Montante financiado</span>
                                </p>
                                <p><span className="text-base sm:text-base  font-bold">{valorChaveNaMaoParaCredito ? `${formatarNumero(valorChaveNaMaoParaCredito)} €` : `0 €`}</span></p>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-1 justify-items-center bg-[#fff] rounded text-black p-2">
                                <p className="flex items-center gap-2 justify-center">
                                    <span className="text-sm text-center">Juros</span>
                                </p>
                                <p className="flex items-center gap-2 justify-center"><span className="text-base sm:text-base  font-bold">{jurosValor ? `${formatarNumero(jurosValor)} €` : `0 €`}</span><AiOutlineInfoCircle size={15} onClick={showModalJuros} /></p>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-1 justify-items-center bg-[#fff] rounded text-black p-2">
                                <p className="flex items-center gap-2 justify-center">
                                    <span className="text-sm text-center">Encargos e Despesas</span>
                                </p>
                                <p className='flex items-center gap-2 justify-center'><span className="text-base sm:text-base  font-bold">{encargosdespesasValor ? `${formatarNumero(valorDasDespesas)} €` : `0 €`}</span><AiOutlineInfoCircle size={15} onClick={showModalDespesas} /></p>
                            </div>

                        </div>
                        {/* <hr />
                    <div className="grid grid-cols-1 md:grid-cols-1 justify-items-center">
                        <p className="flex items-center gap-2 justify-center">
                            <span className="text-sm">Comissão de Disponibilização de Capital</span>
                        </p>
                        <p><span className="text-base sm:text-base font-bold">{comissaoDeCapital ? `${formatarNumero(comissaoDeCapital)} €` : `0 €`}</span></p>
                    </div> */}
                        <hr />
                        <div className="grid grid-cols-1 md:grid-cols-1 justify-items-center bg-[#069CFF] rounded text-white p-4">
                            <p className="flex items-center gap-2 justify-center">
                                <span className="text-center font-bold text-base">Valor Total do Financiamento</span>
                                <Tooltip title="Montante financiado + Juros + Encargos e Despesas.">
                                    <AiOutlineInfoCircle size={15} />
                                </Tooltip>
                            </p>
                            <p><span className="text-lg font-bold">{isNaN(mticValor) || mticValor === null ? `0 €` : `${formatarNumero(mticValor)} €`}</span></p>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-1 justify-items-center bg-[#3ea163] rounded text-white p-4">
                            <p className="flex items-center gap-2 justify-center">
                                <span className="text-center font-bold text-base">Valor Total da Viatura</span>
                                <Tooltip title="Valor de entrada + Valor total a pagar + Comissão de disponibilização de capital.">
                                    <AiOutlineInfoCircle size={15} />
                                </Tooltip>
                            </p>
                            <p><span className="text-lg font-bold">{valorDoCarro ? `${formatarNumero(valorDoCarro)} €` : `0 €`}</span></p>
                        </div>
                    </div>



                    <Modal
                        title={<span className="flex items-center gap-2"><AiOutlineInfoCircle /> Explicação de cálculo:</span>}
                        open={isModalOpenJuros}
                        onOk={handleOkJuros}
                        onCancel={handleCancelJuros}
                        okText="Fechar"
                        footer={null}
                    >
                        <p className="text-black text-base flex justify-between">Juro:  <span className='font-bold'>{jurosValor ? `${formatarNumero(jurosValor - impostoDoSeloValor)} €` : `0 €`}</span></p>
                        <p className="text-black text-base flex justify-between">Imposto do Selo sobre os juros:  <span className='font-bold'>{impostoDoSeloValor ? `${formatarNumero(impostoDoSeloValor)} €` : `0 €`}</span></p>
                    </Modal>


                    <Modal
                        title={<span className="flex items-center gap-2"><AiOutlineInfoCircle /> Explicação de cálculo:</span>}
                        open={isModalOpenDespesas}
                        onOk={handleOkDespesas}
                        onCancel={handleCancelDespesas}
                        okText="Fechar"
                        footer={null}
                    >
                        <p className="text-black text-base flex justify-between">Encargos e Despesas:  <span className='font-bold'>{encargosdespesasValor ? `${formatarNumero(encargosdespesasValor)} €` : `0 €`}</span></p>
                        <p className="text-black text-base flex justify-between">Comissão de Disponibilização de Capital:<p><span className="text-base sm:text-base font-bold">{comissaoDeCapital ? `${formatarNumero(comissaoDeCapital)} €` : `0 €`}</span></p>
                        </p>
                    </Modal>



                    {/* {isMobile && valorChaveNaMaoParaCredito && prestacoesMesesCredito && (
                    <div>
                        <div className='grid md:grid-cols-2 items-center justify-bertween gap-2 text-md border-card-tipo bg-black mt-5' >
                            <div className='flex gap-4'>
                                <AiOutlinePushpin size={40} />
                                <h2 className='text-xl'>Quer guardar a simulação para mais tardse?</h2>
                            </div>
                            <div className='flex items-center justify-center md:justify-end'>
                                <Button className='flex items-center gap-3 bg-white text-black font-bold rounded' type="primary" onClick={captureDivAsImage}>
                                    <FaPrint />
                                    Guardar imagem
                                </Button>
                            </div>
                        </div>
                    </div>
                )} */}

                </div>
            </div>
            {/* {!isMobile && valorChaveNaMaoParaCredito && prestacoesMesesCredito && (
            <>
                <div className='grid md:grid-cols-2 items-center justify-bertween gap-2 text-md border-card-tipo bg-black  mt-8'>
                    <div className='flex gap-4'>
                        <AiOutlinePushpin size={40} />
                        <h2 className='text-xl'>Quer guardar a simulação para mais tarde?</h2>
                    </div>
                    <div className='flex items-center justify-center md:justify-end'>
                        <Button className='flex items-center gap-3 bg-white text-black font-bold rounded' type="primary" onClick={captureDivAsImage}>
                            <FaPrint />
                            Guardar imagem
                        </Button>
                    </div>
                </div>
            </>
        )} */}
        </>
    );
}
