import React, { useState, useEffect } from "react";
import { Button, Form, Input, Modal, Tabs, Tooltip, Spin, message } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);



export default function PT_ResultadodaSimulacao({
    iDTokenAuthenticated,
    activeTabKeyResultadoSimulacao,
    setActiveTabKeyResultadoSimulacao,
    formatarNumero,
    valorFinalISV,
    valorFinalIUC,
    setTaxaDeServico,
    idDaPropostaEncontrada,
    iucCilindrada,
    iucCO2,
    iucAdicionalCO2,
    iucCoeficienteAnoMatriculo,
    iucAdicionalGasoleo,
    isvcomponentecilindrada,
    isvcomponenteambiental,
    isvReducaoAnosUsoCilindrada,
    isvReducaoAnosUsoAmbiental,
    isvAgravamentoParticulas,
    isvReducaoAnosUsoParticulas,
    isvTaxaAplicavelTabela,
    dataGeralIUC,
    taxaDeServico,
    tituloDaPropostaChaveNaMao,
    temParametros,
    precoFinalCarro,
    setPrecoFinalCarro,
    dataCarros,
    setTituloDaPropostaChaveNaMao,
    tituloDaPropostaChaveNaMaoComLinkFinal,
    valorChaveNaMaoEmpresa,
    valorChaveNaMaoParticular,
    precoFinalDoCarroSemIva,
    urlCarroLink,
    setPrecoFinalDoCarroSemIva,
    tituloDaPropostaChaveNaMaoComLinkFinalParticular,
    setTabResultadoSimulacao,
    tabResultadoSimulacao,
    estadoDaPartilhaNomeDaProposta,
    setIsParticular,
    mostrarResultado,
    comissaoCredito,
    isCheckedCredito,
    saveToken,
    comissaoDeServico,
    setComissaoDeServico,
    isModalEditarVisible,
    setIsModalEditarVisible
}) {


    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenISV, setIsModalOpenISV] = useState(false);


    const handleTabChange = (key) => {
        setActiveTabKeyResultadoSimulacao(key);
        // console.log('activeTabKeyResultadoSimulacao',activeTabKeyResultadoSimulacao)
        if (activeTabKeyResultadoSimulacao === '2') {
            setTabResultadoSimulacao('0')
            setIsParticular(true)
            // console.log('particular')
        } else {
            setTabResultadoSimulacao('1')
            // console.log('empresa')
            setIsParticular(false)
        }
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showModalISV = () => {
        setIsModalOpenISV(true);
    };

    const handleOkISV = () => {
        setIsModalOpenISV(false);
    };

    const handleCancelISV = () => {
        setIsModalOpenISV(false);
    };


    useEffect(() => {

        if (tabResultadoSimulacao === '0') {
            setActiveTabKeyResultadoSimulacao('1');
        } else {
            setActiveTabKeyResultadoSimulacao('2');
        }

    }, [estadoDaPartilhaNomeDaProposta]);


    const showModalEditar = (id_user) => {
        setIsModalEditarVisible(true);
    };

    const handleEditarOk = () => {
        setLoading(true);

        const precoFinalChaveNaMao = activeTabKeyResultadoSimulacao === '2'
            ? valorChaveNaMaoParticular
            : valorChaveNaMaoEmpresa;

        const data = {
            id_proposta: idDaPropostaEncontrada,
            array_dados: [
                {
                    preco_final_carro: precoFinalCarro,
                    taxadeservico: taxaDeServico,
                    valor_chavenamao_particular: precoFinalCarro,
                    valor_chavenamao_empresa: precoFinalDoCarroSemIva,
                    comissaodeservico: comissaoDeServico,
                    precofinal: precoFinalChaveNaMao
                }
            ],
            user_id: iDTokenAuthenticated,
            token: saveToken,
        };

        axios.post(`${process.env.REACT_APP_URL_EDITAR_PROPOSTA}`, data)
            .then((response) => {
                if (response.data.sucesso) {
                    message.success(response.data.mensagem);
                    handleEditarCancel();
                } else {
                    message.error(response.data.mensagem);
                }
            })
            .catch((error) => {
                message.error(`Erro ao editar: ${error.message}`);
            })
            .finally(() => {
                setLoading(false);
            });
    };



    const handleEditarCancel = () => {
        setIsModalEditarVisible(false);
        setLoading(false)
    };

    const calcularComissao = (taxa) => {
        if (!taxa || isNaN(taxa)) return 0;

        let resultado = taxa * 0.039
        // console.log(resultado)
        setComissaoDeServico(resultado)

        return taxa
    };

    return (
        <div className='card grid gap-4'>
            {/* <div className='titulo-form'>
                <p>{t('resultadodasimulacao.titulo_principal')}</p>
            </div> */}
            <div className='flex justify-between items-center gap-4'>
                <div className='grid gap-1'>
                    <p className='text-lg font-semibold'>{t('resultadodasimulacao.titulo_principal')}</p>
                </div>
              
            </div>
            <hr></hr>
            <Tabs className='calculo-chave-na-mao' activeKey={activeTabKeyResultadoSimulacao} onChange={handleTabChange}
                tabBarGutter={16} centered>
                {(estadoDaPartilhaNomeDaProposta && (tabResultadoSimulacao == '0')) || iDTokenAuthenticated ? (
                    <TabPane tab={<span className='text-black'>{t('main.particular')}</span>}
                        key="1">
                        <div>

                            <p className='text-black text-base flex justify-between'>{t('resultadodasimulacao.preco_do_carro')}
                                <span className='font-bold text-lg'>{formatarNumero(precoFinalCarro)}€</span>
                            </p>

                            <p className='text-black text-base flex justify-between'>
                                <span className="flex items-center gap-1">
                                    {t('main.isv')}<AiOutlineInfoCircle className='icon-question' onClick={showModalISV} />
                                </span>
                                <span className='font-bold text-lg flex items-center gap-2'>
                                    {valorFinalISV ? `${formatarNumero(valorFinalISV)}€` : "0€"}
                                </span>
                            </p>
                            <Modal
                                title={<span className="flex items-center gap-2"><AiOutlineInfoCircle /> {t('resultadodasimulacao.explicacao_do_calculo')}</span>}
                                open={isModalOpenISV}
                                onOk={handleOkISV}
                                onCancel={handleCancelISV}
                                okText="Fechar"
                                footer={null}
                            >
                                <p className="text-black text-base flex justify-between">Componente cilindrada: <span className="font-bold text-lg">{isvcomponentecilindrada ? `${isvcomponentecilindrada}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Componente ambiental: <span className="font-bold text-lg">{isvcomponenteambiental ? `${isvcomponenteambiental}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Redução anos uso cilindrada: <span className="font-bold text-lg">{isvReducaoAnosUsoCilindrada ? `${isvReducaoAnosUsoCilindrada}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Redução anos uso ambiental: <span className="font-bold text-lg">{isvReducaoAnosUsoAmbiental ? `${isvReducaoAnosUsoAmbiental}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Agravamento particulas: <span className="font-bold text-lg">{isvAgravamentoParticulas ? `${isvAgravamentoParticulas}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Redução anos uso particulas: <span className="font-bold text-lg">{isvReducaoAnosUsoParticulas ? `${isvReducaoAnosUsoParticulas}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Taxa aplicável tabela: <span className="font-bold text-lg">{isvTaxaAplicavelTabela ? `${isvTaxaAplicavelTabela}` : "Sem informação"}</span></p>

                            </Modal>
                            <p className='text-black text-base flex justify-between'>
                                <span className="flex items-center gap-1">
                                    {t('resultadodasimulacao.taxa_de_servico')}
                                    <Tooltip title="A taxa de serviço inclui o custo do transporte, seguro, legalização e todas as restantes despesas do processo de importação.">
                                        <AiOutlineInfoCircle className='icon-question' />
                                    </Tooltip>
                                </span>
                                <span className='font-bold text-lg flex items-center gap-2'>{taxaDeServico !== '' && `${formatarNumero(calcularComissao(taxaDeServico))}€`}</span>
                                {/* {taxaDeServico ? `${formatarNumero(taxaDeServico)}€` : "0€"} */}
                            </p>

                            {isCheckedCredito && (
                                <p className='text-black text-base flex justify-between'>
                                    <span className="flex items-center gap-1">
                                        {t('resultadodasimulacao.comissao')}
                                        <Tooltip title="A taxa de financiamento serve para cobrir a disponibilização de capital por parte da Importrust, que adiantará 80% do valor do carro até que este este legalizado (altura em que a financeira do cliente disponibilizará o valor financiado).">
                                            <AiOutlineInfoCircle className='icon-question' />
                                        </Tooltip>
                                    </span>
                                    <span className='font-bold text-lg flex items-center gap-2'>{comissaoCredito} €</span>
                                </p>
                            )}

                        </div>

                        <div className="flex justify-between items-center">
                            <span className='center text-black text-base font-semibold'>{t('resultadodasimulacao.preco_chave_nao_mao')}</span>
                            <span className='text-lg sm:text-2xl font-extrabold'>{formatarNumero(valorChaveNaMaoParticular)}€</span>
                        </div>

                        <div className="border-t-4 pt-2 mt-2">
                            <p className='text-black text-base flex justify-between'>
                                <span className="flex items-center gap-1">
                                    {t('main.iuc')}
                                    <AiOutlineInfoCircle className='icon-question' onClick={showModal} />
                                </span>
                                <span className='font-bold text-lg flex items-center gap-2'>
                                    {valorFinalIUC ? `${formatarNumero(valorFinalIUC)}€` : "0€"}

                                </span>
                            </p>
                            <Modal
                                title={<span className="flex items-center gap-2"><AiOutlineInfoCircle /> {t('resultadodasimulacao.explicacao_do_calculo')}</span>}
                                open={isModalOpen}
                                onOk={handleOk}
                                onCancel={handleCancel}
                                okText="Fechar"
                                footer={null}
                            >
                                <p className="text-black text-base flex justify-between">Cilindrada: <span className="font-bold text-lg">{iucCilindrada ? `${iucCilindrada}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">CO2: <span className="font-bold text-lg">{iucCO2 ? `${iucCO2}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Adicional CO2: <span className="font-bold text-lg">{iucAdicionalCO2 ? `${iucAdicionalCO2}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Coeficiente ano matricula: <span className="font-bold text-lg">{iucCoeficienteAnoMatriculo ? `${iucCoeficienteAnoMatriculo}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Adicional Gasóleo: <span className="font-bold text-lg">{iucAdicionalGasoleo ? `${iucAdicionalGasoleo}€` : "0€"}</span></p>

                                <div className="border-t-4 pt-2 mt-2">
                                    <p className="text-black text-base flex justify-between">O IUC é pago anualmente pelo proprietário do veículo. A primeira guia de IUC tem de ser paga dentro dos 90 dias após emissão da DAV.</p>
                                </div>
                            </Modal>
                        </div>
                    </TabPane>
                ) : null}
                {(estadoDaPartilhaNomeDaProposta && (tabResultadoSimulacao == '1')) || iDTokenAuthenticated ? (
                    <TabPane tab={<span className='text-black'>Empresa</span>}
                        key="2">
                        <div>
                            <p className='text-black text-base flex justify-between'>{t('resultadodasimulacao.preco_do_carro')} <span
                                className='font-bold text-lg'>{formatarNumero(precoFinalDoCarroSemIva)}€</span>
                            </p>
                            <p className='text-black text-base flex justify-between'>
                                <span className="flex items-center gap-1">
                                    {t('main.isv')}<AiOutlineInfoCircle className='icon-question' onClick={showModalISV} />
                                </span>
                                <span className='font-bold text-lg flex items-center gap-2'>
                                    {valorFinalISV ? `${formatarNumero(valorFinalISV)}€` : "0€"}
                                </span>
                            </p>
                            <Modal
                                title={<span className="flex items-center gap-2"><AiOutlineInfoCircle /> {t('resultadodasimulacao.explicacao_do_calculo')}</span>}
                                open={isModalOpenISV}
                                onOk={handleOkISV}
                                onCancel={handleCancelISV}
                                okText="Fechar"
                                footer={null}
                            >
                                <p className="text-black text-base flex justify-between">Componente cilindrada: <span className="font-bold text-lg">{isvcomponentecilindrada ? `${isvcomponentecilindrada}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Componente ambiental: <span className="font-bold text-lg">{isvcomponenteambiental ? `${isvcomponenteambiental}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Redução anos uso cilindrada: <span className="font-bold text-lg">{isvReducaoAnosUsoCilindrada ? `${isvReducaoAnosUsoCilindrada}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Redução anos uso ambiental: <span className="font-bold text-lg">{isvReducaoAnosUsoAmbiental ? `${isvReducaoAnosUsoAmbiental}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Agravamento particulas: <span className="font-bold text-lg">{isvAgravamentoParticulas ? `${isvAgravamentoParticulas}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Redução anos uso particulas: <span className="font-bold text-lg">{isvReducaoAnosUsoParticulas ? `${isvReducaoAnosUsoParticulas}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Taxa aplicável tabela: <span className="font-bold text-lg">{isvTaxaAplicavelTabela ? `${isvTaxaAplicavelTabela}` : "Sem informação"}</span></p>

                            </Modal>
                            <p className='text-black text-base flex justify-between'>
                                <span className="flex items-center gap-1">
                                    {t('resultadodasimulacao.taxa_de_servico')}
                                    <Tooltip title="A taxa de serviço inclui o custo do transporte, seguro, legalização e todas as restantes despesas do processo de importação.">
                                        <AiOutlineInfoCircle className='icon-question' />
                                    </Tooltip>
                                </span>
                                <span className='font-bold text-lg flex items-center gap-2'>{taxaDeServico !== '' && `${formatarNumero(calcularComissao(taxaDeServico))}€`}</span>
                                {/* {taxaDeServico ? `${formatarNumero(taxaDeServico)}€` : "0€"}  */}
                            </p>

                            {isCheckedCredito && (
                                <p className='text-black text-base flex justify-between'>
                                    <span className="flex items-center gap-1">
                                        {t('resultadodasimulacao.comissao')}
                                        <Tooltip title="A taxa de financiamento serve para cobrir a disponibilização de capital por parte da Importrust, que adiantará 80% do valor do carro até que este este legalizado (altura em que a financeira do cliente disponibilizará o valor financiado).">
                                            <AiOutlineInfoCircle className='icon-question' />
                                        </Tooltip>
                                    </span>
                                    <span className='font-bold text-lg flex items-center gap-2'>{comissaoCredito} €</span>
                                </p>
                            )}

                        </div>

                        <div className="flex justify-between items-center">
                            <span className='center text-black text-base font-semibold flex justify-between'>{t('resultadodasimulacao.preco_chave_nao_mao')} </span>
                            <span className='text-lg sm:text-2xl font-extrabold'>{formatarNumero(valorChaveNaMaoEmpresa)}€</span>
                        </div>
                        <div className="border-t-4 pt-2 mt-2">
                            <p className='text-black text-base flex justify-between'>
                                <span className="flex items-center gap-1">{t('main.iuc')}<AiOutlineInfoCircle className='icon-question' onClick={showModal} /></span>
                                <span className='font-bold text-lg flex items-center gap-2'>
                                    {valorFinalIUC ? `${formatarNumero(valorFinalIUC)}€` : "0€"}
                                </span>
                            </p>
                            <Modal
                                title={<span className="flex items-center gap-2"><AiOutlineInfoCircle /> {t('resultadodasimulacao.explicacao_do_calculo')}</span>}
                                open={isModalOpen}
                                onOk={handleOk}
                                onCancel={handleCancel}
                                okText="Fechar"
                                footer={null} // Não há necessidade de um rodapé neste caso
                            >
                                <p className="text-black text-base flex justify-between">Cilindrada: <span className="font-bold text-lg">{iucCilindrada ? `${iucCilindrada}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">CO2: <span className="font-bold text-lg">{iucCO2 ? `${iucCO2}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Adicional CO2: <span className="font-bold text-lg">{iucAdicionalCO2 ? `${iucAdicionalCO2}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Coeficiente ano matricula: <span className="font-bold text-lg">{iucCoeficienteAnoMatriculo ? `${iucCoeficienteAnoMatriculo}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Adicional Gasóleo: <span className="font-bold text-lg">{iucAdicionalGasoleo ? `${iucAdicionalGasoleo}€` : "0€"}</span></p>
                                <div className="border-t-4 pt-2 mt-2">
                                    <p className="text-black text-base flex justify-between">O IUC é pago anualmente pelo proprietário do veículo. A primeira guia de IUC tem de ser paga dentro dos 90 dias após emissão da DAV.</p>
                                </div>
                            </Modal>
                        </div>
                    </TabPane>
                ) : null}
            </Tabs>
            <Modal
                title="Editar Proposta"
                visible={isModalEditarVisible}
                onOk={handleEditarOk}
                onCancel={handleEditarCancel}
                okText="Guardar"
                cancelText="Fechar"
                confirmLoading={false}
            >
                <div className="grid">
                    <div>
                        <p className="text-sm text-black mb-2">Valor da Viatura</p>
                        <Form.Item
                            name="precoFinalCarro"
                            rules={[{ required: true, message: 'Por favor, insira o valor do carro.' }]}
                        >
                            <Input
                                placeholder="Valor da Viatura"
                                value={precoFinalCarro}
                                onChange={(e) =>
                                    tabResultadoSimulacao === '0'
                                        ? setPrecoFinalCarro(e.target.value)
                                        : setPrecoFinalDoCarroSemIva(e.target.value)
                                }
                            />
                        </Form.Item>
                    </div>
                    <div>
                        <p className="text-sm text-black mb-2">Taxa de Serviço</p>
                        <Form.Item
                            name="taxaServico"
                            rules={[{ required: true, message: 'Por favor, insira o valor da Taxa de Serviço.' }]}
                        >
                            <Input
                                placeholder="Taxa de Serviço"
                                value={taxaDeServico}
                                onChange={(e) => setTaxaDeServico(e.target.value)}
                            />
                        </Form.Item>
                    </div>
                    {loading ? (
                        <div className="card-white tabela-propostas flex flex-col gap-4 justify-center">
                            <Spin indicator={antIcon} />
                            <p className='text-black text-center'>A atualizar ...</p>
                        </div>
                    ) : null}
                </div>
            </Modal>

        </div>

    )
}
