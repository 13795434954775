import React, { useEffect, useState } from 'react';
import {
    Routes,
    Route, useLocation
} from "react-router-dom";
import Login from './components/pt/login';
import ErroPagina from './routes/erropagina';
import Propostas from './routes/pt/proposta/propostas';
import UltimasPesquisas from './routes/pt/proposta/ultimas-pesquisas';
import PT_Calculo from './routes/pt/calculo/pt_calculo';
import ES_Calculo from './routes/es/calculo/es_calculo';
import { useTranslation } from 'react-i18next';
import PerfilDoContactoHubspot from './components/pt/hubspot/contactos/perfil-do-contacto';
import ConsultarListaDeContactosHubspot from './components/pt/hubspot/contactos/consultar-lista-de-contactos';
import ConsultarListaDeDealsHubspot from './components/pt/hubspot/deals/consultar-lista-de-deals';
import PerfilDoDealHubspot from './components/pt/hubspot/deals/perfil-do-deal';
import LoginMain from './components/pt/login-main';
import DashboardTeste from './routes/dashboard copy';
import GerarContratoProcuracao from './components/pt/gerador_de_documentos/gerar-contratos-procuracao';
import ListadeDocumentoGerados from './components/pt/gerador_de_documentos/lista-documentos-gerados';
import AssinaturaEmail from './components/pt/gerador_de_documentos/assinatura-email';
import SimuladorDeCredito from './components/pt/gerador_de_documentos/simuladorDeCreditoNovo';
import Search from './routes/search';
import FerramentasDeSuporte from './routes/pt/ferramentasdesuporte';
import RegisterForm from './components/user/registar-user';
import ListaDeUtilizadores from './components/user/lista-de-utilizadores';
import Notificacoes from './components/pt/notificacoes/notificacoes';
import SimuladorISV from './routes/pt/calculo/pt_simuladorISV';
import ConsultarListaDeEmails from './components/pt/hubspot/emails/consultar-lista-de-emails';


export function AppRoutes({
    teste,
    setTeste,
    setNovaPesquisa,
    novaPesquisa,
    isAuthenticated,
    userData,
    currentLanguage,
    setCurrentLanguage,
    allLanguage,
    saveToken,
    setEstadoDaPartilhaNomeDaProposta,
    setTemParametros,
    temParametros,
    estadoDaPartilhaNomeDaProposta,
    iDTokenAuthenticated,
    linkDaProposta,
    setEsconde,
    setErroPedido,
    urlCarroLink,
    setUrlCarroLink,
    esconde,
    erroPedido,
    validLogin,
    searchQuery,
    setSearchQuery,
    setCountNotificacoes,
    nomeDaProposta,
    setNomeDaProposta
}) {

    const { t } = useTranslation();

    const location = useLocation();

    const [todosOsDadosDoCarro, setTodosOsDadosDoCarro] = useState('');
    const [partilhaNomeDaProposta, setPartilhaNomeDaProposta] = useState('');
    const [nomeGeradoDaProposta, setNomeGeradoDaProposta] = useState('');


    const [validartipodeparametro, setValidarTipoDeParametro] = useState(true);




    const [showHubspot, setShowHubspot] = useState(false);
    const [checkDataHubspot, setCheckDataHubspot] = useState([]);
    const [nomeDoDeal, setNomeDoDeal] = useState('');
    const [nomeDoClienteDeal, setNomeDoClienteDeal] = useState('');
    const [emailDoClienteDeal, setEmailDoClienteDeal] = useState('');
    const [dataDeAssociacaoDoDeal, setDataDeAssociacaoDoDeal] = useState('');
    const [etapaDoDeal, setEtapaDoDeal] = useState('');
    const [pipelineDoDeal, setPipelineDoDeal] = useState('');




    // useEffect(() => {

    //     const parametros = new URLSearchParams(location.search);

    //     if (parametros.size > 0) {

    //         const nomePropostaParam = parametros.get('nome_proposta');
    //         const idPropostaParam = parametros.get('id_proposta');

    //         if (nomePropostaParam || idPropostaParam) {

    //             if (nomePropostaParam) {
    //                 setNomeGeradoDaProposta(nomePropostaParam);
    //                 setValidarTipoDeParametro(true)
    //             }
    //             if (idPropostaParam) {
    //                 setNomeGeradoDaProposta(idPropostaParam);
    //                 setValidarTipoDeParametro(false)
    //             }

    //             setEstadoDaPartilhaNomeDaProposta(true);

    //             if (!isAuthenticated) {
    //                 setNovaPesquisa(false);
    //                 setTemParametros(true);
    //             } else {
    //                 setNovaPesquisa(true);
    //                 setTemParametros(false);
    //             }

    //         }

    //     } else {
    //         setEstadoDaPartilhaNomeDaProposta(false);
    //     }

    // }, []);







    function formatarNumero(number, decimalPlaces = 0, thousandsSeparator = ' ', decimalSeparator = '.') {

        number = parseFloat(number);
        if (number < 10000) {
            //   return number.toFixed(decimalPlaces);
        }

        const formattedValue = number.toLocaleString('en-US', {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        });


        // SEPARAR NAS DECIMAIS
        let parts = formattedValue.split('.');

        let integerPart = parts[0];
        // FORMATAR A PARTE INTEIRA (SEM VIRGULAS)
        if (thousandsSeparator === ' ') {
            integerPart = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ').replace(",", ' ');
        }

        let decimalPart = parts.length > 1 ? decimalSeparator + `${parts[1]}` : '';
        if (thousandsSeparator === '.') {
            decimalPart = parts.length > 1 ? `.${parts[1]}` : '';
        }

        // q: o que isto faz? a: se tiver 2 decimais, vai buscar a parte decimal, se nao tiver, fica vazio
        // console.log(`${integerPart}${decimalPart}`)

        return `${integerPart}${decimalPart}`;
    }



    return (
        <Routes>
            {validLogin && (
                <>

                    <Route path="/" element={
                        <div>
                            <DashboardTeste
                                currentLanguage={currentLanguage}
                                userData={userData}
                                saveToken={saveToken}
                                iDTokenAuthenticated={iDTokenAuthenticated}
                                formatarNumero={formatarNumero}
                            />
                        </div>
                    } />

                    {userData.roles[0] === "administrator" && (
                        <>
                            <Route path="/lista-de-utilizadores" element={
                                <div>
                                    <ListaDeUtilizadores
                                        saveToken={saveToken}
                                        iDTokenAuthenticated={iDTokenAuthenticated}
                                    />
                                </div>
                            } />
                            <Route path="/registar-utilizador" element={
                                <div>
                                    <RegisterForm
                                        currentLanguage={currentLanguage}
                                        userData={userData}
                                        saveToken={saveToken}
                                        iDTokenAuthenticated={iDTokenAuthenticated}
                                        formatarNumero={formatarNumero}
                                    />
                                </div>
                            } />
                        </>

                    )}

                    <Route path="/notificacoes" element={
                        <div>
                            <Notificacoes
                                currentLanguage={currentLanguage}
                                saveToken={saveToken}
                                iDTokenAuthenticated={iDTokenAuthenticated}
                                formatarNumero={formatarNumero}
                                userData={userData}
                                setCountNotificacoes={setCountNotificacoes}
                            />
                        </div>
                    } />

                    <Route path="/search" element={
                        <div>
                            <Search
                                currentLanguage={currentLanguage}
                                saveToken={saveToken}
                                iDTokenAuthenticated={iDTokenAuthenticated}
                                setSearchQuery={setSearchQuery}
                                searchQuery={searchQuery}
                                formatarNumero={formatarNumero}
                                userData={userData}
                            />
                        </div>
                    } />

                    {/* PORTUGAL */}
                    {/* {allLanguage.includes('pt') && (
                        <> */}

                    <Route path="/pt/simulador" element={
                        <PT_Calculo
                            setCurrentLanguage={setCurrentLanguage}
                            currentLanguage={currentLanguage}
                            validartipodeparametro={validartipodeparametro}
                            userData={userData}
                            teste={teste}
                            setTeste={setTeste}
                            setNomeGeradoDaProposta={setNomeGeradoDaProposta}
                            formatarNumero={formatarNumero}
                            setEstadoDaPartilhaNomeDaProposta={setEstadoDaPartilhaNomeDaProposta}
                            setNovaPesquisa={setNovaPesquisa}
                            novaPesquisa={novaPesquisa}
                            estadoDaPartilhaNomeDaProposta={estadoDaPartilhaNomeDaProposta}
                            partilhaNomeDaProposta={partilhaNomeDaProposta}
                            setPartilhaNomeDaProposta={setPartilhaNomeDaProposta}
                            nomeGeradoDaProposta={nomeGeradoDaProposta}
                            linkDaProposta={linkDaProposta}
                            temParametros={temParametros}
                            setTemParametros={setTemParametros}
                            setTodosOsDadosDoCarro={setTodosOsDadosDoCarro}
                            saveToken={saveToken}
                            iDTokenAuthenticated={iDTokenAuthenticated}
                            setEsconde={setEsconde}
                            setErroPedido={setErroPedido}
                            urlCarroLink={urlCarroLink}
                            setUrlCarroLink={setUrlCarroLink}
                            esconde={esconde}
                            erroPedido={erroPedido}
                            isAuthenticated={isAuthenticated}
                            nomeDaProposta={nomeDaProposta}
                            setNomeDaProposta={setNomeDaProposta}
                            showHubspot={showHubspot}
                            setShowHubspot={setShowHubspot}
                            checkDataHubspot={checkDataHubspot}
                            setCheckDataHubspot={setCheckDataHubspot}
                            nomeDoDeal={nomeDoDeal}
                            setNomeDoDeal={setNomeDoDeal}
                            nomeDoClienteDeal={nomeDoClienteDeal}
                            setNomeDoClienteDeal={setNomeDoClienteDeal}
                            emailDoClienteDeal={emailDoClienteDeal}
                            setEmailDoClienteDeal={setEmailDoClienteDeal}
                            dataDeAssociacaoDoDeal={dataDeAssociacaoDoDeal}
                            setDataDeAssociacaoDoDeal={setDataDeAssociacaoDoDeal}
                            etapaDoDeal={etapaDoDeal}
                            setEtapaDoDeal={setEtapaDoDeal}
                            pipelineDoDeal={pipelineDoDeal}
                            setPipelineDoDeal={setPipelineDoDeal}
                        />
                    }
                    />

                    <Route path={`/pt/${t('header.propostas')}`} element={
                        <Propostas
                            currentLanguage={currentLanguage}
                            formatarNumero={formatarNumero}
                            saveToken={saveToken}
                            iDTokenAuthenticated={iDTokenAuthenticated}
                            showHubspot={showHubspot}
                            setShowHubspot={setShowHubspot}
                            checkDataHubspot={checkDataHubspot}
                            setCheckDataHubspot={setCheckDataHubspot}
                            nomeDoDeal={nomeDoDeal}
                            setNomeDoDeal={setNomeDoDeal}
                            nomeDoClienteDeal={nomeDoClienteDeal}
                            setNomeDoClienteDeal={setNomeDoClienteDeal}
                            emailDoClienteDeal={emailDoClienteDeal}
                            setEmailDoClienteDeal={setEmailDoClienteDeal}
                            dataDeAssociacaoDoDeal={dataDeAssociacaoDoDeal}
                            setDataDeAssociacaoDoDeal={setDataDeAssociacaoDoDeal}
                            etapaDoDeal={etapaDoDeal}
                            setEtapaDoDeal={setEtapaDoDeal}
                            pipelineDoDeal={pipelineDoDeal}
                            setPipelineDoDeal={setPipelineDoDeal}
                            userData={userData}
                        />
                    }
                    />

                    <Route path='/pt/gerar-documento' element={
                        <GerarContratoProcuracao
                            currentLanguage={currentLanguage}
                            userData={userData}
                            saveToken={saveToken}
                            iDTokenAuthenticated={iDTokenAuthenticated}
                        />
                    } />

                    <Route path='/pt/ferramentas-suporte' element={
                        <FerramentasDeSuporte
                            currentLanguage={currentLanguage}
                            userData={userData}
                            saveToken={saveToken}
                            iDTokenAuthenticated={iDTokenAuthenticated}
                        />
                    } />

                    {/* <Route path='/pt/simulador-isv' element={
                        <SimuladorISV
                            currentLanguage={currentLanguage}
                            userData={userData}
                            saveToken={saveToken}
                            iDTokenAuthenticated={iDTokenAuthenticated}
                        />
                    } /> */}


                    <Route path='/pt/lista-de-documentos' element={
                        <ListadeDocumentoGerados
                            currentLanguage={currentLanguage}
                            userData={userData}
                            saveToken={saveToken}
                            iDTokenAuthenticated={iDTokenAuthenticated}
                        />
                    } />



                    <Route path="/pt/simulador-de-credito" element={
                        <SimuladorDeCredito
                            currentLanguage={currentLanguage}
                            userData={userData}
                            saveToken={saveToken}
                            iDTokenAuthenticated={iDTokenAuthenticated}
                        />
                    } />

                    <Route path="/pt/assinatura-de-email" element={
                        <AssinaturaEmail
                            currentLanguage={currentLanguage}
                            userData={userData}
                            saveToken={saveToken}
                            iDTokenAuthenticated={iDTokenAuthenticated}
                        />
                    } />



                    <Route path={`/pt/${t('header.historico-de-pesquisas')}`} element={
                        <UltimasPesquisas currentLanguage={currentLanguage} saveToken={saveToken} iDTokenAuthenticated={iDTokenAuthenticated} formatarNumero={formatarNumero} userData={userData} />
                    } />

                    {/* <Route path={`/pt/${t('header.todas.propostas')}`}
                        element={
                            <PropostasAdmin currentLanguage={currentLanguage} saveToken={saveToken} iDTokenAuthenticated={iDTokenAuthenticated} formatarNumero={formatarNumero} userData={userData} />
                        }
                    /> */}

                    <Route path={`/pt/hubspot/consultar-lista-contactos`} element={
                        <div>
                            <ConsultarListaDeContactosHubspot
                                currentLanguage={currentLanguage}
                                userData={userData}
                                saveToken={saveToken}
                                iDTokenAuthenticated={iDTokenAuthenticated}
                            />
                        </div>
                    } />

                    <Route path={`/pt/hubspot/logs-emails`} element={
                        <div>
                            <ConsultarListaDeEmails
                                currentLanguage={currentLanguage}
                                userData={userData}
                                saveToken={saveToken}
                                iDTokenAuthenticated={iDTokenAuthenticated}
                            />
                        </div>
                    } />

                    <Route path={`/pt/hubspot/perfil-contacto`} element={
                        <div>
                            <PerfilDoContactoHubspot
                                currentLanguage={currentLanguage}
                                userData={userData}
                                saveToken={saveToken}
                                iDTokenAuthenticated={iDTokenAuthenticated}
                            />
                        </div>
                    } />

                    <Route path={`/pt/hubspot/consultar-lista-deals`} element={
                        <div>
                            <ConsultarListaDeDealsHubspot
                                currentLanguage={currentLanguage}
                                userData={userData}
                                saveToken={saveToken}
                                iDTokenAuthenticated={iDTokenAuthenticated}
                            />
                        </div>
                    } />

                    <Route path={`/pt/hubspot/perfil-deal`} element={
                        <div>
                            <PerfilDoDealHubspot
                                formatarNumero={formatarNumero}
                                currentLanguage={currentLanguage}
                                userData={userData}
                                saveToken={saveToken}
                                iDTokenAuthenticated={iDTokenAuthenticated}
                            />
                        </div>
                    } />


                    {/* <Route path="/pt/proposta/:id" element={
                        <SingleProposta />
                    } 
                    />*/}


                    {/* ESPANHA */}
                    {/* {allLanguage.includes('es') && (
                        <> */}
                    {/* <Route path="/es" element={
                                <div>
                                    <Dashboard
                                        userData={userData}
                                        saveToken={saveToken}
                                        iDTokenAuthenticated={iDTokenAuthenticated}
                                    />
                                </div>
                            } /> */}

                    <Route path="/es/simulador" element={
                        <ES_Calculo
                            setCurrentLanguage={setCurrentLanguage}
                            currentLanguage={currentLanguage}
                            validartipodeparametro={validartipodeparametro}
                            userData={userData}
                            teste={teste}
                            setTeste={setTeste}
                            setNomeGeradoDaProposta={setNomeGeradoDaProposta}
                            formatarNumero={formatarNumero}
                            setEstadoDaPartilhaNomeDaProposta={setEstadoDaPartilhaNomeDaProposta}
                            setNovaPesquisa={setNovaPesquisa}
                            novaPesquisa={novaPesquisa}
                            estadoDaPartilhaNomeDaProposta={estadoDaPartilhaNomeDaProposta}
                            partilhaNomeDaProposta={partilhaNomeDaProposta}
                            setPartilhaNomeDaProposta={setPartilhaNomeDaProposta}
                            nomeGeradoDaProposta={nomeGeradoDaProposta}
                            linkDaProposta={linkDaProposta}
                            temParametros={temParametros}
                            setTemParametros={setTemParametros}
                            setTodosOsDadosDoCarro={setTodosOsDadosDoCarro}
                            saveToken={saveToken}
                            iDTokenAuthenticated={iDTokenAuthenticated}
                            setEsconde={setEsconde}
                            setErroPedido={setErroPedido}
                            // urlCarroLink={urlCarroLink}
                            // setUrlCarroLink={setUrlCarroLink}
                            esconde={esconde}
                            erroPedido={erroPedido}
                            isAuthenticated={isAuthenticated}
                            urlCarroLink={urlCarroLink}
                            setUrlCarroLink={setUrlCarroLink}
                        />
                    }
                    />


                    <Route path={`/es/${t('header.propostas')}`} element={
                        <Propostas
                            userData={userData}
                            currentLanguage={currentLanguage}
                            formatarNumero={formatarNumero}
                            saveToken={saveToken}
                            iDTokenAuthenticated={iDTokenAuthenticated} />
                    } />

                    <Route path={`/es/${t('header.historico-de-pesquisas')}`} element={
                        <UltimasPesquisas currentLanguage={currentLanguage} saveToken={saveToken} iDTokenAuthenticated={iDTokenAuthenticated} formatarNumero={formatarNumero} userData={userData} />
                    } />

                </>
            )}

            {!validLogin && (
                <Route path="/" element={<div className='h-screen p-2 md:p-10 content-center'><LoginMain /></div>} />
                // <Route path="/" element={<div className='alinhar-centro'><Login sAuthenticated={isAuthenticated} /></div>} />
            )}

            <Route path="*" element={<div className='alinhar-centro'><ErroPagina /></div>} />
        </Routes>
    )
}
