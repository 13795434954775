import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Select, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import flagPortugal from '../../../assets/flags/flag-portugal.svg'; // Adjust the path if necessary
import flagSpain from '../../../assets/flags/flag-spain.svg'; // Adjust the path if necessary


const { Option } = Select;

const filterOptions = [
    'formulario_contacto',
    'simulador_propostas',
    'automacoes_operacoes',
    'automacoes_sales',
    'gerador_documentos',
    'simulador_isv_pt',
    'proposta_reaberta',
    'proposta_fechada',
    'whatsapp_feedback_formulario_contacto',
    'simulador_iframe'
];

export default function TabelaLogsAutomacoes({ currentLanguage, saveToken, iDTokenAuthenticated, selectedCountry }) {


    const [logs, setLogs] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(['formulario_contacto']);
    // const [selectedCountry, setSelectedCountry] = useState('all');

    useEffect(() => {
        if (selectedFilter.length === 0) return;
        

        setLoading(true);

        // Construa a string de filtros separados por vírgula
        const filters = selectedFilter.join(',');

        const pedido1 = `${process.env.REACT_APP_LOGS_AUTOMACOES}?token=${saveToken}&user_id=${iDTokenAuthenticated}&lang=${currentLanguage}&filter=${filters}&pais=${selectedCountry}`;

        axios.get(pedido1)
            .then((response) => {
                setLogs(response.data.mensagem);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error occurred:', error);
                setLoading(false);
            });
    }, [currentLanguage, saveToken, iDTokenAuthenticated, selectedFilter]);

    const handleChange = (value) => {
        setSelectedFilter(value);
    };





    // Define table columns
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'País',
            dataIndex: 'pais',
            key: 'pais',
            render: (pais) => (
                <img
                    src={pais === "pt" || pais === "Portugal" ? flagPortugal : flagSpain}
                    alt={pais === "pt" || pais === "Portugal" ? "Portugal" : "Spain"}
                    style={{ marginRight: '8px', width: '35px', height: '22px' }}
                />
            )
        },
        {
            title: 'Tabela',
            dataIndex: 'tabela',
            key: 'tabela',
        },
        {
            title: 'Data',
            dataIndex: 'data_criacao',
            key: 'data_criacao',
        },
        {
            title: 'Assunto da Mensagem',
            dataIndex: 'mensagem',
            key: 'mensagem',
            render: (html) => (
                <div dangerouslySetInnerHTML={{ __html: html }} />
            )
        }
    ];









    return (
        <>
            <div className="grid gap-4">

                <div className='flex gap-2 items-center'>

                    <span>Filtros</span>
                    <Select
                        mode="multiple"
                        value={selectedFilter}
                        onChange={handleChange}
                        placeholder="Select filters"
                        style={{ width: '400px' }}
                    >
                        {filterOptions.map(option => (
                            <Option key={option} value={option}>
                                {option}
                            </Option>
                        ))}
                    </Select>
                    {/* <Select
                        value={selectedCountry}
                        onChange={value => setSelectedCountry(value)}
                        style={{ width: 120 }}
                        placeholder='País'
                    >
                        <Option value="all">Todos</Option>
                        <Option value="pt">Portugal</Option>
                        <Option value="es">Espanha</Option>
                    </Select> */}
                </div>


                <Table
                    dataSource={logs}
                    columns={columns}
                    rowKey={(record) => record.id}
                    pagination={true}
                    scroll={{ x: false }}
                    bordered
                    style={{ width: '100%' }}
                    tableLayout="auto"
                />

            </div>

        </>
    );
}
