import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import axios from 'axios';
import { IoArrowBack } from 'react-icons/io5';
import { Button } from 'antd';


export default function InfoDoDealHubspot({
    dealsData,
    infoDealsData
}) {


    return (
        <>
            <div className='card-hubspot grid gap-4'>
                <div className='grid gap-1'>
                    <p className='text-lg font-bold'>Perfil do Deal</p>
                </div>
                <hr className='borda-white' />
                {dealsData ? (
                    <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                        <p className='text-sm text-black font-semibold'>ID: <br /><span className='text-lg text-white font-sembold'>{infoDealsData.hs_object_id}</span></p>
                        <p className='text-sm text-black font-semibold'>Nome: <br /><span className='text-lg text-white font-sembold capitalize'>{infoDealsData.dealname}</span></p>
                        <p className='text-sm text-black font-semibold'>Data de Criação: <br /><span className='text-lg text-white font-sembold'>{dealsData.createdAt}</span></p>
                        <p className='text-sm text-black font-semibold'>Estado: <br /><span className='text-lg text-white font-sembold'>{infoDealsData.dealstage}</span></p>
                        <p className='text-sm text-black font-semibold'>Pipeline: <br /><span className='text-lg text-white font-sembold'>{infoDealsData.pipeline}</span></p>
                        <p className='text-sm text-black font-semibold'>Data de Última Atualização: <br /><span className='text-lg text-white font-sembold'>{dealsData.updatedAt}</span></p>

                    </div>
                ) : null}

            </div>
        </>
    );
}
