import React, { useEffect, useState } from 'react';
import { Input, Table, Image, Spin, Button, Checkbox, Modal, Tooltip, Switch, Select } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import redbubble from '../../../assets/red-circle.svg';
import greenbubble from '../../../assets/green-circle.svg';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { IoAddCircleOutline, IoEyeOutline } from 'react-icons/io5';
import { IoCloseCircleOutline } from "react-icons/io5";
import AssociarDeal from '../hubspot/deals/associar-deal';
// import logo from '../../../assets/hubspot.svg';
import logo from '../../../assets/hubspot-icon.svg';
import logoCor from '../../../assets/hubspot-icon-cor.svg';
import HistoricoDePesquisasPropostas from '../historico-de-acessos-proposta';
import { AiOutlineInfoCircle } from "react-icons/ai";
import { IoMdAdd } from 'react-icons/io';
import { RiFilePaperFill } from "react-icons/ri";
import { FaHourglass } from "react-icons/fa6";
import { FaCheckCircle } from 'react-icons/fa';
import flagPortugal from '../../../assets/flags/flag-portugal.svg';
import flagSpain from '../../../assets/flags/flag-spain.svg';
import { SearchOutlined } from '@ant-design/icons';
import FilterTabelaPropostas from '../filters/filter-tabela-propostas';

const { Option } = Select;


const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);


export default function TabeladePropostas({
    currentLanguage,
    saveToken,
    iDTokenAuthenticated,
    formatarNumero,
    idDaPropostaEncontrada,
    showHubspot,
    setShowHubspot,
    checkDataHubspot,
    setCheckDataHubspot,
    nomeDoDeal,
    setNomeDoDeal,
    nomeDoClienteDeal,
    setNomeDoClienteDeal,
    emailDoClienteDeal,
    setEmailDoClienteDeal,
    dataDeAssociacaoDoDeal,
    setDataDeAssociacaoDoDeal,
    etapaDoDeal,
    setEtapaDoDeal,
    pipelineDoDeal,
    setPipelineDoDeal,
    userData
}) {

    const { t } = useTranslation();

    const [searchQuery, setSearchQuery] = useState('');
    const [propostaData, setPropostaData] = useState([]);

    const [historicoDeAcessos, setHistoricoDeAcessos] = useState([]);
    const [historicoSelecionadodeAcessos, setHistoricoSelecionadodeAcessos] = useState([]);

    //config
    const [isColunasShow, setIsColunasShow] = useState(false);
    const [isConfigHubspotOpen, setIsConfigHubspotOpen] = useState(false);
    const [selectedPropostaParaHubspot, setSelectedPropostaParaHubspot] = useState([]);

    //popups
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenHistorico, setIsModalOpenHistorico] = useState(false);
    const [isModalOpenPerfilDoDeal, setIsModalOpenPerfilDoDeal] = useState(false);
    const [hubspotData, setHubspotData] = useState('');


    //filtros
    const [filtersSelected, setFiltersSelected] = useState([]);


    const [loading, setLoading] = useState(false);
    const [loadingHubspot, setLoadingHubspot] = useState(false);


    const fetchPropostas = async () => {
        setLoading(true)

        try {
            let url = `${process.env.REACT_APP_GET_PROPOSTAS}?token=${saveToken}&user_id=${iDTokenAuthenticated}&lang=${currentLanguage}`;

            const { dealHubspotFechado, id_criou, paisPropostas, dataCriacaoInicio, dataCriacaoFim } = filtersSelected;


            if (dealHubspotFechado !== undefined) url += `&deal_hubspot_fechado=${dealHubspotFechado}`;
            if (id_criou !== undefined) url += `&id_criou=${id_criou.toString()}`; // Se necessário, converta para string
            if (paisPropostas !== undefined) url += `&pais=${paisPropostas}`;
            if (dataCriacaoInicio) url += `&data_criacao_inicio=${dataCriacaoInicio}`;
            if (dataCriacaoFim) url += `&data_criacao_fim=${dataCriacaoFim}`;

            // console.log('Constructed URL:', url); // Confirme se a URL contém id_criou com o valor correto

            const response = await axios.get(url);

            setPropostaData(response.data);
            setHistoricoDeAcessos(response.data.map(item => ({
                acessos: item.ultimos_acessos,
                id: item.id_proposta
            })));
            setLoading(false)
        } catch (error) {
            console.error('Error occurred:', error);
            setLoading(false)
        }
    };


    useEffect(() => {
        fetchPropostas();
    }, []);

    useEffect(() => {
        fetchPropostas();
    }, [filtersSelected]);



    const handleInputChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
    };


    const handleHabilitarHubSpotConfig = () => {
        setSelectedPropostaParaHubspot([]);
        setIsConfigHubspotOpen(!isConfigHubspotOpen);
    };


    const onSelectChange = propostaId => {
        setSelectedPropostaParaHubspot(prevSelected =>
            prevSelected.includes(propostaId)
                ? prevSelected.filter(id => id !== propostaId)
                : [...prevSelected, propostaId]
        );
    };



    const showModalHistorico = value => {
        const selectedOption = historicoDeAcessos.find(option => option.id === value);
        setHistoricoSelecionadodeAcessos(selectedOption);
        setIsModalOpenHistorico(true);
    };






    const showModalPerfilDeal = async (valueHub, valueProposta) => {
        try {
            setLoadingHubspot(true);
            const url = `${process.env.REACT_APP_GET_PROCURAR_DEALS}?token=${saveToken}&user_id=${iDTokenAuthenticated}&id_deal=${valueHub}&lang=${currentLanguage}&id_proposta=${valueProposta}`;
            const response = await axios.get(url);
            setHubspotData(response.data.deal);
            setLoadingHubspot(false);
            setIsModalOpenPerfilDoDeal(true);
        } catch (error) {
            setLoadingHubspot(false);
            console.error('Error occurred:', error);
        }
    };



    const showModal = () => setIsModalOpen(true);
    const handleOk = () => setIsModalOpen(false);
    const handleCancel = () => setIsModalOpen(false);



    const handleOkHistorico = () => setIsModalOpenHistorico(false);
    const handleCancelHistorico = () => setIsModalOpenHistorico(false);



    const handleOkPerfilDeal = () => setIsModalOpenPerfilDoDeal(false);
    const handleCancelPerfilDeal = () => setIsModalOpenPerfilDoDeal(false);
    const handleColunasShow = checked => setIsColunasShow(checked);



    const filteredPropostas = propostaData.filter(data => {

        // Divide a searchQuery em partes (palavras)
        const searchQueryParts = searchQuery.toLowerCase().split(' ');

        // Função para verificar se todas as partes da query estão incluídas em uma string
        const includesAllParts = (string) => {
            return searchQueryParts.every(part => string.includes(part));
        };

        // Verifica se qualquer campo inclui todas as partes da query
        return (
            includesAllParts(data.nome_proposta.toLowerCase()) ||
            includesAllParts(data.user_data.nome_criou.toLowerCase()) ||
            includesAllParts(data.pais.toLowerCase())
        );
    });



    return (
        <>
            <div className='card'>
                <div className='grid gap-4 mb-7'>
                    <div className='flex justify-between items-center gap-4'>
                        <div className='grid gap-1'>
                            <p className='text-lg font-bold'>{t('propostas.titulo_principal')}</p>
                            <p className='text-sm font-normal'>{t('propostas.sub_titulo')}</p>
                        </div>
                        <hr></hr>
                        <div className='flex gap-2 items-center'>
                         {currentLanguage === "pt" && (
                                <>
                                    {selectedPropostaParaHubspot.length > 0 ? (
                                        <>
                                            <div className='flex justify-end gap-4 items-center'>
                                                <Button type="primary" className="flex items-center gap-2 bg-white text-black font-bold rounded" onClick={showModal}>
                                                    <img
                                                        src={logoCor}
                                                        alt="Logo"
                                                        className="w-6 object-contain"
                                                    />
                                                    Associar
                                                </Button>
                                            </div>
                                            <div>|</div>
                                        </>
                                    ) : null}
                                </>
                            )} 
                            {currentLanguage === "pt" && (
                                <>
                                    <Button
                                        className="flex gap-2 hubspot-btn items-center font-bold rounded"
                                        onClick={handleHabilitarHubSpotConfig}
                                    >
                                        {isConfigHubspotOpen ? (
                                            <div className='flex items-center gap-2'>
                                                <IoCloseCircleOutline className='iconc' />
                                                Fechar configurações
                                            </div>
                                        ) : (
                                            <div className='flex items-center gap-2'>
                                                <img
                                                    src={logo}
                                                    alt="Logo"
                                                    className="w-6 object-contain"
                                                />
                                                Atribuir proposta
                                            </div>
                                        )}
                                    </Button>
                               
                                </>
                            )} 
                            
                        </div>
                    </div>
                    <hr></hr>
                    <div className='grid grid-cols-10 gap-2 items-center'>
                        <div className='flex items-center gap-2 col-span-8'>
                            <Input
                                type="text"
                                placeholder={t('propostas.input')}
                                value={searchQuery}
                                onChange={handleInputChange}
                                prefix={<SearchOutlined />}
                            />
                            <div className='barra'></div>
                            <div className='flex gap-2 '>
                            </div>
                        </div>
                        <div className='col-span-2 flex gap-3 items-center justify-end'>
                            <label className='flex justify-end gap-2 items-center cursor-pointer text-sm'>
                                {t('propostas.expandircolunas')}
                                <Switch checked={isColunasShow} onChange={handleColunasShow} />
                            </label>
                        </div>
                    </div >
                </div>
                {currentLanguage === "pt" && (
                    <FilterTabelaPropostas userData={userData} filtersSelected={filtersSelected} setFiltersSelected={setFiltersSelected} saveToken={saveToken} iDTokenAuthenticated={iDTokenAuthenticated} />
                )}
                <div className="w-full">
                    {loading ? (
                        <div className="flex flex-col gap-4 justify-center">
                            <Spin indicator={antIcon} />
                            <p className='text-white text-center'>{t('media_mercado.loading')}</p>
                        </div>
                    ) : filteredPropostas.length === 0 ? (
                        <div className="tabela-propostas">
                            <p className='text-white text-center'>Sem Resultados...</p>
                        </div>
                    ) : (
                        <div className="tabela-propostas">
                            <Table
                                dataSource={filteredPropostas}
                                rowKey={(proposta) => proposta.id_proposta}
                                scroll={{ x: 'max-content' }}
                                bordered
                            >
                                {isConfigHubspotOpen ? (
                                    <>
                                        <Table.Column
                                            title="Ação"
                                            key="select"
                                            width={100}
                                            fixed={'left'}
                                            className='text-center column-hubspot'
                                            render={(proposta) => (
                                                <Checkbox
                                                    checked={selectedPropostaParaHubspot.includes(proposta.id_proposta)}
                                                    onChange={() => onSelectChange(proposta.id_proposta)}
                                                />
                                            )}
                                        />
                                        <Table.Column
                                            title="Associado"
                                            key="associado"
                                            width={100}
                                            fixed={'left'}
                                            className='text-center column-hubspot'
                                            render={(proposta) => (
                                                proposta.id_deal_hubspot ? (
                                                    <span className='flex justify-center cursor-pointer' onClick={() => showModalPerfilDeal(proposta.id_deal_hubspot)}>
                                                        <img style={{ height: '20px' }} src={greenbubble} alt="Green Bubble" />
                                                    </span>
                                                ) : (
                                                    <span className='flex justify-center'>
                                                        <img style={{ height: '20px' }} src={redbubble} alt="Red Bubble" />
                                                    </span>

                                                )
                                            )}
                                        />
                                    </>
                                ) : null}

                                {userData.roles[0] === "administrator" && (
                                    <Table.Column
                                        title="País"
                                        key="pais"
                                        // fixed={'left'}
                                        width={70}
                                        render={(proposta) => (
                                            proposta.pais === "pt" ? (
                                                <img
                                                    src={flagPortugal}
                                                    alt="Portugal"
                                                    style={{ marginRight: '8px', width: '35px', height: '22px' }}
                                                />
                                            ) : (
                                                <img
                                                    src={flagSpain}
                                                    alt="Spain"
                                                    style={{ marginRight: '8px', width: '35px', height: '22px' }}
                                                />
                                            )
                                        )}
                                    />
                                )}

                                <Table.Column
                                    title={t('propostas.criado_por')}
                                    key="criado_por"
                                    width={190}
                                    // fixed={!isConfigHubspotOpen ? "left" : undefined}
                                    render={(proposta) => (
                                        <div className='flex items-center gap-2'>
                                            <img src={proposta.user_data.foto_perfil} className='w-8 object-cover h-8 rounded-full' alt='User Profile' />
                                            <span>{proposta.user_data.nome_criou}</span>
                                        </div>
                                    )}
                                />


                                <Table.Column
                                    title={t('propostas.nome_da_proposta')}
                                    key="nomedaproposta"
                                    render={(proposta) => {
                                        const isPortuguese = proposta.pais === "pt";
                                        const linkLanguage = isPortuguese ? 'pt' : 'es';

                                        // Define o parâmetro da URL com base na expiração
                                        const linkParam = proposta.expirou === "0"
                                            ? (isPortuguese ? `nome_proposta=${proposta.url_acesso}` : `propuesta_nombre=${proposta.url_acesso}`)
                                            : (isPortuguese ? `id_proposta=${proposta.unique_id}` : `id_propuesta=${proposta.unique_id}`);

                                        const linkUrl = `/${linkLanguage}/simulador/?${linkParam}`;
                                        const displayedName = isColunasShow ? proposta.nome_proposta : `${proposta.nome_proposta.slice(0, 11)}...`;

                                        return (
                                            <Tooltip placement="topRight" title={proposta.nome_proposta}>
                                                <a href={linkUrl} target='_blank'>
                                                    {displayedName}
                                                </a>
                                            </Tooltip>
                                        );
                                    }}
                                />




                                <Table.Column
                                    title={t('propostas.data')}
                                    key="data"
                                    render={(proposta) => (
                                        <span>{proposta.data_criacao}</span>
                                    )}
                                />


                                {currentLanguage === "pt" && (
                                    <>
                                        <Table.Column
                                            title="Tipo de Proposta"
                                            key="tipo"
                                            render={(proposta) => {
                                                if (proposta.tipo_proposta === 'proposta_cliente') {
                                                    return 'Proposta Cliente';
                                                } else if (proposta.tipo_proposta === 'proposta_de_content') {
                                                    return 'Proposta de Content';
                                                } else if (proposta.tipo_proposta === 'proposta_interna') {
                                                    return 'Proposta Interna';
                                                }
                                                return null; // Ou um valor padrão, caso não bata com nenhum tipo
                                            }}
                                        />

                                        <Table.Column
                                            title={t('propostas.estado')}
                                            key="estado"
                                            render={(proposta) => (
                                                proposta.estado_proposta_hubspot === "proposta_aceite" ? (
                                                    <div className='rounded-full bg-verde text-white px-3 w-auto inline-table'>
                                                        <span className='flex gap-2 items-center'><FaCheckCircle /> {t('propostas.aceite')}</span>
                                                    </div>
                                                ) : (
                                                    <div className='rounded-full bg-[#fff6] text-black px-3 w-auto inline-table'>
                                                        <span className='flex gap-2 items-center text-white'><FaHourglass />  {t('propostas.aberto')}</span>
                                                    </div>
                                                )
                                            )}

                                        />
                                    </>
                                )}



                                <Table.Column
                                    title={t('ultimas_pesquisas.imagem_viatura')}
                                    className='text-center'
                                    render={(proposta) => (
                                        <Image
                                            style={{ textAlign: 'center', objectFit: 'contain' }}
                                            width={60}
                                            height={45}
                                            src={proposta.info?.imagens?.[0]}
                                        />
                                    )}
                                />

                                <Table.Column
                                    title={t('propostas.marca')}
                                    key="marca"
                                    render={(proposta) => (
                                        <span>{proposta.caracteristicas.marca}</span>
                                    )}
                                />

                                {isColunasShow && (
                                    <>
                                        <Table.Column
                                            title={t('propostas.modelo')}
                                            key="modelo"
                                            render={(proposta) => (
                                                <span>{proposta.caracteristicas.modelo}</span>
                                            )}
                                        />

                                        <Table.Column
                                            title={t('propostas.sociedade')}
                                            key="sociedade"
                                            render={(proposta) => (
                                                proposta.info.empresa === '0' ? (
                                                    <span>Particular</span>
                                                ) : (
                                                    proposta.info.empresa === '1' ? (
                                                        <span>Empresa</span>
                                                    ) : (
                                                        <span>Sem dados</span>
                                                    )
                                                )
                                            )}
                                        />

                                        <Table.Column
                                            title={t('propostas.preco_da_viatura')}
                                            key="precoviatura"
                                            render={(proposta) => (
                                                proposta.info.preco_final_carro ? `${formatarNumero(proposta.info.preco_final_carro)}€` : "0€"
                                            )}
                                        />

                                        <Table.Column
                                            title={t('main.isv')}
                                            key="isv"
                                            render={(proposta) => (
                                                proposta.info.isv ? `${formatarNumero(proposta.info.isv)}€` : "0€"
                                            )}
                                        />

                                    </>
                                )}


                                <Table.Column
                                    title={t('propostas.taxa_de_servico')}
                                    key="taxadeservico"
                                    render={(proposta) => (
                                        proposta.info.taxadeservico ? `${formatarNumero(proposta.info.taxadeservico)}€` : "0€"
                                    )}
                                />

                                {isColunasShow && (
                                    <>
                                        <Table.Column
                                            title={t('propostas.comissaodeservico')}
                                            key="comissaodeservico"
                                            render={(proposta) => (
                                                proposta.info.comissaodeservico ? `${formatarNumero(proposta.info.comissaodeservico)}€` : "0€"
                                            )}

                                        />

                                        <Table.Column
                                            title={t('propostas.preco_chave_da_mao')}
                                            key="precochavenamao"
                                            render={(proposta) => (
                                                proposta.info.valor_chavenamao_particular ? `${formatarNumero(proposta.info.valor_chavenamao_particular)}€` : "0€"
                                            )}
                                        />

                                    </>

                                )}

                                <Table.Column
                                    title={t('propostas.acedido')}
                                    key="acedido"
                                    render={(proposta) => (
                                        proposta.acedido == 0 ? <span className='flex justify-center'><img style={{ height: '20px' }} src={redbubble} alt="Red Bubble" /></span> : <div className='grid gap-1'><span onClick={() => showModalHistorico(proposta.id_proposta)} className='cursor-pointer flex gap-2 flex-row items-center justify-center'><img style={{ height: '20px' }} src={greenbubble} alt="Green Bubble" />{proposta.acedido}</span><span className='label-tabelaproposta text-center'>{proposta.ultimo_acesso}</span></div>
                                    )}
                                />
                                {isColunasShow && (
                                    <Table.Column
                                        title={t('propostas.disponivel')}
                                        key="expirou"
                                        render={(proposta) => (
                                            proposta.expirou == 1 ? (
                                                <span className='flex justify-center'>
                                                    <img style={{ height: '20px' }} src={redbubble} alt="Red Bubble" />
                                                </span>
                                            ) : (
                                                <span className='flex justify-center' >
                                                    <img style={{ height: '20px' }} src={greenbubble} alt="Green Bubble" />
                                                </span>

                                            )

                                        )}
                                    />
                                )}

                                {currentLanguage === "pt" && (
                                    <>
                                        {!isConfigHubspotOpen ? (
                                            <>
                                                <Table.Column
                                                    title="Associado"
                                                    key="associado"
                                                    className='text-center column-hubspot'
                                                    render={(proposta) => (
                                                        proposta.id_deal_hubspot ? (
                                                            <span className='flex justify-center cursor-pointer' onClick={() => showModalPerfilDeal(proposta.id_deal_hubspot, proposta.id_proposta)}>
                                                                <img style={{ height: '20px' }} src={greenbubble} alt="Green Bubble" />
                                                            </span>
                                                        ) : (
                                                            <span className='flex justify-center'>
                                                                <img style={{ height: '20px' }} src={redbubble} alt="Red Bubble" />
                                                            </span>

                                                        )
                                                    )}
                                                />
                                            </>
                                        ) : null}
                                    </>
                                )}
                            </Table>


                            <Modal
                                title={null}
                                open={isModalOpen}
                                onOk={handleOk}
                                onCancel={handleCancel}
                                okText="Fechar"
                                footer={null}
                                width={1000}
                                className="hubspot-modal"
                            >
                                <AssociarDeal
                                    idDaPropostaEncontrada={idDaPropostaEncontrada}
                                    saveToken={saveToken}
                                    iDTokenAuthenticated={iDTokenAuthenticated}
                                    selectedPropostaParaHubspot={selectedPropostaParaHubspot}
                                    setCheckDataHubspot={setCheckDataHubspot}
                                    setNomeDoDeal={setNomeDoDeal}
                                    setNomeDoClienteDeal={setNomeDoClienteDeal}
                                    setEmailDoClienteDeal={setEmailDoClienteDeal}
                                    setDataDeAssociacaoDoDeal={setDataDeAssociacaoDoDeal}
                                    setEtapaDoDeal={setEtapaDoDeal}
                                    setPipelineDoDeal={setPipelineDoDeal}
                                    setIsModalOpen={setIsModalOpen}
                                />
                            </Modal>
                            <Modal
                                title={<span className="flex items-center gap-2"><AiOutlineInfoCircle /> {t('historicodeacessos.titulo_principal')}</span>}
                                open={isModalOpenHistorico}
                                onOk={handleOkHistorico}
                                onCancel={handleCancelHistorico}
                                okText="Fechar"
                                footer={null}
                                // width={1000}
                                className="hubspot-modal"
                            >
                                <HistoricoDePesquisasPropostas
                                    historicoSelecionadodeAcessos={historicoSelecionadodeAcessos}
                                    historicoDeAcessos={historicoDeAcessos}
                                />
                            </Modal>
                            <Modal
                                title={<span className="flex items-center gap-2"><RiFilePaperFill /> Perfil do Deal</span>}
                                open={isModalOpenPerfilDoDeal}
                                onOk={handleOkPerfilDeal}
                                onCancel={handleCancelPerfilDeal}
                                okText="Fechar"
                                footer={null}
                            >

                                {loadingHubspot ? (
                                    <div className="card-white tabela-propostas flex flex-col gap-4 justify-center">
                                        <Spin indicator={antIcon} />
                                        <p className='text-black text-center'>{t('media_mercado.loading')}</p>
                                    </div>
                                ) : hubspotData && (
                                    <>
                                        <div>
                                            <p className="text-black text-base flex justify-between">
                                                Cliente: <span className="font-bold capitalize">{hubspotData.contacto.properties.firstname}</span>
                                            </p>
                                            <p className="text-black text-base flex justify-between">
                                                Email: <span className="font-bold">{hubspotData.contacto.properties.email}</span>
                                            </p>
                                            <p className="text-black text-base flex justify-between">
                                                Negócio: <span className="font-bold">{hubspotData.properties.dealname}</span>
                                            </p>
                                            <p className="text-black text-base flex justify-between">
                                                Data de Associação: <span className="font-bold">{hubspotData.properties.createdate}</span>
                                            </p>
                                            <p className="text-black text-base flex justify-between">
                                                Etapa: <span className="font-bold">{hubspotData.properties.dealstage}</span>
                                            </p>
                                            <p className="text-black text-base flex justify-between">
                                                Pipeline: <span className="font-bold">{hubspotData.properties.pipeline}</span>
                                            </p>
                                        </div>
                                        <div className="border-t-4 pt-2 mt-2 flex gap-2 justify-end">
                                            <Link to={`/${currentLanguage}/hubspot/perfil-deal/?id_deal=${hubspotData.id}`}>
                                                <Button
                                                    type="primary"
                                                    target="_blank"
                                                    className="flex gap-2 items-center bg-white text-black font-bold rounded"
                                                >
                                                    <IoEyeOutline />
                                                    Ver Perfil
                                                </Button>
                                            </Link>
                                        </div>
                                    </>
                                )}
                            </Modal>
                        </div>
                    )}

                </div>
            </div>
        </>
    );
}
