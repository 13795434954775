import React, { useState, useEffect } from 'react';
import logo from '../../assets/logo-branco 2.svg';
import Login from './login';
import { useMediaQuery } from 'react-responsive';


function LoginMain({ }) {

    //Responsive
    const isMobile = useMediaQuery({
        query: '(max-width: 1023px)'
    })


    useEffect(() => {
        document.body.style.backgroundColor = 'hsl(0, 0%, 0%)';
        document.body.style.backgroundImage = 'radial-gradient(circle farthest-side at left center, hsl(0deg 0% 46% / 41%) 0%, hsl(0, 0%, 0%) 100%)';
        document.body.style.backgroundSize = 'cover';

        return () => {
            document.body.style.backgroundColor = '#000000';
            document.body.style.backgroundImage = '';
            document.body.style.backgroundSize = '';
        };
    }, []);


    return (
        <div className={` ${isMobile ? 'flex flex-col justify-center gap-4' : 'grid grid-cols-10'}`}>
            <div className={`flex flex-col items-center ${isMobile ? '' : 'col-span-6 items-baseline justify-between'}`}>
                <a href='https://importrust.com/' target='_blank'>
                    <img
                        src={logo}
                        alt="Logo"
                        className="w-40 max-w-40 h-10 object-contain cursor-pointer"

                    />
                </a>
                {!isMobile && (
                    <>
                        <div>
                            <h2 className="text-2xl md:text-4xl font-bold">Especialistas em<br></br> importação automóvel</h2>
                        </div>
                        <div className="flex flex-col gap-5">
                            <div className="flex flex-row gap-3">
                                <div className='card'>
                                    <h3 className="text-xl md:text-2xl font-semibold">+2000</h3>
                                    <span className="text-xs">Clientes Satisfeitos</span>
                                </div>
                                <div className='card'>
                                    <h3 className="text-xl md:text-2xl font-semibold">5000€</h3>
                                    <span className="text-xs">Poupança Média</span>
                                </div>
                                <div className='card'>
                                    <h3 className="text-xl md:text-2xl font-semibold">10M€</h3>
                                    <span className="text-xs">Poupança Acumulada</span>
                                </div>
                            </div>

                            <div className='flex gap-2 items-center'>
                                <div className="flex items-center">
                                    <span className="text-yellow-500">★</span>
                                    <span className="text-yellow-500">★</span>
                                    <span className="text-yellow-500">★</span>
                                    <span className="text-yellow-500">★</span>
                                    <span className="text-yellow-500">★</span>
                                </div>
                                <span className="text-xs">Avaliação média dos nossos clientes</span>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className="md:col-span-4 content-center">
                <div className="card-white text-black flex flex-col justify-between py-10 md:p-4">
                    <div>
                        <div className="flex flex-col items-center gap-2">
                            <span>Login</span>
                            <span className="font-bold text-xl">Bem-vindo/a</span>
                        </div>
                        <div className="pt-10 md:pt-10 pb-10 md:pb-10 px-5">
                            <Login />
                        </div>
                    </div>
                    <div className="flex flex-col items-center gap-2">
                        <a href="https://importrust.com" target="_blank" rel="noopener noreferrer">
                            importrust.com
                        </a>
                    </div>
                </div>
            </div>

        </div >


    );
}

export default LoginMain;


