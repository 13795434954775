import React, { useState, useEffect, useRef } from 'react';
import {
    Link,
    useLocation, useNavigate
} from "react-router-dom";
import axios from 'axios';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import CalculoChaveMao from "../../../components/pt/calculo-chave-mao";
import ErroSection from "../../../components/pt/erros/erro-section";
import { Button, List, Popover, Spin, Select, Modal } from 'antd';
import PesquisaSimulacao from '../../../components/pt/pesquisa-simulacao';
import { useTranslation } from 'react-i18next';
import PT_PartilharProposta from '../../../components/pt/pt_partilhar-proposta';
import PT_ResultadodaSimulacao from '../../../components/pt/pt_resultado-da-simulacao';
import InformacoesDeal from '../../../components/pt/hubspot/deals/informacoes-deal';
import CalculoChaveMaoVista from '../../../components/pt/calculo-chave-mao-vista';
import { LoadingOutlined } from '@ant-design/icons';
import HitoricoDeAcesso from '../../../components/pt/calculo/historico-de-acesso';
import AssociarDeal from '../../../components/pt/hubspot/deals/associar-deal';
import TabelaViaturaCliente from '../../../components/pt/tabela/tabelaViaturaCliente';
import TabelaViatura from '../../../components/pt/tabela/tabelaViatura';


const { Option } = Select;


dayjs.extend(customParseFormat);


const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);


export default function PT_Calculo({
    setNovaPesquisa,
    isAuthenticated,
    setCurrentLanguage,
    currentLanguage,
    validartipodeparametro,
    userData,
    isMobile,
    setNomeGeradoDaProposta,
    setEstadoDaPartilhaNomeDaProposta,
    estadoDaPartilhaNomeDaProposta,
    nomeGeradoDaProposta,
    linkDaProposta,
    temParametros,
    formatarNumero,
    setTemParametros,
    setPartilhaNomeDaProposta,
    partilhaNomeDaProposta,
    iDTokenAuthenticated,
    saveToken,
    setEsconde,
    setErroPedido,
    urlCarroLink,
    setUrlCarroLink,
    esconde,
    erroPedido,
    teste,
    setTeste,

    showHubspot,
    setShowHubspot,
    checkDataHubspot,
    setCheckDataHubspot,
    nomeDoDeal,
    setNomeDoDeal,
    nomeDoClienteDeal,
    setNomeDoClienteDeal,
    emailDoClienteDeal,
    setEmailDoClienteDeal,
    dataDeAssociacaoDoDeal,
    setDataDeAssociacaoDoDeal,
    etapaDoDeal,
    setEtapaDoDeal,
    pipelineDoDeal,
    setPipelineDoDeal,
    setLinkDaProposta,
    nomeDaProposta,
    setNomeDaProposta
}) {



    const { t } = useTranslation();
    const location = useLocation();

    const [loading, setLoading] = useState(false);

    //INFORMAÇÕES IMPORTANTES
    const [dataCarros, setDataCarros] = useState([]);
    const [tipoDeVeiculo, setTipoDeVeiculo] = useState('LigeiroPassageiros');
    const [tipoDeVeiculoParaInsencoes, setTipoDeVeiculoParaInsencoes] = useState('');
    const [tipoDeVeiculoParaOutros, setTipoDeVeiculoParaOutros] = useState('LigeiroPassageiros');
    const [novoOuUsado, setNovoOuUsado] = useState('');
    const [dataDeRegisto, setDataDeRegisto] = useState('');
    const [cilindrada, setCilindrada] = useState('');
    const [tipoDeCombustivel, setTipoDeCombustivel] = useState('');
    const [particulas, setParticulas] = useState('');
    const [tipoDeTestes, setTipoDeTestes] = useState('');
    const [emissoesGases, setEmissoesGases] = useState('');

    //GUARDAR VALORES IMPORTANTES
    const [taxaDeServico, setTaxaDeServico] = useState('2800');
    const [comissaoDeServico, setComissaoDeServico] = useState('');
    const [precoFinalCarro, setPrecoFinalCarro] = useState('');
    const [valorFinalISV, setValorFinalISV] = useState('0');
    const [dataGeralISV, setDataGeralISV] = useState('');
    const [valorChaveNaMaoEmpresa, setValorChaveNaMaoEmpresa] = useState('');
    const [valorChaveNaMaoParticular, setValorChaveNaMaoParticular] = useState('');
    const [ivaNaOrigem, setIvaNaOrigem] = useState(false);
    const [valorIvaNaOrigem, setValorIvaNaOrigem] = useState('');
    //IUC
    const [valorFinalIUC, setValorFinalIUC] = useState('0');
    const [dataGeralIUC, setDataGeralIUC] = useState('');
    const [iucCilindrada, setIucCilindrada] = useState('');
    const [iucCO2, setIucCO2] = useState('');
    const [iucAdicionalCO2, setIucAdicionalCO2] = useState('');
    const [iucCoeficienteAnoMatriculo, setIucCoeficienteAnoMatriculo] = useState('');
    const [iucAdicionalGasoleo, setIucAdicionalGasoleo] = useState('');
    //ISV
    const [isvcomponentecilindrada, setIsvComponenteCilindrada] = useState('');
    const [isvcomponenteambiental, setIsvcomponenteambiental] = useState('');
    const [isvReducaoAnosUsoCilindrada, setIsvReducaoAnosUsoCilindrada] = useState('');
    const [isvReducaoAnosUsoAmbiental, setIsvReducaoAnosUsoAmbiental] = useState('');
    const [isvAgravamentoParticulas, setIsvAgravamentoParticulas] = useState('');
    const [isvReducaoAnosUsoParticulas, setIsvReducaoAnosUsoParticulas] = useState('');
    const [isvTaxaAplicavelTabela, setIsvTaxaAplicavelTabela] = useState('');


    //Dados - Validar Dados - Guardar Dados
    const [quantidadeDeKM, setQuantidadeDeKM] = useState('');
    const [anoDoRegisto, setAnoDoRegisto] = useState('');
    const [precoFinalDoCarroSemIva, setPrecoFinalDoCarroSemIva] = useState('');
    const [precoFinalDoCarroIvaDedutival, setPrecoFinalDoCarroIvaDedutival] = useState(false);
    const [hibridoIsChecked, setHibridoIsChecked] = useState(false);
    const [co2IsChecked, setCo2IsChecked] = useState(false);
    const [wltpIsChecked, setWltpIsChecked] = useState(false);
    const [outrosServicos, setOutrosServicos] = useState('');
    const [validarAnoDeRegisto, setValidarAnoDeRegisto] = useState(false);
    const [validarViaturaEletrico, setValidarViaturaEletrico] = useState(false);
    // VALIDACAO HIBRIDO PLUGIN
    const [hibridoValidationIsChecked, setHibridoValidationIsChecked] = useState(false);
    // PARA SABER SE É TAB PARTICULAR OU EMPRESA (PARTICULAR COMEÇA COM TRUE)
    const [isParticular, setIsParticular] = useState(true);

    const [erroInicial, setErroInicial] = useState('Ocorreu algum erro, tente novamente!');

    //CONFIGURAÇÕES
    const [activeTabKeyResultadoSimulacao, setActiveTabKeyResultadoSimulacao] = useState("1");
    const [tabResultadoSimulacao, setTabResultadoSimulacao] = useState("0");
    const [IgnorarAvisos, setIgnorarAvisos] = useState(false);
    const [alertasNoPedido, setAlertasNoPedido] = useState(false);



    //HUBSPOT
    const [isModalOpen, setIsModalOpen] = useState(false); //popup de associar
    const [idDoDealAtual, setIdDoDealAtual] = useState('');
    const [propostaAceiteAprovada, setPropostaAceiteAprovada] = useState('');
    const [propostaDataAceiteAprovada, setPropostaDataAceiteAprovada] = useState('');
    const [statusDaProposta, setStatusDaProposta] = useState(null);
    const [loadingFetchStatusDaProposta, setLoadingFetchStatusDaProposta] = useState(false);
    const [pedidoFetchStatusDaProposta, setPedidoFetchStatusDaProposta] = useState(false);
    const [idDoDealAssociar, setIdDoDealAssociar] = useState('');


    //Dados após gerar proposta
    const [idDaPropostaEncontrada, setIdDaPropostaEncontrada] = useState('');
    const [allDataLinkPartilhado, setAllDataLinkPartilhado] = useState('');
    const [tituloDoMobileDaViatura, setTituloDoMobileDaViatura] = useState('');


    //PROPOSTA
    const [linkPartilhavel, setLinkPartilhavel] = useState('');
    //Estados da Proposta
    const [tipoDaProposta, setTipoDaProposta] = useState('proposta_cliente');
    const [estadoDaProposta, setEstadoDaProposta] = useState('nao_associada');

    const [infoInicialPartilhaProposta, setInfoInicialPartilhaProposta] = useState('');
    //Editar Proposta
    const [isModalEditarVisible, setIsModalEditarVisible] = useState(false); //popup de editar user



    const [alertaLinkPartilhado, setAlertaLinkPartilhado] = useState('');


    useEffect(() => {

        const parametros = new URLSearchParams(location.search)

        if (parametros.size > 0) {

            setNomeGeradoDaProposta('')

            const nomePropostaParam = parametros.get('nome_proposta');
            const idPropostaParam = parametros.get('id_proposta');
            const idDealParam = parametros.get('id_deal');

            if (nomePropostaParam || idPropostaParam) {
                // console.log('entrou aqui')
                setCurrentLanguage('pt')
                if (nomePropostaParam) {
                    setNomeGeradoDaProposta(nomePropostaParam);
                }
                if (idPropostaParam) {
                    setNomeGeradoDaProposta(idPropostaParam);
                }
                // setNomeGeradoDaProposta(nomePropostaParam)
                setEstadoDaPartilhaNomeDaProposta(true)
                setTeste(true)

                if (!isAuthenticated) {
                    setNovaPesquisa(false);
                    setTemParametros(true);
                } else {
                    setNovaPesquisa(true);
                    setTemParametros(false);
                }
            } else if (idDealParam) {
                setIdDoDealAssociar(idDealParam)
                setNovaPesquisa(true);
                setEstadoDaPartilhaNomeDaProposta(false)
            }

        } else {
            console.log('coreuaquii')
            setNomeGeradoDaProposta('')
            setEstadoDaPartilhaNomeDaProposta(false);
        }

    }, []);



    useEffect(() => {

        setIdDaPropostaEncontrada('')
        setIdDoDealAtual('')
        setNomeDaProposta('')
        setDataCarros([])

        if (nomeGeradoDaProposta) {

            console.log('entrou', nomeGeradoDaProposta)


            setLoading(true);
            setEsconde(true);

            let linkdopedido

            if (validartipodeparametro) {
                linkdopedido = `${process.env.REACT_APP_GET_PROPOSTA_PT}?nome_proposta=${nomeGeradoDaProposta}&user_id=${iDTokenAuthenticated}&token=${saveToken}&lang=${currentLanguage}`
            } else {
                linkdopedido = `${process.env.REACT_APP_GET_PROPOSTA_PT}?id_proposta=${nomeGeradoDaProposta}&user_id=${iDTokenAuthenticated}&token=${saveToken}&lang=${currentLanguage}`
            }

            axios.get(linkdopedido)
                .then((response) => {

                    if (response.data.sucesso) {
                        setLoading(false);
                        setEsconde(false);
                        setShowHubspot(true)

                        if (response.data.hubspot.deal) {
                            const hubspot = response.data?.hubspot
                            setIdDoDealAtual(response.data.hubspot.deal.id_deal_hubspot)
                            setCheckDataHubspot(hubspot)
                            setNomeDoDeal(hubspot.deal.info.properties.dealname)
                            setNomeDoClienteDeal(hubspot.contacto.info.properties.firstname)
                            setEmailDoClienteDeal(hubspot.contacto.info.properties.email)
                            setDataDeAssociacaoDoDeal(hubspot.deal.data_registo_hubspot)
                            setEtapaDoDeal(hubspot.deal.info.properties.dealstage)
                            setPipelineDoDeal(hubspot.deal.info.properties.pipeline)

                            setPropostaAceiteAprovada(hubspot.deal.deal_hubspot_fechado)
                            setPropostaDataAceiteAprovada(hubspot.deal.data_registo_hubspot)
                        }
                        // console.log('hubspot', hubspot)
                        const dataLinkPartilhado = response.data?.data
                        console.log(response.data)
                        setAllDataLinkPartilhado(dataLinkPartilhado)
                        setLinkPartilhavel(response.data.link_proposta)
                        setTituloDoMobileDaViatura(dataLinkPartilhado?.titulo)
                        setEstadoDaProposta(response.data?.estado_proposta)
                        processosRepetidosNosPedidos(dataLinkPartilhado);

                        setIdDaPropostaEncontrada(dataLinkPartilhado.id_proposta)

                        setValorFinalISV(dataLinkPartilhado.isv)
                        setValorFinalIUC(dataLinkPartilhado.iuc)

                        setIucCilindrada(dataLinkPartilhado.iuc_cilindrada)
                        setIucCO2(dataLinkPartilhado.iuc_co2)
                        setIucAdicionalCO2(dataLinkPartilhado.iuc_adicional_co2)
                        setIucCoeficienteAnoMatriculo(dataLinkPartilhado.iuc_coeficiente_ano_matriculo)
                        setIucAdicionalGasoleo(dataLinkPartilhado.iuc_adicional_gasoleo)


                        setIsvComponenteCilindrada(dataLinkPartilhado.isv_componente_cilindrada)
                        setIsvcomponenteambiental(dataLinkPartilhado.isv_componente_ambiental)
                        setIsvReducaoAnosUsoCilindrada(dataLinkPartilhado.isv_reducao_anos_uso_cilindrada)
                        setIsvReducaoAnosUsoAmbiental(dataLinkPartilhado.isv_reducao_anos_uso_ambiental)
                        setIsvAgravamentoParticulas(dataLinkPartilhado.isv_agravamento_particulas)
                        setIsvReducaoAnosUsoParticulas(dataLinkPartilhado.isv_reducao_anos_uso_particulas)
                        setIsvTaxaAplicavelTabela(dataLinkPartilhado.isv_taxa_aplicavel_tabela)


                        setTaxaDeServico(dataLinkPartilhado.taxadeservico)
                        setInfoInicialPartilhaProposta(response.data.data_expira)
                        setIgnorarAvisos(true);
                        setTabResultadoSimulacao(response.data.data.empresa)


                        if (dataLinkPartilhado.tipo_de_combustivel === "Eletrico") {
                            // console.log('asdsadas')
                            setValidarViaturaEletrico(true)
                        } else {
                            setValidarViaturaEletrico(false)
                            // console.log('asdsasfasfdafdfafasadas')
                        }


                    } else {

                        setErroInicial(response.data.mensagem)
                        setErroPedido(true)
                        setLoading(false);
                        setEsconde(false);

                    }


                })
                .catch((error) => {


                })
                .finally(() => {

                });
        }


    }, [nomeGeradoDaProposta]);




    useEffect(() => {

        let linkdopedido

        if (nomeGeradoDaProposta) {
            linkdopedido = `${process.env.REACT_APP_GET_PROPOSTA_PT}?nome_proposta=${nomeGeradoDaProposta}&user_id=${iDTokenAuthenticated}&token=${saveToken}&lang=${currentLanguage}`
        } else {
            linkdopedido = `${process.env.REACT_APP_GET_PROPOSTA_PT}?id_proposta=${nomeGeradoDaProposta}&user_id=${iDTokenAuthenticated}&token=${saveToken}&lang=${currentLanguage}`
        }

        axios.get(linkdopedido)
            .then((response) => {

                if (response.data.sucesso) {

                    if (response.data.hubspot.deal) {
                        const hubspot = response.data?.hubspot
                        setPropostaAceiteAprovada(hubspot.deal.deal_hubspot_fechado)
                        setPropostaDataAceiteAprovada(hubspot.deal.data_registo_hubspot)
                    }

                    setPedidoFetchStatusDaProposta(false)
                }

            })
            .catch((error) => {

            })
            .finally(() => {
            });


    }, [nomeGeradoDaProposta]);







    //Pedido
    useEffect(() => {

        setErroPedido(false)

        const fetchData = async () => {
            try {

                const requestUrl = `${process.env.REACT_APP_PEDIDO_PROPOSTA}?request=${encodeURIComponent(urlCarroLink)}&user_id=${iDTokenAuthenticated}&token=${saveToken}&lang=${currentLanguage}`;
                const response = await axios.get(requestUrl);
                const dataFinalDadosObjeto = response.data;

                if (dataFinalDadosObjeto.car) {
                    processosRepetidosNosPedidos(dataFinalDadosObjeto.car);
                    // Guardar dados do URL
                    setDataCarros(dataFinalDadosObjeto.car);
                    setLoading(false);
                } else {
                    if (dataFinalDadosObjeto.erro) {
                        setErroInicial(dataFinalDadosObjeto.erro)
                    }
                    setErroPedido(true);
                }

            } catch (error) {
                setLoading(false);
                setErroPedido(true);
            }
        };

        if (urlCarroLink) {

            console.log('urlCarroLink', urlCarroLink)

            setLoading(true);
            setCheckDataHubspot([])
            setErroPedido(false)
            setTipoDeVeiculoParaInsencoes('')
            setNovoOuUsado('')
            setDataDeRegisto('')
            setCilindrada('')
            setTipoDeCombustivel('')
            setParticulas('')
            setTipoDeTestes('')
            setEmissoesGases('')
            setPrecoFinalCarro('')
            setValidarAnoDeRegisto(false)
            setAlertasNoPedido(false)
            setValorChaveNaMaoEmpresa('')
            setValorChaveNaMaoParticular('')
            setCo2IsChecked(false);
            setWltpIsChecked(false);
            setHibridoIsChecked(false)
            setValidarViaturaEletrico(false)
            setIvaNaOrigem(false)
            setShowHubspot(false)
            setIucCilindrada('')
            setIucCO2('')
            setIucAdicionalCO2('')
            setIucCoeficienteAnoMatriculo('')
            setIucAdicionalGasoleo('')
            setIdDaPropostaEncontrada('')
            setIdDoDealAtual('')
            setNomeDaProposta('')
            setIsvComponenteCilindrada('')
            setIsvcomponenteambiental('')
            setIsvReducaoAnosUsoCilindrada('')
            setIsvReducaoAnosUsoAmbiental('')
            setIsvAgravamentoParticulas('')
            setIsvReducaoAnosUsoParticulas('')
            setIsvTaxaAplicavelTabela('')

            setErroPedido(false)


            fetchData();
        }


    }, [urlCarroLink]);



    // CODIGO QUE SEJA EXATAMENTE O MESMO PARA OS DOIS PEDIDOS (AUTOSCOUT24 E MOBILE.DE ou + no futuro)
    function processosRepetidosNosPedidos(dataFinalDadosObjeto) {

        // console.log('dataFinalDadosObjeto',dataFinalDadosObjeto)

        if (estadoDaPartilhaNomeDaProposta) {
            setDataCarros(dataFinalDadosObjeto)
        }

        if (dataFinalDadosObjeto.novo_ou_usado === "1") {
            setNovoOuUsado('Novo')
        } else {
            setNovoOuUsado('Usado')
        }

        // IVA NA ORIGEM
        setIvaNaOrigem(dataFinalDadosObjeto.tem_iva)
        setValorIvaNaOrigem(dataFinalDadosObjeto.perc_iva)


        // Tipo de Combustivel
        setTipoDeCombustivel(dataFinalDadosObjeto.tipo_combustivel_traduzido || dataFinalDadosObjeto.tipo_de_combustivel)

        // CILINDRADA
        setCilindrada(dataFinalDadosObjeto.cilindrada)

        // EMISSOES
        setEmissoesGases(dataFinalDadosObjeto.emissoes_numerico)

        // Data primeiro Registo
        setDataDeRegisto(dataFinalDadosObjeto.data_registo ? dataFinalDadosObjeto.data_registo : '')


        // TIPO DE TESTES:
        setTipoDeTestes('NEDC');
        setValidarAnoDeRegisto(false)
        setAnoDoRegisto(false)

        // PARA CIMA DE 10/2018 - WLTP POR DEFEITO
        let data_registo = dataFinalDadosObjeto.data_registo;

        // PRECO DA VIATURA (COM IVA)
        const precoViatura = dataFinalDadosObjeto.preco_final_carro ? dataFinalDadosObjeto.preco_final_carro : "";
        setPrecoFinalCarro(precoViatura)


        setPrecoFinalDoCarroIvaDedutival(false)
        let precoViaturaSemIva = precoViatura;

        if (dataFinalDadosObjeto.preco_final_carro_sem_iva) { // SE EXISTIR SEM IVA
            setPrecoFinalDoCarroIvaDedutival(true)
            precoViaturaSemIva = dataFinalDadosObjeto.preco_final_carro_sem_iva;
        }
        setPrecoFinalDoCarroSemIva(precoViaturaSemIva)

        //validar a quantidade de KM
        let quantidadeKM = dataFinalDadosObjeto.quantidade_de_km ? dataFinalDadosObjeto.quantidade_de_km : "";

        if (quantidadeKM) {
            quantidadeKM = parseFloat(quantidadeKM.toString().replace(",", ""));
        }

        let tipo_combustivel_raw = dataFinalDadosObjeto.tipo_de_combustivel ? dataFinalDadosObjeto.tipo_de_combustivel : "";
        let tipo_combustivel = dataFinalDadosObjeto.tipo_combustivel_traduzido ? dataFinalDadosObjeto.tipo_combustivel_traduzido : "";

        if (data_registo) {

            // SEPARAR AS DATA EM DUAS VARIAVEIS
            let [data_registo_month, data_registo_year] = data_registo.split('/');
            // CRIAR COMO DATA (ANO, MES)
            data_registo = new Date(data_registo_year, data_registo_month - 1);
            // CRIAR A DATA DO WLTP
            let data_wltp = new Date(2018, 10 - 1);

            // COMPARAR DATAS
            if (data_registo > data_wltp) {
                //setTipoDeTestes('WLTP');
                setWltpIsChecked(true);
                setValidarAnoDeRegisto(true)
            }


            // //Validar o ano do Registo
            // const validarAnoDoRegisto = data_registo
            // console.log('validarAnoDoRegisto', validarAnoDoRegisto);
            // // Convertendo a string para um objeto Date
            // const dataRegistro = new Date(validarAnoDoRegisto);
            // // Obtendo a data atual
            // const dataAtual = new Date();
            // // Adicionando 6 meses à data atual para obter a data limite
            // const dataLimite = new Date();
            // dataLimite.setMonth(dataAtual.getMonth() + 6);
            // // Comparando as datas

            // console.log('dataRegistro', dataRegistro)
            // console.log('dataLimite', dataLimite)
            // if (dataLimite <= dataRegistro) {
            //     console.log('correu aqui')
            //     setAnoDoRegisto(true)
            // }

            const validarAnoDoRegisto = data_registo;
            // console.log('validarAnoDoRegisto', validarAnoDoRegisto);

            // Convertendo a string para um objeto Date
            const dataRegistro = new Date(validarAnoDoRegisto);

            // Obtendo a data atual
            const dataAtual = new Date();

            // Subtraindo 6 meses da data atual para obter a data limite
            const dataLimite = new Date();
            dataLimite.setMonth(dataAtual.getMonth() - 6);

            // Comparando as datas
            // console.log('dataRegistro', dataRegistro);
            // console.log('dataLimite', dataLimite);
            if (dataRegistro >= dataLimite) {
                setAnoDoRegisto(true);
            }


        }


        //validar a quantidade de KM
        if (quantidadeKM < 6000) {

            setQuantidadeDeKM(true)
        } else {

            setQuantidadeDeKM(false)
        }


        //Validar eletrico
        if (tipo_combustivel_raw === "Electric") {
            setValidarViaturaEletrico(true)
            setValorFinalISV('0€')
            setAlertasNoPedido(false)
            setValidarAnoDeRegisto(false)
            setCo2IsChecked(true)
        } else {
            setValidarViaturaEletrico(false)
        }


        //validar hibrido
        if (tipo_combustivel_raw === "Hybrid (petrol/electric)") {
            setHibridoIsChecked(true)
            setValorFinalISV('0€')
            setCo2IsChecked(false)
            setTipoDeCombustivel('Gasolina')

        } else if (tipo_combustivel_raw === "Hybrid (diesel/electric)") {
            setHibridoIsChecked(true)
            setValorFinalISV('0€')
            setCo2IsChecked(false)
            setTipoDeCombustivel('Gasoleo')
            tipo_combustivel = 'Gasoleo'
        } else {
            setHibridoIsChecked(false)
        }



        // DEFINIR PARTICULAS SE FOR GASOLEO OU GASOLINA
        if (tipo_combustivel.toLowerCase() == "gasoleo") {
            setParticulas('1')
        } else {
            setParticulas('0')
        }





        if (!estadoDaPartilhaNomeDaProposta) {
            setLoading(false);
        }

        setEsconde(true);

    }




    useEffect(() => {


        if (IgnorarAvisos || estadoDaPartilhaNomeDaProposta || !urlCarroLink) {
            return;
        }


        if (validarAnoDeRegisto) {
            setValorFinalISV('0')
            if (!co2IsChecked) {
                setAlertasNoPedido(true)
                return
            }
        }



        if (!hibridoValidationIsChecked && hibridoIsChecked) {
            setValorFinalISV('0€')
            return;
        }



        if (hibridoIsChecked && hibridoValidationIsChecked && emissoesGases >= 50) {
            setValorFinalISV('0€')
            return;
        }


        let ignore_isv = false;
        // SE FOR ELETRICO N FAZ PEDIDO
        if (validarViaturaEletrico) {
            setValorFinalISV('0€')
            setAlertasNoPedido(false)
            ignore_isv = true;
        }



        // SE NAO CONFIRMO EMISSOES CO2
        if (alertasNoPedido) {
            setValorFinalISV('0€')
            return
        }


        let pedidoChaveNaMao
        let pedidoIUC
        let tipoDeVeiculoFinal = tipoDeVeiculoParaOutros;

        if (hibridoIsChecked) {
            tipoDeVeiculoFinal = 'HibridosPlugIn';
        } else {
            tipoDeVeiculoFinal = tipoDeVeiculoParaOutros;

        }

        let tipoDeTestes = 'NEDC';



        // WLTP
        if (wltpIsChecked) {
            tipoDeTestes = 'WLTP';
        }


        if (typeof urlCarroLink === '' && estadoDaPartilhaNomeDaProposta) {
            return;
        }

        let novo_usado_int = 0;
        if (novoOuUsado === "Novo") {

            novo_usado_int = 1;
        }


        if (!ignore_isv) {
            pedidoChaveNaMao = `${process.env.REACT_APP_PEDIDO_ISV}?novo=${novo_usado_int}&tipo_de_testes_co2=${tipoDeTestes}&tipo_carro=${tipoDeVeiculoFinal}&combustivel=${tipoDeCombustivel}&particulas=${particulas}&co2=${emissoesGases}&cilindrada=${cilindrada}&ano=${dataDeRegisto}&isencao=${tipoDeVeiculoParaInsencoes}&dev`;


            axios.get(pedidoChaveNaMao)
                .then((response) => {

                    setValorFinalISV(response.data.isv)
                    setDataGeralISV(response.data)
                    setIsvComponenteCilindrada(response.data.taxas.componente_cilindrada)
                    setIsvcomponenteambiental(response.data.taxas.componente_ambiental)
                    setIsvReducaoAnosUsoCilindrada(response.data.taxas.reducao_anos_uso_cilindrada)
                    setIsvReducaoAnosUsoAmbiental(response.data.taxas.reducao_anos_uso_ambiental)
                    setIsvAgravamentoParticulas(response.data.taxas.agravamento_particulas)
                    setIsvReducaoAnosUsoParticulas(response.data.taxas.reducao_anos_uso_particulas)
                    setIsvTaxaAplicavelTabela(response.data.taxas.taxa_aplicavel_tabela)

                })
                .catch((error) => {

                });
        }


        // console.log(cilindrada)


        pedidoIUC = `${process.env.REACT_APP_PEDIDO_IUC}?cilindrada=${cilindrada}&ano=${dataDeRegisto}&combustivel=${tipoDeCombustivel}&co2=${emissoesGases}&tipo_carro=${tipoDeVeiculoParaOutros}&tipo_de_testes_co2=${tipoDeTestes}&novo=${novo_usado_int}`;

        axios.get(pedidoIUC)
            .then((response) => {
                setValorFinalIUC(response.data.iuc)
                setDataGeralIUC(response.data)
                setIucCilindrada(response.data.taxas.cilindrada)
                setIucCO2(response.data.taxas.co2)
                setIucAdicionalCO2(response.data.taxas.adicional_co2)
                setIucCoeficienteAnoMatriculo(response.data.taxas.coeficiente_ano_matricula)
                setIucAdicionalGasoleo(response.data.taxas.adicional_gasoleo)

            })
            .catch((error) => {

            });



    }, [hibridoValidationIsChecked, tipoDeTestes, co2IsChecked, hibridoIsChecked, wltpIsChecked, tipoDeVeiculo, tipoDeVeiculoParaOutros, tipoDeCombustivel, particulas, emissoesGases, cilindrada, dataDeRegisto, tipoDeVeiculoParaInsencoes, novoOuUsado]);





    useEffect(() => {


        let chaveNaMaoValue = ""
        let precoFinalCarroCal = ""

        let taxaDeServicoCal = parseFloat(taxaDeServico);
        let valorFinalISVCal = parseFloat(valorFinalISV);
        // let comissaoCreditoCal = parseFloat(comissaoCredito);

        if (isNaN(taxaDeServicoCal)) {
            taxaDeServicoCal = 0;
        }

        // Particular
        precoFinalCarroCal = parseFloat(precoFinalCarro);
        if (!isNaN(precoFinalCarroCal) && !isNaN(valorFinalISVCal)) {

            // if (isCheckedCredito) {

            //     chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal + valorFinalISVCal + comissaoCreditoCal;

            //     setValorChaveNaMaoParticular(chaveNaMaoValue);
            // } else {
            //     chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal + valorFinalISVCal;

            //     setValorChaveNaMaoParticular(chaveNaMaoValue);
            // }

            chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal + valorFinalISVCal;
            setValorChaveNaMaoParticular(chaveNaMaoValue);

        } else {


            chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal;
            setValorChaveNaMaoParticular(chaveNaMaoValue);

            // if (isCheckedCredito) {

            //     chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal;
            //     setValorChaveNaMaoParticular(chaveNaMaoValue);
            // } else {
            //     chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal;
            //     setValorChaveNaMaoParticular(chaveNaMaoValue);
            // }


        }

        // EMPRESA
        precoFinalCarroCal = parseFloat(precoFinalDoCarroSemIva);
        if (!isNaN(precoFinalCarroCal) && !isNaN(valorFinalISVCal)) {


            // if (isCheckedCredito) {
            //     chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal + valorFinalISVCal;
            //     setValorChaveNaMaoEmpresa(chaveNaMaoValue);
            // } else {

            //     chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal + valorFinalISVCal;
            //     setValorChaveNaMaoEmpresa(chaveNaMaoValue);
            // }

            chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal + valorFinalISVCal;
            setValorChaveNaMaoEmpresa(chaveNaMaoValue);


        } else {

            // if (isCheckedCredito) {
            //     chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal;
            //     setValorChaveNaMaoEmpresa(chaveNaMaoValue);
            // } else {
            //     chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal;
            //     setValorChaveNaMaoEmpresa(chaveNaMaoValue);
            // }

            chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal;
            setValorChaveNaMaoEmpresa(chaveNaMaoValue);

        }


    }, [precoFinalCarro, taxaDeServico, valorFinalISV]);



    //popup de associar DEAL HUBSPOT
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };



    return (


        <>

            <div className='w-full flex flex-col justify-center items-center gap-4'>
                <div className='grid gap-4 grid-cols-1 w-full h-full'>

                    {!estadoDaPartilhaNomeDaProposta && !urlCarroLink ? (
                        <div className='card grid gap-4'>
                            <div className='grid gap-1'>
                                <p className='text-lg font-bold'>{t('simulador.titulo_principal')}</p>
                                <p className='text-sm font-normal'>{t('simulador.sub_titulo')}</p>
                            </div>
                            <hr></hr>
                            <PesquisaSimulacao
                                setEstadoDaPartilhaNomeDaProposta={setEstadoDaPartilhaNomeDaProposta}
                                setEsconde={setEsconde}
                                urlCarroLink={urlCarroLink}
                                setUrlCarroLink={setUrlCarroLink}
                            />
                        </div>
                    ) : null}



                    {loading ? (
                        <div className="card flex flex-col gap-4 justify-center">
                            <Spin indicator={antIcon} />
                            <p className='text-white text-center'>{t('media_mercado.loading')}</p>
                        </div>
                    ) : (
                        <>
                            {urlCarroLink && dataCarros || estadoDaPartilhaNomeDaProposta ? (
                                <>
                                    <div className="w-full text-white">
                                        <div>
                                            <div className='grid gap-4'>

                                                <PT_PartilharProposta
                                                    tipoDaProposta={tipoDaProposta}
                                                    setTipoDaProposta={setTipoDaProposta}
                                                    linkPartilhavel={linkPartilhavel}
                                                    setLinkPartilhavel={setLinkPartilhavel}
                                                    allDataLinkPartilhado={allDataLinkPartilhado}
                                                    setUrlCarroLink={setUrlCarroLink}
                                                    checkDataHubspot={checkDataHubspot}
                                                    idDoDealAssociar={idDoDealAssociar}
                                                    cilindrada={cilindrada}
                                                    novoOuUsado={novoOuUsado}
                                                    tipoDeCombustivel={tipoDeCombustivel}
                                                    emissoesGases={emissoesGases}
                                                    dataDeRegisto={dataDeRegisto}
                                                    particulas={particulas}
                                                    tipoDeVeiculoParaOutros={tipoDeVeiculoParaOutros}
                                                    tipoDeVeiculoParaInsencoes={tipoDeVeiculoParaInsencoes}
                                                    outrosServicos={outrosServicos}
                                                    setEstadoDaPartilhaNomeDaProposta={setEstadoDaPartilhaNomeDaProposta}
                                                    precoFinalCarro={precoFinalCarro}
                                                    valorFinalISV={valorFinalISV}
                                                    taxaDeServico={taxaDeServico}

                                                    valorFinalIUC={valorFinalIUC}
                                                    iucCilindrada={iucCilindrada}
                                                    iucCO2={iucCO2}
                                                    iucAdicionalCO2={iucAdicionalCO2}
                                                    iucCoeficienteAnoMatriculo={iucCoeficienteAnoMatriculo}
                                                    iucAdicionalGasoleo={iucAdicionalGasoleo}

                                                    isvcomponentecilindrada={isvcomponentecilindrada}
                                                    isvcomponenteambiental={isvcomponenteambiental}
                                                    isvReducaoAnosUsoCilindrada={isvReducaoAnosUsoCilindrada}
                                                    isvReducaoAnosUsoAmbiental={isvReducaoAnosUsoAmbiental}
                                                    isvAgravamentoParticulas={isvAgravamentoParticulas}
                                                    isvReducaoAnosUsoParticulas={isvReducaoAnosUsoParticulas}
                                                    isvTaxaAplicavelTabela={isvTaxaAplicavelTabela}

                                                    activeTabKeyResultadoSimulacao={activeTabKeyResultadoSimulacao}
                                                    tabResultadoSimulacao={tabResultadoSimulacao}

                                                    showHubspot={showHubspot}
                                                    setShowHubspot={setShowHubspot}
                                                    idDaPropostaEncontrada={idDaPropostaEncontrada}
                                                    setIdDaPropostaEncontrada={setIdDaPropostaEncontrada}
                                                    setCheckDataHubspot={setCheckDataHubspot}
                                                    setNomeDoDeal={setNomeDoDeal}
                                                    setNomeDoClienteDeal={setNomeDoClienteDeal}
                                                    setEmailDoClienteDeal={setEmailDoClienteDeal}
                                                    setDataDeAssociacaoDoDeal={setDataDeAssociacaoDoDeal}
                                                    setEtapaDoDeal={setEtapaDoDeal}
                                                    setPipelineDoDeal={setPipelineDoDeal}

                                                    setPartilhaNomeDaProposta={setPartilhaNomeDaProposta}
                                                    partilhaNomeDaProposta={partilhaNomeDaProposta}
                                                    precoFinalDoCarroSemIva={precoFinalDoCarroSemIva}
                                                    valorChaveNaMaoParticular={valorChaveNaMaoParticular}
                                                    valorChaveNaMaoEmpresa={valorChaveNaMaoEmpresa}
                                                    formatarNumero={formatarNumero}
                                                    dataCarros={dataCarros}
                                                    urlCarroLink={urlCarroLink}
                                                    setNomeDaProposta={setNomeDaProposta}
                                                    nomeDaProposta={nomeDaProposta}
                                                    saveToken={saveToken}
                                                    iDTokenAuthenticated={iDTokenAuthenticated}

                                                    comissaoDeServico={comissaoDeServico}
                                                    setIsModalOpen={setIsModalOpen}
                                                    setAlertaLinkPartilhado={setAlertaLinkPartilhado}

                                                    setIsModalEditarVisible={setIsModalEditarVisible}
                                                    nomeGeradoDaProposta={nomeGeradoDaProposta}
                                                    tituloDoMobileDaViatura={tituloDoMobileDaViatura}
                                                    // propostaAceiteAprovada={propostaAceiteAprovada}
                                                    estadoDaProposta={estadoDaProposta}
                                                    setEstadoDaProposta={setEstadoDaProposta}
                                                />

                                                <div className='flex flex-col sm:flex-row gap-4 w-full h-full'>
                                                    <div className='w-full sm:w-3/5'>
                                                        {dataCarros && (
                                                            <div>
                                                                {estadoDaPartilhaNomeDaProposta ? (
                                                                    <TabelaViaturaCliente
                                                                        nomeGeradoDaProposta={nomeGeradoDaProposta}
                                                                        allDataLinkPartilhado={allDataLinkPartilhado}
                                                                        formatarNumero={formatarNumero}
                                                                        dataCarros={dataCarros}
                                                                        isParticular={isParticular}
                                                                        emissoesGases={emissoesGases}
                                                                        currentLanguage={currentLanguage}
                                                                        tipoDaProposta={tipoDaProposta}
                                                                        estadoDaProposta={estadoDaProposta}
                                                                    />
                                                                ) : (
                                                                    <TabelaViatura
                                                                        currentLanguage={currentLanguage}
                                                                        formatarNumero={formatarNumero}
                                                                        dataCarros={dataCarros}
                                                                        isParticular={isParticular}
                                                                        urlCarroLink={urlCarroLink}
                                                                        emissoesGases={emissoesGases}
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="w-full sm:w-2/5 flex flex-col gap-4">

                                                        {estadoDaPartilhaNomeDaProposta && (
                                                            <div className='card grid gap-4'>
                                                                <HitoricoDeAcesso dataCarros={dataCarros} />
                                                            </div>
                                                        )}

                                                        <InformacoesDeal
                                                            setIdDoDealAtual={setIdDoDealAtual}
                                                            loadingFetchStatusDaProposta={loadingFetchStatusDaProposta}
                                                            setLoadingFetchStatusDaProposta={setLoadingFetchStatusDaProposta}
                                                            statusDaProposta={statusDaProposta}
                                                            setStatusDaProposta={setStatusDaProposta}
                                                            currentLanguage={currentLanguage}
                                                            setCheckDataHubspot={setCheckDataHubspot}
                                                            checkDataHubspot={checkDataHubspot}
                                                            nomeDoDeal={nomeDoDeal}
                                                            nomeDoClienteDeal={nomeDoClienteDeal}
                                                            emailDoClienteDeal={emailDoClienteDeal}
                                                            dataDeAssociacaoDoDeal={dataDeAssociacaoDoDeal}
                                                            etapaDoDeal={etapaDoDeal}
                                                            pipelineDoDeal={pipelineDoDeal}
                                                            idDaPropostaEncontrada={idDaPropostaEncontrada}
                                                            saveToken={saveToken}
                                                            idDoDealAtual={idDoDealAtual}
                                                            iDTokenAuthenticated={iDTokenAuthenticated}
                                                            setShowHubspot={setShowHubspot}
                                                            setIdDaPropostaEncontrada={setIdDaPropostaEncontrada}
                                                            setNomeDoDeal={setNomeDoDeal}
                                                            setNomeDoClienteDeal={setNomeDoClienteDeal}
                                                            setEmailDoClienteDeal={setEmailDoClienteDeal}
                                                            setDataDeAssociacaoDoDeal={setDataDeAssociacaoDoDeal}
                                                            setEtapaDoDeal={setEtapaDoDeal}
                                                            setPipelineDoDeal={setPipelineDoDeal}
                                                            propostaAceiteAprovada={propostaAceiteAprovada}
                                                            setPedidoFetchStatusDaProposta={setPedidoFetchStatusDaProposta}
                                                            setIsModalOpen={setIsModalOpen}
                                                            isModalOpen={isModalOpen}
                                                        />

                                                        {estadoDaPartilhaNomeDaProposta ? (
                                                            <CalculoChaveMaoVista
                                                                allDataLinkPartilhado={allDataLinkPartilhado}
                                                                urlCarroLink={urlCarroLink}
                                                                validarViaturaEletrico={validarViaturaEletrico}
                                                                temParametros={temParametros}
                                                                dataDeRegisto={dataDeRegisto}
                                                                novoOuUsado={novoOuUsado}
                                                                tipoDeCombustivel={tipoDeCombustivel}
                                                                setTaxaDeServico={setTaxaDeServico}
                                                                tipoDeVeiculoParaOutros={tipoDeVeiculoParaOutros}
                                                                hibridoIsChecked={hibridoIsChecked}
                                                                setCo2IsChecked={setCo2IsChecked}
                                                                setHibridoValidationIsChecked={setHibridoValidationIsChecked}
                                                                setNovoOuUsado={setNovoOuUsado}
                                                                setOutrosServicos={setOutrosServicos}
                                                                setAlertasNoPedido={setAlertasNoPedido}
                                                                setCilindrada={setCilindrada}
                                                                setDataDeRegisto={setDataDeRegisto}
                                                                setEmissoesGases={setEmissoesGases}
                                                                setHibridoIsChecked={setHibridoIsChecked}
                                                                setTipoDeCombustivel={setTipoDeCombustivel}
                                                                setParticulas={setParticulas}
                                                                setTipoDeVeiculoParaOutros={setTipoDeVeiculoParaOutros}
                                                                setWltpIsChecked={setWltpIsChecked}
                                                                setTipoDeVeiculoParaInsencoes={setTipoDeVeiculoParaInsencoes}
                                                                alertasNoPedido={alertasNoPedido}
                                                                taxaDeServico={taxaDeServico}
                                                                tipoDeVeiculoParaInsencoes={tipoDeVeiculoParaInsencoes}
                                                                hibridoValidationIsChecked={hibridoValidationIsChecked}
                                                                outrosServicos={outrosServicos}
                                                                cilindrada={cilindrada}
                                                                emissoesGases={emissoesGases}
                                                                wltpIsChecked={wltpIsChecked}
                                                                particulas={particulas}
                                                                validarAnoDeRegisto={validarAnoDeRegisto}
                                                                co2IsChecked={co2IsChecked}
                                                                valorFinalISV={valorFinalISV}
                                                                dataGeralISV={dataGeralISV}
                                                                quantidadeDeKM={quantidadeDeKM}
                                                                anoDoRegisto={anoDoRegisto}
                                                                IgnorarAvisos={IgnorarAvisos}
                                                                setComissaoDeServico={setComissaoDeServico}
                                                                comissaoDeServico={comissaoDeServico}
                                                            />
                                                        ) : (
                                                            <CalculoChaveMao
                                                                allDataLinkPartilhado={allDataLinkPartilhado}
                                                                urlCarroLink={urlCarroLink}
                                                                validarViaturaEletrico={validarViaturaEletrico}
                                                                temParametros={temParametros}
                                                                dataDeRegisto={dataDeRegisto}
                                                                novoOuUsado={novoOuUsado}
                                                                tipoDeCombustivel={tipoDeCombustivel}
                                                                setTaxaDeServico={setTaxaDeServico}
                                                                tipoDeVeiculoParaOutros={tipoDeVeiculoParaOutros}
                                                                hibridoIsChecked={hibridoIsChecked}
                                                                setCo2IsChecked={setCo2IsChecked}
                                                                setHibridoValidationIsChecked={setHibridoValidationIsChecked}
                                                                setNovoOuUsado={setNovoOuUsado}
                                                                setOutrosServicos={setOutrosServicos}
                                                                setAlertasNoPedido={setAlertasNoPedido}
                                                                setCilindrada={setCilindrada}
                                                                setDataDeRegisto={setDataDeRegisto}
                                                                setEmissoesGases={setEmissoesGases}
                                                                setHibridoIsChecked={setHibridoIsChecked}
                                                                setTipoDeCombustivel={setTipoDeCombustivel}
                                                                setParticulas={setParticulas}
                                                                setTipoDeVeiculoParaOutros={setTipoDeVeiculoParaOutros}
                                                                setWltpIsChecked={setWltpIsChecked}
                                                                setTipoDeVeiculoParaInsencoes={setTipoDeVeiculoParaInsencoes}
                                                                alertasNoPedido={alertasNoPedido}
                                                                taxaDeServico={taxaDeServico}
                                                                tipoDeVeiculoParaInsencoes={tipoDeVeiculoParaInsencoes}
                                                                hibridoValidationIsChecked={hibridoValidationIsChecked}
                                                                outrosServicos={outrosServicos}
                                                                cilindrada={cilindrada}
                                                                emissoesGases={emissoesGases}
                                                                wltpIsChecked={wltpIsChecked}
                                                                particulas={particulas}
                                                                validarAnoDeRegisto={validarAnoDeRegisto}
                                                                co2IsChecked={co2IsChecked}
                                                                valorFinalISV={valorFinalISV}
                                                                dataGeralISV={dataGeralISV}
                                                                quantidadeDeKM={quantidadeDeKM}
                                                                anoDoRegisto={anoDoRegisto}
                                                                IgnorarAvisos={IgnorarAvisos}
                                                                formatarNumero={formatarNumero}
                                                                setComissaoDeServico={setComissaoDeServico}
                                                                comissaoDeServico={comissaoDeServico}
                                                            />
                                                        )}

                                                        <PT_ResultadodaSimulacao
                                                            isModalEditarVisible={isModalEditarVisible}
                                                            setIsModalEditarVisible={setIsModalEditarVisible}
                                                            setComissaoDeServico={setComissaoDeServico}
                                                            comissaoDeServico={comissaoDeServico}
                                                            idDaPropostaEncontrada={idDaPropostaEncontrada}
                                                            setIsParticular={setIsParticular}
                                                            activeTabKeyResultadoSimulacao={activeTabKeyResultadoSimulacao}
                                                            setActiveTabKeyResultadoSimulacao={setActiveTabKeyResultadoSimulacao}
                                                            formatarNumero={formatarNumero}
                                                            valorFinalIUC={valorFinalIUC}
                                                            valorFinalISV={valorFinalISV}
                                                            iucCilindrada={iucCilindrada}
                                                            iucCO2={iucCO2}
                                                            saveToken={saveToken}
                                                            iucAdicionalCO2={iucAdicionalCO2}
                                                            iucCoeficienteAnoMatriculo={iucCoeficienteAnoMatriculo}
                                                            iucAdicionalGasoleo={iucAdicionalGasoleo}
                                                            isvcomponentecilindrada={isvcomponentecilindrada}
                                                            isvcomponenteambiental={isvcomponenteambiental}
                                                            isvReducaoAnosUsoCilindrada={isvReducaoAnosUsoCilindrada}
                                                            isvReducaoAnosUsoAmbiental={isvReducaoAnosUsoAmbiental}
                                                            isvAgravamentoParticulas={isvAgravamentoParticulas}
                                                            isvReducaoAnosUsoParticulas={isvReducaoAnosUsoParticulas}
                                                            isvTaxaAplicavelTabela={isvTaxaAplicavelTabela}
                                                            dataGeralIUC={dataGeralIUC}
                                                            taxaDeServico={taxaDeServico}
                                                            precoFinalCarro={precoFinalCarro}
                                                            setPrecoFinalCarro={setPrecoFinalCarro}
                                                            valorChaveNaMaoParticular={valorChaveNaMaoParticular}
                                                            valorChaveNaMaoEmpresa={valorChaveNaMaoEmpresa}
                                                            temParametros={temParametros}
                                                            dataCarros={dataCarros}
                                                            urlCarroLink={urlCarroLink}
                                                            precoFinalDoCarroSemIva={precoFinalDoCarroSemIva}
                                                            setTabResultadoSimulacao={setTabResultadoSimulacao}
                                                            tabResultadoSimulacao={tabResultadoSimulacao}
                                                            iDTokenAuthenticated={iDTokenAuthenticated}
                                                            estadoDaPartilhaNomeDaProposta={estadoDaPartilhaNomeDaProposta}
                                                            // mostrarResultado={mostrarResultado}
                                                            // comissaoCredito={comissaoCredito}
                                                            // isCheckedCredito={isCheckedCredito}
                                                            setTaxaDeServico={setTaxaDeServico}
                                                            setPrecoFinalDoCarroSemIva={setPrecoFinalDoCarroSemIva}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </>

                    )}

                    {erroPedido && (
                        <ErroSection erroInicial={erroInicial} />
                    )}

                </div>
            </div>

            <Modal
                title={null}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Fechar"
                footer={null}
                width={1000}
                className="hubspot-modal"
            >
                <AssociarDeal
                    setIdDoDealAtual={setIdDoDealAtual}
                    setShowHubspot={setShowHubspot}
                    idDoDealAtual={idDoDealAtual}
                    checkDataHubspot={checkDataHubspot}
                    setIsModalOpen={setIsModalOpen}
                    idDaPropostaEncontrada={idDaPropostaEncontrada}
                    saveToken={saveToken}
                    iDTokenAuthenticated={iDTokenAuthenticated}
                    setIdDaPropostaEncontrada={setIdDaPropostaEncontrada}
                    setCheckDataHubspot={setCheckDataHubspot}
                    setNomeDoDeal={setNomeDoDeal}
                    setNomeDoClienteDeal={setNomeDoClienteDeal}
                    setEmailDoClienteDeal={setEmailDoClienteDeal}
                    setDataDeAssociacaoDoDeal={setDataDeAssociacaoDoDeal}
                    setEtapaDoDeal={setEtapaDoDeal}
                    setPipelineDoDeal={setPipelineDoDeal}
                    precoFinalCarro={precoFinalCarro}
                    valorFinalISV={valorFinalISV}
                    taxaDeServico={taxaDeServico}
                    valorChaveNaMaoParticular={valorChaveNaMaoParticular}
                    formatarNumero={formatarNumero}
                    dataCarros={dataCarros}
                    nomeDaProposta={nomeDaProposta}
                    // linkPartilhavel={linkPartilhavel}
                    alertaLinkPartilhado={alertaLinkPartilhado}
                    valorChaveNaMaoEmpresa={valorChaveNaMaoEmpresa}
                    activeTabKeyResultadoSimulacao={activeTabKeyResultadoSimulacao}
                    precoFinalDoCarroSemIva={precoFinalDoCarroSemIva}
                    setEstadoDaProposta={setEstadoDaProposta}
                />
            </Modal>

        </>
    )

}
