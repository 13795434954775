import React, { useEffect, useState } from 'react';
import { Input, Table, Image, Spin, Button, Checkbox, Modal } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { IoIosBackspace } from 'react-icons/io';



const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);



export default function ConsultarListaDeContactosHubspot({
    currentLanguage,
    idDaPropostaEncontrada,
    saveToken,
    iDTokenAuthenticated,
    setIdDaPropostaEncontrada }) {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [listaDeClientes, setListaDeClientes] = useState([]);




    useEffect(() => {

        setLoading(true)

        const url = `${process.env.REACT_APP_GET_CLIENTES_HUBSPOT}?token=${saveToken}&user_id=${iDTokenAuthenticated}&lang=${currentLanguage}&live`;

        axios.get(url)
            .then((response) => {
                // console.log(response)

                setLoading(false)
                setListaDeClientes(response.data);

            })
            .catch((error) => {
                setLoading(true)
                console.error('Error occurred:', error);
            });
    }, []);


    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };


    const filteredPropostas = listaDeClientes.filter((data) => {
        const nomeDoCliente = data.properties?.firstname?.toLowerCase() || '';
        const emailDoCliente = data.properties?.email?.toLowerCase() || '';

        const searchQueryLowerCase = searchQuery.toLowerCase();

        return (
            emailDoCliente.includes(searchQueryLowerCase) ||
            nomeDoCliente.includes(searchQueryLowerCase)
        );
    });


    const handleApagar = (event) => {
        setSearchQuery('');
    };


    return (
        <>
            <div>
                {loading ? (
                    <>
                        <div className="card-white tabela-propostas flex flex-col gap-4 justify-center">
                            <Spin indicator={antIcon} />
                            <p className='text-black text-center'>{t('media_mercado.loading')}</p>
                        </div>
                    </>
                ) : (
                    <div className="card">
                        <div className='titulo-form'>
                            <p>Lista de Contactos</p>
                        </div>
                        <div>
                            <span>Consulta a lista de contactos do HubSpot. É possível pesquisar pelo nome do cliente ou email do cliente.</span>
                        </div>
                        <br />
                        <div className="flex gap-2">
                            <Input
                                type="text"
                                placeholder="Procurar por nome do cliente ou email do cliente"
                                value={searchQuery}
                                onChange={handleInputChange}
                            />
                            <Button type="primary" htmlType="submit" to="/"
                                className="bg-white text-black font-bold rounded" onClick={handleApagar}>
                                <IoIosBackspace className='iconc' />
                            </Button>
                        </div>
                        <br />
                        <div className="w-full">
                            <div className="">
                                <Table
                                    dataSource={filteredPropostas}
                                    rowKey={(cliente) => cliente.id}
                                    scroll={{ x: 'max-content' }}
                                    bordered
                                    className='hubspot-content'
                                // pagination={{
                                //     position: ['bottomCenter']
                                // }}
                                >
                                    <Table.Column
                                        title="Nome do cliente"
                                        key="primeiro_nome"
                                        className='column-hubspot'
                                        render={(cliente) => (
                                            <>
                                                <Link className='capitalize' to={`/${currentLanguage}/hubspot/perfil-contacto/?id_contacto=${cliente.id}`}>{cliente.properties.firstname} {cliente.properties.lastname}</Link>
                                            </>
                                        )}
                                    />
                                    <Table.Column
                                        title="Email do cliente"
                                        key="email"
                                        className='column-hubspot'
                                        render={(cliente) => (
                                            <>
                                                <Link to={`/${currentLanguage}/hubspot/perfil-contacto/?id_contacto=${cliente.id}`}>{cliente.properties.email}</Link>
                                            </>
                                        )}
                                    />
                                </Table>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
