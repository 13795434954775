import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import { IoArrowBack } from 'react-icons/io5';
import { Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import InfoDoDealHubspot from './components/info-do-deal';
import PropostasDoDealHubspot from './components/propostas-do-deal';


const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);

export default function PerfilDoDealHubspot({
    saveToken,
    iDTokenAuthenticated,
    currentLanguage,
    formatarNumero
}) {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [idDoDeal, setIDdoDeal] = useState('');
    const [dealsData, setDealsData] = useState([]);
    const [infoDealsData, setInfoDealsData] = useState([]);
    const [propostasDealsData, setPropostasDealsData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState('');


    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {

        setLoading(true)

        const parametros = new URLSearchParams(location.search)
        if (parametros.size > 0) {

            const idDealParam = parametros.get('id_deal');

            if (idDealParam) {

                setIDdoDeal(idDealParam);

                const url = `${process.env.REACT_APP_GET_PROCURAR_DEALS}?token=${saveToken}&user_id=${iDTokenAuthenticated}&id_deal=${idDealParam}&lang=${currentLanguage}&live`;

                axios.get(url)
                    .then((response) => {
                        // console.log('responseresponse',response)
                        setLoading(false)
                        setDealsData(response.data.deal)
                        setInfoDealsData(response.data.deal.properties)
                        setPropostasDealsData(response.data.deal.propostas)
                        // setPropostasDealsData(response.data.contactos[0].propostas)
                    })
                    .catch((error) => {
                        console.error('Error occurred:', error);
                    });

            }

        }

    }, []);


    return (
        <>
            <div className='grid gap-4 grid-cols-1 w-full h-full'>
                {loading ? (
                    <>
                        <div className='card'>
                            <div className="flex flex-col gap-4 justify-center">
                                <Spin indicator={antIcon} />
                                <p className='text-white text-center'>{t('media_mercado.loading')}</p>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <InfoDoDealHubspot infoDealsData={infoDealsData} dealsData={dealsData} currentLanguage={currentLanguage} />
                        <PropostasDoDealHubspot setPropostasDealsData={setPropostasDealsData} dealsData={dealsData} saveToken={saveToken} iDTokenAuthenticated={iDTokenAuthenticated} selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} idDoDeal={idDoDeal} propostasDealsData={propostasDealsData} currentLanguage={currentLanguage} formatarNumero={formatarNumero} />
                    </>
                )}
            </div >
        </>
    );
}