import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import flagPortugal from '../../../assets/flags/flag-portugal.svg'; // Adjust the path if necessary
import flagSpain from '../../../assets/flags/flag-spain.svg'; // Adjust the path if necessary
import TabelaLogsAutomacoes from './tabelaLogsAutomacoes';

const { Option } = Select;

export default function TabelaAutomacoes({ automacoes, selectedMes, formatarNumero, selectedCountry, saveToken, filtersSelected, iDTokenAuthenticated, currentLanguage }) {


    return (
        <>
            <p><strong>Alerta:</strong> Atualmente, as automações não suportam filtragem específica para o filtro: <strong>Business Developer</strong>.</p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {(selectedCountry === "pt" || selectedCountry === "all") && (
                    <>
                        <div className='border-card bg-black card-cinza'>
                            <div className='flex gap-2 justify-between'>
                                <p className='text-sm font-medium pb-4'>Operações</p>
                                <img
                                    src={flagPortugal}
                                    alt="Portugal"
                                    style={{ marginRight: '8px', width: '25px', height: '22px' }}
                                />
                            </div>
                            <div className='flex gap-2 items-center'>
                                <span className='text-lg font-bold text-center'>
                                    {formatarNumero(automacoes.automacoes_operacoes_pt_mes_pesquisado)}
                                </span>
                            </div>
                            {/* {automacoes.automacoes_operacoes_pt_mes_atual !== automacoes.automacoes_operacoes_pt_mes_pesquisado && (
                                <span className='text-base text-neutral-500'>
                                    <span className='text-white'>{formatarNumero(automacoes.automacoes_operacoes_pt_mes_pesquisado)}</span> em {selectedMes.label}
                                </span>
                            )} */}
                        </div>

                        <div className='border-card bg-black card-cinza'>
                            <div className='flex gap-2 justify-between'>
                                <p className='text-sm font-medium pb-4'>Sales</p>
                                <img
                                    src={flagPortugal}
                                    alt="Portugal"
                                    style={{ marginRight: '8px', width: '25px', height: '22px' }}
                                />
                            </div>
                            <div className='flex gap-2 items-center'>
                                <span className='text-lg font-bold text-center'>
                                    {formatarNumero(automacoes.automacoes_sales_pt_mes_pesquisado)}
                                </span>
                            </div>
                            {/* {automacoes.automacoes_sales_pt_mes_atual !== automacoes.automacoes_sales_pt_mes_pesquisado && (
                                <span className='text-base text-neutral-500'>
                                    <span className='text-white'>{formatarNumero(automacoes.automacoes_sales_pt_mes_pesquisado)}</span> em {selectedMes.label}
                                </span>
                            )} */}
                        </div>

                        <div className='border-card bg-black card-cinza'>
                            <div className='flex gap-2 justify-between'>
                                <p className='text-sm font-medium pb-4'>Formulário Contacto</p>
                                <img
                                    src={flagPortugal}
                                    alt="Portugal"
                                    style={{ marginRight: '8px', width: '25px', height: '22px' }}
                                />
                            </div>
                            <div className='flex gap-2 items-center'>
                                <span className='text-lg font-bold text-center'>
                                    {formatarNumero(automacoes.formularios_contacto_pt_mes_pesquisado)}
                                </span>
                            </div>
                            {/* {automacoes.formularios_contacto_pt_mes_atual !== automacoes.formularios_contacto_pt_mes_pesquisado && (
                                <span className='text-base text-neutral-500'>
                                    <span className='text-white'>{formatarNumero(automacoes.formularios_contacto_pt_mes_pesquisado)}</span> em {selectedMes.label}
                                </span>
                            )} */}
                        </div>

                        <div className='border-card bg-black card-cinza'>
                            <div className='flex gap-2 justify-between'>
                                <p className='text-sm font-medium pb-4'>Formulário ISV</p>
                                <img
                                    src={flagPortugal}
                                    alt="Portugal"
                                    style={{ marginRight: '8px', width: '25px', height: '22px' }}
                                />
                            </div>
                            <div className='flex gap-2 items-center'>
                                <span className='text-lg font-bold text-center'>
                                    {formatarNumero(automacoes.formularios_isv_pt_mes_pesquisado)}
                                </span>
                            </div>
                            {/* {automacoes.formularios_isv_pt_mes_atual !== automacoes.formularios_isv_pt_mes_pesquisado && (
                                <span className='text-base text-neutral-500'>
                                    <span className='text-white'>{formatarNumero(automacoes.formularios_isv_pt_mes_pesquisado)}</span> em {selectedMes.label}
                                </span>
                            )} */}
                        </div>
                    </>
                )}

                {(selectedCountry === "es" || selectedCountry === "all") && (
                    <>
                        <div className='border-card bg-black card-cinza'>
                            <div className='flex gap-2 justify-between'>
                                <p className='text-sm font-medium pb-4'>Operações</p>
                                <img
                                    src={flagSpain}
                                    alt="Portugal"
                                    style={{ marginRight: '8px', width: '25px', height: '22px' }}
                                />
                            </div>
                            <div className='flex gap-2 items-center'>
                                <span className='text-lg font-bold text-center'>
                                    {/* {formatarNumero(automacoes.automacoes_operacoes_es_mes_atual)} */}
                                    Em breve
                                </span>
                            </div>
                            {/* {automacoes.automacoes_operacoes_es_mes_atual !== automacoes.automacoes_operacoes_es_mes_pesquisado && (
                                <span className='text-base text-neutral-500'>
                                    <span className='text-white'>{formatarNumero(automacoes.automacoes_operacoes_es_mes_pesquisado)}</span> em {selectedMes.label}
                                </span>
                            )} */}
                        </div>

                        <div className='border-card bg-black card-cinza'>
                            <div className='flex gap-2 justify-between'>
                                <p className='text-sm font-medium pb-4'>Sales</p>
                                <img
                                    src={flagSpain}
                                    alt="Portugal"
                                    style={{ marginRight: '8px', width: '25px', height: '22px' }}
                                />
                            </div>
                            <div className='flex gap-2 items-center'>
                                <span className='text-lg font-bold text-center'>
                                    {formatarNumero(automacoes.automacoes_sales_es_mes_pesquisado)}
                                </span>
                            </div>
                            {/* {automacoes.automacoes_sales_es_mes_atual !== automacoes.automacoes_sales_es_mes_pesquisado && (
                                <span className='text-base text-neutral-500'>
                                    <span className='text-white'>{formatarNumero(automacoes.automacoes_sales_es_mes_pesquisado)}</span> em {selectedMes.label}
                                </span>
                            )} */}
                        </div>

                        <div className='border-card bg-black card-cinza'>
                            <div className='flex gap-2 justify-between'>
                                <p className='text-sm font-medium pb-4'>Formulário Contacto</p>
                                <img
                                    src={flagSpain}
                                    alt="Portugal"
                                    style={{ marginRight: '8px', width: '25px', height: '22px' }}
                                />
                            </div>
                            <div className='flex gap-2 items-center'>
                                <span className='text-lg font-bold text-center'>
                                    {formatarNumero(automacoes.formularios_contacto_es_mes_pesquisado)}
                                </span>
                            </div>
                            {/* {automacoes.formularios_contacto_es_mes_atual !== automacoes.formularios_contacto_es_mes_pesquisado && (
                                <span className='text-base text-neutral-500'>
                                    <span className='text-white'>{formatarNumero(automacoes.formularios_contacto_es_mes_pesquisado)}</span> em {selectedMes.label}
                                </span>
                            )} */}
                        </div>

                        <div className='border-card bg-black card-cinza'>
                            <div className='flex gap-2 justify-between'>
                                <p className='text-sm font-medium pb-4'>Formulário ISV</p>
                                <img
                                    src={flagSpain}
                                    alt="Portugal"
                                    style={{ marginRight: '8px', width: '25px', height: '22px' }}
                                />
                            </div>
                            <div className='flex gap-2 items-center'>
                                <span className='text-lg font-bold text-center'>
                                    {/* {formatarNumero(automacoes.formularios_isv_es_mes_atual)} */}
                                    Em breve
                                </span>
                            </div>

                        </div>
                    </>
                )}

            </div>
            <hr></hr>
            <div className='grid gap-1'>
                <p className='text-lg font-bold'>Logs das Automações</p>
                <p className='text-sm font-normal'>Versão BETA</p>
            </div>

            <TabelaLogsAutomacoes
                saveToken={saveToken}
                iDTokenAuthenticated={iDTokenAuthenticated}
                currentLanguage={currentLanguage}
                selectedCountry={selectedCountry}
            />

        </>
    );
}
