import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Select, Button, Spin, Input, Popconfirm, notification, Menu, Dropdown, Modal, message, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import flagPortugal from '../../assets/flags/flag-portugal.svg';
import flagSpain from '../../assets/flags/flag-spain.svg';
import { useMediaQuery } from 'react-responsive';
import redbubble from '../../assets/red-circle.svg';
import greenbubble from '../../assets/green-circle.svg';
import { Link } from 'react-router-dom';
import { LoadingOutlined, SearchOutlined, DeleteOutlined, EllipsisOutlined } from '@ant-design/icons';
import { FiEdit2 } from "react-icons/fi";
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { MdDelete, MdModeEdit } from "react-icons/md";

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);


export default function ListaDeUtilizadores({ saveToken, iDTokenAuthenticated }) {

    const { t } = useTranslation();

    //Responsive
    const isMobile = useMediaQuery({
        query: '(max-width: 1023px)'
    })


    const [utilizadores, setUtilizadores] = useState([]);
    const [loading, setLoading] = useState(false);

    const [searchQuery, setSearchQuery] = useState(''); //pesquisa

    const [isModalVisible, setIsModalVisible] = useState(false); //popup de eliminar user
    const [isModalEditarVisible, setIsModalEditarVisible] = useState(false); //popup de editar user

    const [currentUserId, setCurrentUserId] = useState(null); //detatar user

    const [userPassword, setUserPassword] = useState(''); //password


    useEffect(() => {
        setLoading(true)
        const url = `${process.env.REACT_APP_OBTER_UTILIZADORES}?token=${saveToken}&user_id=${iDTokenAuthenticated}`;

        axios.get(url)
            .then((response) => {
                setUtilizadores(response.data.mensagem);
                setLoading(false)
            })
            .catch((error) => {
                console.error('Error occurred:', error);
                setLoading(true)
            });
    }, []);


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id_user',
            key: 'id_user',
        },
        {
            title: 'País',
            dataIndex: 'pais',
            key: 'pais',
            render: (pais) => (
                pais[0] === "pt" ? (
                    <img
                        src={flagPortugal}
                        alt="Portugal"
                        style={{ marginRight: '8px', width: '35px', height: '22px' }}
                    />
                ) : (
                    <img
                        src={flagSpain}
                        alt="Spain"
                        style={{ marginRight: '8px', width: '35px', height: '22px' }}
                    />
                )
            )
        },
        {
            title: 'Nome Utilizador',
            dataIndex: 'nome_criou',
            key: 'nome_criou',
            render: (text, record) => {
                return (
                    <div className='flex items-center gap-2 overflow-hidden'>
                        <img
                            src={record.foto_perfil}
                            className='w-8 object-cover h-8 rounded-full'
                            alt='User Profile'
                        />
                        <span>{record.first_name}</span>
                    </div>
                );
            }
        },
        {
            title: 'Cargo',
            dataIndex: 'cargo',
            key: 'cargo',
        },
        {
            title: 'Email',
            dataIndex: 'email_interno',
            key: 'email_interno',
            render: (text, record) => {
                return (
                    <span>{record.email_interno || record.user_email}</span>
                );
            }
        },
        {
            title: 'Whatsapp',
            dataIndex: 'numero_whatsapp',
            key: 'numero_whatsapp',
        },
        {
            title: 'Login',
            dataIndex: 'user_login',
            key: 'user_login',
        },
        {
            title: 'Business Developer',
            dataIndex: 'business_developer',
            key: 'business_developer',
            render: (business_developer) => (
                <span className='flex justify-center'>
                    <img
                        style={{ height: '20px', cursor: 'pointer' }}
                        src={business_developer ? greenbubble : redbubble}
                        alt={business_developer ? "Green Bubble" : "Red Bubble"}
                    />
                </span>
            )
        },
        {
            title: 'Ativo',
            dataIndex: 'user_ativo',
            key: 'user_ativo',
            render: (user_ativo) => (
                <span className='flex justify-center'>
                    <img
                        style={{ height: '20px', cursor: 'pointer' }}
                        src={user_ativo ? greenbubble : redbubble}
                        alt={user_ativo ? "Green Bubble" : "Red Bubble"}
                    />
                </span>
            )
        },
        {
            title: '',
            key: 'actions',
            render: (text, record) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item>
                                    <Button onClick={() => showModalEditar(record.id_user)} className='flex items-center gap-2'><MdModeEdit /> Editar Password </Button>
                                </Menu.Item>
                                {/* <Menu.Item>
                                    <Button onClick={() => showModal(record.id_user)} className='flex items-center gap-2 eliminar-cta bg-red-500 text-white hover:text-white'><MdDelete /> Eliminar Utilizador </Button>
                                </Menu.Item> */}
                            </Menu>
                        }
                        trigger={['click']}
                    >
                        <Button className="bg-white p-2">
                            <HiOutlineDotsVertical />
                        </Button>
                    </Dropdown>
                </div>
            )
        }
    ];


    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };


    const todosUtilizadores = utilizadores.filter((data) => {

        const nome = data.first_name.toLowerCase();
        const searchQueryLowerCase = searchQuery.toLowerCase();


        return (
            nome.includes(searchQueryLowerCase)
        );

    });



    const showModal = (id_user) => {
        setCurrentUserId(id_user);
        setIsModalVisible(true);
    };



    const handleOk = () => {
        const data = {
            user_id_to_change: currentUserId,
            action: 'delete_user',
            user_id: iDTokenAuthenticated,
            token: saveToken,
        };

        axios.post(`${process.env.REACT_APP_URL_ELIMINAR_USER}`, data)
            .then((response) => {
                if (response.data.sucesso) {
                    message.success(`${response.data.mensagem}`);
                    setUtilizadores(utilizadores.filter(user => user.id_user !== currentUserId)); // Actualizar lista de utilizadores
                    handleCancel();
                } else {
                    message.error(response.data.mensagem);
                }
            })
            .catch((error) => {
                message.error(`${error.message}`);
            });
    };



    const handleCancel = () => {
        setIsModalVisible(false);
        setCurrentUserId(null);
    };





    const showModalEditar = (id_user) => {
        setCurrentUserId(id_user);
        setIsModalEditarVisible(true);
    };

    const handleEditarOk = () => {
        const data = {
            user_id_to_change: currentUserId,
            user_pass: userPassword,
            action: 'change_password',
            user_id: iDTokenAuthenticated,
            token: saveToken,
        };

        axios.post(`${process.env.REACT_APP_URL_ELIMINAR_USER}`, data)
            .then((response) => {
                if (response.data.sucesso) {
                    message.success(`${response.data.mensagem}`);
                    // setUtilizadores(utilizadores.filter(user => user.id_user !== currentUserId)); // Actualizar lista de utilizadores
                    handleEditarCancel();
                } else {
                    message.error(response.data.mensagem);
                }
            })
            .catch((error) => {
                message.error(`${error.message}`);
            });
    };

    const handleEditarCancel = () => {
        setIsModalEditarVisible(false);
        setCurrentUserId(null);
    };






    return (
        <>
            <div className='card grid gap-4'>
                <div className='flex items-center gap-4'>
                    <p className='text-lg font-bold'>Lista de Utilizadores</p>
                    {/* <div>|</div>
                    <Link to={`/registar-utilizador`}>
                        <Button
                            type="primary"
                            target="_blank"
                            className="flex gap-2 items-center bg-white text-black font-bold rounded"
                        >
                            Criar Novo utilizador
                        </Button>
                    </Link> */}
                </div>
                <Input
                    type="text"
                    placeholder={t('propostas.input')}
                    value={searchQuery}
                    onChange={handleInputChange}
                    prefix={<SearchOutlined />}
                />
                <hr></hr>
                {loading ? (
                    <div className="flex flex-col gap-4 justify-center">
                        <Spin indicator={antIcon} />
                        <p className='text-white text-center'>{t('media_mercado.loading')}</p>
                    </div>
                ) : (
                    <div className="w-full">
                        <div className="tabela-propostas tabela-users">
                            <Table
                                dataSource={todosUtilizadores}
                                columns={columns}
                                rowKey={(record) => record.id_user}
                                // pagination={false}
                                scroll={isMobile ? { x: 'max-content' } : { x: false }}
                                bordered
                                style={isMobile ? {} : { width: '100%' }}
                                tableLayout={isMobile ? undefined : 'auto'}
                            />
                        </div>
                        <Modal
                            title="Eliminar Utilizador"
                            visible={isModalVisible}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            okText="Sim"
                            cancelText="Não"
                            confirmLoading={false}
                        >
                            <p>Tem a certeza que deseja eliminar este utilizador?</p>
                        </Modal>
                        <Modal
                            title="Editar Password"
                            visible={isModalEditarVisible}
                            onOk={handleEditarOk}
                            onCancel={handleEditarCancel}
                            okText="Alterar Password"
                            cancelText="Fechar"
                            confirmLoading={false}
                        >
                            <div>
                                <Form.Item
                                    name="userPassword"
                                    rules={[{ required: true, message: 'Por favor, insira uma password.' }]}
                                >
                                    <Input.Password
                                        placeholder="Password"
                                        value={userPassword}
                                        onChange={(e) => setUserPassword(e.target.value)}
                                    />
                                </Form.Item>
                            </div>
                        </Modal>

                    </div>
                )}

            </div>
        </>
    );
}
