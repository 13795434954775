import { AiOutlineInfoCircle } from 'react-icons/ai';


export default function InfoBlock({ }) {



    return (
        <div className='flex items-center gap-3 flex-col justify-center text-lg rounded-md p-2.5'>
            <AiOutlineInfoCircle className='icon-question text-white text-2xl' />
            <p className='text-white text-sm frase-style'>Antes de avançar valida por favor todos os campos</p>
        </div >
    );
}
