import React, { useState } from 'react';
import { Steps, Button } from 'antd';

const { Step } = Steps;

const PassoAPasso = ({ setIsModalPassoAPassoVisible, setCurrent, current }) => {

    const steps = [
        {
            title: 'Primeiro Passo',
            content: (
                <div>
                    Analisar e validar todos os valores antes de criar a proposta.
                </div>
            ),
        },
        {
            title: 'Segundo Passo',
            content: (
                <div>
                    Após criar a proposta e a proposta ser criada com sucesso, deve associá-la ao HubSpot.
                </div>
            ),
        },
        {
            title: 'Terceiro Passo',
            content: (
                <div>
                    Depois de associar a proposta ao negócio, poderá partilhá-la com o cliente.
                </div>
            ),
        },
        {
            title: 'Quarto Passo',
            content: (
                <div>
                    Finalizar o processo de acordo com as necessidades do cliente.
                </div>
            ),
        },
    ];

    const next = () => {
        setCurrent(current + 1);
        // console.log(current)
    };

    const prev = () => {
        setCurrent(current - 1);
        // console.log(current)
    };

    return (
        <div className="p-4 text-center passo-a-passo">
            <Steps current={current} style={{ color: '#000' }}>
                {steps.map((item, index) => (
                    <Step key={index} />
                ))}
            </Steps>
            <div className="steps-content mt-4" style={{ color: '#000' }}>
                {steps[current].content}
            </div>
            <div className="steps-action mt-4">
                {current > 0 && (
                    <Button type="default" ghost={false} style={{ margin: '0 8px', color: '#000' }} onClick={prev}>
                        Voltar
                    </Button>
                )}
                {current < steps.length - 1 && (
                    <Button type="default" ghost={false} onClick={next} style={{ color: '#000' }}>
                        Próximo
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button
                        type="default"
                        ghost={false}
                        onClick={() => setIsModalPassoAPassoVisible(false)}
                        style={{ color: '#000' }}
                    >
                        {current === steps.length - 1 ? 'Fechar' : 'Concluir'}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default PassoAPasso;
