import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import { IoArrowBack } from 'react-icons/io5';
import { Button, Spin } from 'antd';
import InfoDoContactoHubspot from './components/info-do-contacto';
import DealsDoContactoHubspot from './components/deals-do-contacto';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropostasDoContactoHubspot from './components/propostas-do-contacto';


const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);

export default function PerfilDoContactoHubspot({
    saveToken,
    iDTokenAuthenticated,
    currentLanguage
}) {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [nomeGeradoDaProposta, setNomeGeradoDaProposta] = useState('');
    const [contactoData, setContactoData] = useState([]);
    const [contactoInfoData, setContactoInfoData] = useState([]);
    const [contactoDealsData, setContactoDealsData] = useState([]);
    const [propostasDealsData, setPropostasDealsData] = useState([]);

    const [alerta, setAlerta] = useState('');


    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {

        setLoading(true)

        const parametros = new URLSearchParams(location.search)
        if (parametros.size > 0) {

            const idPropostaParam = parametros.get('id_contacto');

            if (idPropostaParam) {

                setNomeGeradoDaProposta(idPropostaParam);


                const url = `${process.env.REACT_APP_GET_PROCURAR_DEALS}?token=${saveToken}&user_id=${iDTokenAuthenticated}&id_contacto=${idPropostaParam}&lang=${currentLanguage}&live`;

                axios.get(url)
                    .then((response) => {
                        setLoading(false)
                        setContactoData(response)
                        setContactoInfoData(response.data.contactos[0].properties)
                        setContactoDealsData(response.data.contactos[0].deals)
                        setPropostasDealsData(response.data.contactos[0].propostas)
                        // console.log(response)
                    })
                    .catch((error) => {
                        console.error('Error occurred:', error);
                    });

            }

        }

    }, []);


    return (
        <>
            <div className='grid gap-4 grid-cols-1 w-full h-full'>
                {/* <div className='card'>
                    <Button
                        onClick={() => navigate(-1)}
                        type="primary"
                        target="_blank"
                        className="flex gap-2 items-center bg-white text-black font-bold rounded"
                    >
                        <IoArrowBack />
                        Voltar
                    </Button>
                </div> */}
                {loading ? (
                    <>
                        <div className="card-white tabela-propostas flex flex-col gap-4 justify-center">
                            <Spin indicator={antIcon} />
                            <p className='text-black text-center'>{t('media_mercado.loading')}</p>
                        </div>
                    </>
                ) : (
                    <>
                        <InfoDoContactoHubspot contactoInfoData={contactoInfoData} />
                        <DealsDoContactoHubspot contactoDealsData={contactoDealsData} currentLanguage={currentLanguage} />
                        <PropostasDoContactoHubspot currentLanguage={currentLanguage} propostasDealsData={propostasDealsData} />
                    </>
                )}
            </div >
        </>
    );
}