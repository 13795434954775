import { Input, Select, DatePicker } from "antd";
import React, { useState } from "react";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const { Option } = Select;

export default function CompContratoEmpresa({
    dataDoDocumento,
    setDataDoDocumento,
    nomeSocioEmpresa,
    setNomeSocioEmpresa,
    numeroCCEmpresa,
    setNumeroCCEmpresa,
    nomeEmpresa,
    setNomeEmpresa,
    sedeEmpresa,
    setSedeEmpresa,
    nifEmpresa,
    setNifEmpresa,
    emailDaEmpresa,
    setEmailDaEmpresa,
    valorServicoPrestado,
    setValorServicoPrestado,
    nifGerente,
    setNifGerente,
    emailResponsavel,
    setEmailResponsavel,

    tipoContrato,
    tipoSociedade,

    marcaViatura,
    setMarcaViatura,
    modeloViatura,
    setModeloViatura,
    matriculaViatura,
    setMatriculaViatura,
    vinViatura,
    setVinViatura,
    seguroViatura,
    setSeguroViatura,
    percentagemServicoPrestado,
    setPercentagemServicoPrestado,
    idDoNegocio,
    setIDdoNegocio,
    valorDeposito,
    setValorDeposito,
    alertaDataDocumento,
    setDataSociosDois,
    tipo
}) {


    const [numSocios, setnumSocios] = useState('0');
    const [inputs, setInputs] = useState([]);



    // console.log('tipoContrato', tipoContrato)
    // console.log('tipoSociedade', tipoSociedade)


    const handleDataChange = (e) => {
        let day = "";
        let month = "";
        let year = "";

        if (e && e instanceof Object && e.hasOwnProperty('$y') && e.hasOwnProperty('$M') && e.hasOwnProperty('$D')) {
            // Extract day, month, and year
            day = e.$D.toString().padStart(2, '0');
            month = (e.$M + 1).toString().padStart(2, '0'); // Month is zero-based
            year = e.$y.toString();
        }

        setDataDoDocumento(day + '/' + month + '/' + year);
        // console.log(dataDoDocumento)
    };

    const dayjsDate = (date) => {
        if (date && date !== '') {
            return dayjs(date, 'DD/MM/YYYY')
        }
    }

    const handleNomeSocioChange = (e) => {
        setNomeSocioEmpresa(e.target.value);
    };

    const handleNumeroCCChange = (e) => {
        setNumeroCCEmpresa(e.target.value);
    };

    const handleNomeEmpresaChange = (e) => {
        setNomeEmpresa(e.target.value);
    };

    const handleSedeEmpresaChange = (e) => {
        setSedeEmpresa(e.target.value);
    };

    const handleNifEmpresaChange = (e) => {
        setNifEmpresa(e.target.value);
    };


    const handleEmailDaEmpresaChange = (e) => {
        setEmailDaEmpresa(e.target.value);
    };

    const handleValorServicoPrestadoChange = (e) => {
        setValorServicoPrestado(e.target.value);
    };

    const handleNIFGerenteChange = (e) => {
        setNifGerente(e.target.value);
    };

    const handleEmailRsponsavelChange = (e) => {
        setEmailResponsavel(e.target.value);
    };



    //vendas - empresa

    const handleMarcaDaViaturaChange = (e) => {
        setMarcaViatura(e.target.value);
    };


    const handleModeloDaViaturaChange = (e) => {
        setModeloViatura(e.target.value);
    };


    const handleMatriculaDaViaturaChange = (e) => {
        setMatriculaViatura(e.target.value);
    };


    const handleVINDaViaturaChange = (e) => {
        setVinViatura(e.target.value);
    };


    const handleSeguroDaViaturaChange = (e) => {
        setSeguroViatura(e.target.value);
    };


    const handlePercentagemServicoPrestadoChange = (e) => {
        setPercentagemServicoPrestado(e.target.value);
    };


    const handleValorDepositolChange = (e) => {
        setValorDeposito(e.target.value);
    };



    const handleSubmit = () => {

    }



    const handleAdicionarMaisSocios = (value) => {
        setnumSocios(value);
        const numInputs = parseInt(value);
        const currentInputs = [...inputs]; // Copia os inputs existentes
        if (currentInputs.length > numInputs) {
            const updatedInputs = currentInputs.slice(0, numInputs); // Remove inputs extras se houverem
            setInputs(updatedInputs);
            setDataSociosDois(updatedInputs)
        } else {
            const newInputs = [];
            for (let i = currentInputs.length; i < numInputs; i++) {
                newInputs.push({ nome_gerente: "", nif_gerente: "" });
            }
            const updatedInputs = [...currentInputs, ...newInputs]; // Adiciona novos inputs aos existentes
            setInputs(updatedInputs);
            setDataSociosDois(updatedInputs)
        }
    };


    const handleNomeSociooChange = (index, value) => {
        const newInputs = [...inputs];
        newInputs[index].nome_gerente = value;
        setInputs(newInputs);
        // console.log(inputs)
        setDataSociosDois(newInputs)
    };

    const handleNifSocioChange = (index, value) => {
        const newInputs = [...inputs];
        newInputs[index].nif_gerente = value;
        setInputs(newInputs);
        setDataSociosDois(inputs)
    };

    const handleIDdoNegocioChange = (e) => {
        setIDdoNegocio(e.target.value);
    };


    return (
        <>


            <div className="grid grid-cols-2 gap-2">
                <div className='grid gap-2'>
                    <p>ID do negócio</p>
                    <Input
                        placeholder="ID do negócio"
                        value={idDoNegocio}
                        onChange={handleIDdoNegocioChange}
                        type="text"
                        required
                    />
                </div>
                <div className='grid gap-2'>
                    <p>Data do documento</p>
                    <DatePicker placeholder="dd/mm/aaaa"
                        defaultValue={dayjsDate(dataDoDocumento)}
                        value={dayjsDate(dataDoDocumento)}
                        format={'DD/MM/YYYY'}
                        // picker="month"
                        onChange={handleDataChange}
                        required
                    />
                    {alertaDataDocumento && <small className="text-red-500">A data do documento é obrigatória</small>}
                </div>
            </div>
            <p className="font-semibold">Detalhes do Cliente:</p>

            <div className="grid grid-cols-2 gap-2">
                <div className='grid gap-2'>
                    <p>Nome do sócio gerente</p>
                    <Input
                        placeholder="Nome do sócio gerente"
                        value={nomeSocioEmpresa}
                        onChange={handleNomeSocioChange}
                        type="text"
                        required
                    />
                </div>

                {tipoContrato !== "venda" && tipoSociedade !== "empresa" ? (
                    <div className='grid gap-2'>
                        <p>Nº cartão de cidadão do sócio gerente</p>
                        <Input
                            placeholder="Nº cartão de cidadão do sócio gerente"
                            value={numeroCCEmpresa}
                            onChange={handleNumeroCCChange}
                            type="text"
                            required
                        />
                    </div>
                ) : null}

                <div className='grid gap-2'>
                    <p>NIF do sócio gerente</p>
                    <Input
                        placeholder="NIF do sócio gerente"
                        value={nifGerente}
                        onChange={handleNIFGerenteChange}
                        type="text"
                        required
                    />
                </div>

            </div>

            <p className="font-semibold">Detalhes da Empresa:</p>

            <div className="grid grid-cols-2 gap-2">
                <div className='grid gap-2'>
                    <p>Nome da empresa</p>
                    <Input
                        placeholder="Nome da empresa"
                        value={nomeEmpresa}
                        onChange={handleNomeEmpresaChange}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>Sede da empresa</p>
                    <Input
                        placeholder="Sede da empresa"
                        value={sedeEmpresa}
                        onChange={handleSedeEmpresaChange}
                        type="text"
                        required
                    />
                </div>


                {tipoContrato !== "venda" && tipoSociedade !== "particular" ? (
                    <div className='grid gap-2'>
                        <p>Email da empresa</p>
                        <Input
                            placeholder="Email da empresa"
                            value={emailDaEmpresa}
                            onChange={handleEmailDaEmpresaChange}
                            type="text"
                            required
                        />
                    </div>
                ) : null}


                <div className='grid gap-2'>
                    <p>NIF da empresa</p>
                    <Input
                        placeholder="NIF da empresa"
                        value={nifEmpresa}
                        onChange={handleNifEmpresaChange}
                        type="text"
                        required
                    />
                </div>




                {tipoContrato !== "venda" ? (
                    <div className='grid gap-2'>
                        <p>Valor do serviço prestado</p>
                        <Input
                            placeholder="xxxx,xx"
                            value={valorServicoPrestado}
                            onChange={handleValorServicoPrestadoChange}
                            type="text"
                            required
                        />
                    </div>
                ) : null}


                {tipoContrato !== "venda" && tipoContrato !== "procuracao" /*&& tipoSociedade !== "empresa"*/ ? (
                    <div className='grid gap-2'>
                        <p>Email do responsável/tech sale</p>
                        <Input
                            placeholder="Email do responsável/tech sale"
                            value={emailResponsavel}
                            onChange={handleEmailRsponsavelChange}
                            type="text"
                            required
                        />
                    </div>
                ) : null}

            </div>
            {tipoContrato === "venda" && tipoSociedade === "empresa" ? (
                <>
                    <p className="font-semibold">Detalhes da Viatura:</p>

                    <div className="grid grid-cols-2 gap-2">
                        <div className='grid gap-2'>
                            <p>Marca da viatura</p>
                            <Input
                                placeholder="Marca da viatura"
                                value={marcaViatura}
                                onChange={handleMarcaDaViaturaChange}
                                type="text"
                                required
                            />
                        </div>

                        <div className='grid gap-2'>
                            <p>Modelo da viatura</p>
                            <Input
                                placeholder="Modelo da viatura"
                                value={modeloViatura}
                                onChange={handleModeloDaViaturaChange}
                                type="text"
                                required
                            />
                        </div>

                        <div className='grid gap-2'>
                            <p>Matrícula da viatura</p>
                            <Input
                                placeholder="Matrícula da viatura"
                                value={matriculaViatura}
                                onChange={handleMatriculaDaViaturaChange}
                                type="text"
                                required
                            />
                        </div>

                        <div className='grid gap-2'>
                            <p>VIN da viatura</p>
                            <Input
                                placeholder="VIN da viatura"
                                value={vinViatura}
                                onChange={handleVINDaViaturaChange}
                                type="text"
                                required
                            />
                        </div>

                        {/* <div className='grid gap-2'>
                            <p>Seguro da viatura</p>
                            <Input
                                placeholder="Seguro da viatura"
                                value={seguroViatura}
                                onChange={handleSeguroDaViaturaChange}
                                type="text"
                                required
                            />
                        </div> */}
                    </div>

                    <p className="font-semibold">Outros Detalhes:</p>

                    <div className="grid grid-cols-2 gap-2">

                        <div className='grid gap-2'>
                            <p>Percentagem serviço prestado (%)</p>
                            <Input
                                placeholder="Percentagem serviço prestado (%)"
                                value={percentagemServicoPrestado}
                                onChange={handlePercentagemServicoPrestadoChange}
                                type="text"
                                required
                            />
                        </div>


                        <div className='grid gap-2'>
                            <p>Valor depósito</p>
                            <Input
                                placeholder="Valor depósito"
                                value={valorDeposito}
                                onChange={handleValorDepositolChange}
                                type="text"
                                required
                            />
                        </div>
                    </div>
                </>
            ) : null}
            {/* </div > */}

            {tipo !== "novo_negocio" ? (
                <div className="grid grid-cols-1 gap-2">
                    <p>Número de Sócios</p>
                    <span className="text-sm">Preencha apenas os sócios para além do gerente já preenchido acima.</span>
                    <Select
                        placeholder="Selecione o numSocios"
                        value={numSocios}
                        onChange={handleAdicionarMaisSocios}
                    >
                        <Option value="0">0</Option>
                        <Option value="1">+1</Option>
                        <Option value="2">+2</Option>
                        <Option value="3">+3</Option>
                        <Option value="4">+4</Option>
                        <Option value="5">+5</Option>
                    </Select>
                    <div className="grid grid-cols-1 gap-2">
                        {inputs.map((input, index) => (
                            <div className="grid grid-cols-2 gap-2 separador-socios" key={index}>
                                <div className="grid gap-2">
                                    <p>Nome do Sócio gerente {index + 1}</p>
                                    <Input
                                        placeholder="Nome do Sócio gerente"
                                        value={input.nome}
                                        onChange={(e) => handleNomeSociooChange(index, e.target.value)}
                                        type="text"
                                        required
                                    />
                                </div>
                                <div className="grid gap-2">
                                    <p>NIF do Sócio gerente {index + 1}</p>
                                    <Input
                                        placeholder="NIF do Sócio gerente"
                                        value={input.nif}
                                        onChange={(e) => handleNifSocioChange(index, e.target.value)}
                                        type="text"
                                        required
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : null
            }

        </>
    )
}

