import React, { useState } from 'react';
import axios from 'axios';
import { Input, Button, Select, Form, message } from 'antd';

const { Option } = Select;

export default function ES_RegistarViatura({ currentLanguage, setIsModalOpenModelo, setValorViaturaNova, valorViaturaNova, listaDeModelos, idModelo, setIdModelo, idMarca, setIdMarca, setListaDeModelos, listaDeMarcas, saveToken, iDTokenAuthenticated }) {
    // State variables
    const [nomeModelo, setNomeModelo] = useState('');
    // const [idMarca, setIdMarca] = useState('');

    // const [idModelo, setIdModelo] = useState('');

    const [inicio, setInicio] = useState('');
    const [fim, setFim] = useState('');
    const [cilindrada, setCilindrada] = useState('');
    const [combustivel, setCombustivel] = useState('');
    const [kw, setKw] = useState('');
    const [cvf, setCvf] = useState('');
    const [cavalos, setCavalos] = useState('');
    const [co2, setCo2] = useState('');

    // const [valorNovo, setValorNovo] = useState('');
    const [cilindros, setCilindros] = useState('');

    // Handle change functions
    const handleModeloChange = (e) => setNomeModelo(e.target.value);
    const handleIdMarcaChange = (value) => setIdMarca(value);
    const handleInicioChange = (e) => setInicio(e.target.value);
    const handleFimChange = (e) => setFim(e.target.value);
    const handleCilindradaChange = (e) => setCilindrada(e.target.value);
    const handleCombustivelChange = (value) => setCombustivel(value);
    const handleKwChange = (e) => setKw(e.target.value);
    const handleco2Change = (e) => setCo2(e.target.value);
    const handleCvfChange = (e) => setCvf(e.target.value);
    const handleCavalosChange = (e) => setCavalos(e.target.value);
    const handleValorNovoChange = (e) => setValorViaturaNova(e.target.value);
    const handleCilindrosChange = (e) => setCilindros(e.target.value);


    // Handle register function
    const handleRegistarModelo = () => {
        if (
            !nomeModelo ||
            !idMarca ||
            !inicio ||

            !cilindrada ||
            !combustivel ||

            !cvf ||
            !co2 ||

            !valorViaturaNova ||
            !cilindros
        ) {
            alert("Rellene todos los campos obligatorios.");
            return;
        }

        const dataCriarLink = {
            nome_modelo: nomeModelo,
            id_marca: idMarca,
            inicio: inicio,
            fim: fim,
            cilindrada: cilindrada,
            combustivel: combustivel,
            kw: kw,
            cvf: cvf,
            co2: co2,
            cavalos: cavalos,
            valor_novo: valorViaturaNova,
            cilindros: cilindros,
            user_id: iDTokenAuthenticated,
            token: saveToken,
        };

        axios.post(`${process.env.REACT_APP_REGISTAR_MODELO_ES}?lang=${currentLanguage}`, dataCriarLink)
            .then((response) => {
                if (response.data.success) {
                    const novoModelo = {
                        nome_modelo: nomeModelo,
                        id_marca: idMarca,
                        id_modelo: response.data.mensagem, // Use o ID do modelo retornado
                        inicio: inicio,
                        fim: fim,
                        cilindrada: cilindrada,
                        combustivel: combustivel,
                        kw: kw,
                        co2: co2,
                        cvf: cvf,
                        cavalos: cavalos,
                        valor_novo: valorViaturaNova,
                        cilindros: cilindros
                    };

                    setListaDeModelos(prevModelos => [novoModelo, ...prevModelos]);

                    // setValorViaturaNova()

                    setIdModelo(response.data.mensagem); // Atualize o idModelo com o novo ID

                    message.success('¡Modelo registrado con éxito!');

                    // Limpe os campos
                    setNomeModelo('');
                    setInicio('');
                    setFim('');
                    setCilindrada('');
                    setCombustivel('');
                    setKw('');
                    setCo2('');
                    setCvf('');
                    setCavalos('');
                    // setValorViaturaNova('');
                    setCilindros('');
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };


    const onSearchMarca = (value) => {
        const filteredCities = listaDeMarcas.filter(marca =>
            marca.nome_marca.toLowerCase().includes(value.toLowerCase())
        );
    };

    return (
        <Form className="grid gap-4" onFinish={handleRegistarModelo}>
            <div className="grid gap-2 content-start">
                <p>Nombre del modelo</p>
                <Input
                    placeholder="Nombre del modelo"
                    value={nomeModelo}
                    onChange={handleModeloChange}
                    required
                />
            </div>

            <div className="grid gap-2 content-start">
                <p>Marca</p>
                <Select
                    showSearch
                    placeholder="Selecciona una marca"
                    value={idMarca || "Seleccione"}
                    onChange={handleIdMarcaChange}
                    onSearch={onSearchMarca}
                    required
                >
                    {listaDeMarcas.map((marca) => (
                        <Option key={marca.id_marca} value={marca.id_marca}>
                            {marca.nome_marca}
                        </Option>
                    ))}
                </Select>
            </div>

            <div className="grid gap-2 content-start">
                <p>Inicio de la producción</p>
                <Input
                    placeholder="Inicio de la producción"
                    value={inicio}
                    onChange={handleInicioChange}
                    required
                    type='number'
                />
            </div>

            <div className="grid gap-2 content-start">
                <p>Fin de producción</p>
                <Input
                    placeholder="Fin de producción"
                    value={fim}
                    onChange={handleFimChange}

                    type='number'
                />
            </div>

            <div className="grid gap-2 content-start">
                <p>Cilindrada</p>
                <Input
                    placeholder="Cilindrada"
                    value={cilindrada}
                    onChange={handleCilindradaChange}
                    required
                    type='number'
                />
            </div>

            <div className="grid gap-2 content-start">
                <p>Tipo de Combustible</p>
                <Select
                    placeholder="Seleccione"
                    value={combustivel ? combustivel : undefined}
                    onChange={handleCombustivelChange}
                    required
                >
                    <Option value="">Seleccione</Option>
                    <Option value="Gasolina">Gasolina</Option>
                    <Option value="Gasoleo">Diesel</Option>
                    <Option value="Eletrico">Eléctrico</Option>~
                    <Option value="Gasolina/Gasolina GLP">Gasolina/Gasolina GLP</Option>
                    <Option value="Híbrido (Gasóleo/Eletrico)">Híbrido (Gasóleo/Eletrico)</Option>
                    <Option value="Híbrido (Gasolina/Eletrico)">Híbrido (Gasolina/Eletrico)</Option>
                </Select>
            </div>

            <div className="grid gap-2 content-start">
                <p>CO2</p>
                <Input
                    placeholder="CO2"
                    value={co2}
                    onChange={handleco2Change}

                    type='number'
                />
            </div>

            <div className="grid gap-2 content-start">
                <p>KW</p>
                <Input
                    placeholder="KW"
                    value={kw}
                    onChange={handleKwChange}

                    type='number'
                />
            </div>

            <div className="grid gap-2 content-start">
                <p>CVF - <a className="underline" href="https://www.seisenlinea.com/calcular-caballos-fiscales/" target="_blank" rel="noopener noreferrer">
                    Calcular caballos fiscales
                </a></p>
                <Input
                    placeholder="CVF"
                    value={cvf}
                    onChange={handleCvfChange}
                    required
                />
            </div>

            <div className="grid gap-2 content-start">
                <p>Cavalos</p>
                <Input
                    placeholder="Cavalos"
                    value={cavalos}
                    onChange={handleCavalosChange}

                    type='number'
                />
            </div>

            <div className="grid gap-2 content-start">
                <p>Valor Novo</p>
                <Input
                    placeholder="35000"
                    value={valorViaturaNova}
                    onChange={handleValorNovoChange}
                    required
                    type='number'
                />
            </div>

            <div className="grid gap-2 content-start">
                <p>Cilindros</p>
                <Input
                    placeholder="Cilindros"
                    value={cilindros}
                    onChange={handleCilindrosChange}
                    required
                    type='number'
                />
            </div>



            <Button type="primary" htmlType="submit" className="bg-white text-black font-bold rounded w-full">
                Registrar modelo
            </Button>
        </Form>
    );
}
