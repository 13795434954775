import React, { useState } from 'react';
import { Table, Select, Modal, Checkbox, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaEye, FaArrowDown, FaArrowUp } from 'react-icons/fa';
import flagPortugal from '../../../assets/flags/flag-portugal.svg';
import flagSpain from '../../../assets/flags/flag-spain.svg';
import { useMediaQuery } from 'react-responsive';

export default function TabelaAnalytics({ setIsModalVisible, isModalVisible, propostas, destaquesPropostas, formatarNumero, selectedMes, mesAtual, currentLanguage }) {
    const { t } = useTranslation();

    // Responsive
    const isMobile = useMediaQuery({
        query: '(max-width: 1023px)'
    });

    // State to control visible columns
    const [visibleColumns, setVisibleColumns] = useState([
        'pais',
        'nome_utilizador',
        'propostas_mes_pesquisado',
        'propostas_aceites_mes_pesquisado',
        'propostas_associadas_mes_pesquisado',
        'media_taxa_servico_mes_pesquisado',
        'media_propostas_diaria_mes_pesquisado'
    ]);

    // State to control modal visibility
    // const [isModalVisible, setIsModalVisible] = useState(false);

    // Handle column visibility change
    const handleColumnChange = (checkedValues) => {
        setVisibleColumns(checkedValues);
    };

    // Handle modal visibility
    // const showModal = () => {
    //     setIsModalVisible(true);
    // };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // Define table columns
    const allColumns = [
        {
            title: t('analytics.pais'),
            dataIndex: 'pais',
            key: 'pais',
            render: (pais) => (
                pais === "pt" ? (
                    <img
                        src={flagPortugal}
                        alt="Portugal"
                        style={{ marginRight: '8px', width: '35px', height: '22px' }}
                    />
                ) : (
                    <img
                        src={flagSpain}
                        alt="Spain"
                        style={{ marginRight: '8px', width: '35px', height: '22px' }}
                    />
                )
            ),
            // sorter: (a, b) => a.pais.localeCompare(b.pais)
        },
        {
            title: t('analytics.nome'),
            dataIndex: 'nome_utilizador',
            key: 'nome_utilizador',
            render: (text, record) => {

                const foto = record.foto_perfil
                const nome = record.nome_utilizador

                return (
                    <div className='flex items-center gap-2'>
                        <img src={foto} className='w-8 object-cover h-8 rounded-full' alt='User Profile' />
                        <span className='mr-1'>{nome}</span>
                    </div>
                );
            },
            // sorter: (a, b) => a.nome_utilizador.localeCompare(b.nome_utilizador),
        },
        {
            title: `${t('analytics.propostasmesatual')}`,
            key: 'propostas_mes_pesquisado',
            render: (text, record) => {
                const diferencaPropostas = record.propostas_mes_pesquisado;
                const diferencaPropostasNumero = record.diferenca_propostas;

                let color = 'text-white'; // Default color
                let arrow = null;

                const diferencaPropostasNumeroStr = String(diferencaPropostasNumero);

                if (diferencaPropostasNumeroStr.includes('-')) {
                    color = 'text-red-500';
                    arrow = <FaArrowDown />;
                } else {
                    color = 'text-green-500';
                    arrow = <FaArrowUp />;
                }

                return (
                    <div className='flex items-center gap-2'>
                        <span className='mr-1'>{diferencaPropostas}</span>
                    </div>
                );
            },
            sorter: (a, b) => a.propostas_mes_pesquisado - b.propostas_mes_pesquisado
        },
        {
            title: `${t('analytics.propostasmediadiaria')}`,
            key: 'media_propostas_diaria_mes_pesquisado',
            render: (text, record) => {
                const diferencaPropostas = record.media_propostas_diaria_mes_pesquisado;

                return (
                    <div className='flex items-center gap-2'>
                        <span className='mr-1'>{diferencaPropostas}</span>
                    </div>
                );
            },
            sorter: (a, b) => a.media_propostas_diaria_mes_pesquisado - b.media_propostas_diaria_mes_pesquisado
        },
        ...(currentLanguage === "pt" ? [
            {
                title: 'Propostas Aceites',
                dataIndex: 'propostas_aceites_mes_pesquisado',
                key: 'propostas_aceites_mes_pesquisado',
                sorter: (a, b) => a.propostas_aceites_mes_pesquisado - b.propostas_aceites_mes_pesquisado,
            },
            {
                title: 'Propostas Associadas Hubspot',
                key: 'propostas_associadas_mes_pesquisado',
                render: (text, record) => {
                    const diferencaPropostas = record.propostas_associadas_mes_pesquisado;
                    const diferencaPropostasNumero = record.percentagem_propostas_associadas_mes_pesquisado;

                    let color = 'text-white';

                    return (
                        <div className='flex items-center gap-2'>
                            <span className='mr-1'>{diferencaPropostas}</span>
                            <span className={`flex items-center gap-1 ${color}`}>
                                ( {diferencaPropostasNumero}% )
                            </span>
                        </div>
                    );
                },
                sorter: (a, b) => a.propostas_associadas_mes_pesquisado - b.propostas_associadas_mes_pesquisado
            },
            {
                title: 'Propostas Não Associadas Hubspot',
                dataIndex: 'propostas_nao_associadas_mes_pesquisado',
                key: 'propostas_nao_associadas_mes_pesquisado',
                sorter: (a, b) => a.propostas_nao_associadas_mes_pesquisado - b.propostas_nao_associadas_mes_pesquisado,
            }
        ] : []),
        {
            title: t('analytics.mediataxadeservico'),
            dataIndex: 'media_taxa_servico_mes_pesquisado',
            key: 'media_taxa_servico_mes_pesquisado',
            render: (record) => (
                <span>≈ {record} €</span>
            ),
            sorter: (a, b) => a.media_taxa_servico_mes_pesquisado - b.media_taxa_servico_mes_pesquisado
        }
    ];

    // Filter columns based on visible state
    const columns = allColumns.filter(col => visibleColumns.includes(col.key));

console.log(propostas)

    return (
        <>
            <div className={` ${isMobile ? 'grid gap-2' : 'flex gap-2 items-stretch'}`}>

                <div className='border-card bg-black card-cinza'>
                    <p className='text-sm font-medium pb-4'>{t('analytics.totalpropostasgeradas')}</p>
                    <div className='flex gap-2 items-center'>
                        <span className='text-lg font-bold text-center'>
                            {formatarNumero(destaquesPropostas.total_propostas_mes_pesquisado)}
                        </span>
                    </div>
                </div>

                <div className='border-card bg-black card-cinza'>
                    <p className='text-sm font-medium pb-4'>{t('analytics.mediadiaria')}</p>
                    <div className='flex gap-2 items-center'>
                        <span className='text-lg font-bold text-center'>
                            ≈ {formatarNumero(destaquesPropostas.media_propostas_diarias_mes_pesquisado)}
                        </span>
                    </div>
                </div>


                <div className='border-card bg-black card-cinza'>
                    <p className='text-sm font-medium pb-4'>{t('analytics.taxadeservico')}</p>
                    <div className='flex gap-2 items-center'>
                        <span className='text-lg font-bold text-center'>
                            ≈ {formatarNumero(destaquesPropostas.media_taxa_servico_mes_pesquisado)} €
                        </span>
                    </div>
                </div>
                
            </div>


            <Modal
                title="Configurações da Tabela"
                visible={isModalVisible}
                onOk={handleOk}
                footer={[
                    <Button key="ok" type="primary" onClick={handleOk}>
                        Ok
                    </Button>,
                ]}            >
                <Checkbox.Group
                    style={{ width: '100%' }}
                    value={visibleColumns}
                    onChange={handleColumnChange}
                >
                    {allColumns.map(column => (
                        <Checkbox key={column.key} value={column.key}>
                            {column.title}
                        </Checkbox>
                    ))}
                </Checkbox.Group>
            </Modal>

            {/* Tabela */}
            <div className='grid gap-4'>
                <div className="w-full">
                    <div className="tabela-propostas">
                        <Table
                            dataSource={propostas}
                            columns={columns}
                            rowKey={(record) => record.nome_utilizador}
                            pagination={false}
                            scroll={isMobile ? { x: 'max-content' } : { x: false }}
                            bordered
                            style={isMobile ? {} : { width: '100%' }}
                            tableLayout={isMobile ? undefined : 'auto'}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
