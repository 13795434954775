import React, { useState } from 'react';
import { Button, Popover, Select } from 'antd';
import { FiCopy, FiCheck } from 'react-icons/fi';
import { FaWhatsapp } from 'react-icons/fa6';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { MdShare } from 'react-icons/md';


export default function PartilharComCliente({
    tituloDoMobileDaViatura,
    linkPartilhavel,
    dataCarros,
    formatarNumero,
    valorFinalISV,
    nomeDaProposta,
    activeTabKeyResultadoSimulacao,
    precoFinalCarro,
    taxaDeServico,
    valorChaveNaMaoParticular,
    precoFinalDoCarroSemIva,
    valorChaveNaMaoEmpresa,
}) {

    const { t } = useTranslation();
    const [copiedItem, setCopiedItem] = useState(null);


    const handleOptionClick = (value) => {

        console.log(value)

        if (value === 1) {
            let text
            if (activeTabKeyResultadoSimulacao === 1) {
                text = (
                    <div>
                        <div>
                            <a target={"_blank"}
                                style={{ cursor: "pointer" }}
                                href={linkPartilhavel}>{`${nomeDaProposta || dataCarros.titulo}`}</a>
                        </div>
                        <div>
                            {`${t('gerar_proposta.preco_carro')}: ${formatarNumero(precoFinalCarro)}€ (Por Negociar)`}
                        </div>
                        <div>
                            {`${t('main.isv')}: ${calcularISV(valorFinalISV)} €`}
                        </div>
                        <div>
                            {`${t('gerar_proposta.despesas_de_importacao')}: ${formatarNumero(taxaDeServico)}€`}
                        </div>
                        <div>
                            <b>{`${t('gerar_proposta.preco_final_chave_mao')}: ${formatarNumero(valorChaveNaMaoParticular)}`}€</b>
                        </div>
                    </div>
                );
            } else {
                text = (
                    <div>
                        <div>
                            <a target={"_blank"}
                                style={{ cursor: "pointer" }}
                                href={linkPartilhavel}>{`${nomeDaProposta || dataCarros.titulo}`}</a>
                        </div>
                        <div>
                            {`${t('gerar_proposta.preco_carro')}: ${formatarNumero(precoFinalDoCarroSemIva)}€ (Por Negociar)`}
                        </div>
                        <div>
                            {`${t('main.isv')}: ${calcularISV(valorFinalISV)} €`}
                        </div>
                        <div>
                            {`${t('gerar_proposta.despesas_de_importacao')}: ${formatarNumero(taxaDeServico)}€`}
                        </div>
                        <div>
                            <b>{`${t('gerar_proposta.preco_final_chave_mao')}: ${formatarNumero(valorChaveNaMaoEmpresa)}`}€</b>
                        </div>
                    </div>
                )
            }

            const textString = ReactDOMServer.renderToStaticMarkup(text);

            const blob = new Blob([textString], { type: 'text/html' });

            navigator.clipboard.write([new ClipboardItem({ "text/html": blob })])
                .then(() => {

                })
                .catch((error) => {

                });


        } else if (value === 2) {

            let text = (
                <div><a href={linkPartilhavel}>{`${linkPartilhavel}`}</a></div>
            );

            const textString = ReactDOMServer.renderToStaticMarkup(text);

            const blob = new Blob([textString], { type: 'text/html' });

            navigator.clipboard.write([new ClipboardItem({ "text/html": blob })])
                .then(() => {

                })
                .catch((error) => {

                });

        } else if (value === 3) {

            const text = `${linkPartilhavel}`;

            console.log(text);

            const blob = new Blob([text], { type: 'text/plain' });

            navigator.clipboard.write([new ClipboardItem({ "text/plain": blob })])
                .then(() => {
                    // Copied successfully
                })
                .catch((error) => {
                    // Handle errors
                });

        } else if (value === 5) {


            let text
            if (activeTabKeyResultadoSimulacao === "1") {
                text = (
                    <div>
                        <div>
                            <a target={"_blank"}
                                style={{ cursor: "pointer" }}
                                href={linkPartilhavel}>{`${nomeDaProposta || dataCarros.titulo}`}</a>
                        </div>
                        <div>
                            {`${t('gerar_proposta.preco_carro_en')}: ${formatarNumero(precoFinalCarro)}€ (To be negotiated)`}
                        </div>
                        <div>
                            {`${t('main.isv')}: ${calcularISV(valorFinalISV)} €`}
                        </div>
                        <div>
                            {`${t('gerar_proposta.despesas_de_importacao_en')}: ${formatarNumero(taxaDeServico)}€`}
                        </div>
                        <div>
                            <b>{`${t('gerar_proposta.preco_final_chave_mao_en')}: ${formatarNumero(valorChaveNaMaoParticular)}`}€</b>
                        </div>
                    </div>
                );
            } else {
                text = (
                    <div>
                        <div>
                            <a target={"_blank"}
                                style={{ cursor: "pointer" }}
                                href={linkPartilhavel}>{`${nomeDaProposta || dataCarros.titulo}`}</a>
                        </div>
                        <div>
                            {`${t('gerar_proposta.preco_carro_en')}: ${formatarNumero(precoFinalDoCarroSemIva)}€ (To be negotiated)`}
                        </div>
                        <div>
                            {`${t('main.isv')}: ${calcularISV(valorFinalISV)} €`}
                        </div>
                        <div>
                            {`${t('gerar_proposta.despesas_de_importacao_en')}: ${formatarNumero(taxaDeServico)}€`}
                        </div>
                        <div>
                            <b>{`${t('gerar_proposta.preco_final_chave_mao_en')}: ${formatarNumero(valorChaveNaMaoEmpresa)}`}€</b>
                        </div>
                    </div>
                )
            }

            const textString = ReactDOMServer.renderToStaticMarkup(text);

            const blob = new Blob([textString], { type: 'text/html' });

            navigator.clipboard.write([new ClipboardItem({ "text/html": blob })])
                .then(() => {

                })
                .catch((error) => {

                });

        } else {
            window.open(linkPartilhavel, '_blank');
        }
    };


    const handleCopy = () => {
        navigator.clipboard.writeText(linkPartilhavel);
        setCopiedItem(''); // Reset the copied item after copying
        setTimeout(() => {
            setCopiedItem(null); // Clear the copied state after 2 seconds
        }, 2000);
    };


    // const handleShare = () => {
    //     const whatsappUrl = `whatsapp://send?text=${encodeURIComponent(shareMessage)}%0A${encodeURIComponent(linkPartilhavel)}`;
    //     window.open(whatsappUrl, '_blank');
    // };

    //retornar o valor Isento quando o isv for a 0
    function calcularISV(valorFinalISV) {
        if (valorFinalISV === '0€') {
            return 'Isento';
        } else {
            return formatarNumero(Math.ceil(valorFinalISV));
        }
    }


    return (

        <Popover
            content={
                <div className="text-black grid gap-2">
                    {/* WhatsApp */}
                    {/* <div className="flex items-center gap-2">
                        <label className="flex items-center gap-2 cursor-pointer">
                            <FaWhatsapp
                                className="text-2xl text-black"
                                title="WhatsApp"
                                onClick={handleShare}
                            />
                            <span onClick={handleShare}>WhatsApp</span>
                        </label>
                    </div> */}

                    {/* Copiar Texto para Email */}
                    <div className="flex items-center gap-2">
                        <label className="flex items-center gap-2 cursor-pointer">
                            {copiedItem === 1 ? (
                                <FiCheck
                                    className="text-2xl text-black"
                                    title="Copiado!"
                                />
                            ) : (
                                <FiCopy
                                    className="text-2xl text-black"
                                    title={t('gerar_proposta.copiar_texto_para_email')}
                                    onClick={() => {
                                        handleCopy();
                                        setCopiedItem(1); // Set the copied item
                                        handleOptionClick(1);
                                    }}
                                />
                            )}
                            <span
                                onClick={() => {
                                    handleCopy();
                                    setCopiedItem(1); // Set the copied item
                                    handleOptionClick(1);
                                }}
                            >
                                {t('gerar_proposta.copiar_texto_para_email')}
                            </span>
                        </label>
                    </div>

                    {/* Copiar Texto para Email em Inglês */}
                    <div className="flex items-center gap-2">
                        <label className="flex items-center gap-2 cursor-pointer">
                            {copiedItem === 5 ? (
                                <FiCheck
                                    className="text-2xl text-black"
                                    title="Copiado!"
                                />
                            ) : (
                                <FiCopy
                                    className="text-2xl text-black"
                                    title={t('gerar_proposta.copiar_texto_para_email_en')}
                                    onClick={() => {
                                        handleCopy();
                                        setCopiedItem(5); // Set the copied item
                                        handleOptionClick(5);
                                    }}
                                />
                            )}
                            <span
                                onClick={() => {
                                    handleCopy();
                                    setCopiedItem(5); // Set the copied item
                                    handleOptionClick(5);
                                }}
                            >
                                {t('gerar_proposta.copiar_texto_para_email_en')}
                            </span>
                        </label>
                    </div>

                    {/* Copiar URL com Link */}
                    <div className="flex items-center gap-2">
                        <label className="flex items-center gap-2 cursor-pointer">
                            {copiedItem === 2 ? (
                                <FiCheck
                                    className="text-2xl text-black"
                                    title="Copiado!"
                                />
                            ) : (
                                <FiCopy
                                    className="text-2xl text-black"
                                    title={t('gerar_proposta.copiar_url_com_link')}
                                    onClick={() => {
                                        handleCopy();
                                        setCopiedItem(2); // Set the copied item
                                        handleOptionClick(2);
                                    }}
                                />
                            )}
                            <span
                                onClick={() => {
                                    handleCopy();
                                    setCopiedItem(2); // Set the copied item
                                    handleOptionClick(2);
                                }}
                            >
                                {t('gerar_proposta.copiar_url_com_link')}
                            </span>
                        </label>
                    </div>

                    {/* Copiar URL com Link */}
                    <div className="flex items-center gap-2">
                        <label className="flex items-center gap-2 cursor-pointer">
                            {copiedItem === 3 ? (
                                <FiCheck
                                    className="text-2xl text-black"
                                    title="Copiado!"
                                />
                            ) : (
                                <FiCopy
                                    className="text-2xl text-black"
                                    title={t('gerar_proposta.copiar_url')}
                                    onClick={() => {
                                        handleCopy();
                                        setCopiedItem(3); // Set the copied item
                                        handleOptionClick(3);
                                    }}
                                />
                            )}
                            <span
                                onClick={() => {
                                    handleCopy();
                                    setCopiedItem(3); // Set the copied item
                                    handleOptionClick(3);
                                }}
                            >
                                {t('gerar_proposta.copiar_url')}
                            </span>
                        </label>
                    </div>

                </div>
            }
            title="Partilhar com Cliente"
            trigger="click"
        >

            <label className="flex items-center gap-2 cursor-pointer">
                <MdShare
                    className="text-2xl text-white cursor-pointer"
                    title="Partilhar com Cliente"
                />
                <p>Partilhar com Cliente</p>
            </label>

        </Popover>
    );
}
