import React from "react";
import { Link } from 'react-router-dom';
import { BiError } from "react-icons/bi";
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { TbExternalLink } from "react-icons/tb";

export default function FerramentasDeSuporte({ }) {


    return (
        <>
            <div className='card ferramentas-de-apoio'>
                <div className='grid gap-4'>
                    <div className='grid gap-1'>
                        <p className='text-lg font-bold'>Ferramentas de Apoio</p>
                    </div>
                    <hr></hr>
                    <div className="border-card bg-black text-white">
                        <a className="flex items-center justify-between" href="https://www.ilovepdf.com/pdf_to_word" target="_blank"><span>Converter PDF to Word</span> <TbExternalLink size={20} /></a>
                    </div>
                    <div className="border-card bg-black text-white">
                        <a className="flex items-center justify-between" href="https://www.deepl.com/pt-PT/translator" target="_blank"><span>Tradutor</span> <TbExternalLink size={20} /></a>
                    </div>
                </div>
            </div>
        </>
    )
}
