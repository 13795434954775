import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { BiError } from "react-icons/bi";
import { Button, Form, Input, Select, message } from 'antd';

export default function AssinaturaEmail({ }) {


    const [signatureOutput, setSignatureOutput] = useState('');
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewContent, setPreviewContent] = useState('');
    const [country, setCountry] = useState('portugal');

    const onFinish = (values) => {
        const { name, role, phone, email } = values;
        const indicativo = country === 'portugal' ? '+351' : '+34';
        const frase_final = country === 'portugal' ? 'Especialistas em Importação Automóvel' : 'Especialistas en Importación de Automóviles';
        const site = country === 'portugal' ? 'importrust.com' : 'importrust.es';
        const formattedPhone = phone.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
        const fullPhone = `${indicativo} ${formattedPhone}`;
        const fullEmail = `${email}@importrust.com`;

        const signatureTemplate = `
        <table border="0" width="235" cellspacing="0" cellpadding="0" style="font-family:helvetica, arial;">
          <tbody style="border:0px solid !important;">
            <tr style="border:0px solid !important;">
              <td style="vertical-align: top; text-align: left; font-size: 12px; border:0px solid !important;">
                <span style="font-size: 15px; font-weight: 700; border:0px solid;">${name}</span><br>
                ${role}<br><br>
                <span style="font:12px helvetica, arial; border:0px solid;"><a style="color: #000; text-decoration:none !important;" href="mailto:${fullEmail}">${fullEmail}</a></span><br>
                <span style="font:12px helvetica, arial; border:0px solid;"><a style="color: #000; text-decoration:none !important;" href="tel:${fullPhone}" id="telefone">${fullPhone}</a></span><br>
                <span style="font:12px helvetica, arial; border:0px solid;"><a style="color: #000; text-decoration:none !important;" href="https://${site}">${site}</a></span>
              </td>
            </tr>
            <tr style="border:0px solid !important;">
              <td style="border-bottom: solid #000000 1px;" height="11"></td>
            </tr>
            <tr style="border:0px solid !important;">
              <td style="text-align: left; padding-top: 10px; border:0px solid !important;">
                <a href="https://${site}"><img style="border: none;" src="https://backoffice.importrust.com/wp-content/uploads/2024/03/logo-preto.png" alt="Importrust Logo" width="150"></a>
              </td>
            </tr>
            <tr style="border:0px solid !important;">
              <td style="text-align: left; color: #aaaaaa; font-size: 10px; border:0px solid !important;">
                <p style="margin-top:10px; margin-bottom:10px;">${frase_final}</p>
              </td>
            </tr>
          </tbody>
        </table>
      `;

        setSignatureOutput(signatureTemplate);
        setPreviewContent(signatureTemplate);
        setPreviewVisible(true);
    };

    const copySignature = () => {
        navigator.clipboard.writeText(signatureOutput);
        message.success('Assinatura copiada!');
    };

    const downloadSignature = () => {
        const blob = new Blob([signatureOutput], { type: 'text/html' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'assinatura.html';
        link.click();
    };

    return (
        <div className='grid gap-4'>
            <div className='card grid text-white gap-4'>
                <div className='grid gap-1'>
                    <p className='text-lg font-bold'>Gerador de Assinaturas de Email</p>
                </div>
                <hr></hr>
                <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4">
                    <div className="col-span-2">
                        <Form className="text-white" layout="vertical" onFinish={onFinish}>
                            <div>
                                <p className="text-base text-white mb-2">Nome</p>
                                <Form.Item name="name" rules={[{ required: true, message: 'Por favor, insira o nome' }]}>
                                    <Input />
                                </Form.Item>
                            </div>
                            <div>
                                <p className="text-base text-white mb-2">Função</p>
                                <Form.Item name="role" rules={[{ required: true, message: 'Por favor, insira a função' }]}>
                                    <Input />
                                </Form.Item>
                            </div>
                            <div>
                                <p className="text-base text-white mb-2">Contacto</p>
                                <Form.Item name="phone" rules={[{ required: true, message: 'Por favor, insira o número de telemóvel' }]}>
                                    <Input />
                                </Form.Item>
                            </div>
                            <div>
                                <p className="text-base text-white mb-2">Email</p>
                                <Form.Item name="email" rules={[{ required: true, message: 'Por favor, insira o email' }]}>
                                    <Input addonAfter="@importrust.com" />
                                </Form.Item>
                            </div>
                            <div>
                                <p className="text-base text-white mb-2">País</p>
                                <Form.Item name="country">
                                    <Select defaultValue="portugal" onChange={setCountry}>
                                        <Select.Option value="portugal">Portugal</Select.Option>
                                        <Select.Option value="espanha">Espanha</Select.Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            <div>
                                <Form.Item>
                                    <Button className="flex gap-2 items-center bg-white text-black font-bold rounded" type="primary" htmlType="submit">
                                        Gerar Assinatura
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className='max-md:col-span-2'>
                        <p className="text-base text-white mb-2">Estrutura HTML</p>
                        <div className="mb-2">
                            <Input.TextArea value={signatureOutput} rows={10} readOnly />
                        </div>
                        {previewVisible && (
                            <div className="bg-white p-4 rounded text-black">
                                <div dangerouslySetInnerHTML={{ __html: previewContent }} />
                            </div>
                        )}
                        <div className="flex items-center gap-1">
                            <Button className="flex gap-2 items-center bg-white text-black font-bold rounded" type="primary" onClick={copySignature} style={{ marginTop: '10px' }}>
                                Copiar HTML
                            </Button>
                            <Button className="flex gap-2 items-center bg-white text-black font-bold rounded" type="primary" onClick={downloadSignature} style={{ marginLeft: '10px', marginTop: '10px' }}>
                                Download
                            </Button>
                        </div>
                    </div>
                </div>

            </div >
        </div >
    );
}
