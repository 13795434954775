import React, { useState } from "react";
import { Button, Input, Spin } from "antd";
import axios from "axios";
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import CopiarLinkseTextos from "../pt/partilha/select-com-copiesbutton";
import { BiError } from "react-icons/bi";


const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);


export default function ES_PartilharProposta({
    valorFinalCredito,
    currentLanguage,
    idModelo,
    idMarca,
    valorViaturaNova,
    idCidade,
    idMunicipio,
    cilindrada,
    novoOuUsado,
    tipoDeCombustivel,
    emissoesGases,
    dataDeRegisto,
    particulas,
    tipoDeVeiculoParaOutros,
    tipoDeVeiculoParaInsencoes,
    outrosServicos,

    precoFinalCarro,
    valorFinalISV,
    taxaDeServico,

    valorFinalIUC,

    iucCavalosFiscais,
    iucCidade,
    iucMunicipio,
    iucPercentagemDescontoEletrico,
    isvComunidad,
    isvValoracionInicial,
    isvPorcentajeaAplicar,
    isvMinoracion,
    isvBaseImponible,
    isvReduccionBaseImponible,
    isvBaseImponibleFinal,
    isvCuotaTributaria,
    isvTotalaPagar,

    activeTabKeyResultadoSimulacao,

    setPartilhaNomeDaProposta,
    partilhaNomeDaProposta,
    tituloDaPropostaChaveNaMaoComLinkFinalParticular,
    precoFinalDoCarroSemIva,
    valorChaveNaMaoEmpresa,
    valorChaveNaMaoParticular,
    formatarNumero,
    dataCarros,
    setTituloDaPropostaChaveNaMao,
    tituloDaPropostaChaveNaMao,
    urlCarroLink,
    setNomeDaProposta,
    nomeDaProposta,
    saveToken,
    iDTokenAuthenticated,
    erroPedido,
    tabResultadoSimulacao,
}) {


    const { t } = useTranslation();


    //Estados de jogo de sintura
    //#1 Loading
    //#2 Sucesso
    //#3 Alerta


    const [linkPartilhavel, setLinkPartilhavel] = useState('');
    const [validarLinkPartilhavel, setValidarLinkPartilhavel] = useState('');
    const [esconde, setEsconde] = useState(true);
    const [alertaLinkPartilhado, setAlertaLinkPartilhado] = useState('');






    const handlePartilharProposta = () => {

        // console.log('entrou')

        setValidarLinkPartilhavel('1')
        setAlertaLinkPartilhado('')



        const listaDeDadosAtualizados = {


            id_modelo: idModelo,
            id_marca: idMarca,
            valor_viatura_nova: valorViaturaNova,
            id_cidade: idCidade,
            id_municipio: idMunicipio,

            cilindrada: cilindrada,
            novo: novoOuUsado,
            tipo_de_combustivel: tipoDeCombustivel,
            emissoes_numerico: emissoesGases,
            data_registo: dataDeRegisto,
            particulas: particulas,
            tipo_de_veiculo: tipoDeVeiculoParaOutros,
            tipo_de_isencoes: tipoDeVeiculoParaInsencoes,
            outros_servicos: outrosServicos,
            isv: valorFinalISV,
            iuc: valorFinalIUC,

            // iuc_cilindrada: iucCilindrada,
            // iuc_co2: iucCO2,
            // iuc_adicional_co2: iucAdicionalCO2,
            // iuc_coeficiente_ano_matriculo: iucCoeficienteAnoMatriculo,
            // iuc_adicional_gasoleo: iucAdicionalGasoleo,
            iuc_cavalos_fiscais: iucCavalosFiscais,
            iuc_cidade: iucCidade,
            iuc_municipio: iucMunicipio,
            iuc_percentagem_desconto_eletrico: iucPercentagemDescontoEletrico,


            isv_base_imponible: isvBaseImponible,
            isv_base_imponible_final: isvBaseImponibleFinal,
            isv_comunidad: isvComunidad,
            isv_cuota_tributaria: isvCuotaTributaria,
            isv_minoracion: isvMinoracion,
            isv_porcentaje_aplicar: isvPorcentajeaAplicar,
            isv_reduccion_base_imponible: isvReduccionBaseImponible,
            isv_total_pagar: isvTotalaPagar,
            isv_valoracion_inicial: isvValoracionInicial,


            // isv_componente_cilindrada: isvcomponentecilindrada,
            // isv_componente_ambiental: isvcomponenteambiental,
            // isv_reducao_anos_uso_cilindrada: isvReducaoAnosUsoCilindrada,
            // isv_reducao_anos_uso_ambiental: isvReducaoAnosUsoAmbiental,
            // isv_agravamento_particulas: isvAgravamentoParticulas,
            // isv_reducao_anos_uso_particulas: isvReducaoAnosUsoParticulas,
            // isv_taxa_aplicavel_tabela: isvTaxaAplicavelTabela,


            taxadeservico: taxaDeServico,
            precofinal: precoFinalCarro,
            valor_chavenamao_particular: valorChaveNaMaoParticular,
            valor_chavenamao_empresa: valorChaveNaMaoEmpresa,
            empresa: tabResultadoSimulacao,
        };



        const dataCriarLink = {
            nome_proposta: nomeDaProposta,
            array_dados: dataCarros,
            user_id: iDTokenAuthenticated,
            user_token: saveToken,
            array_dados_atualizado: listaDeDadosAtualizados,
        }


        axios.post(`${process.env.REACT_APP_CREATE_PROPOSTA_ES}?lang=${currentLanguage}`, dataCriarLink, {

        })
            .then((response) => {
                // console.log(response)
                setLinkPartilhavel(response.data.link_proposta);
                setAlertaLinkPartilhado(response.data.mensagem)
                if (response.data.sucesso === true) {
                    setValidarLinkPartilhavel('2')
                    setEsconde(false)
                } else {
                    setValidarLinkPartilhavel('3')
                    setEsconde(true)
                }

            })
            .catch((error) => {
                setValidarLinkPartilhavel('3')
                setValidarLinkPartilhavel(false)
                setEsconde(true)
            })
            .finally(() => {
            });



    }

    // const handleclickCopy = () => {
    //     setEsconde(true)
    //     setValidarLinkPartilhavel('')
    // }


    const handleChangeNomeDaProposta = (e) => {
        setNomeDaProposta(e.target.value);
        setPartilhaNomeDaProposta(nomeDaProposta)
        setValidarLinkPartilhavel('')
        setEsconde(true)
    }


    // console.log(linkPartilhavel)
    // function calcularISV(valorFinalISV) {
    //     if (valorFinalISV === '0€') {
    //         return 'Isento';
    //     } else {
    //         return formatarNumero(Math.ceil(valorFinalISV));
    //     }
    // }

    return (
        <div className='card'>
            <div className='titulo-form'>
                <p>{t('partilha_da_proposta.titulo_principal')}</p>
            </div>
            <div className="grid gap-4">
                <div className='flex flex-col gap-4'>
                    <Input
                        placeholder={t('partilha_da_proposta.input')}
                        value={nomeDaProposta}
                        onChange={handleChangeNomeDaProposta}
                    />
                    {validarLinkPartilhavel === "1" ? (
                        <>
                            <div className='flex items-center gap-5 flex-col justify-center text-md p-2.5'>
                                <Spin indicator={antIcon} />
                                <p className='text-white'>{t('partilha_da_proposta.gerar')}</p>
                            </div>
                        </>
                    ) : validarLinkPartilhavel === "2" ? (
                        <>
                            <div className="flex flex-col items-center gap-2 text-md border-card bg-black link-partilha-sec">
                                <p className='text-white'>{alertaLinkPartilhado}</p>
                                <div className="flex items-center flex-row gap-4 w-full">
                                    <CopiarLinkseTextos
                                        valorFinalCredito={valorFinalCredito}
                                        precoFinalCarro={precoFinalCarro}
                                        valorFinalISV={valorFinalISV}
                                        taxaDeServico={taxaDeServico}
                                        valorChaveNaMaoParticular={valorChaveNaMaoParticular}
                                        formatarNumero={formatarNumero}
                                        dataCarros={dataCarros}
                                        urlCarroLink={urlCarroLink}
                                        nomeDaProposta={nomeDaProposta}
                                        linkPartilhavel={linkPartilhavel}
                                        valorChaveNaMaoEmpresa={valorChaveNaMaoEmpresa}
                                        activeTabKeyResultadoSimulacao={activeTabKeyResultadoSimulacao}
                                        precoFinalDoCarroSemIva={precoFinalDoCarroSemIva}
                                    />
                                    {/* {activeTabKeyResultadoSimulacao === "1" ? (
                                        <>
                                            <CopyButton
                                                text={
                                                    <div>
                                                        <div>
                                                            <a target={"_blank"}
                                                                style={{ cursor: "pointer" }}
                                                                href={urlCarroLink}>{`${nomeDaProposta || dataCarros.titulo}`}</a>
                                                        </div>
                                                        <div>
                                                            {`Preço carro: ${formatarNumero(precoFinalCarro)}€ (Por Negociar)`}
                                                        </div>
                                                        <div>
                                                            {`ISV: ${calcularISV(valorFinalISV)} €`}
                                                        </div>
                                                        <div>
                                                            {`Despesas de Importação: ${formatarNumero(taxaDeServico)}€`}
                                                        </div>
                                                        <div>
                                                            <b>{`Preço final Chave-na-mão: ${formatarNumero(valorChaveNaMaoParticular)}`}€</b>
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <CopyButton
                                                text={
                                                    <div>
                                                        <div>
                                                            <a target={"_blank"}
                                                                style={{ cursor: "pointer" }}
                                                                href={urlCarroLink}>{`${nomeDaProposta || dataCarros.titulo}`}</a>
                                                        </div>
                                                        <div>
                                                            {`Preço carro: ${formatarNumero(precoFinalDoCarroSemIva)}€ (Por Negociar)`}
                                                        </div>
                                                        <div>
                                                            {`ISV: ${calcularISV(valorFinalISV)} €`}
                                                        </div>
                                                        <div>
                                                            {`Despesas de Importação: ${formatarNumero(taxaDeServico)}€`}
                                                        </div>
                                                        <div>
                                                            <b>{`Preço final Chave-na-mão: ${formatarNumero(valorChaveNaMaoEmpresa)}`}€</b>
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        </>
                                    )}
                                    <div>
                                        <CopyLinkPartilhado
                                            setEsconde={setEsconde}
                                            setNomeDaProposta={setNomeDaProposta}
                                            setValidarLinkPartilhavel={setValidarLinkPartilhavel}
                                            text={<div><a href={linkPartilhavel}>{`${linkPartilhavel}`}</a></div>}
                                            //text={linkPartilhavel}
                                            className="hover:text-white hover:underline" />
                                    </div>
                                    <div>
                                        <Button type="primary" target="_blank" href={linkPartilhavel} className="no-bg-btn text-white flex items-center gap-2">
                                            Abrir Link <RxExternalLink />
                                        </Button>
                                    </div> */}
                                </div>
                            </div>
                        </>
                    ) : validarLinkPartilhavel === "3" ? (
                        <>
                            <div className="mensagem-de-erro flex flex-row items-center gap-4 rounded p-2">
                                <BiError />
                                <span>{alertaLinkPartilhado}</span >
                            </div>
                        </>
                    ) : (
                        <>
                        </>
                    )}
                </div>
                <div className="flex gap-4 justify-center">
                    {esconde ? (
                        <Button type="primary" htmlType="submit" to="/" className="bg-white text-black font-bold rounded w-full"
                            onClick={handlePartilharProposta}>
                            {t('partilha_da_proposta.criar')}
                        </Button>
                    ) : null}
                </div>
            </div>
        </div>
    );
}
