import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
// import { FaArrowDown, FaArrowUp } from 'react-icons/fa6';
import { Button, Segmented, Select, Spin, DatePicker } from 'antd';
import 'swiper/css';
import 'swiper/css/navigation';
import TabelaAnalytics from '../components/pt/tabela/tabelaAnalytics';
import TabelaAutomacoes from '../components/pt/tabela/tabelaAutomacoes';
import ChartMarcas from '../components/charts/chartsMarcas';
import ChartModelos from '../components/charts/chartsModelo';
import ChartCombustivel from '../components/charts/chartsCombustivel';
import { ConsoleSqlOutlined, LoadingOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import logo from '../assets/hubspot-icon.svg';
import { FaCheckCircle, FaEnvelope, FaEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { GoAlertFill } from "react-icons/go";
import { HiRefresh } from 'react-icons/hi';

const { Option } = Select;
const { RangePicker } = DatePicker;

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);

const meses = [
    { value: 'personalizado', label: 'Personalizado' },
    { value: '01', label: 'Janeiro' },
    { value: '02', label: 'Fevereiro' },
    { value: '03', label: 'Março' },
    { value: '04', label: 'Abril' },
    { value: '05', label: 'Maio' },
    { value: '06', label: 'Junho' },
    { value: '07', label: 'Julho' },
    { value: '08', label: 'Agosto' },
    { value: '09', label: 'Setembro' },
    { value: '10', label: 'Outubro' },
    { value: '11', label: 'Novembro' },
    { value: '12', label: 'Dezembro' },
    { value: 'all', label: 'Sempre' }
];


export default function DashboardTeste({ currentLanguage, saveToken, iDTokenAuthenticated, userData, formatarNumero }) {

    const { t } = useTranslation();

    //Responsive
    const isMobile = useMediaQuery({
        query: '(max-width: 1023px)'
    })



    // Data
    const currentMonth = new Date().getMonth() + 1;
    const currentMonthValue = currentMonth.toString().padStart(2, '0');
    const currentMonthObj = meses.find(mes => mes.value === currentMonthValue);
    const [mesAtual, setMesAtual] = useState(currentMonthObj.label);
    const [selectedMes, setSelectedMes] = useState(currentMonthObj);
    const [selectedCountry, setSelectedCountry] = useState('all');

    //Obter dia de hoje
    const currentDate = new Date();
    const year = currentDate.getFullYear(); // Ano com 4 dígitos
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Mês (1-12) com dois dígitos
    const day = String(currentDate.getDate()).padStart(2, '0'); // Dia (1-31) com dois dígitos

    const dataDeHoje = `${year}-${month}-${day}`; // Formato YYYY-MM-DD



    const [propostas, setPropostas] = useState(''); //tabela das propostas
    const [automacoes, setAutomacoes] = useState(''); //tabela das automacoes


    const [utilizadores, setUtilizadores] = useState([]);
    const [selectedUtilizadores, setSelectedUtilizadores] = useState([]);


    const [marcas, setMarcas] = useState(''); //chart dos marcas
    const [modelos, setModelos] = useState(''); //chart dos modelos
    const [combustiveis, setCombustiveis] = useState(''); //chart dos combustiveis

    const [destaques, setDestaques] = useState(''); //numeros destaques
    const [destaquesPropostas, setDestaquesPropostas] = useState(''); //numeros destaques da tabela das propostas


    //configs componente
    const [selectedOption, setSelectedOption] = useState('Propostas');
    const [loading, setLoading] = useState('');
    const [loadingTabela, setLoadingTabela] = useState(false);

    const [filtersSelected, setFiltersSelected] = useState([]);
    const [dateRange, setDateRange] = useState([null, null]);

    const [isModalVisible, setIsModalVisible] = useState(false);

    //logs emails
    const [analyticsListaDeEmails, setAnalyticsListaDeEmails] = useState('');


    useEffect(() => {
        // console.log(dateRange)
        if (
            selectedMes.value === 'personalizado' &&
            Array.isArray(dateRange) &&
            dateRange.length === 2 &&
            dateRange[0] === null &&
            dateRange[1] === null
        ) {
            return;
        }

        setLoadingTabela(true);

        const fetchData = async () => {
            const filters = filtersSelected.id_criou?.join(',') || '';

            let url = `${process.env.REACT_APP_GET_ANALYTICS_TABELA}?token=${saveToken}&user_id=${iDTokenAuthenticated}&lang=${currentLanguage}&mes=${selectedMes.value}&pais=${selectedCountry}`;

            try {
                let { dataCriacaoInicio, dataCriacaoFim } = filtersSelected;

                if (filters) url += `&id_criou=${filters}`;
                if (dataCriacaoInicio) url += `&data_inicio=${dataCriacaoInicio}`;
                if (dataCriacaoFim) url += `&data_fim=${dataCriacaoFim}`;

                const response = await axios.get(url);

                setPropostas(response.data.data.user_data);
                setAutomacoes(response.data.data);
                setDestaques(response.data.data.destaques);
                setDestaquesPropostas(response.data.data);
                setMarcas(response.data.data.marcas_mes_pesquisado);
                setModelos(response.data.data.modelos_mes_pesquisado);
                setCombustiveis(response.data.data.combustiveis_mes_pesquisado);
                setLoading(false);
                setLoadingTabela(false);

            } catch (error) {
                console.error('Error occurred:', error);
            }
        };

        fetchData();

    }, [filtersSelected, selectedMes, selectedCountry]);




    useEffect(() => {
        const url = `${process.env.REACT_APP_OBTER_UTILIZADORES}?token=${saveToken}&user_id=${iDTokenAuthenticated}&bussiness_developer=1`;

        axios.get(url)
            .then((response) => {
                setUtilizadores(response.data.mensagem);
            })
            .catch((error) => {
                console.error('Error occurred:', error);
            });
    }, []);


    useEffect(() => {

        const obterEmailsFalhados = {
            data_inicio: dataDeHoje,
            data_fim: dataDeHoje,
            nome_evento: 'Falha',
            user_id: iDTokenAuthenticated,
            user_token: saveToken,
            dashboard: '1'
        };


        axios.get(`${process.env.REACT_APP_OBTER_LOGS_EMAILS}`, { params: obterEmailsFalhados })
            .then((response) => {
                console.log(response)
                setAnalyticsListaDeEmails(response.data.count);
            })
            .catch((error) => {
                console.error('Error occurred:', error);
            });
    }, []);



    const handleSegmentedChange = (value) => {
        setSelectedOption(value);
    };




    const handleReset = () => {
        setDateRange([null, null]);
        setFiltersSelected({});
        setSelectedCountry('all')
        setSelectedMes(currentMonthObj)
    };

    const handleButtonClick = (filterType, value) => {
        setFiltersSelected(prev => ({ ...prev, [filterType]: value }));
    };

    const handleDateChange = (dates, dateStrings) => {
        setDateRange(dates);
        setFiltersSelected(prev => ({
            ...prev,
            dataCriacaoInicio: dateStrings[0] || null,
            dataCriacaoFim: dateStrings[1] || null,
        }));
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    console.log(analyticsListaDeEmails)

    return (
        <>

            {loading ? (
                <>
                    <div className='grid gap-4'>
                        <div className={`grid gap-4 ${isMobile ? 'grid-cols-1' : 'grid-cols-3'}`}>
                            <div className="bg-main animate-pulse p-4 rounded-lg shadow-lg">
                                <div className="h-24"></div>
                            </div>
                            <div className="bg-main animate-pulse p-4 rounded-lg shadow-lg">
                                <div className="h-24"></div>
                            </div>
                            <div className="bg-main animate-pulse p-4 rounded-lg shadow-lg">
                                <div className="h-24"></div>
                            </div>
                        </div>
                        <div className="bg-main animate-pulse p-4 rounded-lg shadow-lg">
                            <div className="h-96"></div>
                        </div>
                    </div>
                </>
            ) : (
                <>

                    <div className='grid gap-4'>
                        <div className={`grid gap-4 ${isMobile ? 'grid-cols-1' : 'grid-cols-3'}`}>

                            <div className='card'>
                                <div className="flex w-full flex-row items-center justify-between space-y-0 pb-4">
                                    <p className='text-sm font-medium'>{t('dashboard.propostas_criadas_hoje')}</p>
                                    <HiRefresh size={20} />
                                </div>
                                <div className='flex gap-2 items-center'>
                                    <span className='text-lg font-bold text-center'>

                                        {destaques !== "" ? (
                                            <>
                                                {formatarNumero(destaques?.propostas_geradas_hoje)}
                                            </>

                                        ) : (
                                            <div className="inline-flex items-center text-lg">
                                                <span className="animate-pulse text-sm mx-1">{t('analytics.loading')}</span>
                                                <span className="animate-pulse text-sm mx-1">.</span>
                                                <span className="animate-pulse text-sm mx-1" style={{ animationDelay: '0.2s' }}>.</span>
                                                <span className="animate-pulse text-sm mx-1" style={{ animationDelay: '0.4s' }}>.</span>
                                            </div>
                                        )}

                                    </span>
                                </div>
                            </div>

                            {currentLanguage === "pt" && (
                                <div className='card'>
                                    <div className="flex w-full flex-row items-center justify-between space-y-0 pb-4">
                                        <p className='text-sm font-medium'>Propostas aceites hoje</p>
                                        <FaCheckCircle size={20} />
                                    </div>
                                    <div className='flex gap-2 items-center'>
                                        <span className='text-lg font-bold text-center'>

                                            {destaques !== "" ? (
                                                <>
                                                    {formatarNumero(destaques?.propostas_aceites_hoje)}
                                                </>

                                            ) : (
                                                <div className="inline-flex items-center text-lg">
                                                    <span className="animate-pulse text-sm mx-1">A carregar</span>
                                                    <span className="animate-pulse text-sm mx-1">.</span>
                                                    <span className="animate-pulse text-sm mx-1" style={{ animationDelay: '0.2s' }}>.</span>
                                                    <span className="animate-pulse text-sm mx-1" style={{ animationDelay: '0.4s' }}>.</span>
                                                </div>
                                            )}

                                        </span>
                                    </div>
                                </div>
                            )}



                            {currentLanguage === "pt" && (
                                <>
                                    <div className="card-hubspot card-hover h-full md:...">
                                        <div>
                                            <div className='flex items-center gap-4 text-white'>
                                                <div className="flex w-full flex-row items-center justify-between space-y-0 pb-4">
                                                    <p className="text-sm font-medium">Propostas associadas hoje</p>
                                                    <img
                                                        src={logo}
                                                        alt="Logo"
                                                        className="h-4 object-contain"
                                                    />
                                                </div>
                                            </div>
                                            <div className='flex gap-2 justify-between'>
                                                <span className='text-lg font-bold text-center'>
                                                    {destaques !== "" ? (
                                                        <>
                                                            {formatarNumero(destaques?.propostas_associadas_hoje)}
                                                            <span className='font-normal'> ({destaques?.percentagem_propostas_associadas_hoje}%)</span>
                                                        </>

                                                    ) : (
                                                        <div className="inline-flex items-center text-lg">
                                                            <span className="animate-pulse text-sm mx-1">A carregar</span>
                                                            <span className="animate-pulse text-sm mx-1">.</span>
                                                            <span className="animate-pulse text-sm mx-1" style={{ animationDelay: '0.2s' }}>.</span>
                                                            <span className="animate-pulse text-sm mx-1" style={{ animationDelay: '0.4s' }}>.</span>
                                                        </div>

                                                    )}
                                                </span>


                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>

                        {userData.roles[0] === "administrator" && (
                            <div className='grid gap-4 grid-cols-3'>
                                <div className={`${analyticsListaDeEmails > 0 ? 'card-email-falhado' : 'card-email-falhado-zero'}`}>
                                    <div className="flex w-full flex-row items-center justify-between space-y-0 pb-4">
                                        <p className='text-sm font-medium'>Emails falhados hoje</p>
                                        {analyticsListaDeEmails > 0 ? (
                                            <GoAlertFill size={20} />
                                        ) : (
                                            <FaEnvelope size={20} />
                                        )}
                                    </div>
                                    <div className='flex gap-2 items-center'>
                                        <span className='text-lg font-bold text-center'>
                                            {analyticsListaDeEmails > 0? (
                                                <>
                                                    {formatarNumero(analyticsListaDeEmails)}
                                                </>

                                            ) : (
                                                <div className="inline-flex items-center text-lg">
                                                    <span className="animate-pulse text-sm mx-1">A carregar</span>
                                                    <span className="animate-pulse text-sm mx-1">.</span>
                                                    <span className="animate-pulse text-sm mx-1" style={{ animationDelay: '0.2s' }}>.</span>
                                                    <span className="animate-pulse text-sm mx-1" style={{ animationDelay: '0.4s' }}>.</span>
                                                </div>
                                            )}

                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}


                        <div className='card grid gap-4'>
                            {userData.roles[0] === "administrator" && (
                                <div className='flex'>
                                    <Segmented
                                        options={['Propostas', 'Automações']}
                                        onChange={handleSegmentedChange}
                                    />
                                </div>
                            )}


                            <div className='flex gap-2 items-center justify-between'>

                                <div className='flex gap-2 items-center'>
                                    <span>{t('analytics.filtros')}</span>
                                    {Object.keys(filtersSelected).length > 0 && (
                                        <>
                                            <Button
                                                type="primary"
                                                className='shadow-none font-bold text-black bg-white'
                                                onClick={handleReset}
                                            >
                                                Limpar Filtros
                                            </Button>
                                            <div>|</div>
                                        </>
                                    )}
                                    <div className="flex flex-wrap gap-2">
                                        <Select
                                            placeholder={'Mês'}
                                            value={filtersSelected.mes?.value || undefined}
                                            style={{ width: 170 }}
                                            onChange={(value) => {
                                                const selectedMonth = meses.find(mes => mes.value === value);
                                                handleButtonClick('mes', selectedMonth);
                                                setSelectedMes(selectedMonth);
                                            }}
                                            options={meses.map(mes => ({ value: mes.value, label: mes.label }))}
                                        />

                                        {selectedMes.value === 'personalizado' && (
                                            <RangePicker
                                                value={dateRange}
                                                onChange={handleDateChange}
                                                placeholder={['Data de início', 'Data de fim']}
                                            />
                                        )}

                                        {userData.data.cargo_select !== "business_developer" && (
                                            <>
                                                <Select
                                                    mode="multiple"
                                                    placeholder={'Business Developer'}
                                                    className='select-filters capitalize'
                                                    onChange={(value) => handleButtonClick('id_criou', value)}
                                                    value={filtersSelected.id_criou || []}
                                                    style={{ maxWidth: 'max-content', minWidth: '200px' }}
                                                >
                                                    {utilizadores.map(utilizador => (
                                                        <Select.Option key={utilizador.id_user} value={utilizador.id_user}>
                                                            {utilizador.nome_criou}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </>
                                        )}

                                        {userData.roles[0] === "administrator" && (
                                            <>
                                                <Select
                                                    placeholder={'País'}
                                                    className='select-filters capitalize'
                                                    // onChange={(value) => handleButtonClick('paisPropostas', value)}
                                                    onChange={(value) => {
                                                        // const selectedMonth = meses.find(mes => mes.value === value);
                                                        handleButtonClick('paisPropostas', selectedCountry);
                                                        setSelectedCountry(value);
                                                    }}
                                                    value={selectedCountry}
                                                    style={{ width: '150px' }}
                                                >
                                                    <Select.Option key="TODOS" value="all" className="capitalize">País</Select.Option>
                                                    <Select.Option key="PT" value="pt" className="capitalize">Portugal</Select.Option>
                                                    <Select.Option key="ES" value="es" className="capitalize">Espanha</Select.Option>
                                                </Select>

                                            </>
                                        )}

                                    </div>
                                </div>
                                {userData.roles[0] === "administrator" && (
                                    <>
                                        <div className='tabela-analytics'>
                                            <Link onClick={showModal} className='flex justify-end items-center gap-2'>
                                                <FaEye size={20} style={{ cursor: 'pointer' }} />
                                                Colunas
                                            </Link>
                                        </div>
                                    </>
                                )}
                            </div>

                            {loadingTabela ? (
                                <>
                                    <div className="flex flex-col gap-4 justify-center">
                                        <Spin indicator={antIcon} />
                                        <p className='text-white text-center'>{t('media_mercado.loading')}</p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {selectedOption === 'Propostas' && (
                                        <>
                                            <TabelaAnalytics
                                                propostas={propostas}
                                                destaquesPropostas={destaquesPropostas}
                                                formatarNumero={formatarNumero}
                                                selectedMes={selectedMes}
                                                mesAtual={mesAtual}
                                                currentLanguage={currentLanguage}
                                                setIsModalVisible={setIsModalVisible}
                                                isModalVisible={isModalVisible}
                                            />

                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">

                                                {/* {!marcas.length === 0 && ( */}
                                                <div className='border-card bg-black card-cinza'>
                                                    <div className='flex items-center justify-center' style={{ marginTop: '-40px' }}>
                                                        <ChartMarcas
                                                            formatarNumero={formatarNumero}
                                                            marcas={marcas}
                                                        />
                                                    </div>
                                                    <div className="grid grid-cols-1 md:grid-cols-1 justify-items-center" style={{ marginTop: '-50px' }}>
                                                        <p className="flex items-center gap-2 justify-center">
                                                            <span className="text-sm">{t('analytics.totalmarcas')}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* )} */}

                                                {/* {!modelos.length === 0 && ( */}
                                                <div className='border-card bg-black card-cinza'>
                                                    <div className='flex items-center justify-center' style={{ marginTop: '-40px' }}>
                                                        <ChartModelos
                                                            formatarNumero={formatarNumero}
                                                            modelos={modelos}
                                                        />
                                                    </div>
                                                    <div className="grid grid-cols-1 md:grid-cols-1 justify-items-center" style={{ marginTop: '-50px' }}>
                                                        <p className="flex items-center gap-2 justify-center">
                                                            <span className="text-sm">{t('analytics.totalmodelos')}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* )} */}

                                                {/* {!combustiveis.length === 0 && ( */}
                                                <div className='border-card bg-black card-cinza'>
                                                    <div className='flex items-center justify-center' style={{ marginTop: '-40px' }}>
                                                        <ChartCombustivel
                                                            formatarNumero={formatarNumero}
                                                            combustiveis={combustiveis}
                                                        />
                                                    </div>
                                                    <div className="grid grid-cols-1 md:grid-cols-1 justify-items-center" style={{ marginTop: '-50px' }}>
                                                        <p className="flex items-center gap-2 justify-center">
                                                            <span className="text-sm">{t('analytics.totalcombustiveis')}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* )} */}

                                            </div>

                                        </>

                                    )}

                                    {selectedOption === 'Automações' && (
                                        <TabelaAutomacoes
                                            automacoes={automacoes}
                                            selectedMes={selectedMes}
                                            formatarNumero={formatarNumero}
                                            selectedCountry={selectedCountry}
                                            filtersSelected={filtersSelected}
                                            saveToken={saveToken}
                                            iDTokenAuthenticated={iDTokenAuthenticated}
                                            currentLanguage={currentLanguage}
                                        />
                                    )}
                                </>
                            )}


                        </div>

                    </div>
                </>
            )}
        </>
    )
}

