import { Input, Select } from "antd";
import React, { useState } from "react";
import { DatePicker } from 'antd';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const { Option } = Select;

export default function CompProcuracaoEmpresa({
    dataDoDocumento,
    setDataDoDocumento,
    nomeSocioEmpresa,
    setNomeSocioEmpresa,
    numeroCCEmpresa,
    setNumeroCCEmpresa,
    validadeCCEmpresa,
    setValidadeCCEmpresa,
    paisEmissorEmpresa,
    setPaisEmissorEmpresa,
    nomeEmpresa,
    setNomeEmpresa,
    sedeEmpresa,
    setSedeEmpresa,
    conservatoriaEmpresa,
    setConservatoriaEmpresa,
    nifEmpresa,
    setNifEmpresa,
    idDoNegocio,
    setIDdoNegocio,
    capitalSocialEmpresa,
    setCapitalSocialEmpresa,
    alertaDataDocumento,
    alertaDataValidadeCC,
    setDataSociosQuatro,
    tipo
}) {


    const [numSocios, setnumSocios] = useState('0');
    const [inputs, setInputs] = useState([]);



    const handleDataChange = (e) => {
        let day = "";
        let month = "";
        let year = "";

        if (e && e instanceof Object && e.hasOwnProperty('$y') && e.hasOwnProperty('$M') && e.hasOwnProperty('$D')) {
            // Extract day, month, and year
            day = e.$D.toString().padStart(2, '0');
            month = (e.$M + 1).toString().padStart(2, '0'); // Month is zero-based
            year = e.$y.toString();
        }

        setDataDoDocumento(day + '/' + month + '/' + year);

        // console.log('dataDoDocumento', dataDoDocumento);
    };


    // const formatDate = (dateObject) => {
    //     console.log(dateObject)
    //     // Extrair os componentes de data do objeto
    //     const day = dateObject.$D;
    //     const month = dateObject.$M + 1; // Adicionar 1 ao mês, pois os meses começam de 0
    //     const year = dateObject.$y;

    //     // Formatar a data no formato dd/mm/aaaa
    //     const formattedDate = `${day}/${month}/${year}`;
    //     console.log(formattedDate)
    //     return formattedDate;
    // };

    const dayjsDate = (date) => {
        // console.log('date',date)
        if (date && date !== '') {
            return dayjs(date, 'DD/MM/YYYY')
        }
    }





    const handleNomeSocioChange = (e) => {
        setNomeSocioEmpresa(e.target.value);
    };

    const handleNumeroCCChange = (e) => {
        setNumeroCCEmpresa(e.target.value);
    };

    const handleValidadeCCChange = (e) => {
        // setValidadeCCEmpresa(e.target.value);
        let day = "";
        let month = "";
        let year = "";

        if (e && e instanceof Object && e.hasOwnProperty('$y') && e.hasOwnProperty('$M') && e.hasOwnProperty('$D')) {
            // Extract day, month, and year
            day = e.$D.toString().padStart(2, '0');
            month = (e.$M + 1).toString().padStart(2, '0'); // Month is zero-based
            year = e.$y.toString();
        }

        setValidadeCCEmpresa(day + '/' + month + '/' + year);

    };

    const handlePaisEmissorChange = (e) => {
        setPaisEmissorEmpresa(e.target.value);
    };

    const handleNomeEmpresaChange = (e) => {
        setNomeEmpresa(e.target.value);
    };

    const handleSedeEmpresaChange = (e) => {
        setSedeEmpresa(e.target.value);
    };

    const handleConservatoriaEmpresaChange = (e) => {
        setConservatoriaEmpresa(e.target.value);
    };

    const handleNifEmpresaChange = (e) => {
        setNifEmpresa(e.target.value);
    };

    const handleCapitalSocialEmpresaChange = (e) => {
        setCapitalSocialEmpresa(e.target.value);
    };


    // const handleSubmit = () => {
    //     console.log('enviou')
    // }

    // const datePickerStyle = {
    //     width: '100%',
    //     borderColor: dataDoDocumento ? '#d9d9d9' : 'red', // Change border color based on validity
    // };


    // const handleAdicionarMaisSocios = (value) => {
    //     setnumSocios(value);
    //     const numInputs = parseInt(value);
    //     const newInputs = [];
    //     for (let i = 0; i < numInputs; i++) {
    //         newInputs.push(<input key={i} />);
    //     }
    //     setInputs(newInputs - 1);
    //     // Se o novo valor for maior que 0, remova 1 input
    //     if (numInputs > 0) {
    //         const newInputsLength = newInputs.length;
    //         if (newInputsLength > 0) {
    //             const inputsToRemove = newInputs.slice(0, newInputsLength - 1);
    //             setInputs(inputsToRemove);
    //         }
    //     }
    // };

    const handleAdicionarMaisSocios = (value) => {
        setnumSocios(value);
        const numInputs = parseInt(value);
        const currentInputs = [...inputs]; // Copia os inputs existentes
        if (currentInputs.length > numInputs) {
            const updatedInputs = currentInputs.slice(0, numInputs); // Remove inputs extras se houverem
            setInputs(updatedInputs);
            setDataSociosQuatro(updatedInputs)
        } else {
            const newInputs = [];
            for (let i = currentInputs.length; i < numInputs; i++) {
                if (tipo === "novo_negocio") {
                    newInputs.push({ nome_gerente: "", numero_cc: "", pais_emissor_cc: "", validade_cc: "", nif_gerente: "" });
                } else {
                    newInputs.push({ nome_gerente: "", numero_cc: "", pais_emissor_cc: "", validade_cc: "" });
                }
            }
            const updatedInputs = [...currentInputs, ...newInputs]; // Adiciona novos inputs aos existentes
            setInputs(updatedInputs);
            setDataSociosQuatro(updatedInputs)
        }
    };


    const handleNomeGerenteChange = (index, value) => {
        const newInputs = [...inputs];
        newInputs[index].nome_gerente = value;
        setInputs(newInputs);
        // console.log(inputs)
        setDataSociosQuatro(newInputs)
    };

    const handleEntidadeEmissoraChange = (index, value) => {
        const newInputs = [...inputs];
        newInputs[index].pais_emissor_cc = value;
        setInputs(newInputs);
        setDataSociosQuatro(inputs)
    };

    const handleNumCCSocioChange = (index, value) => {
        const newInputs = [...inputs];
        newInputs[index].numero_cc = value;
        setInputs(newInputs);
        setDataSociosQuatro(inputs)
    };

    // const handleValidadeCCSocioChange = (index, e) => {
    //     const newInputs = [...inputs];
    //     newInputs[index].validade_cc = e;
    //     setInputs(newInputs);
    //     setDataSociosQuatro(inputs)

    //     // setValidadeCCEmpresa(e.target.value);
    //     let day = "";
    //     let month = "";
    //     let year = "";

    //     if (e && e instanceof Object && e.hasOwnProperty('$y') && e.hasOwnProperty('$M') && e.hasOwnProperty('$D')) {
    //         // Extract day, month, and year
    //         day = e.$D.toString().padStart(2, '0');
    //         month = (e.$M + 1).toString().padStart(2, '0'); // Month is zero-based
    //         year = e.$y.toString();
    //     }

    //     setValidadeCCEmpresa(day + '/' + month + '/' + year);
    // };


    // const handleValidadeCCSocioChange = (index, e) => {
    //     const newInputs = [...inputs];
    //     const { day, month, year } = e;
    //     newInputs[index].validade_cc = { $D: day, $M: month - 1, $y: year }; // Update the validade_cc property
    //     setInputs(newInputs);
    //     setDataSociosQuatro(newInputs);
    // };

    const handleValidadeCCSocioChange = (index, date) => {
        const newInputs = [...inputs];
        const formattedDate = `${('0' + date.$d.getDate()).slice(-2)}/${('0' + (date.$d.getMonth() + 1)).slice(-2)}/${date.$d.getFullYear()}`;
        // console.log('datedatedate', formattedDate);
        newInputs[index].validade_cc = formattedDate; // Update the validity date
        setInputs(newInputs);
        setDataSociosQuatro(newInputs);
    };



    // console.log(inputs)

    const handleNifSocioChange = (index, value) => {
        const newInputs = [...inputs];
        newInputs[index].nif_gerente = value;
        setInputs(newInputs);
        setDataSociosQuatro(inputs)
    };

    const handleIDdoNegocioChange = (e) => {
        setIDdoNegocio(e.target.value);
    };


    return (
        <>

            <div className="grid grid-cols-2 gap-2">
                <div className='grid gap-2'>
                    <p>ID do negócio</p>
                    <Input
                        placeholder="ID do negócio"
                        value={idDoNegocio}
                        onChange={handleIDdoNegocioChange}
                        type="text"
                        required
                    />
                </div>
                <div className='grid gap-2'>
                    <p>Data do Documento</p>
                    <DatePicker placeholder="dd/mm/aaaa"
                        defaultValue={dayjsDate(dataDoDocumento)}
                        value={dayjsDate(dataDoDocumento)}
                        format={'DD/MM/YYYY'}
                        // picker="month"
                        onChange={handleDataChange}
                        required
                    />
                    {alertaDataDocumento && <small className="text-red-500">A data do documento é obrigatória</small>}
                </div>
            </div>

            <p className="font-semibold">Detalhes do Cliente:</p>

            <div className="grid grid-cols-2 gap-2">

                <div className='grid gap-2'>
                    <p>Nome do Sócio gerente</p>
                    <Input
                        placeholder="Nome do Sócio gerente"
                        value={nomeSocioEmpresa}
                        onChange={handleNomeSocioChange}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>Nº Cartão de Cidadão</p>
                    <Input
                        placeholder="Nº Cartão de Cidadão"
                        value={numeroCCEmpresa}
                        onChange={handleNumeroCCChange}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>Validade do Cartão de Cidadão</p>
                    {/* <Input
                    placeholder="Validade do Cartão de Cidadão"
                    value={validadeCCEmpresa}
                    onChange={handleValidadeCCChange}
                    type="text"
                    required
                /> */}

                    <DatePicker placeholder="dd/mm/aaaa"
                        defaultValue={dayjsDate(validadeCCEmpresa)}
                        format={'DD/MM/YYYY'}
                        // picker="month"
                        onChange={handleValidadeCCChange}
                        required
                    />
                    {alertaDataValidadeCC && <small className="text-red-500">A Validade do Cartão de Cidadão é obrigatória</small>}

                </div>

                <div className='grid gap-2'>
                    <p>Entidade emissora</p>
                    <Input
                        placeholder="Entidade emissora"
                        value={paisEmissorEmpresa}
                        onChange={handlePaisEmissorChange}
                        type="text"
                        required
                    />
                </div>
            </div>

            <p className="font-semibold">Detalhes da Empresa:</p>

            <div className="grid grid-cols-2 gap-2">


                <div className='grid gap-2'>
                    <p>Nome da Empresa</p>
                    <Input
                        placeholder="Nome da Empresa"
                        value={nomeEmpresa}
                        onChange={handleNomeEmpresaChange}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>Sede da Empresa</p>
                    <Input
                        placeholder="Sede da Empresa"
                        value={sedeEmpresa}
                        onChange={handleSedeEmpresaChange}
                        type="text"
                        required
                    />
                </div>


                <div className='grid gap-2'>
                    <p>Conservatória</p>
                    <Input
                        placeholder="Conservatória"
                        value={conservatoriaEmpresa}
                        onChange={handleConservatoriaEmpresaChange}
                        type="text"
                        required
                    />
                </div>


                <div className='grid gap-2'>
                    <p>NIF da Empresa</p>
                    <Input
                        placeholder="NIF da Empresa"
                        value={nifEmpresa}
                        onChange={handleNifEmpresaChange}
                        type="text"
                        required
                    />
                </div>


                <div className='grid gap-2'>
                    <p>Capital Social</p>
                    <Input
                        placeholder="Capital Social"
                        value={capitalSocialEmpresa}
                        onChange={handleCapitalSocialEmpresaChange}
                        type="text"
                        required
                    />
                </div>


            </div>
            {/* 
            <div className="grid grid-col-2 gap-2">
                <p>Número de Sócios</p>
                <Select
                    placeholder="Selecione o numSocios"
                    value={numSocios ? numSocios : undefined}
                    onChange={handleAdicionarMaisSocios}
                >
                    <Option value="0">0</Option>
                    <Option value="2">1</Option>
                    <Option value="3">2</Option>
                    <Option value="4">3</Option>
                    <Option value="5">4</Option>
                </Select>
                <div className="grid grid-cols-1 gap-2">
                    {inputs.map((input, index) => (
                        <div className="grid grid-cols-2 gap-2">
                            <div className="grid gap-2" key={index}>
                                <p>Nome do Sócio gerente {index}</p>
                                <Input
                                    placeholder="Nome do Sócio gerente"
                                    value={capitalSocialEmpresa}
                                    onChange={handleCapitalSocialEmpresaChange}
                                    type="text"
                                    required
                                />
                            </div>
                            <div className="grid gap-2">
                                <p>NIF do Sócio gerente {index}</p>
                                <Input
                                    placeholder="NIF do Sócio gerente"
                                    value={capitalSocialEmpresa}
                                    onChange={handleCapitalSocialEmpresaChange}
                                    type="text"
                                    required
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div> */}
            <div className="grid grid-col-2 gap-2">
                <p>Número de Sócios</p>
                <span className="text-sm">Preencha apenas os sócios para além do gerente já preenchido acima.</span>
                <Select
                    placeholder="Selecione o numSocios"
                    value={numSocios}
                    onChange={handleAdicionarMaisSocios}
                >
                    <Option value="0">0</Option>
                    <Option value="1">+1</Option>
                    <Option value="2">+2</Option>
                    <Option value="3">+3</Option>
                    <Option value="4">+4</Option>
                    <Option value="5">+5</Option>
                </Select>
                <div className="grid grid-cols-1 gap-2">
                    {inputs.map((input, index) => (
                        <div className="grid grid-cols-2 gap-2 separador-socios" key={index}>
                            <div className="grid gap-2">
                                <p>Nome do Sócio gerente {index + 1}</p>
                                <Input
                                    placeholder="Nome do Sócio gerente"
                                    value={input.nome}
                                    onChange={(e) => handleNomeGerenteChange(index, e.target.value)}
                                    type="text"
                                    required
                                />
                            </div>
                            <div className="grid gap-2">
                                <p>Entidade emissora {index + 1}</p>
                                <Input
                                    placeholder="Entidade emissora"
                                    value={input.entidadeemissora}
                                    onChange={(e) => handleEntidadeEmissoraChange(index, e.target.value)}
                                    type="text"
                                    required
                                />
                            </div>
                            <div className="grid gap-2">
                                <p>Nº Cartão de Cidadão {index + 1}</p>
                                <Input
                                    placeholder="Nº Cartão de Cidadão"
                                    value={input.cartaodecidadao}
                                    onChange={(e) => handleNumCCSocioChange(index, e.target.value)}
                                    type="text"
                                    required
                                />
                            </div>
                            <div className="grid gap-2">
                                <p>Validade do Cartão de Cidadão {index + 1}</p>
                                {/* <Input
                                    placeholder="Validade do Cartão de Cidadão"
                                    value={input.validadecartaocidadao}
                                    onChange={(e) => handleValidadeCCSocioChange(index, e.target.value)}
                                    type="text"
                                    required
                                /> */}


                                {/* <DatePicker
                                    placeholder="dd/mm/aaaa"
                                    defaultValue={dayjsDate(input.validadecartaocidadao)}
                                    format={'DD/MM/YYYY'}
                                    onChange={(date) => handleValidadeCCSocioChange(index, date)}
                                    required
                                />

                                {alertaDataValidadeCC && <small className="text-red-500">A Validade do Cartão de Cidadão é obrigatória</small>} */}
                                <DatePicker
                                    placeholder="dd/mm/aaaa"
                                    defaultValue={dayjsDate(input.validade_cc)}
                                    format={'DD/MM/YYYY'}
                                    onChange={(date) => handleValidadeCCSocioChange(index, date)}
                                    required
                                />
                                {alertaDataValidadeCC && <small className="text-red-500">A Validade do Cartão de Cidadão é obrigatória</small>}


                            </div>

                            {tipo === "novo_negocio" ? (
                                <div className="grid gap-2">
                                    <p>NIF do Sócio gerente {index + 1}</p>
                                    <Input
                                        placeholder="NIF do Sócio gerente"
                                        value={input.nif}
                                        onChange={(e) => handleNifSocioChange(index, e.target.value)}
                                        type="text"
                                        required
                                    />
                                </div>
                            ) : null}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

