import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

const COLORS = ['#ffe8d4', '#ffbe8f', '#ff9351', '#ff6814', '#e24a00'];

// Custom tooltip component to add Euro symbol
const CustomTooltip = ({ payload, formatarNumero }) => {
    if (payload && payload.length) {
        return (
            <div className="custom-tooltip text-sm text-center">
                {payload[0].name}<br /> <strong>{formatarNumero(payload[0].value)}</strong>
            </div>
        );
    }
    return null;
};

const ChartCombustivel= ({ formatarNumero, combustiveis }) => {

    const data = Object.keys(combustiveis).map(key => ({
        name: key,
        value: combustiveis[key]
    }));

    return (
        <PieChart width={300} height={230}>
            <Pie
                data={data}
                innerRadius={30}
                outerRadius={100}
                paddingAngle={5}
                cornerRadius={5}
                startAngle={0}
                endAngle={180}
                cx={150}
                cy={150}
                dataKey="value"
            >
                {
                    data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))
                }
            </Pie>
            <Tooltip content={<CustomTooltip formatarNumero={formatarNumero} />} />
        </PieChart>
    );
};

export default ChartCombustivel;
