import { List } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function HistoricoDePesquisasPropostas({
    historicoSelecionadodeAcessos
}) {

    const { t } = useTranslation();


    return (
        <>
            <List
                style={{ padding: 0 }}
                size="small"
                dataSource={historicoSelecionadodeAcessos.acessos}
                renderItem={(item) => (
                    <List.Item>
                        <div className='grid grid-cols-2 gap-4 w-full'>
                            <span className='text-xs sm:text-base'>
                                <p className='font-semibold'>{t('historicodeacessos.data_de_visualizacao')}</p>
                            </span>
                            <p className='text-xs text-right sm:text-base'>{item}</p>
                        </div>
                    </List.Item>
                )}
            />
        </>
    );
}
