import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import {
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    FileTextOutlined,
    ContactsOutlined,
    BarChartOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo-branco 2.svg';
import logoIcon from '../../assets/logo-icon-branco 2.svg';
import { useTranslation } from 'react-i18next';
import { GoTools } from "react-icons/go";
import { FaHubspot } from 'react-icons/fa6';
import { PiHouse } from "react-icons/pi";
import { useMediaQuery } from 'react-responsive';
import { LuUsers } from "react-icons/lu";

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = ({ collapsed, currentLanguage, setSearchQuery, userData, setUrlCarroLink, setEstadoDaPartilhaNomeDaProposta }) => {
    
    // Responsive
    // const isMobile = useMediaQuery({ query: '(max-width: 1023px)' });

    const { t } = useTranslation();
    const [openKey, setOpenKey] = useState(null);

    const handleMenuClick = (e) => {
        const { key } = e;
    
        if (key === "2") {
            setUrlCarroLink('');
            setEstadoDaPartilhaNomeDaProposta('')
        }
    
        setSearchQuery('');
        setOpenKey(null);
    };

    return (
        <Sider className="card-sidebar sidebar" trigger={null} collapsible collapsed={collapsed} style={{ position: 'sticky', top: 0 }}>
            <div className="flex justify-center p-2 mt-2 mb-2">
                {collapsed ? (
                    <Link to="/">
                        <img
                            src={logoIcon}
                            alt="Logo"
                            className="w-40 max-w-40 h-10 object-contain cursor-pointer"
                        />
                    </Link>
                ) : (
                    <Link to="/">
                        <img
                            src={logo}
                            alt="Logo"
                            className="w-40 max-w-40 h-10 object-contain cursor-pointer"
                        />
                    </Link>
                )}
            </div>
            <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={['1']}
                openKeys={[openKey]}
                onOpenChange={(keys) => {
                    const latestOpenKey = keys.find((key) => key !== openKey);
                    setOpenKey(latestOpenKey);
                }}
            >
                <Menu.Item
                    className="text-xs font-bold"
                    key="1"
                    icon={<PiHouse />}
                    onClick={handleMenuClick}
                >
                    <Link to="/">{t('header.painel')}</Link>
                </Menu.Item>

                <SubMenu
                    key="propostas"
                    title={t('header.propostas')}
                    icon={<FileTextOutlined />}
                    className="text-xs font-bold capitalize"
                >
                    <Menu.Item key="2" onClick={handleMenuClick}>
                        <Link to={`/${currentLanguage}/${t('header.simulador')}`}>
                            {t('header.Simulador')}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="3" onClick={handleMenuClick}>
                        <Link to={`/${currentLanguage}/${t('header.propostas')}`}>
                            {t('header.Ver Propostas')}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="4" onClick={handleMenuClick}>
                        <Link to={`/${currentLanguage}/${t('header.historico-de-pesquisas')}`}>
                            {t('header.Histórico de Pesquisas')}
                        </Link>
                    </Menu.Item>
                </SubMenu>

                {currentLanguage === 'pt' && (
                    <>
                        <SubMenu
                            key="hubspot"
                            title="Hubspot"
                            icon={<FaHubspot />}
                            className="text-xs font-bold"
                        >
                            <Menu.Item key="5" onClick={handleMenuClick}>
                                <Link to={`/${currentLanguage}/hubspot/consultar-lista-deals`}>
                                    Ver Deals
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="12" onClick={handleMenuClick}>
                                <Link to={`/${currentLanguage}/hubspot/logs-emails`}>
                                    Logs Emails
                                </Link>
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="documentos"
                            title="Ferramentas"
                            icon={<GoTools />}
                            className="text-xs font-bold"
                        >
                            <Menu.Item key="6" onClick={handleMenuClick}>
                                <Link to={`/${currentLanguage}/gerar-documento`}>Gerar Documento</Link>
                            </Menu.Item>
                            <Menu.Item key="7" onClick={handleMenuClick}>
                                <Link to={`/${currentLanguage}/simulador-de-credito`}>Simulador de Crédito</Link>
                            </Menu.Item>
                            {/* <Menu.Item key="12" onClick={handleMenuClick}>
                                <Link to={`/${currentLanguage}/simulador-isv`}>Simulador de ISV</Link>
                            </Menu.Item> */}
                            <Menu.Item key="8" onClick={handleMenuClick}>
                                <Link to={`/${currentLanguage}/assinatura-de-email`}>Gerar Assinatura de Email</Link>
                            </Menu.Item>
                            <Menu.Item key="9" onClick={handleMenuClick}>
                                <Link to={`/${currentLanguage}/ferramentas-suporte`}>Ferramentas de Apoio</Link>
                            </Menu.Item>
                        </SubMenu>
                    </>
                )}

                {userData.roles[0] === "administrator" && (
                    <SubMenu
                        key="utilizadores"
                        title="Utilizadores"
                        icon={<LuUsers />}
                        className="text-xs font-bold"
                    >
                        <Menu.Item key="10" onClick={handleMenuClick}>
                            <Link to="/lista-de-utilizadores">Lista de Utilizadores</Link>
                        </Menu.Item>
                        <Menu.Item key="11" onClick={handleMenuClick}>
                            <Link to="/registar-utilizador">Registar Utilizador</Link>
                        </Menu.Item>
                    </SubMenu>
                )}

            </Menu>
        </Sider>
    );
};

export default Sidebar;
