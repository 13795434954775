import { List } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';


export default function HitoricoDeAcesso({ dataCarros }) {

    const { t } = useTranslation();


    return (
        <>
            <div className='flex justify-between items-center gap-4'>
                <div className='grid gap-1'>
                    <p className='text-lg font-semibold'>Histórico de acessos</p>
                </div>
            </div>
            <hr></hr>

            {dataCarros.ultimos_acessos ? (
                <div className='h-36 overflow-auto pr-2'>
                    <List
                        style={{ padding: 0 }}
                        size="small"
                        dataSource={dataCarros.ultimos_acessos}
                        renderItem={(item) => (
                            <List.Item>
                                <div className='grid grid-cols-2 gap-4 w-full text-white'>
                                    <p className='text-sm text-neutral-500 font-semibold'>{t('historicodeacessos.data_de_visualizacao')}</p>
                                    <p className='text-xs text-right sm:text-base'>{item}</p>
                                </div>
                            </List.Item>
                        )}
                    />
                </div>
            ) : (
                <div className='grid gap-4 w-full text-white'>
                    <p className='text-sm text-neutral-500 font-semibold'>O Cliente ainda não acedeu à proposta.</p>
                </div>
            )}
        </>
    );
}
