import { AutoComplete, Button, Modal, Radio, Table, Select, Spin } from 'antd';
import React, { useState } from 'react';
import axios from 'axios';
import { AlertaMsg } from '../../mensagens/mensagem_alerta';
import logo from '../../../../assets/hubspot.svg';
import { SucessoMsg } from '../../mensagens/mensagem_sucesso';
import { MdOutlineModeEditOutline } from "react-icons/md";
import AssociarDeal from './associar-deal';
import { IoAddCircleOutline } from "react-icons/io5";
import logoIcon from '../../../../assets/hubspot-icon.svg';
import { IoEyeOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';
import { FaHourglass } from "react-icons/fa6";
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;


const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);

export default function InformacoesDeal({
    currentLanguage,
    checkDataHubspot,
    nomeDoDeal,
    nomeDoClienteDeal,
    emailDoClienteDeal,
    dataDeAssociacaoDoDeal,
    etapaDoDeal,
    pipelineDoDeal,
    idDaPropostaEncontrada,
    saveToken,
    iDTokenAuthenticated,
    setIdDaPropostaEncontrada,
    setCheckDataHubspot,
    setNomeDoDeal,
    setNomeDoClienteDeal,
    setEmailDoClienteDeal,
    setDataDeAssociacaoDoDeal,
    setEtapaDoDeal,
    setPipelineDoDeal,
    idDoDealAtual,
    setShowHubspot,
    propostaAceiteAprovada,
    statusDaProposta,
    setStatusDaProposta,
    setLoadingFetchStatusDaProposta,
    loadingFetchStatusDaProposta,
    setIdDoDealAtual,
    setPedidoFetchStatusDaProposta,
    setIsModalOpen,
    isModalOpen
}) {



    const showModal = () => {
        setIsModalOpen(true);
    };


    return (
        <>
            {checkDataHubspot.length !== 0 ? (
                <>
                    <div className='card hubspot-card'>
                        <div className={`${checkDataHubspot.length !== 0 ? 'titulo-form-hubspot mb-6' : ''}`}>
                            <div className={`flex items-center gap-4 ${checkDataHubspot.length !== 0 ? 'pb-4' : ''}`}>
                                <img
                                    src={logo}
                                    alt="Logo"
                                    className="w-24 h-10 object-contain"
                                />
                                <span>|</span>
                                <span>Informação do deal</span>
                            </div>
                        </div>
                        <div className='card-white grid'>
                            <div>
                                {nomeDoClienteDeal && (
                                    <p className="text-black text-base flex justify-between">
                                        Cliente: <span className="font-bold capitalize">{nomeDoClienteDeal}</span>
                                    </p>
                                )}
                                {emailDoClienteDeal && (
                                    <p className="text-black text-base flex justify-between">
                                        Email: <span className="font-bold">{emailDoClienteDeal}</span>
                                    </p>
                                )}
                                {nomeDoDeal && (
                                    <p className="text-black text-base flex justify-between">
                                        Negócio: <span className="font-bold">{nomeDoDeal}</span>
                                    </p>
                                )}
                                {dataDeAssociacaoDoDeal && (
                                    <p className="text-black text-base flex justify-between">
                                        Data de Associação: <span className="font-bold">{dataDeAssociacaoDoDeal}</span>
                                    </p>
                                )}
                                {etapaDoDeal && (
                                    <p className="text-black text-base flex justify-between">
                                        Etapa: <span className="font-bold">{etapaDoDeal}</span>
                                    </p>
                                )}
                                {pipelineDoDeal && (
                                    <p className="text-black text-base flex justify-between">
                                        Pipeline: <span className="font-bold">{pipelineDoDeal}</span>
                                    </p>
                                )}

                            </div>
                            <div className="border-t-4 pt-2 mt-2 flex gap-2 justify-end">
                                <Button
                                    type="primary"
                                    target="_blank"
                                    className="flex gap-2 items-center bg-white text-black font-bold rounded"
                                    onClick={showModal}
                                >
                                    <MdOutlineModeEditOutline />
                                    Alterar associação
                                </Button>
                                <Link to={`/${currentLanguage}/hubspot/perfil-deal/?id_deal=${checkDataHubspot.deal.id_deal_hubspot}`}>
                                    <Button
                                        type="primary"
                                        target="_blank"
                                        className="flex gap-2 items-center bg-white text-black font-bold rounded"
                                    >
                                        <IoEyeOutline />
                                        Ver Perfil
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
}
